import {
  getApiProjectsProjectIdIssuesIssueIdFeedPublic,
  getApiProjectsProjectIdIssuesIssueIdFeedTeam,
} from '@shape-construction/api/src/api';
import {
  getApiProjectsProjectIdIssuesIssueIdFeedPublicQueryOptions,
  getApiProjectsProjectIdIssuesIssueIdFeedTeamQueryOptions,
} from '@shape-construction/api/src/hooks';
import { infiniteQueryOptions } from '@tanstack/react-query';
import { environment } from 'app/config/environment';

export type Channel = 'public' | 'team';

const getProjectFeedInfiniteQueryOptions = {
  public: getApiProjectsProjectIdIssuesIssueIdFeedPublicQueryOptions,
  team: getApiProjectsProjectIdIssuesIssueIdFeedTeamQueryOptions,
};
const getQueryFn = {
  public: getApiProjectsProjectIdIssuesIssueIdFeedPublic,
  team: getApiProjectsProjectIdIssuesIssueIdFeedTeam,
};

export const getInfinityIssueFeedQueryOptions = (
  ...args: [Channel, ...Parameters<(typeof getQueryFn)[keyof typeof getQueryFn]>]
) => {
  const [channel, projectId, issueId, params, config] = args;
  const queryFn = getQueryFn[channel];
  const options = getProjectFeedInfiniteQueryOptions[channel](projectId, issueId, params);

  return infiniteQueryOptions({
    queryKey: options.queryKey,
    queryFn: ({ signal, pageParam }) => {
      return queryFn(
        projectId,
        issueId,
        {
          ...params,
          ...pageParam,
          after: pageParam?.after ?? params?.after,
          page_size: Number(environment.FEED_EVENTS_BATCH_NUMBER!),
        },
        { ...config, signal }
      );
    },
    getNextPageParam: (lastPage) => {
      const { hasNextPage, lastEntryCursor } = lastPage.meta;
      return hasNextPage ? { after: lastEntryCursor } : undefined;
    },
    getPreviousPageParam: (firstPage) => {
      const { hasPreviousPage, firstEntryCursor } = firstPage.meta;
      return hasPreviousPage ? { after: firstEntryCursor } : undefined;
    },
    initialPageParam: { after: null as string | null },
  });
};
