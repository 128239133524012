import { useMessageGetter } from '@messageformat/react';
import type { IssueStateSchema } from '@shape-construction/api/src/types';
import { useStableCallback } from '@shape-construction/hooks';
import { useSuspenseQuery } from '@tanstack/react-query';
import { stateObjectFromState } from 'app/components/IssueStateInformation/IssueStateInformation';
import { type ImpactType, getImpactRecord } from 'app/constants/Impact';
import { ISSUE_STATES } from 'app/constants/IssueStates';
import { getProjectCustomFieldsQueryOptions } from 'app/queries/projects/custom.fields';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import type { TabOption } from '../../IssueList/issue-tab-options';

export type IssueGroupKey =
  | 'user_issues'
  | 'team_issues'
  | 'discipline'
  | 'impact'
  | 'location'
  | 'responsible'
  | 'responsible_team'
  | 'state'
  | 'custom_field';

export type IssueGroupOptionKey = IssueGroupKey | 'custom' | 'none';

export type StatusFields = ImpactType | IssueStateSchema | 'default';

export type IssueSubGroupOption = {
  key: `${Extract<IssueGroupKey, 'custom_field'>}_${number}`;
  parentKey: Extract<IssueGroupKey, 'custom_field'>;
  name: string;
  value?: string;
};

export type IssueGroupOption = {
  key: IssueGroupOptionKey;
  name: string;
  description?: string;
  label?: string;
  customOrdering?: StatusFields[];
  customNaming?: (groupLabel?: string | undefined) => Partial<Record<StatusFields, string>>;
  subGroupOptions?: IssueSubGroupOption[];
};

export const defaultCollapsed: Record<TabOption, IssueStateSchema[]> = {
  myIssues: [],
  drafts: [],
  all: [],
  archived: [],
};

export const defaultTabGrouping: Record<TabOption, IssueGroupKey[]> = {
  drafts: [],
  all: ['state'],
  archived: [],
  myIssues: ['state'],
};

export type Params = {
  projectId: string;
};

export const useGroupingOptions = () => {
  const { projectId } = useParams() as Params;
  const groupMessageGetter = useMessageGetter('issue.options.groupBy');
  const issueDetailMessageGetter = useMessageGetter('issue.detail');
  const impactRecord = getImpactRecord(issueDetailMessageGetter);
  const {
    data: { customFields },
  } = useSuspenseQuery(getProjectCustomFieldsQueryOptions(projectId));

  const groupByKeys: IssueGroupKey[] = [
    'user_issues',
    'team_issues',
    'discipline',
    'impact',
    'location',
    'responsible',
    'responsible_team',
    'state',
    'custom_field',
  ];

  const groupByOptions: Record<IssueGroupOptionKey, IssueGroupOption> = useMemo(
    () => ({
      impact: {
        key: 'impact',
        name: groupMessageGetter('impact'),
        customOrdering: [
          impactRecord.liveDelay.value,
          impactRecord.potentialDelay.value,
          impactRecord.completedDelay.value,
          impactRecord.noEffect.value,
          'default',
        ],
        customNaming: () => ({
          liveDelay: impactRecord.liveDelay.groupingOptionLabel,
          potentialDelay: impactRecord.potentialDelay.groupingOptionLabel,
          completedDelay: impactRecord.completedDelay.groupingOptionLabel,
          noEffect: impactRecord.noEffect.groupingOptionLabel,
          default: impactRecord.notEntered.groupingOptionLabel,
        }),
      },
      responsible: {
        key: 'responsible',
        name: groupMessageGetter('responsiblePerson'),
        customNaming: () => ({
          default: 'Unassigned',
        }),
      },
      responsible_team: {
        key: 'responsible_team',
        name: groupMessageGetter('responsibleTeam'),
        customNaming: () => ({
          default: 'Unassigned',
        }),
      },
      user_issues: {
        key: 'user_issues',
        name: groupMessageGetter('originator'),
        customNaming: () => ({
          default: 'Unassigned',
        }),
      },
      team_issues: {
        key: 'team_issues',
        name: groupMessageGetter('originatorTeam'),
        customNaming: () => ({
          default: 'Unassigned',
        }),
      },
      discipline: {
        key: 'discipline',
        name: groupMessageGetter('discipline'),
        customNaming: () => ({
          default: 'Not set',
        }),
      },
      location: {
        key: 'location',
        name: groupMessageGetter('location'),
      },
      state: {
        key: 'state',
        name: groupMessageGetter('state'),
        customOrdering: [
          'assignment_rejected', // Unassigned
          'assignment_requested', // Pending Responsible
          'draft', // Draft
          'assigned', // Not started (formerly Outstanding)
          'in_progress', // Started (formerly In progress)
          'completed', // Pending Approval
          'resolved', // Resolved
        ],
        customNaming: () => ({
          assignment_rejected: stateObjectFromState(ISSUE_STATES.ASSIGNMENT_REJECTED).displayedState,
          assignment_requested: stateObjectFromState(ISSUE_STATES.ASSIGNMENT_REQUESTED).displayedState,
          in_progress: stateObjectFromState(ISSUE_STATES.IN_PROGRESS).displayedState,
          assigned: stateObjectFromState(ISSUE_STATES.ASSIGNED).displayedState,
          resolved: stateObjectFromState(ISSUE_STATES.RESOLVED).displayedState,
          draft: stateObjectFromState(ISSUE_STATES.DRAFT).displayedState,
          completed: stateObjectFromState(ISSUE_STATES.COMPLETED).displayedState,
          default: 'Other',
        }),
      },
      custom_field: {
        key: 'custom_field',
        name: groupMessageGetter('customField'),
        customNaming: (groupLabel) => ({
          default: 'Not set',
        }),
        subGroupOptions: customFields.map((customField) => ({
          key: `custom_field_${customField.id}`,
          parentKey: 'custom_field',
          name: customField.label,
          value: customField.id,
        })) as IssueSubGroupOption[],
      },
      custom: {
        key: 'custom',
        name: groupMessageGetter('custom.title'),
        description: groupMessageGetter('custom.description'),
      },
      none: {
        key: 'none',
        name: groupMessageGetter('none'),
      },
    }),
    [
      customFields,
      groupMessageGetter,
      impactRecord.completedDelay.groupingOptionLabel,
      impactRecord.completedDelay.value,
      impactRecord.liveDelay.groupingOptionLabel,
      impactRecord.liveDelay.value,
      impactRecord.noEffect.groupingOptionLabel,
      impactRecord.noEffect.value,
      impactRecord.notEntered.groupingOptionLabel,
      impactRecord.potentialDelay.groupingOptionLabel,
      impactRecord.potentialDelay.value,
    ]
  );

  const getOption = useStableCallback((optionKey: IssueGroupOptionKey) => groupByOptions[optionKey]);

  return { groupByOptions, groupByKeys, getOption };
};
