import * as React from 'react';
import type { SVGProps } from 'react';
const InformationCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/information-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 11.9999C21.6 17.3018 17.302 21.5999 12 21.5999C6.69809 21.5999 2.40002 17.3018 2.40002 11.9999C2.40002 6.69797 6.69809 2.3999 12 2.3999C17.302 2.3999 21.6 6.69797 21.6 11.9999ZM13.2 7.1999C13.2 7.86264 12.6628 8.3999 12 8.3999C11.3373 8.3999 10.8 7.86264 10.8 7.1999C10.8 6.53716 11.3373 5.9999 12 5.9999C12.6628 5.9999 13.2 6.53716 13.2 7.1999ZM10.8 10.7999C10.303 10.7999 9.90002 11.2028 9.90002 11.6999C9.90002 12.197 10.303 12.5999 10.8 12.5999H11.1041C11.296 12.5999 11.4386 12.7776 11.3969 12.965L10.846 15.4443C10.5545 16.7558 11.5525 17.9999 12.896 17.9999H13.2C13.6971 17.9999 14.1 17.597 14.1 17.0999C14.1 16.6028 13.6971 16.1999 13.2 16.1999H12.896C12.704 16.1999 12.5615 16.0222 12.6031 15.8348L13.1541 13.3555C13.4455 12.044 12.4476 10.7999 11.1041 10.7999H10.8Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default InformationCircleIcon;
