import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chevron-right">
      <path
        id="Icon"
        d="M16.2864 12.6365C16.6379 12.285 16.6379 11.7152 16.2864 11.3637L9.7864 4.8637C9.43492 4.51223 8.86508 4.51223 8.5136 4.8637C8.16213 5.21517 8.16213 5.78502 8.5136 6.13649L14.3772 12.0001L8.5136 17.8637C8.16213 18.2152 8.16213 18.785 8.5136 19.1365C8.86508 19.488 9.43492 19.488 9.7864 19.1365L16.2864 12.6365Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChevronRightIcon;
