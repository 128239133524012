import * as React from 'react';
import type { SVGProps } from 'react';
const BellAlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/bell-alert">
      <g id="Icon">
        <path
          d="M5.05735 3.87262C5.37391 3.48941 5.31988 2.92213 4.93666 2.60557C4.55345 2.289 3.98616 2.34304 3.6696 2.72625C2.59047 4.03261 1.81193 5.59836 1.44186 7.31688C1.33723 7.8028 1.64631 8.28154 2.13223 8.38617C2.61815 8.49081 3.09689 8.18172 3.20153 7.69581C3.50935 6.26637 4.15715 4.96237 5.05735 3.87262Z"
          fill="currentColor"
        />
        <path
          d="M20.33 2.72625C20.0135 2.34304 19.4462 2.289 19.063 2.60557C18.6797 2.92213 18.6257 3.48941 18.9423 3.87262C19.8425 4.96237 20.4903 6.26637 20.7981 7.69581C20.9027 8.18172 21.3815 8.49081 21.8674 8.38617C22.3533 8.28154 22.6624 7.8028 22.5578 7.31688C22.1877 5.59836 21.4092 4.03261 20.33 2.72625Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.40044C8.02353 2.40044 4.79998 5.62399 4.79998 9.60044C4.79998 11.8644 4.25551 13.9986 3.29115 15.8816C3.16312 16.1316 3.15915 16.427 3.2804 16.6804C3.40166 16.9337 3.63424 17.116 3.90925 17.1731C5.19047 17.4391 6.4942 17.6432 7.81714 17.7821C8.00996 19.9228 9.80908 21.6004 12 21.6004C14.191 21.6004 15.9901 19.9228 16.1829 17.7821C17.5058 17.6432 18.8095 17.4391 20.0907 17.1731C20.3657 17.116 20.5983 16.9337 20.7196 16.6804C20.8408 16.427 20.8368 16.1316 20.7088 15.8816C19.7445 13.9986 19.2 11.8644 19.2 9.60044C19.2 5.62399 15.9764 2.40044 12 2.40044ZM12 19.8004C10.8573 19.8004 9.90119 19.0019 9.65918 17.9324C10.4338 17.9775 11.2142 18.0004 12 18.0004C12.7858 18.0004 13.5663 17.9775 14.3409 17.9324C14.0989 19.0019 13.1427 19.8004 12 19.8004Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default BellAlertIcon;
