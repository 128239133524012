import { infiniteQueryOptions, queryOptions, useQueryClient } from '@tanstack/react-query';

import { getApiNotifications } from '@shape-construction/api/src/api';
import {
  getApiNotificationsOverviewQueryOptions,
  getApiNotificationsQueryOptions,
  usePostApiNotificationsMarkAllRead,
  usePostApiNotificationsNotificationIdMarkRead,
} from '@shape-construction/api/src/hooks';

import { environment } from 'app/config/environment';

// Queries
export const getNotificationsOverviewQueryOptions = () => {
  return queryOptions({
    ...getApiNotificationsOverviewQueryOptions(),
    refetchInterval: Number(environment.NOTIFICATIONS_CHECK_INTERVAL_MS),
    refetchOnWindowFocus: false,
  });
};

export const getNotificationsInfiniteQueryOptions = (...args: Parameters<typeof getApiNotifications>) => {
  const [params, options] = args;

  return infiniteQueryOptions({
    queryKey: getApiNotificationsQueryOptions(params).queryKey,
    queryFn: ({ pageParam, signal }) => getApiNotifications({ ...params, ...pageParam }, { ...options, signal }),
    getNextPageParam: ({ meta }) => (meta.currentPage !== meta.totalPages ? { page: meta.currentPage + 1 } : undefined),
    refetchInterval: Number(environment.NOTIFICATIONS_CHECK_INTERVAL_MS),
    refetchOnWindowFocus: false,
    initialPageParam: { page: undefined as undefined | number },
  });
};

// Mutations
export const useNotificationMarkReadMutation = () => {
  const queryClient = useQueryClient();

  return usePostApiNotificationsNotificationIdMarkRead({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getApiNotificationsQueryOptions());
        queryClient.invalidateQueries(getApiNotificationsOverviewQueryOptions());
      },
    },
  });
};

export const useNotificationMarkReadAllMutation = () => {
  const queryClient = useQueryClient();

  return usePostApiNotificationsMarkAllRead({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getApiNotificationsQueryOptions());
        queryClient.invalidateQueries(getApiNotificationsOverviewQueryOptions());
      },
    },
  });
};
