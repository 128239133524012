import * as React from 'react';
import type { SVGProps } from 'react';
const DragIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/drag">
      <g id="Icon">
        <path
          d="M15 3.6001C15.9941 3.6001 16.8 4.40598 16.8 5.4001C16.8 6.39421 15.9941 7.2001 15 7.2001C14.0058 7.2001 13.2 6.39421 13.2 5.4001C13.2 4.40598 14.0058 3.6001 15 3.6001Z"
          fill="currentColor"
        />
        <path
          d="M15 10.2001C15.9941 10.2001 16.8 11.006 16.8 12.0001C16.8 12.9942 15.9941 13.8001 15 13.8001C14.0058 13.8001 13.2 12.9942 13.2 12.0001C13.2 11.006 14.0058 10.2001 15 10.2001Z"
          fill="currentColor"
        />
        <path
          d="M16.8 18.6001C16.8 17.606 15.9941 16.8001 15 16.8001C14.0058 16.8001 13.2 17.606 13.2 18.6001C13.2 19.5942 14.0058 20.4001 15 20.4001C15.9941 20.4001 16.8 19.5942 16.8 18.6001Z"
          fill="currentColor"
        />
        <path
          d="M8.99995 3.6001C9.99406 3.6001 10.8 4.40598 10.8 5.4001C10.8 6.39421 9.99406 7.2001 8.99995 7.2001C8.00584 7.2001 7.19995 6.39421 7.19995 5.4001C7.19995 4.40598 8.00584 3.6001 8.99995 3.6001Z"
          fill="currentColor"
        />
        <path
          d="M8.99995 10.2001C9.99406 10.2001 10.8 11.006 10.8 12.0001C10.8 12.9942 9.99406 13.8001 8.99995 13.8001C8.00584 13.8001 7.19995 12.9942 7.19995 12.0001C7.19995 11.006 8.00584 10.2001 8.99995 10.2001Z"
          fill="currentColor"
        />
        <path
          d="M10.8 18.6001C10.8 17.606 9.99406 16.8001 8.99995 16.8001C8.00584 16.8001 7.19995 17.606 7.19995 18.6001C7.19995 19.5942 8.00584 20.4001 8.99995 20.4001C9.99406 20.4001 10.8 19.5942 10.8 18.6001Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default DragIcon;
