import * as React from 'react';
import type { SVGProps } from 'react';
const FolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/folder">
      <g id="Icon">
        <path
          d="M4.50002 3.6001C3.34023 3.6001 2.40002 4.5403 2.40002 5.7001V9.61319C3.0062 9.2251 3.72684 9.0001 4.50002 9.0001H19.5C20.2732 9.0001 20.9938 9.22509 21.6 9.61319V8.1001C21.6 6.9403 20.6598 6.0001 19.5 6.0001H13.6971C13.6175 6.0001 13.5412 5.96849 13.485 5.91223L11.7879 4.21517C11.3941 3.82135 10.8599 3.6001 10.303 3.6001H4.50002Z"
          fill="currentColor"
        />
        <path
          d="M4.50002 10.8001C3.34023 10.8001 2.40002 11.7403 2.40002 12.9001V18.3001C2.40002 19.4599 3.34023 20.4001 4.50002 20.4001H19.5C20.6598 20.4001 21.6 19.4599 21.6 18.3001V12.9001C21.6 11.7403 20.6598 10.8001 19.5 10.8001H4.50002Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default FolderIcon;
