import { useMessageGetter } from '@messageformat/react';
import { Alert, Button } from '@shape-construction/arch-ui';
import { atom, useAtom } from 'jotai';
import React from 'react';
import { NavLink } from 'react-router';

const enableNewPasswordPolicyBanner = atom(true);

export const NewPasswordPolicyBanner = () => {
  const [showNewPasswordPolicyBanner, setShowNewPasswordPolicyBanner] = useAtom(enableNewPasswordPolicyBanner);
  const messages = useMessageGetter('newPasswordPolicyBanner');

  if (!showNewPasswordPolicyBanner) {
    return null;
  }

  return (
    <Alert color="discovery" justifyContent="start" displayInRow format="floating">
      <Alert.Message>
        {messages('message')}{' '}
        <a
          href="https://help.shape.construction/hc/en-us/articles/26093771528861-Changing-your-Password"
          target="_blank"
          className="underline font-semibold"
          rel="noreferrer"
        >
          {messages('link')}
        </a>
      </Alert.Message>
      <Alert.Actions>
        <Button color="discovery" variant="outlined" size="xs" onClick={() => setShowNewPasswordPolicyBanner(false)}>
          {messages('laterButton')}
        </Button>
        <NavLink to={'/my-profile/password'}>
          <Button color="discovery" variant="contained" size="xs">
            {messages('updateButton')}
          </Button>
        </NavLink>
      </Alert.Actions>
    </Alert>
  );
};
