import * as React from 'react';
import type { SVGProps } from 'react';
const PlayCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/play-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40002 12.0004C2.40002 6.69846 6.69809 2.40039 12 2.40039C17.302 2.40039 21.6 6.69846 21.6 12.0004C21.6 17.3023 17.302 21.6004 12 21.6004C6.69809 21.6004 2.40002 17.3023 2.40002 12.0004ZM10.0688 8.51045C10.3577 8.35269 10.7098 8.3653 10.9867 8.54333L15.1867 11.2433C15.4443 11.4089 15.6 11.6942 15.6 12.0004C15.6 12.3066 15.4443 12.5919 15.1867 12.7575L10.9867 15.4575C10.7098 15.6355 10.3577 15.6481 10.0688 15.4903C9.77979 15.3326 9.60003 15.0296 9.60003 14.7004V9.30039C9.60003 8.97117 9.77979 8.66821 10.0688 8.51045Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PlayCircleIcon;
