import { useMediaQuery } from '@shape-construction/hooks';
import React, { type ComponentProps } from 'react';
import ModalBase from '../ModalBase';
import { breakpoints } from '../utils/breakpoints';
import { cn } from '../utils/classes';

export const DrawerRoot = ({
  children,
  maxWidth = 'md',
  ...drawerProps
}: Pick<
  ComponentProps<typeof ModalBase.Root>,
  'open' | 'onClose' | 'maxWidth' | 'zIndex' | 'transition' | 'children'
>) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  return (
    <ModalBase.Root
      transition="right-to-left"
      {...drawerProps}
      fullWidth
      maxWidth={isLargeScreen ? maxWidth : 'none'}
      fullScreen={!isLargeScreen}
      className="fixed inset-y-0 right-0"
    >
      {children}
    </ModalBase.Root>
  );
};

export const DrawerPersistent = ({ open, ...props }: Pick<ComponentProps<'dialog'>, 'open' | 'children'>) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  if (!open) return null;

  return (
    <dialog
      open
      className={cn('static p-0 h-full flex flex-col bg-white shadow', {
        'w-[448px]': isLargeScreen,
        'w-full fixed inset-0 z-modal': !isLargeScreen,
      })}
      {...props}
    />
  );
};

export const DrawerTitle = ModalBase.Title;
export const DrawerSubtitle = ModalBase.SubTitle;

export const DrawerHeader = ({ className, ...props }: ComponentProps<typeof ModalBase.Header>) => (
  <ModalBase.Header bottomBorder {...props} className={cn('min-h-fit px-6', className)} {...props} />
);

export const DrawerFooter = ({ className, ...props }: ComponentProps<typeof ModalBase.Footer>) => (
  <ModalBase.Footer topBorder {...props} className={cn('flex flex-row gap-7 p-6', className)} />
);

export const DrawerContent = ({ className, ...props }: ComponentProps<typeof ModalBase.Content>) => (
  <ModalBase.Content {...props} className={cn('px-0', className)} />
);
