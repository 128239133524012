import { useMessageGetter } from '@messageformat/react';
import React from 'react';

export interface PushNotificationBannerProps {
  open: boolean;
  onClick: () => void;
}

export const PushNotificationBanner: React.FC<PushNotificationBannerProps> = ({ open, onClick }) => {
  const message = useMessageGetter('notifications.permission.banner');
  const onEnableNotifications = (event: any) => {
    event.preventDefault();
    onClick();
  };

  if (open !== true) return null;

  return (
    <div
      data-testid="res-notification-permission-bar"
      className="bg-warning-subtlest text-link-warning border border-warning-subtlest "
    >
      <div className="py-1.5 px-6 text-sm leading-5 font-normal">
        {message('title')}{' '}
        <button
          type="button"
          data-testid="res-notification-permission-link"
          onClick={onEnableNotifications}
          className=" font-medium underline"
        >
          {message('link')}
        </button>
      </div>
    </div>
  );
};
