import {
  getApiProjectsProjectIdTeamsQueryKey,
  getApiProjectsProjectIdTeamsQueryOptions,
  getApiProjectsProjectIdTeamsTeamIdQueryOptions,
  useDeleteApiProjectsProjectIdTeamsTeamId,
  useGetApiProjectsProjectIdTeamsTeamId,
  useGetApiTeamJoinTokensToken,
  usePatchApiProjectsProjectIdTeamsTeamId,
  usePostApiProjectsProjectIdTeams,
} from '@shape-construction/api/src/hooks';
import type { IssueGroupCountEntitySchema, ProjectSchema } from '@shape-construction/api/src/types';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

export const getProjectTeamsQueryOptions = getApiProjectsProjectIdTeamsQueryOptions;

/**
 * @deprecated Use getProjectTeamQueryOptions instead
 */
export const useProjectTeam = useGetApiProjectsProjectIdTeamsTeamId;
export const getProjectTeamQueryOptions = getApiProjectsProjectIdTeamsTeamIdQueryOptions;

/**
 * @deprecated Use getProjectTeamQueryOptions instead
 */
export const useTeamGetter = (projectId: ProjectSchema['id']) => {
  const { data } = useSuspenseQuery(getProjectTeamsQueryOptions(projectId));

  return useCallback(
    (teamId: IssueGroupCountEntitySchema['identifier']) => data?.find(({ id }) => id === teamId),
    [data]
  );
};

export const useTeamJoinToken = useGetApiTeamJoinTokensToken;

// mutations
export const useCreateTeam = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdTeams({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdTeamsQueryKey(variables.projectId),
        });
      },
    },
  });
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdTeamsTeamId({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdTeamsQueryKey(variables.projectId),
        });
      },
    },
  });
};

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdTeamsTeamId({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdTeamsQueryKey(variables.projectId),
        });
      },
    },
  });
};
