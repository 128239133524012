import { useMediaQuery } from '@shape-construction/hooks';
import React, { type ElementType } from 'react';
import { breakpoints } from '../utils/breakpoints';
import { cn } from '../utils/classes';
import { useSelectContext } from './SelectContext';
import { SelectPanel, type SelectPanelProps } from './SelectPanel';

export type SelectResponsivePanelProps<TTag extends ElementType> = SelectPanelProps<TTag>;
export function SelectResponsivePanel<TTag extends ElementType>({
  className,
  ...props
}: SelectResponsivePanelProps<TTag>) {
  const { open } = useSelectContext();
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  // For small screens, fixed will be applied and the style will be clean so we can position
  // in the bottom
  const positionRules = {
    strategy: isLargeScreen ? 'absolute' : 'fixed',
    style: isLargeScreen ? undefined : {},
  } as const;

  return (
    <>
      <div
        data-testid="select-backdrop"
        className={cn(
          'fixed h-screen w-screen left-0 top-0 bg-neutral-bolder opacity-75 md:opacity-0 backdrop-filter overflow-none z-popover',
          { hidden: !open }
        )}
      />
      <SelectPanel
        {...props}
        {...positionRules}
        className={cn(
          { 'fixed left-0 bottom-0 rounded-none rounded-t-lg max-h-[80%]': !isLargeScreen },
          'md:absolute md:rounded-md md:bottom-auto md:max-h-72',
          className
        )}
      />
    </>
  );
}
SelectResponsivePanel.displayName = 'Select.ResponsivePanel';
