import type {
  GetApiProjectsProjectIdIssuesQueryParamsSchema,
  IssueViewGroupPropertySchema,
} from '@shape-construction/api/src/types';
import type { SortingOption } from './IssueList/IssueSortingOptions';
import type { TabOption } from './IssueList/issue-tab-options';
import type { IssueGroupKey } from './IssuesFilters/hooks/useGroupingOptions';

export const tabIds: Record<TabOption, TabOption> = {
  all: 'all',
  drafts: 'drafts',
  archived: 'archived',
  myIssues: 'myIssues',
};

export const tabHref: Record<TabOption, string> = {
  all: 'all',
  drafts: 'unpublished',
  archived: 'archived',
  myIssues: 'my-issues',
};

export type ScreenViewType = {
  id: TabOption;
  to: string;
  name: string;
  defaultGrouping: IssueGroupKey[];
  defaultGroupingProperties: IssueViewGroupPropertySchema[];
  defaultSorting: SortingOption;
  queryParams: GetApiProjectsProjectIdIssuesQueryParamsSchema;
  showExportButton?: boolean;
  content: JSX.Element;
  badge?: number | null;
};
