import * as React from 'react';
import type { SVGProps } from 'react';
const PrinterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/printer">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00002 3.3002C6.00002 2.1404 6.94023 1.2002 8.10003 1.2002H15.9C17.0598 1.2002 18 2.1404 18 3.3002V7.5002C18 7.52099 17.9997 7.54171 17.9991 7.56235C18.4519 7.6174 18.9027 7.6786 19.3516 7.74586C20.6696 7.94336 21.6 9.08585 21.6 10.3838V15.3002C21.6 16.7914 20.3912 18.0002 18.9 18.0002H18.6104L18.9766 20.3809C19.1723 21.653 18.1881 22.8002 16.9011 22.8002H7.09852C5.81146 22.8002 4.82723 21.653 5.02294 20.3809L5.3892 18.0002H5.10002C3.60886 18.0002 2.40002 16.7914 2.40002 15.3002V10.3838C2.40002 9.08585 3.33048 7.94336 4.64844 7.74586C5.09733 7.6786 5.54819 7.6174 6.00093 7.56235C6.00033 7.54171 6.00002 7.52099 6.00002 7.5002V3.3002ZM16.2 7.377C14.8154 7.25992 13.4146 7.2002 12 7.2002C10.5855 7.2002 9.18465 7.25992 7.80002 7.377V3.3002C7.80002 3.13451 7.93434 3.0002 8.10003 3.0002H15.9C16.0657 3.0002 16.2 3.13451 16.2 3.3002V7.377ZM7.92929 15.0002C7.78122 15.0002 7.6553 15.1082 7.63278 15.2546L6.80201 20.6546C6.77405 20.8363 6.91466 21.0002 7.09852 21.0002H16.9011C17.0849 21.0002 17.2255 20.8363 17.1976 20.6546L16.3668 15.2546C16.3443 15.1082 16.2184 15.0002 16.0703 15.0002H7.92929Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PrinterIcon;
