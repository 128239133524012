/**
 * Generated by kubb.
 * Do not edit manually.
 * Shape API
 */

export const teamMemberStatusEnum = {
  joined: 'joined',
  invited: 'invited',
  archived: 'archived',
} as const;

export type TeamMemberStatusEnumSchema = (typeof teamMemberStatusEnum)[keyof typeof teamMemberStatusEnum];

export type TeamMemberStatusSchema = TeamMemberStatusEnumSchema;
