import * as React from 'react';
import type { SVGProps } from 'react';
const NoSymbolIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/no-symbol">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.15756 5.88514L18.1153 16.8429C19.1707 15.5122 19.8 13.8304 19.8 12.0004C19.8 7.69257 16.3078 4.20039 12 4.20039C10.17 4.20039 8.48826 4.82974 7.15756 5.88514ZM16.8425 18.1156L5.88477 7.15793C4.82937 8.48862 4.20002 10.1704 4.20002 12.0004C4.20002 16.3082 7.6922 19.8004 12 19.8004C13.83 19.8004 15.5118 19.171 16.8425 18.1156ZM5.2118 5.21217C6.94807 3.47589 9.34931 2.40039 12 2.40039C17.302 2.40039 21.6 6.69846 21.6 12.0004C21.6 14.6511 20.5245 17.0523 18.7883 18.7886C17.052 20.5249 14.6507 21.6004 12 21.6004C6.69809 21.6004 2.40002 17.3023 2.40002 12.0004C2.40002 9.34967 3.47553 6.94844 5.2118 5.21217Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default NoSymbolIcon;
