import * as React from 'react';
import type { SVGProps } from 'react';
const EyeSlashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/eye-slash">
      <g id="Icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.9364 2.66351C3.58492 2.31203 3.01508 2.31203 2.6636 2.66351C2.31213 3.01498 2.31213 3.58483 2.6636 3.9363L20.0636 21.3363C20.4151 21.6878 20.9849 21.6878 21.3364 21.3363C21.6879 20.9848 21.6879 20.415 21.3364 20.0635L19.2422 17.9693C21.0077 16.6312 22.3914 14.8157 23.2012 12.715C23.3777 12.2571 23.3779 11.7494 23.2016 11.2915C21.4704 6.79251 17.1072 3.5999 11.9983 3.5999C9.93849 3.5999 7.99988 4.11889 6.30612 5.03323L3.9364 2.66351ZM9.30232 8.02943L10.6124 9.33952C11.0274 9.12258 11.4995 8.9999 12.0002 8.9999C13.6571 8.9999 15.0002 10.343 15.0002 11.9999C15.0002 12.5006 14.8775 12.9727 14.6606 13.3877L15.9709 14.698C16.4944 13.9291 16.8004 13.0003 16.8004 11.9999C16.8004 9.34894 14.6514 7.1999 12.0004 7.1999C11.0001 7.1999 10.0712 7.50591 9.30232 8.02943Z"
          fill="currentColor"
        />
        <path
          d="M12.8981 16.7161L15.9253 19.7434C14.6955 20.1688 13.375 20.3999 12.0004 20.3999C6.89157 20.3999 2.52837 17.2073 0.797106 12.7084C0.62087 12.2504 0.621022 11.7427 0.797532 11.2848C1.38724 9.75508 2.2813 8.37659 3.40549 7.22353L7.28423 11.1023C7.22923 11.3931 7.20045 11.6931 7.20045 11.9999C7.20045 14.6509 9.34948 16.7999 12.0004 16.7999C12.3072 16.7999 12.6073 16.7711 12.8981 16.7161Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default EyeSlashIcon;
