import { useMediaQuery } from '@shape-construction/hooks';
import React, { type ComponentProps, useMemo } from 'react';
import { getChildByType } from 'react-nanny';
import type { ButtonProps } from '../Button/Button';
import ModalBase from '../ModalBase';
import { breakpoints } from '../utils/breakpoints';
import { cn } from '../utils/classes';

export const ConfirmationModalRoot = ({
  className,
  ...props
}: Pick<ComponentProps<typeof ModalBase.Root>, 'children' | 'className' | 'initialFocus' | 'open' | 'onClose'>) => (
  <ModalBase.Root maxWidth="md" roundBorders className={cn('p-4', className)} {...props} />
);

export const ConfirmationModalHeader = ({
  children,
  className,
  centered,
  ...props
}: ComponentProps<typeof ModalBase.Header> & { centered?: boolean }) => {
  const slots = useMemo(
    () => ({
      Image: getChildByType(children, ConfirmationModalImage),
      Title: getChildByType(children, ConfirmationModalTitle),
      SubTitle: getChildByType(children, ConfirmationModalSubTitle),
    }),
    [children]
  );

  const classes = cn(
    'flex flex-col items-center space-y-3 pb-3',
    {
      'md:flex-row md:items-start md:space-x-4 md:space-y-0': !centered,
      'pl-16': centered && props.onClose,
    },
    className
  );

  const titleSubtitleClasses = cn('text-center', {
    'md:text-left': !centered,
  });

  return (
    <ModalBase.Header className={classes} {...props}>
      {slots.Image}
      <div className={titleSubtitleClasses}>
        {slots.Title}
        {slots.SubTitle}
      </div>
    </ModalBase.Header>
  );
};

export const ConfirmationModalSubTitle = ModalBase.SubTitle;

export const ConfirmationModalTitle = ({ className, ...props }: ComponentProps<typeof ModalBase.Title>) => (
  <ModalBase.Title className={cn('text-lg leading-6 font-medium', className)} {...props} />
);

export const ConfirmationModalImage = ({ className, ...props }: ComponentProps<'div'>) => (
  <div className={cn('flex-none shrink-0', className)} {...props} />
);

export const ConfirmationModalContent = ({
  className,
  centered,
  ...props
}: ComponentProps<typeof ModalBase.Content> & { centered?: boolean }) => (
  <ModalBase.Content {...props} className={cn({ 'text-center items-center': centered }, className)} />
);

export const ConfirmationModalFooter = ({
  children,
  className,
  centered,
  ...props
}: ComponentProps<typeof ModalBase.Footer> & { centered?: boolean }) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const Actions = React.Children.map(children, (child) => {
    if (!React.isValidElement<ButtonProps>(child)) return child;

    return React.cloneElement(child, { fullWidth: !isLargeScreen });
  });

  return (
    <ModalBase.Footer
      {...props}
      className={cn(
        'flex flex-col-reverse space-x-0 space-y-2 space-y-reverse md:flex-row md:space-y-0 md:space-x-2',
        {
          'md:justify-center': centered,
        },
        className
      )}
    >
      {Actions}
    </ModalBase.Footer>
  );
};
