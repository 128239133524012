import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { createContext, forwardRef, useEffect, useMemo, useState } from 'react';
import { IconButton } from '../../Button/IconButton/IconButton';
import Divider from '../../Divider';
import { ArrowLeftIcon } from '../../Icons/solid';
import VisuallyHidden from '../../VisuallyHidden';
import { cn } from '../../utils/classes';
import { useSidebar } from '../Sidebar.context';
import SidebarScrollableContent from './SidebarScrollableContent';

const SubmenuContext = createContext<{ open: boolean }>({ open: false });
export type SidebarSubmenuRootProps = {
  defaultOpen?: boolean;
  divider?: boolean;
} & React.ComponentProps<'div'>;
export const SidebarSubmenuRoot = ({ children, defaultOpen, divider }: SidebarSubmenuRootProps) => {
  const [open, setOpen] = useState<boolean>(Boolean(defaultOpen));

  const context = useMemo(() => ({ open }), [open]);

  return (
    <SubmenuContext.Provider value={context}>
      <DialogPrimitive.Root open={open} modal={false} onOpenChange={setOpen}>
        <div className="w-full group-data-[state=collapsed]:w-10">{children}</div>
        {divider && (
          <div className="my-1 w-full">
            <Divider orientation="horizontal" />
          </div>
        )}
      </DialogPrimitive.Root>
    </SubmenuContext.Provider>
  );
};

type SidebarSubmenuContentProps = { title: string } & React.ComponentPropsWithoutRef<
  typeof DialogPrimitive.Content | 'div'
>;
export const SidebarSubmenuContent = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content | 'div'>,
  SidebarSubmenuContentProps
>(({ className, children, title, ...props }, ref) => {
  const { open: sidebarOpen, openSidebarSubmenuContainerRef, closedSidebarSubmenuContainerRef } = useSidebar();

  const [portalContainer, setPortalContainer] = useState<HTMLDivElement>();

  useEffect(() => {
    const container = sidebarOpen ? openSidebarSubmenuContainerRef.current : closedSidebarSubmenuContainerRef.current;
    setPortalContainer(container as HTMLDivElement);
  }, [sidebarOpen]);

  if (!portalContainer) return null;

  return (
    <DialogPrimitive.Portal container={portalContainer}>
      <DialogPrimitive.Content
        asChild
        ref={ref}
        data-sidebar="submenu-content"
        className={cn(
          'group  absolute overflow-hidden duration-300 data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 flex flex-1 flex-col bg-surface-navigation',
          {
            'inset-0 data-[state=open]:slide-in-from-right-1/2 data-[state=closed]:slide-out-to-right-1/2': sidebarOpen,
            'inset-y-0 group-data-[side=left]:left-[--sidebar-width-icon] group-data-[side=right]:right-[--sidebar-width-icon]  w-[--sidebar-width] data-[state=open]:slide-in-from-left group-data-[side=right]:slide-in-from-right data-[state=closed]:slide-out-to-left-1/2 group-data-[side=right]:data-[state=closed]:slide-out-to-right-1/2 group-data-[side=left]:border-r group-data-[side=right]:border-l':
              !sidebarOpen,
          },
          className
        )}
        onEscapeKeyDown={(e) => {
          if (sidebarOpen) e.preventDefault();
        }}
        onInteractOutside={(e) => {
          if (!openSidebarSubmenuContainerRef.current?.contains(e.target as HTMLElement)) e.preventDefault();
        }}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        {...props}
      >
        <div role="menu" aria-label={title}>
          <DialogPrimitive.Title className="p-2">
            <div className="flex gap-2 items-center h-10">
              {sidebarOpen && (
                <DialogPrimitive.Close asChild>
                  <IconButton color="secondary" shape="square" size="xs" variant="text" icon={ArrowLeftIcon} />
                </DialogPrimitive.Close>
              )}
              <span className="text-sm text-neutral leading-5 font-semibold">{title}</span>
            </div>
          </DialogPrimitive.Title>
          <VisuallyHidden.Root>
            <DialogPrimitive.Description>{title}</DialogPrimitive.Description>
          </VisuallyHidden.Root>
          <SidebarScrollableContent.Root>
            <SidebarScrollableContent.ScrollUpButton />
            <div className="flex flex-1 flex-col gap-2 px-2">{children}</div>
            <SidebarScrollableContent.ScrollDownButton />
          </SidebarScrollableContent.Root>
        </div>
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  );
});

export const SidebarSubmenuTrigger = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Trigger
    asChild
    ref={ref}
    className={cn('data-[state=open]:bg-neutral-alpha-subltest-pressed', className)}
    {...props}
  />
));

export const SidebarSubmenu = {
  Root: SidebarSubmenuRoot,
  Trigger: SidebarSubmenuTrigger,
  Content: SidebarSubmenuContent,
};
