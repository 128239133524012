import * as React from 'react';
import type { SVGProps } from 'react';
const ComputerDesktopIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/computer-desktop">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40002 5.10039C2.40002 3.60922 3.60886 2.40039 5.10002 2.40039H18.9C20.3912 2.40039 21.6 3.60922 21.6 5.10039V15.3004C21.6 16.7916 20.3912 18.0004 18.9 18.0004H15.1738C15.4101 18.7946 15.8751 19.4905 16.4939 20.0125C16.7828 20.2561 16.8886 20.6543 16.7589 21.0093C16.6292 21.3643 16.2915 21.6004 15.9136 21.6004H8.08659C7.70867 21.6004 7.371 21.3643 7.24127 21.0093C7.11155 20.6543 7.21741 20.2561 7.50629 20.0125C8.12505 19.4905 8.59008 18.7946 8.82633 18.0004H5.10002C3.60886 18.0004 2.40002 16.7916 2.40002 15.3004V5.10039ZM4.20002 5.10039C4.20002 4.60333 4.60297 4.20039 5.10002 4.20039H18.9C19.3971 4.20039 19.8 4.60333 19.8 5.10039V14.1004C19.8 14.5974 19.3971 15.0004 18.9 15.0004H5.10002C4.60297 15.0004 4.20002 14.5974 4.20002 14.1004V5.10039Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ComputerDesktopIcon;
