import * as React from 'react';
import type { SVGProps } from 'react';
const SignalSlashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/signal-slash">
      <g id="Icon">
        <path
          d="M2.66356 2.66399C3.01503 2.31252 3.58488 2.31252 3.93635 2.66399L12.0751 10.8027C12.678 10.8399 13.1604 11.3224 13.1976 11.9253L21.3363 20.064C21.6878 20.4155 21.6878 20.9853 21.3363 21.3368C20.9849 21.6883 20.415 21.6883 20.0636 21.3368L11.9248 13.1981C11.3219 13.1608 10.8395 12.6784 10.8023 12.0755L2.66356 3.93679C2.31208 3.58532 2.31208 3.01547 2.66356 2.66399Z"
          fill="currentColor"
        />
        <path
          d="M4.3632 19.6372C0.804775 16.0787 0.248578 10.655 2.69461 6.51342L4.01815 7.83697C2.24715 11.2298 2.78643 15.5148 5.63599 18.3644C5.98746 18.7158 5.98746 19.2857 5.63599 19.6372C5.28452 19.9886 4.71467 19.9886 4.3632 19.6372Z"
          fill="currentColor"
        />
        <path
          d="M7.75731 16.2431C6.10088 14.5866 5.61542 12.2026 6.30094 10.1198L7.81581 11.6346C7.71212 12.8279 8.11688 14.057 9.0301 14.9703C9.38157 15.3217 9.38157 15.8916 9.0301 16.2431C8.67863 16.5945 8.10878 16.5945 7.75731 16.2431Z"
          fill="currentColor"
        />
        <path
          d="M19.6367 4.36365C23.1951 7.92207 23.7513 13.3458 21.3053 17.4874L19.9818 16.1638C21.7527 12.771 21.2135 8.486 18.3639 5.63645C18.0124 5.28497 18.0124 4.71513 18.3639 4.36365C18.7154 4.01218 19.2852 4.01218 19.6367 4.36365Z"
          fill="currentColor"
        />
        <path
          d="M16.2426 7.75777C17.899 9.4142 18.3845 11.7982 17.699 13.881L16.1841 12.3662C16.2878 11.1729 15.883 9.94378 14.9698 9.03057C14.6183 8.67909 14.6183 8.10925 14.9698 7.75777C15.3213 7.4063 15.8911 7.4063 16.2426 7.75777Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default SignalSlashIcon;
