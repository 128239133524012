import * as React from 'react';
import type { SVGProps } from 'react';
const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/check">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0454 4.98421C20.4408 5.28545 20.5171 5.85016 20.2159 6.24554L10.6159 18.8455C10.4585 19.0521 10.2195 19.1806 9.96037 19.1981C9.70125 19.2155 9.44722 19.1201 9.26358 18.9365L3.86358 13.5365C3.51211 13.185 3.51211 12.6152 3.86358 12.2637C4.21505 11.9122 4.7849 11.9122 5.13637 12.2637L9.80819 16.9355L18.7841 5.15466C19.0853 4.75928 19.65 4.68297 20.0454 4.98421Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default CheckIcon;
