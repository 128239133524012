import type { LocationSchema } from '@shape-construction/api/src/types';
import { TreeSelector } from '@shape-construction/arch-ui/src/TreeSelector/TreeSelector';
import { mapToTreeSelector } from 'app/components/Utils/locations';
import React, { type FC, useMemo } from 'react';

export type LocationSelectorProps = {
  defaultLocationId: LocationSchema['id'] | null;
  locations: LocationSchema[];
  name: string;
  onSelect: (name: string, nodeId: LocationSchema['id']) => void;
};

export const LocationSelector: FC<LocationSelectorProps> = ({ defaultLocationId, locations, name, onSelect }) => {
  const nodes = useMemo(() => mapToTreeSelector(locations), [locations]);
  const { id: rootLocationId = '' } = useMemo(
    () => locations.find(({ parentLocationId }) => parentLocationId === null)!,
    [locations]
  );

  return (
    <TreeSelector
      nodes={nodes}
      rootNodeId={rootLocationId}
      defaultSelectedNodeId={defaultLocationId}
      onSelect={onSelect}
      name={name}
    />
  );
};
