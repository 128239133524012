import { useFeatureFlag } from '@shape-construction/feature-flags';
import { useSendAnalyticsEvent } from 'app/queries/analytics/analytics';
import { useEffect, useMemo } from 'react';
import { matchRoutes, useLocation } from 'react-router';

export const usePageViewAnalytics = () => {
  const { mutate: sendAnalyticsEvent } = useSendAnalyticsEvent();
  const location = useLocation();
  const pathname = location.pathname;
  const { value: isPageViewEventEnabled } = useFeatureFlag('pageview-event-mixpanel');
  const projectId = useMemo(
    () => matchRoutes([{ path: '/projects/:projectId/*' }], location)?.[0]?.params?.projectId,
    [location]
  );

  useEffect(() => {
    if (isPageViewEventEnabled) {
      sendAnalyticsEvent({
        event_name: 'page_view',
        properties: {
          project_id: projectId,
          pathname,
        },
      });
    }
  }, [projectId, sendAnalyticsEvent, pathname, isPageViewEventEnabled]);
};
