import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * Subset of AxiosRequestConfig
 */
export type RequestConfig<TData = unknown> = {
  baseURL?: string;
  url?: string;
  method: 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE' | 'OPTIONS';
  params?: unknown;
  data?: TData | FormData;
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
  signal?: AbortSignal;
  headers?: AxiosRequestConfig['headers'];
};
/**
 * Subset of AxiosResponse
 */
export type ResponseConfig<TData = unknown> = {
  data: TData;
  status: number;
  statusText: string;
  headers?: AxiosResponse['headers'];
};
export type ResponseErrorConfig<Error> = AxiosError<Error>;

export let instance: AxiosInstance;

export const initialiseAxiosClient = (newInstance: AxiosInstance): AxiosInstance => {
  instance = newInstance;

  return instance;
};

export const customInstance = <TData, TError = unknown, TVariables = unknown>(
  config: RequestConfig<TVariables>
): Promise<ResponseConfig<TData>> => {
  return instance.request<TVariables, ResponseConfig<TData>>(config).catch((e: ResponseErrorConfig<TError>) => {
    throw e;
  });
};

export default customInstance;
