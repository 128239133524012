import classNames from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  MegaphoneIcon,
  XCircleIcon,
} from '../Icons/solid';
import type { AlertColor, AlertEmphasis, AlertFormat, AlertJustifyContent } from './alert-config';
import { AlertActions } from './components/AlertActions';
import { AlertCloseButton } from './components/AlertCloseButton';
import { AlertMessage } from './components/AlertMessage';

export const colorClassesMap: Record<NonNullable<AlertColor>, Record<NonNullable<AlertEmphasis>, string>> = {
  primary: {
    subtle: 'px-3 bg-brand-subtlest border border-brand-subtlest text-brand',
    bold: 'px-3 bg-brand-bolder border-0 text-neutral-inverse',
    minimal: 'text-brand',
  },
  secondary: {
    subtle: 'px-3 bg-neutral-subtlest border border-neutral-subtlest text-neutral',
    bold: 'px-3 bg-neutral-bolder border-0 text-neutral-inverse',
    minimal: 'text-neutral',
  },
  success: {
    subtle: 'px-3 bg-success-subtlest border border-success-subtlest text-success',
    bold: 'px-3 bg-success-bolder border-0 text-neutral-inverse',
    minimal: 'text-success',
  },
  warning: {
    subtle: 'px-3 bg-warning-subtlest border border-warning-subtlest text-warning',
    bold: 'px-3 bg-warning-bolder border-0 text-neutral-inverse',
    minimal: 'text-warning',
  },
  danger: {
    subtle: 'px-3 bg-danger-subtlest border border-danger-subtlest text-danger',
    bold: 'px-3 bg-danger-bolder border-0 text-neutral-inverse',
    minimal: 'text-danger',
  },
  discovery: {
    subtle: 'px-3 bg-discovery-subtlest border border-discovery-subtlest text-discovery',
    bold: 'px-3 bg-discovery-bolder border-0 text-neutral-inverse',
    minimal: 'text-discovery',
  },
};

type Components = {
  Message: typeof AlertMessage;
  Actions: typeof AlertActions;
};

export interface AlertProps {
  children: React.ReactNode;
  /**
   * Type of context for this component.
   * It changes the icon and color scheme
   */
  color: AlertColor;
  /**
   * Alert emphasis (subtle or bold)
   */
  emphasis?: AlertEmphasis;
  /**
   * Alert format (inline or floating)
   */
  format?: AlertFormat;
  /**
   * Override icon
   */
  customIcon?: React.ReactNode;
  /**
   * Called when the Alert is dismissed.
   */
  onClose?: () => void;
  /**
   * Adds rounded borders. Usually used when alert is not top-aligned
   */
  rounded?: boolean;
  /**
   * Displays alert in a row, on mobile view
   */
  displayInRow?: boolean;
  /**
   * Overrides message position in the container
   */
  justifyContent?: AlertJustifyContent;
}

const icons = {
  primary: {
    subtle: <InformationCircleIcon className="h-5 w-5 text-icon-brand" aria-hidden="true" />,
    bold: <InformationCircleIcon className="h-5 w-5 text-icon-brand-inverse" aria-hidden="true" />,
    minimal: <InformationCircleIcon className="h-5 w-5 text-icon-brand" aria-hidden="true" />,
  },
  secondary: {
    subtle: <InformationCircleIcon className="h-5 w-5 text-icon-neutral" aria-hidden="true" />,
    bold: <InformationCircleIcon className="h-5 w-5 text-icon-neutral-inverse" aria-hidden="true" />,
    minimal: <InformationCircleIcon className="h-5 w-5 text-icon-neutral" aria-hidden="true" />,
  },
  success: {
    subtle: <CheckCircleIcon className="h-5 w-5 text-icon-success" aria-hidden="true" />,
    bold: <CheckCircleIcon className="h-5 w-5 text-icon-success-inverse" aria-hidden="true" />,
    minimal: <CheckCircleIcon className="h-5 w-5 text-icon-success" aria-hidden="true" />,
  },
  warning: {
    subtle: <ExclamationTriangleIcon className="h-5 w-5 text-icon-warning" aria-hidden="true" />,
    bold: <ExclamationTriangleIcon className="h-5 w-5 text-icon-warning-inverse" aria-hidden="true" />,
    minimal: <ExclamationTriangleIcon className="h-5 w-5 text-icon-warning" aria-hidden="true" />,
  },
  danger: {
    subtle: <XCircleIcon className="h-5 w-5 text-icon-danger" aria-hidden="true" />,
    bold: <XCircleIcon className="h-5 w-5 text-icon-danger-inverse" aria-hidden="true" />,
    minimal: <XCircleIcon className="h-5 w-5 text-icon-danger" aria-hidden="true" />,
  },
  discovery: {
    subtle: <MegaphoneIcon className="h-5 w-5 text-icon-discovery" aria-hidden="true" />,
    bold: <MegaphoneIcon className="h-5 w-5 text-icon-discovery-inverse" aria-hidden="true" />,
    minimal: <MegaphoneIcon className="h-5 w-5 text-icon-discovery" aria-hidden="true" />,
  },
};

export const Alert: React.FC<AlertProps> & Components = ({
  children,
  color,
  emphasis = 'subtle',
  format = 'inline',
  onClose,
  rounded = true,
  customIcon,
  displayInRow = false,
  justifyContent,
}) => {
  const iconElement = customIcon || icons[color][emphasis];

  return (
    <div
      className={classNames('@container whitespace-normal', {
        'fixed px-4 left-0 bottom-6 w-screen z-modal': format === 'floating',
      })}
    >
      <div
        data-testid={`alert-${color}`}
        role="alert"
        className={twMerge(
          classNames('py-2 relative flex flex-row', colorClassesMap[color][emphasis], {
            'max-w-5xl m-auto shadow-lg': format === 'floating',
            'rounded-md': rounded,
          })
        )}
      >
        <div
          className={classNames('flex-1 flex items-start @md:items-center', {
            '@lg:justify-center': format === 'inline',
            'justify-center': justifyContent === 'center',
            'items-center': displayInRow,
            '@lg:justify-start': justifyContent === 'start',
          })}
        >
          <div className="shrink-0">{iconElement}</div>

          <div
            className={twMerge(
              classNames('flex flex-col @md:flex-row @md:items-center @md:justify-between ml-3 text-sm w-full gap-3', {
                '@lg:justify-center @lg:w-auto': format === 'inline' && justifyContent !== 'start',
                'justify-center w-auto': justifyContent === 'center',
                'flex-row items-center justify-between mr-0': displayInRow,
                '@justify-start': justifyContent === 'start',
              })
            )}
          >
            {children}
          </div>
        </div>
        {onClose && <AlertCloseButton onClose={onClose} color={color} emphasis={emphasis} />}
      </div>
    </div>
  );
};

Alert.Message = AlertMessage;
Alert.Actions = AlertActions;
