import { useNotificationMarkReadMutation } from 'app/queries/notifications/notifications';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

export const NotificationReaderObserver = () => {
  const { mutate: markNotificationRead } = useNotificationMarkReadMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const notificationId = searchParams.get('mark_notification_as_read');

  useEffect(() => {
    if (notificationId) {
      markNotificationRead(
        { notificationId, data: { similar: true } },
        {
          onSettled: () => {
            searchParams.delete('mark_notification_as_read');
            setSearchParams(searchParams);
          },
        }
      );
    }
  }, [markNotificationRead, notificationId, searchParams, setSearchParams]);

  return null;
};
