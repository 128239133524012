import { useMediaQuery } from '@shape-construction/hooks';
import React, { type ComponentPropsWithoutRef } from 'react';
import ModalBase from '../ModalBase';
import type { Transition } from '../ModalBase/config/transitions';
import { breakpoints } from '../utils/breakpoints';

export const ModalRoot = ({
  onClose,
  open,
  ...props
}: Pick<ComponentPropsWithoutRef<typeof ModalBase.Root>, 'children' | 'open' | 'onClose' | 'initialFocus'>) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const fullScreenMode = !isLargeScreen;
  const isPadded = isLargeScreen;
  const hasRoundBorders = isLargeScreen;
  const transition: Transition = isLargeScreen ? 'fade' : 'bottom-to-top';

  return (
    <ModalBase.Root
      {...props}
      onClose={onClose}
      open={open}
      fullScreen={fullScreenMode}
      outsidePad={isPadded}
      roundBorders={hasRoundBorders}
      transition={transition}
      fullWidth
      maxWidth={isLargeScreen ? 'lg' : 'none'}
    />
  );
};

export const ModalContent = ModalBase.Content;
export const ModalTitle = ModalBase.Title;
export const ModalSubTitle = ModalBase.SubTitle;

export const ModalHeader = (props: ComponentPropsWithoutRef<typeof ModalBase.Header>) => (
  <ModalBase.Header bottomBorder {...props} />
);

export const ModalFooter = (props: ComponentPropsWithoutRef<typeof ModalBase.Footer>) => (
  <ModalBase.Footer topBorder {...props} />
);
