import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronUpDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chevron-up-down">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3636 5.1137C11.7151 4.76223 12.2849 4.76223 12.6364 5.1137L16.1364 8.6137C16.4878 8.96517 16.4878 9.53502 16.1364 9.88649C15.7849 10.238 15.2151 10.238 14.8636 9.88649L12 7.02289L9.13637 9.88649C8.7849 10.238 8.21505 10.238 7.86358 9.88649C7.51211 9.53502 7.51211 8.96517 7.86358 8.6137L11.3636 5.1137ZM7.86358 14.1137C8.21505 13.7622 8.7849 13.7622 9.13637 14.1137L12 16.9773L14.8636 14.1137C15.2151 13.7622 15.7849 13.7622 16.1364 14.1137C16.4878 14.4652 16.4878 15.035 16.1364 15.3865L12.6364 18.8865C12.2849 19.238 11.7151 19.238 11.3636 18.8865L7.86358 15.3865C7.51211 15.035 7.51211 14.4652 7.86358 14.1137Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChevronUpDownIcon;
