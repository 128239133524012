import {
  getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey,
  useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan,
  usePostApiProjectsProjectIdTeamsTeamIdSubscription,
  usePostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal,
  usePostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm,
} from '@shape-construction/api/src/hooks';
import type { TeamSubscriptionPlanSchema } from '@shape-construction/api/src/types';
import { useQueryClient } from '@tanstack/react-query';

// queries
export const useTeamsSubscriptionPlan = useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan;

// mutation
export const useSubscribeTeam = usePostApiProjectsProjectIdTeamsTeamIdSubscription;

export const useConfirmTeamSubscription = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm({
    mutation: {
      onSuccess: (updatedSubscriptionPlan, { projectId, teamId }) => {
        queryClient.setQueryData<TeamSubscriptionPlanSchema>(
          getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey(projectId, teamId),
          updatedSubscriptionPlan
        );
      },
    },
  });
};

export const useManageSubscriptionBilling = usePostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal;
