import type { ErrorSchema } from '@shape-construction/api/src/types';
import { environment } from 'app/config/environment';
import axios, { type AxiosError } from 'axios';
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

export type RequestInterceptors = {
  onRequest: (request: InternalAxiosRequestConfig) => InternalAxiosRequestConfig;
};

export type ResponseError<T = ErrorSchema> = AxiosError<T>;

export type ResponseInterceptors = {
  onResponse: (response: AxiosResponse) => AxiosResponse;
  onResponseError: (error: ResponseError) => Promise<AxiosError>;
};

export const axiosInstance = applyCaseMiddleware(axios.create({ baseURL: environment.API_URL }), {
  ignoreHeaders: true,
  preservedKeys: (input) => {
    if (['_destroy', '_id'].includes(input)) return true;
    // preserve keys using UUIDs
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(input);
  },
});

export default axiosInstance;
