import { useMessage } from '@messageformat/react';
import { IconButton, InputText } from '@shape-construction/arch-ui';
import { XMarkIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import type { InputTextProps } from '@shape-construction/arch-ui/src/InputText/InputText';
import { useDebounceCallback } from '@shape-construction/hooks';
import { SEARCH_STATE } from 'app/constants/SearchState';
import { useLayoutContext } from 'app/contexts/layout/layoutContext';
import React, { useRef } from 'react';
import { useSearchParams } from 'react-router';

const SearchField = () => {
  const [searchParams, setSearchParams] = useSearchParams({ q: '' });
  const inputRef = useRef<React.ElementRef<'input'>>(null);
  const searchQuery = searchParams.get('q') as string;
  const placeholder = useMessage('search.issues.placeholder');
  const {
    layoutConfig: { searchState },
  } = useLayoutContext();

  // track the state of the search field X close button
  const displayClearButton = searchQuery !== '';

  const handleFieldChange: InputTextProps['onChange'] = useDebounceCallback((event) => {
    searchParams.set('q', event.target.value);
    setSearchParams(searchParams, { replace: true });
  }, 250);

  const handleClearSearch = () => {
    if (!inputRef.current) return;

    searchParams.set('q', '');
    setSearchParams(searchParams);
    inputRef.current.value = '';
    inputRef.current.focus();
  };

  if (searchState !== SEARCH_STATE.SEARCHING) return null;

  return (
    <div className="max-w-screen-sm flex-1 mr-1">
      <div className="flex items-center relative">
        <InputText
          autoFocus
          fullWidth
          ref={inputRef}
          name="search"
          data-cy="search-field"
          placeholder={placeholder}
          defaultValue={searchQuery}
          onChange={handleFieldChange}
          className="bg-transparent text-neutral-inverse border-2 border-selected focus:outline-none focus:ring-0 pr-9"
        />
        {displayClearButton && (
          <div className="absolute z-10 right-2">
            <IconButton
              color="white"
              icon={XMarkIcon}
              size="xxs"
              variant="text"
              aria-label="clear search terms"
              onClick={handleClearSearch}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { SearchField };
