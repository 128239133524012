import * as React from 'react';
import type { SVGProps } from 'react';
const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/trash">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 1.2002C8.67744 1.2002 7.19998 2.67766 7.19998 4.5002V5.03143C6.24564 5.12386 5.2992 5.24355 4.36147 5.38975C3.87035 5.46631 3.53428 5.92652 3.61085 6.41764C3.68741 6.90876 4.14762 7.24483 4.63874 7.16826C4.69787 7.15905 4.75703 7.14994 4.81624 7.14094L5.82603 19.7634C5.96323 21.4784 7.39503 22.8002 9.11552 22.8002H14.8842C16.6047 22.8002 18.0365 21.4784 18.1737 19.7634L19.1835 7.14086C19.2428 7.14989 19.3022 7.15902 19.3615 7.16826C19.8526 7.24483 20.3128 6.90876 20.3894 6.41764C20.4659 5.92652 20.1299 5.46631 19.6387 5.38975C18.7009 5.24354 17.7544 5.12383 16.8 5.03141V4.5002C16.8 2.67766 15.3225 1.2002 13.5 1.2002H10.5ZM12.0001 4.8002C13.0073 4.8002 14.0076 4.83047 15 4.89018V4.5002C15 3.67177 14.3284 3.0002 13.5 3.0002H10.5C9.67155 3.0002 8.99998 3.67177 8.99998 4.5002V4.8902C9.99246 4.83048 10.9928 4.8002 12.0001 4.8002ZM10.1455 9.27022C10.1289 8.85634 9.77997 8.53424 9.36609 8.5508C8.95221 8.56735 8.63011 8.91629 8.64667 9.33017L9.00668 18.3302C9.02324 18.7441 9.37218 19.0662 9.78606 19.0496C10.1999 19.033 10.522 18.6841 10.5055 18.2702L10.1455 9.27022ZM15.3535 9.33017C15.37 8.91629 15.0479 8.56735 14.6341 8.5508C14.2202 8.53424 13.8712 8.85634 13.8547 9.27022L13.4947 18.2702C13.4781 18.6841 13.8002 19.033 14.2141 19.0496C14.628 19.0662 14.9769 18.7441 14.9935 18.3302L15.3535 9.33017Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default TrashIcon;
