import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { postApiChannelsToken } from '@shape-construction/api/src/api';
import { useUsersMe } from 'app/queries/users/users';
import type { StreamChat } from 'stream-chat';
import { chatClient } from './sdkClient';

export const useCreateChatClient = () => {
  const { data: user } = useUsersMe();
  const [client, setClient] = useState<StreamChat | null>(null);

  const streamUserId = user?.channels?.streamChatUserId;

  useEffect(() => {
    if (!streamUserId) return;

    chatClient
      .connectUser({ id: streamUserId }, async () => {
        const { token } = await postApiChannelsToken();
        return token;
      })
      .then(() => setClient(chatClient));
  }, [streamUserId]);

  return client;
};

export const ChatClientContext = createContext<{ client: StreamChat | null }>({ client: null });

export const ChatClientProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const client = useCreateChatClient();
  const value = useMemo(() => ({ client }), [client]);

  return <ChatClientContext.Provider value={value}>{children}</ChatClientContext.Provider>;
};

export const useChatClient = () => {
  return useContext(ChatClientContext);
};
