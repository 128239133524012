import * as React from 'react';
import type { SVGProps } from 'react';
const DocumentTextIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/document-text">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39998 2.3999C4.40586 2.3999 3.59998 3.20579 3.59998 4.1999V19.7999C3.59998 20.794 4.40586 21.5999 5.39998 21.5999H18.6C19.5941 21.5999 20.4 20.794 20.4 19.7999V9.14549C20.4 8.6681 20.2103 8.21026 19.8728 7.8727L14.9272 2.92711C14.5896 2.58954 14.1318 2.3999 13.6544 2.3999H5.39998ZM8.09997 12.5999C7.60292 12.5999 7.19998 13.0028 7.19998 13.4999C7.19998 13.997 7.60292 14.3999 8.09997 14.3999H15.9C16.397 14.3999 16.8 13.997 16.8 13.4999C16.8 13.0028 16.397 12.5999 15.9 12.5999H8.09997ZM8.09997 16.1999C7.60292 16.1999 7.19998 16.6028 7.19998 17.0999C7.19998 17.597 7.60292 17.9999 8.09997 17.9999H15.9C16.397 17.9999 16.8 17.597 16.8 17.0999C16.8 16.6028 16.397 16.1999 15.9 16.1999H8.09997Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default DocumentTextIcon;
