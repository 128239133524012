export const iconColors = ['primary', 'secondary', 'success', 'warning', 'danger', 'pink', 'blue', 'sky'] as const;
export type SidebarIconColor = (typeof iconColors)[number];

const iconVariants = ['contained', 'outlined', 'text'] as const;
export type SidebarIconVariant = (typeof iconVariants)[number];

const sidebarCollapsibles = ['offcanvas', 'icon', 'none'] as const;
export type SidebarCollapsible = (typeof sidebarCollapsibles)[number];

const sidebarSides = ['left', 'right'] as const;
export type SidebarSide = (typeof sidebarSides)[number];

const sidebarVariants = ['sidebar', 'floating', 'inset'] as const;
export type SidebarVariant = (typeof sidebarVariants)[number];

export const SIDEBAR_WIDTH = '280px';
export const SIDEBAR_WIDTH_MOBILE = '320px';
export const SIDEBAR_WIDTH_ICON = '72px';
