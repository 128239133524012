/**
 * Generated by kubb.
 * Do not edit manually.
 * Shape API
 */

export const issueImageKindEnum = {
  image: 'image',
  evidence: 'evidence',
} as const;

export type IssueImageKindEnumSchema = (typeof issueImageKindEnum)[keyof typeof issueImageKindEnum];

/**
 * @deprecated
 */
export type IssueImageKindSchema = IssueImageKindEnumSchema;
