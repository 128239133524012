import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chevron-up">
      <path
        id="Icon"
        d="M12.6365 7.71356C12.285 7.36208 11.7152 7.36208 11.3637 7.71356L4.8637 14.2136C4.51223 14.565 4.51223 15.1349 4.8637 15.4863C5.21517 15.8378 5.78502 15.8378 6.13649 15.4863L12.0001 9.62274L17.8637 15.4863C18.2152 15.8378 18.785 15.8378 19.1365 15.4863C19.488 15.1349 19.488 14.565 19.1365 14.2136L12.6365 7.71356Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChevronUpIcon;
