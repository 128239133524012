import * as React from 'react';
import type { SVGProps } from 'react';
const ChatBubbleOvalLeftEllipsisIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chat-bubble-oval-left-ellipsis">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.6001C6.82809 3.6001 2.40002 7.24018 2.40002 12.0001C2.40002 14.4294 3.57422 16.5904 5.39882 18.1019C5.38048 18.8754 5.15373 19.5949 4.77293 20.2092C4.607 20.4769 4.59306 20.8119 4.73619 21.0924C4.87932 21.373 5.15868 21.5583 5.4728 21.581C5.64704 21.5937 5.82286 21.6001 6.00002 21.6001C7.56446 21.6001 9.01415 21.1002 10.1954 20.252C10.7806 20.3493 11.3839 20.4001 12 20.4001C17.172 20.4001 21.6 16.76 21.6 12.0001C21.6 7.24018 17.172 3.6001 12 3.6001ZM12 13.2001C12.6628 13.2001 13.2 12.6628 13.2 12.0001C13.2 11.3374 12.6628 10.8001 12 10.8001C11.3373 10.8001 10.8 11.3374 10.8 12.0001C10.8 12.6628 11.3373 13.2001 12 13.2001ZM9.60002 12.0001C9.60002 12.6628 9.06277 13.2001 8.40002 13.2001C7.73728 13.2001 7.20002 12.6628 7.20002 12.0001C7.20002 11.3374 7.73728 10.8001 8.40002 10.8001C9.06277 10.8001 9.60002 11.3374 9.60002 12.0001ZM15.6 13.2001C16.2628 13.2001 16.8 12.6628 16.8 12.0001C16.8 11.3374 16.2628 10.8001 15.6 10.8001C14.9373 10.8001 14.4 11.3374 14.4 12.0001C14.4 12.6628 14.9373 13.2001 15.6 13.2001Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChatBubbleOvalLeftEllipsisIcon;
