import { useMessageGetter } from '@messageformat/react';
import { Divider, IconButton, VisuallyHidden } from '@shape-construction/arch-ui';
import { Cog6ToothIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from '@shape-construction/arch-ui/src/Icons/solid';
import Sidebar, { useSidebar } from '@shape-construction/arch-ui/src/Sidebar';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery, useModal } from '@shape-construction/hooks';
import { useQuery } from '@tanstack/react-query';
import { ProjectsSelector } from 'app/components/ProjectsSelector/ProjectsSelector';
import { getProjectQueryOptions } from 'app/queries/projects/projects';
import { useCurrentUser } from 'app/queries/users/users';
import React, { useEffect } from 'react';
import { Link, matchPath, useLocation, useMatch, useNavigate } from 'react-router';
import { ADMIN_OR_ABOVE } from '../../../constants/Roles';
import { BackNavigationButton } from '../Navbar/components/BackNavigationButton';
import { ForwardNavigationButton } from '../Navbar/components/ForwardNavigationButton';
import { ReloadButton } from '../Navbar/components/ReloadButton';
import ShapeLogo from '../ShapeLogo/ShapeLogo';
import { AppSidebarQRButton } from './AppSidebarQRButton';
import { AppSidebarUpgradePlanButton } from './AppSidebarUpgradePlanButton';
import QRScanDetails from './QRScanDetailsModal';
import { type SidebarItemType, useAdminItems, useHighlightItems, useOverviewItems } from './useSidebarItems';

export const AppSidebarCollapseButton = () => {
  const { isLargeScreen, open, toggleSidebar } = useSidebar();
  const isExpanded = isLargeScreen && open;

  return (
    <div className="hidden md:flex md:justify-end">
      <IconButton
        icon={isExpanded ? ChevronDoubleLeftIcon : ChevronDoubleRightIcon}
        size="md"
        shape="square"
        aria-label="sidebar control"
        aria-expanded={isExpanded}
        color="secondary"
        variant="text"
        onClick={() => toggleSidebar()}
      />
    </div>
  );
};

export const AppSidebarMobileHeader: React.FC = () => {
  const projectRoute = useMatch('/projects/:projectId/*');
  const notificationRoute = useMatch('/notifications/projects/:projectId');
  const match = projectRoute || notificationRoute;
  const { defaultProject } = useCurrentUser();

  const currentProjectId = match?.params?.projectId ?? defaultProject;

  return (
    <div className="section-header my-2 md:hidden">
      <div className="flex h-16 w-full items-center px-6 py-5 justify-between">
        <div className="h-full shrink-0">
          <Link to="/">
            <ShapeLogo withLettering />
          </Link>
        </div>
        <div className="flex items-center">
          {currentProjectId && (
            <>
              <BackNavigationButton color="primary" />
              <ForwardNavigationButton color="primary" />
            </>
          )}
          <ReloadButton color="primary" />
        </div>
      </div>
      <Divider orientation="horizontal" />
    </div>
  );
};

type AppSidebarItemProps = { item: SidebarItemType };
export const AppSidebarItem = ({ item }: AppSidebarItemProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const matchRoute = matchPath(item.isActiveOn ?? item.route, location.pathname);
  const isRoute = !!matchRoute;

  const { isLargeScreen, toggleSidebar } = useSidebar();

  const notifications = item.notifications;
  const activateBadge = Boolean(isLargeScreen && notifications && notifications > 0);
  const badgeText = activateBadge ? notifications?.toString() : undefined;

  const handleRedirect: React.MouseEventHandler<HTMLButtonElement> = () => {
    navigate(item.route);
    if (!isLargeScreen) toggleSidebar(false);
  };

  return (
    <Sidebar.Item
      active={isRoute}
      disabled={item.disabled}
      tooltip={item.hideTooltip ? null : item.title}
      onClick={handleRedirect}
      aria-current="page"
    >
      <Sidebar.Icon icon={item.image} color={item.color} variant={item.variant} />
      <VisuallyHidden.Root>{`${item.title}-icon`}</VisuallyHidden.Root>
      {item.title}
      {badgeText && <Sidebar.Badge label={badgeText} />}
    </Sidebar.Item>
  );
};

export const AppSidebarRoot: React.FC = () => {
  const projectRoute = useMatch('/projects/:projectId/*');
  const adminRoute = useMatch('/projects/:projectId/settings/*');
  const notificationRoute = useMatch('/notifications/projects/:projectId');
  const match = projectRoute || notificationRoute;
  const projectId = match?.params.projectId;
  const { open: qrModalOpen, openModal, closeModal } = useModal(false);

  const isAdminRoute = Boolean(adminRoute);

  const { data: currentProject } = useQuery(getProjectQueryOptions(projectId!));
  const currentUser = useCurrentUser();

  const highlightItems = useHighlightItems(currentProject);
  const overviewItems = useOverviewItems(currentProject);
  const adminItems = useAdminItems(currentProject);

  const messages = useMessageGetter('appSidebar');

  const { isLargeScreen, open, toggleSidebar } = useSidebar();

  const isWideScreen = useMediaQuery(breakpoints.up('lg'));

  useEffect(() => {
    if (isLargeScreen && !isWideScreen) toggleSidebar(false);
  }, [isLargeScreen, isWideScreen]);

  const renderHeader = () => {
    return (
      <>
        <div className="hidden px-2 md:block">
          <ProjectsSelector currentProject={currentProject} isExpanded={open} />
        </div>
        <AppSidebarMobileHeader />
      </>
    );
  };

  const renderHighlightItems = () => {
    return (
      <Sidebar.Section divider data-testid="app-sidebar-highlight-section">
        {highlightItems.map((item) => (
          <AppSidebarItem key={item.key} item={item} />
        ))}
      </Sidebar.Section>
    );
  };

  const renderOverviewItems = () => {
    return (
      <Sidebar.Section divider data-testid="app-sidebar-overview-section">
        {overviewItems.map((item) => (
          <AppSidebarItem key={item.key} item={item} />
        ))}
      </Sidebar.Section>
    );
  };

  const renderAdminItems = () => {
    if (!currentProject) return null;
    if (!ADMIN_OR_ABOVE.includes(currentProject.currentTeamMemberRole)) return null;

    return (
      <Sidebar.Submenu.Root defaultOpen={isAdminRoute}>
        <Sidebar.Submenu.Trigger>
          <Sidebar.Item active={isAdminRoute} tooltip={messages('admin')}>
            <Sidebar.Icon icon={Cog6ToothIcon} />
            {messages('admin')}
            <ChevronRightIcon className="w-5 h-5 ml-auto text-icon-neutral-subtle" />
          </Sidebar.Item>
        </Sidebar.Submenu.Trigger>
        <Sidebar.Submenu.Content title={messages('admin')}>
          <Sidebar.Section>
            {adminItems.map((item) => (
              <AppSidebarItem key={item.key} item={item} />
            ))}
          </Sidebar.Section>
        </Sidebar.Submenu.Content>
      </Sidebar.Submenu.Root>
    );
  };

  if (!currentUser) return null;
  return (
    <>
      <QRScanDetails open={qrModalOpen} onClose={closeModal} />
      <Sidebar.Root data-testid="app-sidebar">
        <Sidebar.Header data-testid="app-sidebar-header">{renderHeader()}</Sidebar.Header>
        <Sidebar.Content>
          {renderHighlightItems()}
          {renderOverviewItems()}
          {renderAdminItems()}
        </Sidebar.Content>
        <Sidebar.Footer data-testid="app-sidebar-footer" className="p-4">
          <AppSidebarQRButton onClick={openModal} />
          <AppSidebarUpgradePlanButton />
          <AppSidebarCollapseButton />
        </Sidebar.Footer>
      </Sidebar.Root>
    </>
  );
};
