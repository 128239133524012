import type { useMessageGetter } from '@messageformat/react';
import type { IssueImpactSchema, IssueSchema } from '@shape-construction/api/src/types';

/**
 * The client code uses two enumerations for Impact
 * 1. IssueImpactSchema (autogenerated) corresponds to the backend data model.
 * 2. ImpactType contains an additional 'notEntered' status,
 *    which is not passed to the server. If the actual impact is null,
 *    the client represents it using a virtual 'notEntered' status.
 */
export type ImpactType = IssueImpactSchema | 'notEntered';

export type ImpactField = keyof Pick<IssueSchema, 'impact' | 'workAffected' | 'dueDate' | 'delayStart' | 'delayFinish'>;

interface ImpactProps {
  value: ImpactType;
  label: string;
  groupingOptionLabel: string;
  description: string;
}

export type ImpactRecord = Record<ImpactType, ImpactProps>;

/**
 * @deprecated use {@link getImpactRecord} instead
 * This const has not yet been removed; The component TimelineEventCard > Update Impact Body,
 * which contains the references to the above need to be fixed.
 */
export const Impact: ImpactRecord = {
  notEntered: {
    value: 'notEntered',
    label: 'Impact not entered',
    groupingOptionLabel: 'Not entered',
    description: 'The impact has not been entered',
  },
  noEffect: {
    value: 'noEffect',
    label: 'No effect',
    groupingOptionLabel: 'No effect',
    description: 'This issue has no effect on other works',
  },
  potentialDelay: {
    value: 'potentialDelay',
    label: 'Potential delay',
    groupingOptionLabel: 'Potential delay',
    description: 'The issue has the potential to cause delay to other works, but there is no impact yet',
  },
  liveDelay: {
    value: 'liveDelay',
    label: 'Live delay',
    groupingOptionLabel: 'Live delay',
    description: 'The issue is preventing/ slowing down progress on other works',
  },
  completedDelay: {
    value: 'completedDelay',
    label: 'Completed delay',
    groupingOptionLabel: 'Completed delay',
    description: 'The issue has already delayed other works, but the delay is completed',
  },
};

export const getImpactRecord = (messages: ReturnType<typeof useMessageGetter>): ImpactRecord => {
  const impactItems: ImpactType[] = ['notEntered', 'noEffect', 'potentialDelay', 'liveDelay', 'completedDelay'];
  return impactItems.reduce(
    (acc, val) => ({
      ...acc,
      [val]: {
        value: val,
        label: messages(`impact.impact.${val}.label`),
        groupingOptionLabel: messages(`impact.impact.${val}.groupingOptionLabel`),
        description: messages(`impact.impact.${val}.description`),
      },
    }),
    {} as ImpactRecord
  );
};

export const impactFieldsMap: Record<ImpactType, ImpactField[]> = {
  notEntered: ['impact'],
  noEffect: ['impact', 'dueDate'],
  potentialDelay: ['impact', 'workAffected', 'dueDate'],
  liveDelay: ['impact', 'workAffected', 'delayStart'],
  completedDelay: ['impact', 'workAffected', 'delayStart', 'delayFinish'],
};

/**
 * Adding a status type to this array will display it on the `edit impact` modal.
 * The virtual `notEntered` status should not exist in this collection.
 */
export const ImpactValues: IssueImpactSchema[] = ['noEffect', 'potentialDelay', 'liveDelay', 'completedDelay'];

export const impactFields = (impact: IssueImpactSchema) => impactFieldsMap[impact] || ['impact'];
