import { RadioGroup } from '@headlessui/react';
import classNames from 'clsx';
import React, { type ReactNode } from 'react';
import type { InputBaseProps } from '../types/InputBase';
import { cn } from '../utils/classes';

export interface InputRadioGroupOptionProps {
  description?: ReactNode;
  disabled?: boolean;
  label?: ReactNode;
  badge?: ReactNode;
  value?: string;
  icon?: ReactNode;
}

export interface InputRadioGroupProps extends InputBaseProps {
  options: InputRadioGroupOptionProps[];
  setFieldValue?: (name: string, value: string) => void;
  alignment?: 'vertical' | 'inline';
}

export const InputRadioGroup = React.forwardRef<HTMLInputElement, InputRadioGroupProps>(
  ({ label, name, value, onBlur, setFieldValue, options, error, touched = true, alignment = 'vertical' }, ref) => {
    const showError = error && touched;

    return (
      <RadioGroup
        ref={ref}
        value={value}
        onBlur={onBlur}
        onChange={(val: string | undefined) => {
          if (setFieldValue) setFieldValue(name, val || '');
        }}
      >
        {label && (
          <RadioGroup.Label className="mb-4 inline-block text-base font-medium leading-6">{label}</RadioGroup.Label>
        )}
        <div
          className={classNames('flex flex-col gap-4', {
            'md:flex-row justify-center': alignment === 'inline',
          })}
        >
          {options.map((option: InputRadioGroupOptionProps) => (
            <RadioGroup.Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              className={({ checked }) =>
                classNames(
                  'relative flex rounded-lg border border-gray-300 bg-white px-5 py-4 shadow-sm focus:outline-none',
                  {
                    'cursor-not-allowed border-gray-100': option.disabled,
                    'hover:border-gray-400 cursor-pointer ': !option.disabled,
                    'ring-1 ring-indigo-500 ring-offset-0': checked,
                    'ring-red-500': checked && showError,
                    'justify-center grow': option.icon,
                  }
                )
              }
            >
              {({ checked }) => (
                <>
                  <div
                    className={classNames('flex items-center', {
                      'flex-col': option.icon,
                    })}
                  >
                    {option.badge && <span className="absolute top-0.5 right-1">{option.badge}</span>}
                    {option.icon && (
                      <div
                        className={classNames('mb-3', {
                          'opacity-50': option.disabled,
                        })}
                      >
                        {option.icon}
                      </div>
                    )}
                    <div className={cn('text-sm', { 'text-center': alignment === 'inline' })}>
                      <RadioGroup.Label
                        as="div"
                        className={classNames('font-medium text-gray-900', {
                          'opacity-50': option.disabled,
                        })}
                      >
                        {option.label}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="div"
                        className={classNames('text-gray-500', {
                          'opacity-50': option.disabled,
                        })}
                      >
                        {option.description}
                      </RadioGroup.Description>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      {
                        'border-indigo-500': checked,
                        'border-transparent': !checked,
                        'border-red-500': showError,
                      },
                      'pointer-events-none absolute -inset-px rounded-lg border-2'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
        {showError && <p className="mt-2 text-sm font-normal text-red-600">{error}</p>}
      </RadioGroup>
    );
  }
);

InputRadioGroup.displayName = 'InputRadioGroup';
