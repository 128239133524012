import { useModal } from '@shape-construction/hooks';
import { useProductTour } from 'app/queries/productTours/productTours';
import React from 'react';
import { ProductTourAlert } from './ProductTourAlert/ProductTourAlert';
import { ProductTourStorylaneModal } from './ProductTourStorylaneModal/ProductTourStorylaneModal';
import { useCurrentProductPage } from './hooks/useCurrentProductPage';

interface ProductTourProps {
  isAlertAlwaysVisible?: boolean;
}

export const ProductTour: React.FC<ProductTourProps> = ({ isAlertAlwaysVisible = false }) => {
  const { open, openModal, closeModal } = useModal(false);

  const currentProductPage = useCurrentProductPage();
  const productTourKey = currentProductPage?.key;

  const { data } = useProductTour(productTourKey!, {
    query: { enabled: Boolean(productTourKey), refetchOnWindowFocus: false }, // avoid closing the modal when the user switches window and modal is open
  });

  if (!productTourKey || !data) return null;
  if (!isAlertAlwaysVisible && (data.firstStartedAt || data.firstDismissedAt)) return null;

  const productTourName = currentProductPage?.title || data.name;

  return (
    <>
      <ProductTourAlert
        openStorylaneModal={openModal}
        productTourKey={productTourKey}
        productTourName={productTourName}
        showCloseButton={!isAlertAlwaysVisible}
        displayInRow={isAlertAlwaysVisible}
      />
      <ProductTourStorylaneModal
        open={open}
        onClose={closeModal}
        productTourKey={productTourKey}
        productTourName={productTourName}
        storylaneUrl={data.storylaneUrl}
      />
    </>
  );
};
