import type { ShiftReportSchema } from '@shape-construction/api/src/types';
import { type PropsWithChildren, createContext, useContext } from 'react';

const currentShiftReportContext = createContext<ShiftReportSchema | undefined>(undefined);

export const useCurrentShiftReport = () => {
  const shiftReport = useContext(currentShiftReportContext);
  if (!shiftReport) throw new Error('Unable to get current shift report');
  return shiftReport;
};

export const CurrentShiftReportProvider: React.FC<PropsWithChildren<{ shiftReport: ShiftReportSchema }>> = ({
  shiftReport,
  children,
}) => <currentShiftReportContext.Provider value={shiftReport}>{children}</currentShiftReportContext.Provider>;
