import { useMessageGetter } from '@messageformat/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ConfirmationModal, IconBadge } from '@shape-construction/arch-ui';
import { SignalSlashIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { type APIError, removeFirst, selectFirstError } from '../../../store/errors/errorSlice';

export const ErrorNotificationModal = () => {
  const dispatch = useDispatch();
  const messages = useMessageGetter('notification.error');
  const error = useSelector(selectFirstError);
  const errorMessage = (error as APIError)?.response?.data?.errorDescription ?? (error as Error)?.message;

  const onClose = () => dispatch(removeFirst());

  if (!error) return null;

  return (
    <div className="w-full">
      <ConfirmationModal.Root open onClose={onClose}>
        <ConfirmationModal.Header centered>
          <ConfirmationModal.Image>
            <IconBadge>
              <SignalSlashIcon />
            </IconBadge>
          </ConfirmationModal.Image>
          <ConfirmationModal.Title>{messages('title')}</ConfirmationModal.Title>
          <ConfirmationModal.SubTitle>{messages('subTitle', { message: errorMessage })}</ConfirmationModal.SubTitle>
        </ConfirmationModal.Header>
        <ConfirmationModal.Footer centered>
          <Button color="primary" variant="outlined" size="md" fullWidth onClick={onClose}>
            {messages('acknowledgeCTA')}
          </Button>
        </ConfirmationModal.Footer>
      </ConfirmationModal.Root>
    </div>
  );
};
