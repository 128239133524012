import React from 'react';
// TODO: Remove: this is a temporary solution that does not create a permanent redirect
// ticket RES-298 has been created to implement a permanent solution

// @ts-expect-error ts-migrate(2554) FIXME
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './App';
import { useSentry } from './analytics/hooks/useSentry';
import { environment } from './config/environment';
import { persistor, store } from './store';

const ReduxApp = React.memo(() => {
  useSentry();

  return (
    <HttpsRedirect disabled={environment.DISABLE_HTTPS_REDIRECT}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </HttpsRedirect>
  );
});

export default ReduxApp;
