import * as React from 'react';
import type { SVGProps } from 'react';
const UserCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/user-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12.0004C21.6 17.3023 17.302 21.6004 12 21.6004C6.69809 21.6004 2.40002 17.3023 2.40002 12.0004C2.40002 6.69846 6.69809 2.40039 12 2.40039C17.302 2.40039 21.6 6.69846 21.6 12.0004ZM15 9.00039C15 10.6572 13.6569 12.0004 12 12.0004C10.3432 12.0004 9.00002 10.6572 9.00002 9.00039C9.00002 7.34354 10.3432 6.00039 12 6.00039C13.6569 6.00039 15 7.34354 15 9.00039ZM12 14.4004C9.64962 14.4004 7.56227 15.5266 6.24817 17.2689C7.67413 18.8248 9.72315 19.8004 12 19.8004C14.2769 19.8004 16.3259 18.8248 17.7519 17.2689C16.4378 15.5266 14.3504 14.4004 12 14.4004Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default UserCircleIcon;
