import { type IssueImageSchema, type IssueSchema, issueImageKindEnum } from '@shape-construction/api/src/types';
import UtilsStats from 'app/components/Utils/UtilsStats';
import { useLiveQuery } from 'dexie-react-hooks';
import db from './db';

export type IssueImageUpload = IssueImageSchema & {
  projectId?: string;
  issueId?: IssueSchema['id'];
  isUploading?: boolean;
  uploadProgress?: number;
  originalFile?: File;
};

export const buildImageUpload = (
  file: File,
  temporaryId: string,
  projectId: string,
  issueId: IssueSchema['id']
): IssueImageUpload => {
  const imageUrl = URL.createObjectURL(file);

  return {
    originalFile: file,
    projectId,
    issueId,
    byteSize: file.size,
    caption: file.name,
    contentType: file.type,
    createdAt: new Date().toISOString(),
    extension: file.type.split('/')[1],
    filename: file.name,
    id: temporaryId,
    isUploading: true,
    kind: issueImageKindEnum.image,
    projectDocumentId: UtilsStats.uuidv4(),
    url: {
      download: imageUrl,
      l: imageUrl,
      original: imageUrl,
      s: imageUrl,
      xxl: imageUrl,
    },
    availableActions: {
      delete: false,
      edit: false,
    },
  };
};

export const createIssueImageUpload = async (image: IssueImageUpload) => {
  await db.issueImageUploads.add(image);
};

export const updateIssueImageUpload = async (id: IssueImageUpload['id'], attributes: Partial<IssueImageUpload>) => {
  await db.issueImageUploads.update(id, attributes);
};

export const deleteIssueImageUpload = async (id: IssueImageUpload['id']) => {
  await db.issueImageUploads.delete(id);
};

export const useIssueImageUploads = (issueId: IssueSchema['id']) =>
  useLiveQuery(() => db.issueImageUploads.where({ issueId }).toArray(), [issueId], []);
