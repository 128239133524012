import { useMessageGetter } from '@messageformat/react';
import {
  postApiProjectsProjectIdIssuesIssueIdIssueImages as createIssueImage,
  deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId as deleteIssueImage,
} from '@shape-construction/api/src/api';
import {
  getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryOptions,
  useDeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId,
  useGetApiProjectsProjectIdIssuesIssueIdIssueImages,
  usePatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId,
} from '@shape-construction/api/src/hooks';
import type {
  IssueImageKindSchema,
  IssueImageListSchema,
  IssueImageSchema,
  IssueSchema,
  ProjectSchema,
} from '@shape-construction/api/src/types';
import { showErrorToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import { type UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import type { ResponseError } from 'app/axios';
import { DirectUpload, type DirectUploadProgressEvent } from 'app/lib/direct-upload/direct-upload';
import {
  buildImageUpload,
  createIssueImageUpload,
  deleteIssueImageUpload,
  updateIssueImageUpload,
} from 'app/localData/issueImages';
import { retryOnNetworkOrServerErrors } from 'app/queries/utils';

export const useIssueImages = useGetApiProjectsProjectIdIssuesIssueIdIssueImages;

type UploadIssueImageParameters = {
  kind: IssueImageKindSchema;
  file: File;
  temporaryId: string;
  projectId: ProjectSchema['id'];
  issueId: IssueSchema['id'];
};
export const useUploadIssueImageOptions = (): UseMutationOptions<
  IssueImageSchema,
  ResponseError,
  UploadIssueImageParameters
> => {
  const queryClient = useQueryClient();
  const messages = useMessageGetter('error');

  return {
    mutationKey: ['createIssueImage'],
    mutationFn: async ({ kind, file, temporaryId, projectId, issueId }) => {
      const uploadRequestDidProgress = (event: DirectUploadProgressEvent) => {
        const currentUploadProgress = event.total ? (event.loaded / event.total) * 100 : 0;

        updateIssueImageUpload(temporaryId, { uploadProgress: currentUploadProgress });
      };

      let signedId;
      if (file) {
        signedId = await DirectUpload(file, 'issue_image', {
          onUploadProgress: uploadRequestDidProgress,
        });

        if (!signedId) {
          throw new Error(messages('uploadError'));
        }
      }

      return createIssueImage(projectId, issueId, { file_signed_id: signedId, kind });
    },
    onMutate: ({ file, temporaryId, projectId, issueId }) => {
      createIssueImageUpload(buildImageUpload(file, temporaryId, projectId, issueId));
    },
    onSuccess: (image, { projectId, issueId, file }) => {
      queryClient.setQueryData<IssueImageListSchema>(
        getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryOptions(projectId, issueId).queryKey,
        (previousImages = []) => [...previousImages, image]
      );
    },
    onError: (error, { issueId }) => {
      const status = error.response?.status;

      if (status === 422) {
        // @ts-ignore
        // Using a deprecatred request. The schema does not specify the correct payload
        const [imageUploadError] = error.response?.data?.images || [];

        showErrorToast({
          message: `${messages('uploadError')}. ${imageUploadError || ''}`,
        });
      } else {
        showErrorToast({
          message: messages('uploadError'),
        });
      }
    },
    onSettled: (_data, _error, { temporaryId }) => {
      deleteIssueImageUpload(temporaryId);
    },
    retry: (_, error) => retryOnNetworkOrServerErrors(error),
  };
};

export const useUploadIssueImage = () => {
  return useMutation(useUploadIssueImageOptions());
};

export const useUpdateIssueImage = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId({
    mutation: {
      onSuccess: (_, { projectId, issueId, issueImageId, data }) => {
        queryClient.setQueryData<IssueImageListSchema>(
          getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryOptions(projectId, issueId).queryKey,
          (previousImages = []) =>
            previousImages.map((image) => {
              if (image.id === issueImageId) {
                return { ...image, ...data };
              }
              return image;
            })
        );
      },
    },
  });
};

export const useDeleteIssueImage = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId({
    mutation: {
      onSuccess: (_, { projectId, issueId, issueImageId }) => {
        queryClient.setQueryData<IssueImageListSchema>(
          getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryOptions(projectId, issueId).queryKey,
          (previousImages = []) => previousImages.filter(({ id }) => id !== issueImageId)
        );
      },
    },
  });
};

export const useDeleteIssueImages = (projectId: ProjectSchema['id'], issueId: IssueSchema['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ imagesId }: { imagesId: string[] }) => {
      const promises = imagesId.map((imageId) => deleteIssueImage(projectId, issueId, imageId));
      return Promise.all(promises);
    },
    onSuccess: (_, { imagesId }) => {
      queryClient.setQueryData<IssueImageListSchema>(
        getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryOptions(projectId, issueId).queryKey,
        (previousImages = []) => previousImages.filter(({ id }) => !imagesId.includes(id))
      );
    },
  });
};
