import React from 'react';
import type { PropsWithChildren } from 'react';
import { cn } from '../utils/classes';

export type CheckListRootProps = PropsWithChildren & {
  isVisible: boolean;
  className?: string;
};

export const CheckListRoot = ({ children, isVisible, className }: CheckListRootProps) => {
  return (
    <div
      className={cn(
        'overflow-hidden transition-all duration-500 ease-in-out grid',
        isVisible ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0',
        isVisible && className
      )}
      aria-hidden={!isVisible}
    >
      <div className="overflow-hidden">
        <ul className="space-y-2">{children}</ul>
      </div>
    </div>
  );
};
