import type { ProjectSchema, ShiftReportSchema } from '@shape-construction/api/src/types';
import { useCreateShiftReport } from 'app/queries/shiftReports/shiftReports';
import {
  getInitialReportFormValues,
  makeFormValuesFromReportData,
} from 'app/screens/Project/ProjectShiftManager/ShiftReports/ShiftReportForm/ShiftReportForm';
import { useNavigate } from 'react-router';

export const useCreateDraftReport = (projectId: ProjectSchema['id']) => {
  const navigate = useNavigate();
  const { mutate: createShiftReport } = useCreateShiftReport();

  const redirectToEditReport = (shiftReportId: string, tab = '') => {
    navigate(`/projects/${projectId}/shift-reports/${shiftReportId}/edit`, { state: { tab } });
  };

  const createDraftReport = (shiftReportTemplate?: ShiftReportSchema) => {
    const values = shiftReportTemplate
      ? makeFormValuesFromReportData(shiftReportTemplate, true)
      : getInitialReportFormValues();
    createShiftReport(
      {
        projectId,
        data: values,
      },
      {
        onSuccess: (createdReport) => redirectToEditReport(createdReport.id, 'drafts'),
      }
    );
  };

  return createDraftReport;
};
