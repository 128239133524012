import * as React from 'react';
import type { SVGProps } from 'react';
const ActivitiesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="outline/activities">
      <path
        id="Icon"
        d="M14.6975 3.75H5.75C4.64543 3.75 3.75 4.64543 3.75 5.75V18.25C3.75 19.3546 4.64543 20.25 5.75 20.25H18.25C19.3546 20.25 20.25 19.3546 20.25 18.25V9.30245M7.31116 14.5246L10.3178 10.5744L13.7067 13.2744L16.6888 9.47461M21.25 4.675C21.25 5.73815 20.3882 6.6 19.325 6.6C18.2619 6.6 17.4 5.73815 17.4 4.675C17.4 3.61185 18.2619 2.75 19.325 2.75C20.3882 2.75 21.25 3.61185 21.25 4.675Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default ActivitiesIcon;
