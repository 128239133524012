import { yupResolver } from '@hookform/resolvers/yup';
import { useMessage } from '@messageformat/react';
import {
  type PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema,
  type PostApiProjectsProjectIdShiftReportsMutationRequestSchema,
  type ShiftReportSchema,
  type ShiftReportVisibilityEnumSchema,
  shiftReportVisibilityEnum,
} from '@shape-construction/api/src/types';
import { now, parseDateWithFormat } from '@shape-construction/utils/DateTime';
import { useCurrentShiftReport } from 'app/contexts/currentShiftReport';
import { useUpdateShiftReport } from 'app/queries/shiftReports/shiftReports';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  type FieldArrayPath,
  FormProvider,
  type UseFormRegister,
  type UseFormReturn,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import {
  formValuesDifference,
  formatItemsToSnakeCase,
  getDirtyValues,
  makeOption,
  replaceNullsFromObject,
  transformFormValueObjectIntoPathname,
} from './formUtils';

type ShiftReportsActivitiesItem = NonNullable<
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema['activities']
>[number];
type ShiftReportsContractForcesItem = NonNullable<
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema['contract_forces']
>[number];
type ShiftReportsDownTimesItem = NonNullable<
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema['down_times']
>[number];
type ShiftReportsEquipmentsItem = NonNullable<
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema['equipments']
>[number];
type ShiftReportsMaterialsItem = NonNullable<
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema['materials']
>[number];
type ShiftReportsSafetyHealthEnvironmentsItem = NonNullable<
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema['safety_health_environments']
>[number];

type ExtraFields = { team_member_id?: number; document_count?: number };
export type ActivityItem = ShiftReportsActivitiesItem & ExtraFields;
export type ContractForceItem = ShiftReportsContractForcesItem & ExtraFields;
export type EquipmentItem = ShiftReportsEquipmentsItem & ExtraFields;
export type SafetyHealthEnvironmentItem = ShiftReportsSafetyHealthEnvironmentsItem & ExtraFields;
export type MaterialItem = ShiftReportsMaterialsItem & ExtraFields;
export type DownTimeItem = ShiftReportsDownTimesItem & ExtraFields;
export type ShiftReportsItemWithExtraFields =
  | ActivityItem
  | ContractForceItem
  | EquipmentItem
  | SafetyHealthEnvironmentItem
  | MaterialItem
  | DownTimeItem;

export type ShiftReportArrayFields = keyof Pick<
  PostApiProjectsProjectIdShiftReportsMutationRequestSchema,
  'activities' | 'contract_forces' | 'equipments' | 'safety_health_environments' | 'materials' | 'down_times'
>;

type DeepObjectRequired<T> = {
  [K in keyof T]-?: T[K] extends object ? DeepRequired<T[K]> : T[K];
};
type DeepRequired<T> = {
  [K in keyof T]-?: T[K] extends Array<infer U> ? DeepRequired<U>[] : DeepObjectRequired<T[K]>;
};

export type ShiftReportFormRowPath<T, K extends keyof T = keyof T> = K extends string
  ? T[K] extends object
    ? T[K] extends { id: string | null }[]
      ? `${K}.${number}` | `${K}.${number}.${ShiftReportFormRowPath<NonNullable<T[K]>[number]>}`
      : never
    : never
  : never;

export type ShiftReportRowPathname = ShiftReportFormRowPath<
  DeepRequired<Pick<ShiftReportFormValues, ShiftReportArrayFields>>
>;

export type ShiftReportRowsPathname = FieldArrayPath<Pick<ShiftReportFormValues, ShiftReportArrayFields>>;

export type ContractorLogo =
  | (ShiftReportSchema['contractorLogo'] & {
      new?: File;
    })
  | null;

export type ShiftReportFormValues = Omit<
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema,
  ShiftReportArrayFields
> & {
  report_date: string;
  visibility: ShiftReportVisibilityEnumSchema;
  activities?: ActivityItem[];
  contract_forces?: ContractForceItem[];
  equipments?: EquipmentItem[];
  safety_health_environments?: SafetyHealthEnvironmentItem[];
  materials?: MaterialItem[];
  down_times?: DownTimeItem[];
  contractor_logo?: ContractorLogo;
};
export type ShiftReportFormComponentProps = UseFormReturn<ShiftReportFormValues>;
interface ShiftReportFormProps {
  component: React.ComponentType<ShiftReportFormComponentProps>;
}

type Params = {
  projectId: string;
  shiftReportId: string;
};

export const useShiftReportFormContext = () => {
  const { projectId, shiftReportId } = useParams<Params>() as Params;
  const { mutate: updateShiftReport, isPending } = useUpdateShiftReport();
  const {
    formState,
    handleSubmit,
    register: contextRegister,
    reset,
    resetField,
    ...formContext
  } = useFormContext<ShiftReportFormValues>();
  const getValues = formContext.getValues;
  const setValue = formContext.setValue;
  const dirtyFields = formState.dirtyFields;
  const dirtyFieldsRef = useRef(dirtyFields);
  const getValuesRef = useRef(getValues);
  const setValueRef = useRef(setValue);

  useEffect(() => {
    getValuesRef.current = getValues;
  }, [getValues]);

  useEffect(() => {
    setValueRef.current = setValue;
  }, [setValue]);

  useEffect(() => {
    dirtyFieldsRef.current = dirtyFields;
  }, [dirtyFields]);

  /**
   * This method has some extra logic to deal with the full shift report response.
   * It only updates the non dirty values.
   * In the future if we migrate this logic to a debounce + websockets this is no
   * longer needed.
   */

  const submitForm = useCallback(
    (onSuccessCallback?: () => void) =>
      handleSubmit(async (values: ShiftReportFormValues) => {
        const hasDirtyValues = Object.keys(dirtyFields).length >= 1;

        if (hasDirtyValues) {
          const dirtyFieldsPayload = getDirtyValues(values, dirtyFieldsRef.current);
          updateShiftReport(
            { projectId, shiftReportId, data: dirtyFieldsPayload },
            {
              onSuccess: (updatedShiftReport) => {
                const newShiftReportFromValues = makeFormValuesFromReportData(updatedShiftReport);

                reset(newShiftReportFromValues);

                const currentDirtyFields = getDirtyValues(values, dirtyFieldsRef.current);

                const dirtyFieldsToMaintain = formValuesDifference(currentDirtyFields, dirtyFieldsPayload);

                const dirtyFieldsPathnameValue = transformFormValueObjectIntoPathname(
                  getValuesRef.current(),
                  dirtyFieldsToMaintain
                );

                dirtyFieldsPathnameValue.forEach((item) =>
                  setValueRef.current(item.pathname, item.value, {
                    shouldDirty: true,
                  })
                );

                typeof onSuccessCallback === 'function' && onSuccessCallback();
              },
            }
          );
        }
      })(),
    [dirtyFields, handleSubmit, projectId, reset, shiftReportId, updateShiftReport]
  );

  const registerWithOnBlurSave: UseFormRegister<ShiftReportFormValues> = (fieldPath, options) =>
    contextRegister(fieldPath, {
      ...options,
      onBlur: () => submitForm(),
    });

  return {
    formState,
    handleSubmit,
    submitForm,
    register: registerWithOnBlurSave,
    reset,
    ...formContext,
  };
};

export const ShiftReportForm = ({ component: Component }: ShiftReportFormProps) => {
  const shiftReport = useCurrentShiftReport();
  const requiredFieldMessage = useMessage('errors.requiredField');
  const validationSchema = Yup.object().shape({
    report_date: Yup.string().required(requiredFieldMessage),
  });

  const values = useMemo(() => makeFormValuesFromReportData(shiftReport), [shiftReport]);

  const form = useForm<ShiftReportFormValues>({
    values,
    resolver: yupResolver(validationSchema),
  });

  return (
    <FormProvider {...form}>
      <Component {...form} />
    </FormProvider>
  );
};

export const arrayFields: Array<ShiftReportArrayFields> = [
  'activities',
  'contract_forces',
  'equipments',
  'safety_health_environments',
  'materials',
  'down_times',
];

export const getInitialReportFormValues = (): PostApiProjectsProjectIdShiftReportsMutationRequestSchema => {
  return {
    visibility: shiftReportVisibilityEnum.private,
    project_number: '',
    contractor_name: '',
    internal_document_reference_number: '',
    client_document_reference_number: '',
    report_title: '',
    report_date: parseDateWithFormat(now(), 'YYYY-MM-DD'),
    shift_type: '',
    shift_start: '',
    shift_end: '',
    weather_temperature: '',
    weather_description: '',
    notes: '',
    collaborators_team_member_ids: [],
    activities: [],
    contract_forces: [],
    equipments: [],
    safety_health_environments: [],
    materials: [],
    down_times: [],
  };
};

const makePreFilledFromValues = (formValues: ShiftReportFormValues) => {
  const preFilledFormValues = { ...formValues };

  arrayFields.forEach((field) => {
    if (field === 'activities' || field === 'down_times') {
      preFilledFormValues[field] = preFilledFormValues[field]?.map((item) => ({
        ...item,
        id: undefined,
        _id: item.id || undefined,
        team_member_id: undefined,
      }));
    } else if (field === 'contract_forces' || field === 'equipments' || field === 'materials') {
      preFilledFormValues[field] = preFilledFormValues[field]?.map((item) => ({
        ...item,
        id: undefined,
        activities: item.activities?.map((activityLink) => ({ ...activityLink, id: undefined })),
        down_times: item.down_times?.map((downtimeLink) => ({ ...downtimeLink, id: undefined })),
        team_member_id: undefined,
      }));
    } else {
      preFilledFormValues[field] = preFilledFormValues[field]?.map((item) => ({
        ...item,
        id: undefined,
        team_member_id: undefined,
      }));
    }
  });

  return preFilledFormValues;
};

export const makeFormValuesFromReportData = (shiftReport: ShiftReportSchema, isPreFillForm = false) =>
  isPreFillForm
    ? makePreFilledFromValues(transformReportDataToFormValues(shiftReport))
    : transformReportDataToFormValues(shiftReport);

export const transformReportDataToFormValues = ({
  visibility,
  projectNumber,
  contractorName,
  internalDocumentReferenceNumber,
  clientDocumentReferenceNumber,
  reportTitle,
  reportDate,
  shiftType,
  shiftStart,
  shiftEnd,
  weatherTemperature,
  weatherDescription,
  notes,
  activities,
  contractForces,
  equipments,
  safetyHealthEnvironments,
  materials,
  downTimes,
  collaboratorsTeamMemberIds,
  contractorLogo,
}: ShiftReportSchema): ShiftReportFormValues => {
  const initialReportFormValues = getInitialReportFormValues();
  const body: ShiftReportFormValues = {
    visibility: visibility ?? initialReportFormValues.visibility,
    project_number: projectNumber,
    contractor_name: contractorName,
    internal_document_reference_number: internalDocumentReferenceNumber,
    client_document_reference_number: clientDocumentReferenceNumber,
    report_title: reportTitle,
    report_date: reportDate ?? initialReportFormValues.report_date,
    shift_type: shiftType,
    shift_start: shiftStart,
    shift_end: shiftEnd,
    weather_temperature: weatherTemperature,
    weather_description: weatherDescription,
    notes,
    contractor_logo: contractorLogo,
    contractor_logo_signed_id: contractorLogo?.signedId,
    collaborators_team_member_ids: collaboratorsTeamMemberIds,
    activities: formatItemsToSnakeCase(activities),
    contract_forces: formatItemsToSnakeCase(contractForces),
    equipments: formatItemsToSnakeCase(equipments),
    safety_health_environments: formatItemsToSnakeCase(safetyHealthEnvironments),
    materials: formatItemsToSnakeCase(materials),
    down_times: formatItemsToSnakeCase(downTimes),
  };
  return replaceNullsFromObject(body);
};

const buildDestroyedRows = (oldRows: ShiftReportsItemWithExtraFields[], newRows: ShiftReportsItemWithExtraFields[]) => {
  const rowsToDestroy = oldRows.map((row) => ({ id: row.id, _destroy: 'true' }));

  return (newRows || rowsToDestroy) && [...newRows, ...rowsToDestroy];
};

export const combineShiftReports = (
  oldShiftReportValues: ShiftReportFormValues,
  newShiftReportValues: ShiftReportFormValues
): ShiftReportFormValues => {
  return {
    ...newShiftReportValues,
    activities: buildDestroyedRows(oldShiftReportValues?.activities || [], newShiftReportValues?.activities || []),
    contract_forces: buildDestroyedRows(
      oldShiftReportValues?.contract_forces || [],
      newShiftReportValues?.contract_forces || []
    ),
    equipments: buildDestroyedRows(oldShiftReportValues?.equipments || [], newShiftReportValues?.equipments || []),
    safety_health_environments: buildDestroyedRows(
      oldShiftReportValues?.safety_health_environments || [],
      newShiftReportValues?.safety_health_environments || []
    ),
    materials: buildDestroyedRows(oldShiftReportValues?.materials || [], newShiftReportValues?.materials || []),
    down_times: buildDestroyedRows(oldShiftReportValues?.down_times || [], newShiftReportValues?.down_times || []),
  };
};

export const unitOptions = [
  '',
  'foot',
  'linear metre',
  'square foot',
  'square metre',
  'cubic foot',
  'cubic metre',
  'litre',
  'gallon',
  'pound',
  'kilogramme',
  'imperial ton',
  'metric tonne',
  'number',
  '%',
].map(makeOption);

export const plannedUnplannedOptions = ['', 'planned', 'unplanned'].map(makeOption);
