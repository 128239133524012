/**
 * Generated by kubb.
 * Do not edit manually.
 * Shape API
 */

import client from '../../client';
import type { RequestConfig } from '../../client';
import type { DeleteApiLogoutMutationResponseSchema, DeleteApiLogout401Schema } from '../types/deleteApiLogoutSchema';
import type {
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema,
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldId401Schema,
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldId403Schema,
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldId404Schema,
} from '../types/deleteApiProjectsProjectIdCustomFieldsCustomFieldIdSchema';
import type {
  DeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineId401Schema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineId403Schema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineId404Schema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineId422Schema,
} from '../types/deleteApiProjectsProjectIdDisciplinesDisciplineIdSchema';
import type {
  DeleteApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
  DeleteApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema,
  DeleteApiProjectsProjectIdDocumentsDocumentId401Schema,
  DeleteApiProjectsProjectIdDocumentsDocumentId403Schema,
  DeleteApiProjectsProjectIdDocumentsDocumentId404Schema,
} from '../types/deleteApiProjectsProjectIdDocumentsDocumentIdSchema';
import type {
  DeleteApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
  DeleteApiProjectsProjectIdGroupsGroupIdPathParamsSchema,
  DeleteApiProjectsProjectIdGroupsGroupId401Schema,
  DeleteApiProjectsProjectIdGroupsGroupId403Schema,
  DeleteApiProjectsProjectIdGroupsGroupId404Schema,
} from '../types/deleteApiProjectsProjectIdGroupsGroupIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId404Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId422Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId404Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueId400Schema,
  DeleteApiProjectsProjectIdIssuesIssueId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueId404Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId404Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId422Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdWatchings401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdWatchings404Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdWatchingsSchema';
import type {
  DeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewId401Schema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewId403Schema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewId404Schema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewId422Schema,
} from '../types/deleteApiProjectsProjectIdIssueViewsIssueViewIdSchema';
import type {
  DeleteApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
  DeleteApiProjectsProjectIdLocationsLocationIdPathParamsSchema,
  DeleteApiProjectsProjectIdLocationsLocationId401Schema,
  DeleteApiProjectsProjectIdLocationsLocationId403Schema,
  DeleteApiProjectsProjectIdLocationsLocationId404Schema,
  DeleteApiProjectsProjectIdLocationsLocationId422Schema,
} from '../types/deleteApiProjectsProjectIdLocationsLocationIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId401Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId403Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema,
} from '../types/deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionSchema';
import type {
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId401Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId403Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId404Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId422Schema,
} from '../types/deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId401Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId403Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId401Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId403Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportId400Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportId401Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportId403Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftReportsShiftReportIdSchema';
import type {
  DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdJoinToken401Schema,
  DeleteApiProjectsProjectIdTeamsTeamIdJoinToken403Schema,
  DeleteApiProjectsProjectIdTeamsTeamIdJoinToken404Schema,
} from '../types/deleteApiProjectsProjectIdTeamsTeamIdJoinTokenSchema';
import type {
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema,
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema,
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema,
} from '../types/deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdSchema';
import type {
  DeleteApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdPathParamsSchema,
  DeleteApiProjectsProjectIdTeamsTeamId401Schema,
  DeleteApiProjectsProjectIdTeamsTeamId403Schema,
  DeleteApiProjectsProjectIdTeamsTeamId404Schema,
} from '../types/deleteApiProjectsProjectIdTeamsTeamIdSchema';
import type {
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationResponseSchema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate401Schema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate403Schema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate404Schema,
} from '../types/deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateSchema';
import type {
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema,
} from '../types/deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSchema';
import type {
  DeleteApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
  DeleteApiPushSubscriptionsPushSubscriptionIdPathParamsSchema,
  DeleteApiPushSubscriptionsPushSubscriptionId401Schema,
  DeleteApiPushSubscriptionsPushSubscriptionId404Schema,
} from '../types/deleteApiPushSubscriptionsPushSubscriptionIdSchema';
import type {
  GetApiAgreementsLatestEuaQueryResponseSchema,
  GetApiAgreementsLatestEua404Schema,
} from '../types/getApiAgreementsLatestEuaSchema';
import type {
  GetApiConstructionRolesQueryResponseSchema,
  GetApiConstructionRoles401Schema,
} from '../types/getApiConstructionRolesSchema';
import type {
  GetApiFeatureFlagsQueryResponseSchema,
  GetApiFeatureFlagsQueryParamsSchema,
  GetApiFeatureFlags422Schema,
} from '../types/getApiFeatureFlagsSchema';
import type {
  GetApiNotificationsOverviewQueryResponseSchema,
  GetApiNotificationsOverview401Schema,
} from '../types/getApiNotificationsOverviewSchema';
import type {
  GetApiNotificationsQueryResponseSchema,
  GetApiNotificationsQueryParamsSchema,
  GetApiNotifications400Schema,
  GetApiNotifications401Schema,
} from '../types/getApiNotificationsSchema';
import type {
  GetApiOnboardingQueryResponseSchema,
  GetApiOnboarding401Schema,
  GetApiOnboarding404Schema,
} from '../types/getApiOnboardingSchema';
import type {
  GetApiOrgsOrgIdQueryResponseSchema,
  GetApiOrgsOrgIdPathParamsSchema,
  GetApiOrgsOrgId401Schema,
  GetApiOrgsOrgId403Schema,
  GetApiOrgsOrgId404Schema,
} from '../types/getApiOrgsOrgIdSchema';
import type { GetApiOrgsQueryResponseSchema, GetApiOrgs401Schema } from '../types/getApiOrgsSchema';
import type {
  GetApiProductToursProductTourKeyQueryResponseSchema,
  GetApiProductToursProductTourKeyPathParamsSchema,
  GetApiProductToursProductTourKey401Schema,
  GetApiProductToursProductTourKey404Schema,
} from '../types/getApiProductToursProductTourKeySchema';
import type {
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema,
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId401Schema,
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId403Schema,
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId404Schema,
} from '../types/getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSchema';
import type {
  GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
  GetApiProjectsProjectIdAccessRequestsPathParamsSchema,
  GetApiProjectsProjectIdAccessRequestsQueryParamsSchema,
  GetApiProjectsProjectIdAccessRequests400Schema,
  GetApiProjectsProjectIdAccessRequests401Schema,
  GetApiProjectsProjectIdAccessRequests403Schema,
  GetApiProjectsProjectIdAccessRequests404Schema,
} from '../types/getApiProjectsProjectIdAccessRequestsSchema';
import type {
  GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
  GetApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema,
  GetApiProjectsProjectIdControlCenterPotentialChangesQueryParamsSchema,
  GetApiProjectsProjectIdControlCenterPotentialChanges401Schema,
  GetApiProjectsProjectIdControlCenterPotentialChanges404Schema,
} from '../types/getApiProjectsProjectIdControlCenterPotentialChangesSchema';
import type {
  GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
  GetApiProjectsProjectIdCustomFieldsPathParamsSchema,
  GetApiProjectsProjectIdCustomFields401Schema,
  GetApiProjectsProjectIdCustomFields403Schema,
  GetApiProjectsProjectIdCustomFields404Schema,
} from '../types/getApiProjectsProjectIdCustomFieldsSchema';
import type {
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryParamsSchema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType401Schema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType403Schema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType404Schema,
} from '../types/getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSchema';
import type {
  GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessCountPathParamsSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessCount401Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessCount403Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessCount404Schema,
} from '../types/getApiProjectsProjectIdDashboardsIssuesStalenessCountSchema';
import type {
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesPathParamsSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryParamsSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssues400Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssues401Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssues403Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssues404Schema,
} from '../types/getApiProjectsProjectIdDashboardsIssuesStalenessIssuesSchema';
import type {
  GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
  GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema,
  GetApiProjectsProjectIdDisciplinesDisciplineId401Schema,
  GetApiProjectsProjectIdDisciplinesDisciplineId403Schema,
  GetApiProjectsProjectIdDisciplinesDisciplineId404Schema,
} from '../types/getApiProjectsProjectIdDisciplinesDisciplineIdSchema';
import type {
  GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
  GetApiProjectsProjectIdDisciplinesPathParamsSchema,
  GetApiProjectsProjectIdDisciplinesQueryParamsSchema,
  GetApiProjectsProjectIdDisciplines401Schema,
  GetApiProjectsProjectIdDisciplines403Schema,
  GetApiProjectsProjectIdDisciplines404Schema,
} from '../types/getApiProjectsProjectIdDisciplinesSchema';
import type {
  GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
  GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema,
  GetApiProjectsProjectIdDocumentsDocumentIdReferences401Schema,
  GetApiProjectsProjectIdDocumentsDocumentIdReferences403Schema,
  GetApiProjectsProjectIdDocumentsDocumentIdReferences404Schema,
} from '../types/getApiProjectsProjectIdDocumentsDocumentIdReferencesSchema';
import type {
  GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
  GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema,
  GetApiProjectsProjectIdDocumentsDocumentId401Schema,
  GetApiProjectsProjectIdDocumentsDocumentId403Schema,
  GetApiProjectsProjectIdDocumentsDocumentId404Schema,
} from '../types/getApiProjectsProjectIdDocumentsDocumentIdSchema';
import type {
  GetApiProjectsProjectIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdDocuments400Schema,
  GetApiProjectsProjectIdDocuments401Schema,
  GetApiProjectsProjectIdDocuments403Schema,
  GetApiProjectsProjectIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdEventsQueryResponseSchema,
  GetApiProjectsProjectIdEventsPathParamsSchema,
  GetApiProjectsProjectIdEventsQueryParamsSchema,
  GetApiProjectsProjectIdEvents401Schema,
  GetApiProjectsProjectIdEvents403Schema,
  GetApiProjectsProjectIdEvents404Schema,
} from '../types/getApiProjectsProjectIdEventsSchema';
import type {
  GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
  GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema,
  GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema,
  GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema,
  GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema,
} from '../types/getApiProjectsProjectIdGroupsGroupIdChannelConfigurationSchema';
import type {
  GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
  GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema,
  GetApiProjectsProjectIdGroupsGroupId401Schema,
  GetApiProjectsProjectIdGroupsGroupId403Schema,
  GetApiProjectsProjectIdGroupsGroupId404Schema,
} from '../types/getApiProjectsProjectIdGroupsGroupIdSchema';
import type {
  GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
  GetApiProjectsProjectIdIssuesGroupCountPathParamsSchema,
  GetApiProjectsProjectIdIssuesGroupCountQueryParamsSchema,
  GetApiProjectsProjectIdIssuesGroupCount400Schema,
  GetApiProjectsProjectIdIssuesGroupCount401Schema,
  GetApiProjectsProjectIdIssuesGroupCount403Schema,
  GetApiProjectsProjectIdIssuesGroupCount404Schema,
} from '../types/getApiProjectsProjectIdIssuesGroupCountSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdDocuments400Schema,
  GetApiProjectsProjectIdIssuesIssueIdDocuments401Schema,
  GetApiProjectsProjectIdIssuesIssueIdDocuments403Schema,
  GetApiProjectsProjectIdIssuesIssueIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublic400Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublic401Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublic404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdFeedPublicSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeam400Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeam401Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeam403Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeam404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdFeedTeamSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImages401Schema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImages403Schema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImages404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdIssueImagesSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueId401Schema,
  GetApiProjectsProjectIdIssuesIssueId403Schema,
  GetApiProjectsProjectIdIssuesIssueId404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema,
  GetApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema,
  GetApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdStatusStatementsSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdVisit401Schema,
  GetApiProjectsProjectIdIssuesIssueIdVisit403Schema,
  GetApiProjectsProjectIdIssuesIssueIdVisit404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdVisitSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdWatchings401Schema,
  GetApiProjectsProjectIdIssuesIssueIdWatchings404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdWatchingsSchema';
import type {
  GetApiProjectsProjectIdIssuesQueryResponseSchema,
  GetApiProjectsProjectIdIssuesPathParamsSchema,
  GetApiProjectsProjectIdIssuesQueryParamsSchema,
  GetApiProjectsProjectIdIssues400Schema,
  GetApiProjectsProjectIdIssues401Schema,
  GetApiProjectsProjectIdIssues403Schema,
  GetApiProjectsProjectIdIssues404Schema,
} from '../types/getApiProjectsProjectIdIssuesSchema';
import type {
  GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
  GetApiProjectsProjectIdIssueViewsPathParamsSchema,
  GetApiProjectsProjectIdIssueViews401Schema,
  GetApiProjectsProjectIdIssueViews403Schema,
  GetApiProjectsProjectIdIssueViews404Schema,
} from '../types/getApiProjectsProjectIdIssueViewsSchema';
import type {
  GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
  GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema,
  GetApiProjectsProjectIdLocationsLocationId401Schema,
  GetApiProjectsProjectIdLocationsLocationId403Schema,
  GetApiProjectsProjectIdLocationsLocationId404Schema,
} from '../types/getApiProjectsProjectIdLocationsLocationIdSchema';
import type {
  GetApiProjectsProjectIdLocationsQueryResponseSchema,
  GetApiProjectsProjectIdLocationsPathParamsSchema,
  GetApiProjectsProjectIdLocationsQueryParamsSchema,
  GetApiProjectsProjectIdLocations401Schema,
  GetApiProjectsProjectIdLocations403Schema,
  GetApiProjectsProjectIdLocations404Schema,
} from '../types/getApiProjectsProjectIdLocationsSchema';
import type {
  GetApiProjectsProjectIdPeopleQueryResponseSchema,
  GetApiProjectsProjectIdPeoplePathParamsSchema,
  GetApiProjectsProjectIdPeopleQueryParamsSchema,
  GetApiProjectsProjectIdPeople400Schema,
  GetApiProjectsProjectIdPeople401Schema,
  GetApiProjectsProjectIdPeople404Schema,
} from '../types/getApiProjectsProjectIdPeopleSchema';
import type {
  GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
  GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema,
  GetApiProjectsProjectIdPeopleTeamMemberId401Schema,
  GetApiProjectsProjectIdPeopleTeamMemberId403Schema,
  GetApiProjectsProjectIdPeopleTeamMemberId404Schema,
} from '../types/getApiProjectsProjectIdPeopleTeamMemberIdSchema';
import type {
  GetApiProjectsProjectIdQueryResponseSchema,
  GetApiProjectsProjectIdPathParamsSchema,
  GetApiProjectsProjectId401Schema,
  GetApiProjectsProjectId403Schema,
  GetApiProjectsProjectId404Schema,
} from '../types/getApiProjectsProjectIdSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesQueryParamsSchema,
  GetApiProjectsProjectIdShiftActivities400Schema,
  GetApiProjectsProjectIdShiftActivities401Schema,
  GetApiProjectsProjectIdShiftActivities403Schema,
  GetApiProjectsProjectIdShiftActivities404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSchema';
import type {
  GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsArchivedPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsArchivedQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsArchived400Schema,
  GetApiProjectsProjectIdShiftReportsArchived401Schema,
  GetApiProjectsProjectIdShiftReportsArchived403Schema,
  GetApiProjectsProjectIdShiftReportsArchived404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsArchivedSchema';
import type {
  GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsCompletionsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsCompletions401Schema,
  GetApiProjectsProjectIdShiftReportsCompletions403Schema,
} from '../types/getApiProjectsProjectIdShiftReportsCompletionsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsDraftPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsDraftQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsDraft400Schema,
  GetApiProjectsProjectIdShiftReportsDraft401Schema,
  GetApiProjectsProjectIdShiftReportsDraft403Schema,
  GetApiProjectsProjectIdShiftReportsDraft404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsDraftSchema';
import type {
  GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsQueryParamsSchema,
  GetApiProjectsProjectIdShiftReports400Schema,
  GetApiProjectsProjectIdShiftReports401Schema,
  GetApiProjectsProjectIdShiftReports403Schema,
  GetApiProjectsProjectIdShiftReports404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeople401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeople403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeople404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdPeopleSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators403Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportId401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportId403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportId404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdSchema';
import type {
  GetApiProjectsProjectIdTeamsQueryResponseSchema,
  GetApiProjectsProjectIdTeamsPathParamsSchema,
  GetApiProjectsProjectIdTeams401Schema,
  GetApiProjectsProjectIdTeams403Schema,
  GetApiProjectsProjectIdTeams404Schema,
} from '../types/getApiProjectsProjectIdTeamsSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema,
  GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema,
  GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdChannelConfigurationSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdJoinToken401Schema,
  GetApiProjectsProjectIdTeamsTeamIdJoinToken403Schema,
  GetApiProjectsProjectIdTeamsTeamIdJoinToken404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdJoinTokenSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies401Schema,
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies403Schema,
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard401Schema,
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard403Schema,
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard503Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdResourcesKindSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamId401Schema,
  GetApiProjectsProjectIdTeamsTeamId403Schema,
  GetApiProjectsProjectIdTeamsTeamId404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan401Schema,
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan403Schema,
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansQueryParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlans400Schema,
  GetApiProjectsProjectIdWeeklyWorkPlans401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlans403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlans404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSchema';
import type { GetApiProjectsQueryResponseSchema, GetApiProjects401Schema } from '../types/getApiProjectsSchema';
import type {
  GetApiQueuedTasksQueryResponseSchema,
  GetApiQueuedTasksQueryParamsSchema,
  GetApiQueuedTasks400Schema,
  GetApiQueuedTasks401Schema,
} from '../types/getApiQueuedTasksSchema';
import type {
  GetApiTeamJoinTokensTokenQueryResponseSchema,
  GetApiTeamJoinTokensTokenPathParamsSchema,
  GetApiTeamJoinTokensToken404Schema,
} from '../types/getApiTeamJoinTokensTokenSchema';
import type { GetApiTimeZonesQueryResponseSchema, GetApiTimeZones401Schema } from '../types/getApiTimeZonesSchema';
import type { GetApiUsersMeQueryResponseSchema, GetApiUsersMe401Schema } from '../types/getApiUsersMeSchema';
import type {
  PatchApiOnboardingMutationRequestSchema,
  PatchApiOnboardingMutationResponseSchema,
  PatchApiOnboarding401Schema,
  PatchApiOnboarding404Schema,
} from '../types/patchApiOnboardingSchema';
import type {
  PatchApiOrgsOrgIdMutationRequestSchema,
  PatchApiOrgsOrgIdMutationResponseSchema,
  PatchApiOrgsOrgIdPathParamsSchema,
  PatchApiOrgsOrgId401Schema,
} from '../types/patchApiOrgsOrgIdSchema';
import type {
  PatchApiProductToursProductTourKeyMutationRequestSchema,
  PatchApiProductToursProductTourKeyMutationResponseSchema,
  PatchApiProductToursProductTourKeyPathParamsSchema,
  PatchApiProductToursProductTourKey401Schema,
  PatchApiProductToursProductTourKey404Schema,
} from '../types/patchApiProductToursProductTourKeySchema';
import type {
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationRequestSchema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationResponseSchema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdPathParamsSchema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId401Schema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId403Schema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId404Schema,
} from '../types/patchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdSchema';
import type {
  PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationRequestSchema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldId401Schema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldId403Schema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldId404Schema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldId422Schema,
} from '../types/patchApiProjectsProjectIdCustomFieldsCustomFieldIdSchema';
import type {
  PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationRequestSchema,
  PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
  PatchApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema,
  PatchApiProjectsProjectIdDisciplinesDisciplineId401Schema,
  PatchApiProjectsProjectIdDisciplinesDisciplineId403Schema,
  PatchApiProjectsProjectIdDisciplinesDisciplineId404Schema,
  PatchApiProjectsProjectIdDisciplinesDisciplineId422Schema,
} from '../types/patchApiProjectsProjectIdDisciplinesDisciplineIdSchema';
import type {
  PatchApiProjectsProjectIdDocumentsDocumentIdMutationRequestSchema,
  PatchApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
  PatchApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema,
  PatchApiProjectsProjectIdDocumentsDocumentId401Schema,
  PatchApiProjectsProjectIdDocumentsDocumentId403Schema,
  PatchApiProjectsProjectIdDocumentsDocumentId404Schema,
  PatchApiProjectsProjectIdDocumentsDocumentId422Schema,
} from '../types/patchApiProjectsProjectIdDocumentsDocumentIdSchema';
import type {
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationRequestSchema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationResponseSchema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration422Schema,
} from '../types/patchApiProjectsProjectIdGroupsGroupIdChannelConfigurationSchema';
import type {
  PatchApiProjectsProjectIdGroupsGroupIdMembersMutationRequestSchema,
  PatchApiProjectsProjectIdGroupsGroupIdMembersMutationResponseSchema,
  PatchApiProjectsProjectIdGroupsGroupIdMembersPathParamsSchema,
  PatchApiProjectsProjectIdGroupsGroupIdMembers401Schema,
  PatchApiProjectsProjectIdGroupsGroupIdMembers403Schema,
  PatchApiProjectsProjectIdGroupsGroupIdMembers404Schema,
  PatchApiProjectsProjectIdGroupsGroupIdMembers422Schema,
} from '../types/patchApiProjectsProjectIdGroupsGroupIdMembersSchema';
import type {
  PatchApiProjectsProjectIdGroupsGroupIdMutationRequestSchema,
  PatchApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
  PatchApiProjectsProjectIdGroupsGroupIdPathParamsSchema,
  PatchApiProjectsProjectIdGroupsGroupId400Schema,
  PatchApiProjectsProjectIdGroupsGroupId401Schema,
  PatchApiProjectsProjectIdGroupsGroupId403Schema,
  PatchApiProjectsProjectIdGroupsGroupId404Schema,
  PatchApiProjectsProjectIdGroupsGroupId422Schema,
} from '../types/patchApiProjectsProjectIdGroupsGroupIdSchema';
import type {
  PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationRequestSchema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationResponseSchema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsPathParamsSchema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFields401Schema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFields403Schema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFields404Schema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFields422Schema,
} from '../types/patchApiProjectsProjectIdIssuesIssueIdCustomFieldsSchema';
import type {
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationRequestSchema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema,
} from '../types/patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdSchema';
import type {
  PatchApiProjectsProjectIdIssuesIssueIdMutationRequestSchema,
  PatchApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
  PatchApiProjectsProjectIdIssuesIssueIdPathParamsSchema,
  PatchApiProjectsProjectIdIssuesIssueId401Schema,
  PatchApiProjectsProjectIdIssuesIssueId403Schema,
  PatchApiProjectsProjectIdIssuesIssueId404Schema,
  PatchApiProjectsProjectIdIssuesIssueId422Schema,
} from '../types/patchApiProjectsProjectIdIssuesIssueIdSchema';
import type {
  PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationRequestSchema,
  PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
  PatchApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema,
  PatchApiProjectsProjectIdIssueViewsIssueViewId401Schema,
  PatchApiProjectsProjectIdIssueViewsIssueViewId404Schema,
  PatchApiProjectsProjectIdIssueViewsIssueViewId422Schema,
} from '../types/patchApiProjectsProjectIdIssueViewsIssueViewIdSchema';
import type {
  PatchApiProjectsProjectIdLocationsLocationIdMutationRequestSchema,
  PatchApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
  PatchApiProjectsProjectIdLocationsLocationIdPathParamsSchema,
  PatchApiProjectsProjectIdLocationsLocationId401Schema,
  PatchApiProjectsProjectIdLocationsLocationId403Schema,
  PatchApiProjectsProjectIdLocationsLocationId404Schema,
  PatchApiProjectsProjectIdLocationsLocationId422Schema,
} from '../types/patchApiProjectsProjectIdLocationsLocationIdSchema';
import type {
  PatchApiProjectsProjectIdMutationRequestSchema,
  PatchApiProjectsProjectIdMutationResponseSchema,
  PatchApiProjectsProjectIdPathParamsSchema,
  PatchApiProjectsProjectId401Schema,
  PatchApiProjectsProjectId403Schema,
  PatchApiProjectsProjectId404Schema,
  PatchApiProjectsProjectId422Schema,
} from '../types/patchApiProjectsProjectIdSchema';
import type {
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationRequestSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationResponseSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId403Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId422Schema,
} from '../types/patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSchema';
import type {
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationRequestSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationResponseSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessPathParamsSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness400Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness401Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness403Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness404Schema,
} from '../types/patchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessSchema';
import type {
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationRequestSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId422Schema,
} from '../types/patchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdSchema';
import type {
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationRequestSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationResponseSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityId403Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityId422Schema,
} from '../types/patchApiProjectsProjectIdShiftActivitiesShiftActivityIdSchema';
import type {
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema,
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
  PatchApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId400Schema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId401Schema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId403Schema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId404Schema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId422Schema,
} from '../types/patchApiProjectsProjectIdShiftReportsShiftReportIdSchema';
import type {
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationRequestSchema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationResponseSchema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration422Schema,
} from '../types/patchApiProjectsProjectIdTeamsTeamIdChannelConfigurationSchema';
import type {
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationRequestSchema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId422Schema,
} from '../types/patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdSchema';
import type {
  PatchApiProjectsProjectIdTeamsTeamIdMutationRequestSchema,
  PatchApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
  PatchApiProjectsProjectIdTeamsTeamIdPathParamsSchema,
  PatchApiProjectsProjectIdTeamsTeamId401Schema,
  PatchApiProjectsProjectIdTeamsTeamId403Schema,
  PatchApiProjectsProjectIdTeamsTeamId404Schema,
} from '../types/patchApiProjectsProjectIdTeamsTeamIdSchema';
import type {
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationRequestSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationResponseSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder422Schema,
} from '../types/patchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSchema';
import type {
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationRequestSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId422Schema,
} from '../types/patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSchema';
import type {
  PatchApiPushSubscriptionsPushSubscriptionIdMutationRequestSchema,
  PatchApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
  PatchApiPushSubscriptionsPushSubscriptionIdPathParamsSchema,
  PatchApiPushSubscriptionsPushSubscriptionId401Schema,
  PatchApiPushSubscriptionsPushSubscriptionId404Schema,
} from '../types/patchApiPushSubscriptionsPushSubscriptionIdSchema';
import type {
  PatchApiUsersMeMutationRequestSchema,
  PatchApiUsersMeMutationResponseSchema,
  PatchApiUsersMe401Schema,
  PatchApiUsersMe422Schema,
} from '../types/patchApiUsersMeSchema';
import type {
  PatchApiUsersPasswordMutationRequestSchema,
  PatchApiUsersPasswordMutationResponseSchema,
  PatchApiUsersPassword400Schema,
  PatchApiUsersPassword422Schema,
} from '../types/patchApiUsersPasswordSchema';
import type {
  PostApiAgreementsAcceptEuaMutationResponseSchema,
  PostApiAgreementsAcceptEuaQueryParamsSchema,
  PostApiAgreementsAcceptEua400Schema,
  PostApiAgreementsAcceptEua401Schema,
} from '../types/postApiAgreementsAcceptEuaSchema';
import type {
  PostApiAnalyticalEventsMutationRequestSchema,
  PostApiAnalyticalEventsMutationResponseSchema,
  PostApiAnalyticalEvents400Schema,
  PostApiAnalyticalEvents401Schema,
} from '../types/postApiAnalyticalEventsSchema';
import type {
  PostApiAuthenticationMutationRequestSchema,
  PostApiAuthenticationMutationResponseSchema,
  PostApiAuthentication400Schema,
} from '../types/postApiAuthenticationSchema';
import type {
  PostApiChannelsTokenMutationResponseSchema,
  PostApiChannelsToken401Schema,
  PostApiChannelsToken422Schema,
  PostApiChannelsToken503Schema,
} from '../types/postApiChannelsTokenSchema';
import type {
  PostApiDirectUploadsTypeMutationRequestSchema,
  PostApiDirectUploadsTypeMutationResponseSchema,
  PostApiDirectUploadsTypePathParamsSchema,
  PostApiDirectUploadsType400Schema,
  PostApiDirectUploadsType401Schema,
  PostApiDirectUploadsType404Schema,
} from '../types/postApiDirectUploadsTypeSchema';
import type {
  PostApiFeedbacksMutationRequestSchema,
  PostApiFeedbacksMutationResponseSchema,
  PostApiFeedbacks401Schema,
  PostApiFeedbacks422Schema,
} from '../types/postApiFeedbacksSchema';
import type {
  PostApiLoginRefreshMutationResponseSchema,
  PostApiLoginRefreshHeaderParamsSchema,
  PostApiLoginRefresh400Schema,
  PostApiLoginRefresh401Schema,
} from '../types/postApiLoginRefreshSchema';
import type {
  PostApiLoginMutationRequestSchema,
  PostApiLoginMutationResponseSchema,
  PostApiLogin401Schema,
} from '../types/postApiLoginSchema';
import type {
  PostApiNotificationsMarkAllReadMutationResponseSchema,
  PostApiNotificationsMarkAllRead401Schema,
} from '../types/postApiNotificationsMarkAllReadSchema';
import type {
  PostApiNotificationsNotificationIdMarkReadMutationRequestSchema,
  PostApiNotificationsNotificationIdMarkReadMutationResponseSchema,
  PostApiNotificationsNotificationIdMarkReadPathParamsSchema,
  PostApiNotificationsNotificationIdMarkRead401Schema,
  PostApiNotificationsNotificationIdMarkRead404Schema,
} from '../types/postApiNotificationsNotificationIdMarkReadSchema';
import type {
  PostApiOnboardingFinishMutationRequestSchema,
  PostApiOnboardingFinishMutationResponseSchema,
  PostApiOnboardingFinish401Schema,
  PostApiOnboardingFinish404Schema,
  PostApiOnboardingFinish422Schema,
} from '../types/postApiOnboardingFinishSchema';
import type {
  PostApiOrgsCheckDomainMutationRequestSchema,
  PostApiOrgsCheckDomainMutationResponseSchema,
  PostApiOrgsCheckDomain401Schema,
} from '../types/postApiOrgsCheckDomainSchema';
import type {
  PostApiOrgsOrgIdResendVerificationEmailMutationResponseSchema,
  PostApiOrgsOrgIdResendVerificationEmailPathParamsSchema,
  PostApiOrgsOrgIdResendVerificationEmail401Schema,
  PostApiOrgsOrgIdResendVerificationEmail403Schema,
  PostApiOrgsOrgIdResendVerificationEmail404Schema,
} from '../types/postApiOrgsOrgIdResendVerificationEmailSchema';
import type {
  PostApiOrgsMutationRequestSchema,
  PostApiOrgsMutationResponseSchema,
  PostApiOrgs401Schema,
  PostApiOrgs422Schema,
} from '../types/postApiOrgsSchema';
import type {
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationRequestSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationResponseSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptPathParamsSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept401Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept403Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept404Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept422Schema,
} from '../types/postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptSchema';
import type {
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationRequestSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationResponseSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectPathParamsSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect400Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect401Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect403Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect404Schema,
} from '../types/postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectSchema';
import type {
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationResponseSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectPathParamsSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject401Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject403Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject404Schema,
} from '../types/postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectSchema';
import type {
  PostApiProjectsProjectIdAccessRequestsMutationRequestSchema,
  PostApiProjectsProjectIdAccessRequestsMutationResponseSchema,
  PostApiProjectsProjectIdAccessRequestsPathParamsSchema,
  PostApiProjectsProjectIdAccessRequests400Schema,
  PostApiProjectsProjectIdAccessRequests401Schema,
  PostApiProjectsProjectIdAccessRequests404Schema,
  PostApiProjectsProjectIdAccessRequests422Schema,
} from '../types/postApiProjectsProjectIdAccessRequestsSchema';
import type {
  PostApiProjectsProjectIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdArchivePathParamsSchema,
  PostApiProjectsProjectIdArchive401Schema,
  PostApiProjectsProjectIdArchive403Schema,
  PostApiProjectsProjectIdArchive404Schema,
} from '../types/postApiProjectsProjectIdArchiveSchema';
import type {
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationResponseSchema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsPathParamsSchema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments401Schema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments403Schema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments404Schema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments422Schema,
} from '../types/postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsSchema';
import type {
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchivePathParamsSchema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive401Schema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive403Schema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive404Schema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive422Schema,
} from '../types/postApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveSchema';
import type {
  PostApiProjectsProjectIdControlCenterPotentialChangesMutationRequestSchema,
  PostApiProjectsProjectIdControlCenterPotentialChangesMutationResponseSchema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema,
  PostApiProjectsProjectIdControlCenterPotentialChanges401Schema,
  PostApiProjectsProjectIdControlCenterPotentialChanges404Schema,
  PostApiProjectsProjectIdControlCenterPotentialChanges422Schema,
} from '../types/postApiProjectsProjectIdControlCenterPotentialChangesSchema';
import type {
  PostApiProjectsProjectIdCustomFieldsMutationRequestSchema,
  PostApiProjectsProjectIdCustomFieldsMutationResponseSchema,
  PostApiProjectsProjectIdCustomFieldsPathParamsSchema,
  PostApiProjectsProjectIdCustomFields401Schema,
  PostApiProjectsProjectIdCustomFields403Schema,
  PostApiProjectsProjectIdCustomFields404Schema,
  PostApiProjectsProjectIdCustomFields422Schema,
} from '../types/postApiProjectsProjectIdCustomFieldsSchema';
import type {
  PostApiProjectsProjectIdDefaultMutationResponseSchema,
  PostApiProjectsProjectIdDefaultPathParamsSchema,
  PostApiProjectsProjectIdDefault401Schema,
  PostApiProjectsProjectIdDefault403Schema,
  PostApiProjectsProjectIdDefault404Schema,
} from '../types/postApiProjectsProjectIdDefaultSchema';
import type {
  PostApiProjectsProjectIdDisciplinesMutationRequestSchema,
  PostApiProjectsProjectIdDisciplinesMutationResponseSchema,
  PostApiProjectsProjectIdDisciplinesPathParamsSchema,
  PostApiProjectsProjectIdDisciplines401Schema,
  PostApiProjectsProjectIdDisciplines403Schema,
  PostApiProjectsProjectIdDisciplines404Schema,
  PostApiProjectsProjectIdDisciplines422Schema,
} from '../types/postApiProjectsProjectIdDisciplinesSchema';
import type {
  PostApiProjectsProjectIdDisciplinesSortMutationRequestSchema,
  PostApiProjectsProjectIdDisciplinesSortMutationResponseSchema,
  PostApiProjectsProjectIdDisciplinesSortPathParamsSchema,
  PostApiProjectsProjectIdDisciplinesSort401Schema,
  PostApiProjectsProjectIdDisciplinesSort403Schema,
  PostApiProjectsProjectIdDisciplinesSort404Schema,
} from '../types/postApiProjectsProjectIdDisciplinesSortSchema';
import type {
  PostApiProjectsProjectIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdDocuments400Schema,
  PostApiProjectsProjectIdDocuments401Schema,
  PostApiProjectsProjectIdDocuments403Schema,
  PostApiProjectsProjectIdDocuments404Schema,
  PostApiProjectsProjectIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdGroupsMutationRequestSchema,
  PostApiProjectsProjectIdGroupsMutationResponseSchema,
  PostApiProjectsProjectIdGroupsPathParamsSchema,
  PostApiProjectsProjectIdGroups400Schema,
  PostApiProjectsProjectIdGroups401Schema,
  PostApiProjectsProjectIdGroups403Schema,
  PostApiProjectsProjectIdGroups404Schema,
  PostApiProjectsProjectIdGroups422Schema,
} from '../types/postApiProjectsProjectIdGroupsSchema';
import type {
  PostApiProjectsProjectIdIssuesExportMutationResponseSchema,
  PostApiProjectsProjectIdIssuesExportPathParamsSchema,
  PostApiProjectsProjectIdIssuesExportQueryParamsSchema,
  PostApiProjectsProjectIdIssuesExport400Schema,
  PostApiProjectsProjectIdIssuesExport401Schema,
  PostApiProjectsProjectIdIssuesExport403Schema,
  PostApiProjectsProjectIdIssuesExport404Schema,
} from '../types/postApiProjectsProjectIdIssuesExportSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdApproveMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdApprovePathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdApprove400Schema,
  PostApiProjectsProjectIdIssuesIssueIdApprove401Schema,
  PostApiProjectsProjectIdIssuesIssueIdApprove403Schema,
  PostApiProjectsProjectIdIssuesIssueIdApprove404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdApproveSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdArchiveMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdArchivePathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdArchive401Schema,
  PostApiProjectsProjectIdIssuesIssueIdArchive403Schema,
  PostApiProjectsProjectIdIssuesIssueIdArchive404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdArchiveSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept401Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept403Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject401Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject403Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignments400Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignments401Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignments403Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignments404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdAssignmentsSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdCommentsMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdCommentsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdCommentsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdComments401Schema,
  PostApiProjectsProjectIdIssuesIssueIdComments403Schema,
  PostApiProjectsProjectIdIssuesIssueIdComments404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdCommentsSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdCompleteMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdCompletePathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdComplete400Schema,
  PostApiProjectsProjectIdIssuesIssueIdComplete401Schema,
  PostApiProjectsProjectIdIssuesIssueIdComplete403Schema,
  PostApiProjectsProjectIdIssuesIssueIdComplete404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdCompleteSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdDocuments400Schema,
  PostApiProjectsProjectIdIssuesIssueIdDocuments401Schema,
  PostApiProjectsProjectIdIssuesIssueIdDocuments403Schema,
  PostApiProjectsProjectIdIssuesIssueIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdExportMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdExportMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdExportPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdExport401Schema,
  PostApiProjectsProjectIdIssuesIssueIdExport403Schema,
  PostApiProjectsProjectIdIssuesIssueIdExport404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdExportSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImages400Schema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImages401Schema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImages403Schema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImages422Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdIssueImagesSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdRejectMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdRejectMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdRejectPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdReject400Schema,
  PostApiProjectsProjectIdIssuesIssueIdReject401Schema,
  PostApiProjectsProjectIdIssuesIssueIdReject404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdRejectSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdReopenMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdReopenPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdReopen400Schema,
  PostApiProjectsProjectIdIssuesIssueIdReopen401Schema,
  PostApiProjectsProjectIdIssuesIssueIdReopen404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdReopenSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdRestoreMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdRestorePathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdRestore401Schema,
  PostApiProjectsProjectIdIssuesIssueIdRestore403Schema,
  PostApiProjectsProjectIdIssuesIssueIdRestore404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdRestoreSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdStartMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdStartPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdStart400Schema,
  PostApiProjectsProjectIdIssuesIssueIdStart401Schema,
  PostApiProjectsProjectIdIssuesIssueIdStart404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdStartSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdStatusStatementsSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdStopMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdStopPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdStop400Schema,
  PostApiProjectsProjectIdIssuesIssueIdStop401Schema,
  PostApiProjectsProjectIdIssuesIssueIdStop404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdStopSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdSubmitMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdSubmitPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdSubmit401Schema,
  PostApiProjectsProjectIdIssuesIssueIdSubmit403Schema,
  PostApiProjectsProjectIdIssuesIssueIdSubmit404Schema,
  PostApiProjectsProjectIdIssuesIssueIdSubmit422Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdSubmitSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpactPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpact401Schema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpact403Schema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpact404Schema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpact422Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdUpdateImpactSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdVisitMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdVisit401Schema,
  PostApiProjectsProjectIdIssuesIssueIdVisit403Schema,
  PostApiProjectsProjectIdIssuesIssueIdVisit404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdVisitSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdWatchings401Schema,
  PostApiProjectsProjectIdIssuesIssueIdWatchings403Schema,
  PostApiProjectsProjectIdIssuesIssueIdWatchings404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdWatchingsSchema';
import type {
  PostApiProjectsProjectIdIssuesMutationRequestSchema,
  PostApiProjectsProjectIdIssuesMutationResponseSchema,
  PostApiProjectsProjectIdIssuesPathParamsSchema,
  PostApiProjectsProjectIdIssuesHeaderParamsSchema,
  PostApiProjectsProjectIdIssues401Schema,
  PostApiProjectsProjectIdIssues403Schema,
  PostApiProjectsProjectIdIssues404Schema,
} from '../types/postApiProjectsProjectIdIssuesSchema';
import type {
  PostApiProjectsProjectIdIssueViewsMutationRequestSchema,
  PostApiProjectsProjectIdIssueViewsMutationResponseSchema,
  PostApiProjectsProjectIdIssueViewsPathParamsSchema,
  PostApiProjectsProjectIdIssueViews401Schema,
  PostApiProjectsProjectIdIssueViews403Schema,
  PostApiProjectsProjectIdIssueViews404Schema,
  PostApiProjectsProjectIdIssueViews422Schema,
} from '../types/postApiProjectsProjectIdIssueViewsSchema';
import type {
  PostApiProjectsProjectIdLocationsLocationIdSortMutationRequestSchema,
  PostApiProjectsProjectIdLocationsLocationIdSortMutationResponseSchema,
  PostApiProjectsProjectIdLocationsLocationIdSortPathParamsSchema,
  PostApiProjectsProjectIdLocationsLocationIdSort401Schema,
  PostApiProjectsProjectIdLocationsLocationIdSort403Schema,
  PostApiProjectsProjectIdLocationsLocationIdSort404Schema,
} from '../types/postApiProjectsProjectIdLocationsLocationIdSortSchema';
import type {
  PostApiProjectsProjectIdLocationsMutationRequestSchema,
  PostApiProjectsProjectIdLocationsMutationResponseSchema,
  PostApiProjectsProjectIdLocationsPathParamsSchema,
  PostApiProjectsProjectIdLocations401Schema,
  PostApiProjectsProjectIdLocations403Schema,
  PostApiProjectsProjectIdLocations404Schema,
  PostApiProjectsProjectIdLocations422Schema,
} from '../types/postApiProjectsProjectIdLocationsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesExportMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesExportMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesExportPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesExport401Schema,
  PostApiProjectsProjectIdShiftActivitiesExport403Schema,
  PostApiProjectsProjectIdShiftActivitiesExport404Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesExportSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesImportsMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesImportsMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesImportsPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesImports401Schema,
  PostApiProjectsProjectIdShiftActivitiesImports403Schema,
  PostApiProjectsProjectIdShiftActivitiesImports404Schema,
  PostApiProjectsProjectIdShiftActivitiesImports422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesImportsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesPathParamsSchema,
  PostApiProjectsProjectIdShiftActivities401Schema,
  PostApiProjectsProjectIdShiftActivities403Schema,
  PostApiProjectsProjectIdShiftActivities404Schema,
  PostApiProjectsProjectIdShiftActivities422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchivePathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort400Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort404Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestorePathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreSchema';
import type {
  PostApiProjectsProjectIdShiftReportsExportMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsExportMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsExportPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsExport400Schema,
  PostApiProjectsProjectIdShiftReportsExport401Schema,
  PostApiProjectsProjectIdShiftReportsExport403Schema,
  PostApiProjectsProjectIdShiftReportsExport404Schema,
} from '../types/postApiProjectsProjectIdShiftReportsExportSchema';
import type {
  PostApiProjectsProjectIdShiftReportsMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsPathParamsSchema,
  PostApiProjectsProjectIdShiftReports401Schema,
  PostApiProjectsProjectIdShiftReports403Schema,
  PostApiProjectsProjectIdShiftReports404Schema,
  PostApiProjectsProjectIdShiftReports422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchivePathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchive401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchive403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchive404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchive422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdArchiveSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExportPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExportQueryParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExport400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExport401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExport403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExport404Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdExportSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImportPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdImportSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublishPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublishQueryParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublish400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublish401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublish403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublish404Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdPublishSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionQueryParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection401Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdResetSectionSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestorePathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestore401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestore403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestore404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestore422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdRestoreSchema';
import type {
  PostApiProjectsProjectIdTeamsMutationRequestSchema,
  PostApiProjectsProjectIdTeamsMutationResponseSchema,
  PostApiProjectsProjectIdTeamsPathParamsSchema,
  PostApiProjectsProjectIdTeams401Schema,
  PostApiProjectsProjectIdTeams403Schema,
  PostApiProjectsProjectIdTeams404Schema,
  PostApiProjectsProjectIdTeams422Schema,
} from '../types/postApiProjectsProjectIdTeamsSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdJoinToken401Schema,
  PostApiProjectsProjectIdTeamsTeamIdJoinToken403Schema,
  PostApiProjectsProjectIdTeamsTeamIdJoinToken404Schema,
  PostApiProjectsProjectIdTeamsTeamIdJoinToken422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdJoinTokenSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdMembersMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembers401Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembers403Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembers404Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembers422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdMembersSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive401Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive403Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive404Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail401Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail403Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail404Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites401Schema,
  PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites404Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdResendMembersInvitesSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKind422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdResourcesKindSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable401Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable403Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable404Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable401Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable403Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable404Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal401Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal403Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal404Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm401Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm403Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm404Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscription401Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscription403Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscription404Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscription422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdSubscriptionSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlans400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlans401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlans403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlans404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlans422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch404Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort404Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchivePathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClosePathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicatePathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport404Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport404Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestorePathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreSchema';
import type {
  PostApiProjectsMutationRequestSchema,
  PostApiProjectsMutationResponseSchema,
  PostApiProjects401Schema,
  PostApiProjects403Schema,
} from '../types/postApiProjectsSchema';
import type {
  PostApiProjectsShowcasesMutationRequestSchema,
  PostApiProjectsShowcasesMutationResponseSchema,
  PostApiProjectsShowcases401Schema,
  PostApiProjectsShowcases403Schema,
} from '../types/postApiProjectsShowcasesSchema';
import type {
  PostApiPushSubscriptionsPingMutationResponseSchema,
  PostApiPushSubscriptionsPing401Schema,
} from '../types/postApiPushSubscriptionsPingSchema';
import type {
  PostApiPushSubscriptionsMutationRequestSchema,
  PostApiPushSubscriptionsMutationResponseSchema,
  PostApiPushSubscriptions401Schema,
  PostApiPushSubscriptions422Schema,
} from '../types/postApiPushSubscriptionsSchema';
import type {
  PostApiTeamMembersMutationRequestSchema,
  PostApiTeamMembersMutationResponseSchema,
  PostApiTeamMembers401Schema,
  PostApiTeamMembers422Schema,
} from '../types/postApiTeamMembersSchema';
import type {
  PostApiUsersConfirmationInstructionsMutationRequestSchema,
  PostApiUsersConfirmationInstructionsMutationResponseSchema,
} from '../types/postApiUsersConfirmationInstructionsSchema';
import type {
  PostApiUsersConfirmationMutationRequestSchema,
  PostApiUsersConfirmationMutationResponseSchema,
  PostApiUsersConfirmation400Schema,
  PostApiUsersConfirmation422Schema,
} from '../types/postApiUsersConfirmationSchema';
import type {
  PostApiUsersPasswordInstructionsMutationRequestSchema,
  PostApiUsersPasswordInstructionsMutationResponseSchema,
} from '../types/postApiUsersPasswordInstructionsSchema';
import type {
  PostApiUsersMutationRequestSchema,
  PostApiUsersMutationResponseSchema,
  PostApiUsers422Schema,
} from '../types/postApiUsersSchema';
import type {
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationRequestSchema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationResponseSchema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId400Schema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId422Schema,
} from '../types/putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSchema';

/**
 * @summary Retrieves the latest End User Agreement
 * {@link /api/agreements/latest_eua}
 */
export async function getApiAgreementsLatestEua(config: Partial<RequestConfig> = {}) {
  const res = await client<GetApiAgreementsLatestEuaQueryResponseSchema, GetApiAgreementsLatestEua404Schema, unknown>({
    method: 'GET',
    url: `/api/agreements/latest_eua`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Accepts the End User Agreement for the current user
 * {@link /api/agreements/accept_eua}
 */
export async function postApiAgreementsAcceptEua(
  params?: PostApiAgreementsAcceptEuaQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiAgreementsAcceptEuaMutationResponseSchema,
    PostApiAgreementsAcceptEua400Schema | PostApiAgreementsAcceptEua401Schema,
    unknown
  >({ method: 'POST', url: `/api/agreements/accept_eua`, params, ...config });
  return res.data;
}

/**
 * @summary Tracks analytical events
 * {@link /api/analytical/events}
 */
export async function postApiAnalyticalEvents(
  data: PostApiAnalyticalEventsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiAnalyticalEventsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiAnalyticalEventsMutationResponseSchema,
    PostApiAnalyticalEvents400Schema | PostApiAnalyticalEvents401Schema,
    PostApiAnalyticalEventsMutationRequestSchema
  >({ method: 'POST', url: `/api/analytical/events`, data, ...config });
  return res.data;
}

/**
 * @summary Authenticates a user
 * {@link /api/login}
 */
export async function postApiLogin(
  data: PostApiLoginMutationRequestSchema,
  config: Partial<RequestConfig<PostApiLoginMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiLoginMutationResponseSchema,
    PostApiLogin401Schema,
    PostApiLoginMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/login`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Logs out a user
 * {@link /api/logout}
 */
export async function deleteApiLogout(config: Partial<RequestConfig> = {}) {
  const res = await client<DeleteApiLogoutMutationResponseSchema, DeleteApiLogout401Schema, unknown>({
    method: 'DELETE',
    url: `/api/logout`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Attempts a login with a provider or checks if the account exists
 * {@link /api/authentication}
 */
export async function postApiAuthentication(
  data: PostApiAuthenticationMutationRequestSchema,
  config: Partial<RequestConfig<PostApiAuthenticationMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiAuthenticationMutationResponseSchema,
    PostApiAuthentication400Schema,
    PostApiAuthenticationMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/authentication`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Requests a new Channels token
 * {@link /api/channels/token}
 */
export async function postApiChannelsToken(config: Partial<RequestConfig> = {}) {
  const res = await client<
    PostApiChannelsTokenMutationResponseSchema,
    PostApiChannelsToken401Schema | PostApiChannelsToken422Schema | PostApiChannelsToken503Schema,
    unknown
  >({ method: 'POST', url: `/api/channels/token`, ...config });
  return res.data;
}

/**
 * @summary Save message attachments to project gallery
 * {@link /api/projects/:project_id/channels/messages/:message_id/save_attachments}
 */
export async function postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments(
  project_id: PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsPathParamsSchema['project_id'],
  message_id: PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsPathParamsSchema['message_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationResponseSchema,
    | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments401Schema
    | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments403Schema
    | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments404Schema
    | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/channels/messages/${message_id}/save_attachments`, ...config });
  return res.data;
}

/**
 * @summary Creates a comment
 * {@link /api/projects/:project_id/issues/:issue_id/comments}
 */
export async function postApiProjectsProjectIdIssuesIssueIdComments(
  project_id: PostApiProjectsProjectIdIssuesIssueIdCommentsPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdCommentsPathParamsSchema['issue_id'],
  data: PostApiProjectsProjectIdIssuesIssueIdCommentsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdCommentsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdCommentsMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdComments401Schema
    | PostApiProjectsProjectIdIssuesIssueIdComments403Schema
    | PostApiProjectsProjectIdIssuesIssueIdComments404Schema,
    PostApiProjectsProjectIdIssuesIssueIdCommentsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/comments`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes a comment
 * {@link /api/projects/:project_id/issues/:issue_id/comments/:comment_id}
 */
export async function deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId(
  project_id: DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema['project_id'],
  issue_id: DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema['issue_id'],
  comment_id: DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema['comment_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId401Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId403Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId404Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId422Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/issues/${issue_id}/comments/${comment_id}`, ...config });
  return res.data;
}

/**
 * @summary Retrieves construction roles
 * {@link /api/construction_roles}
 */
export async function getApiConstructionRoles(config: Partial<RequestConfig> = {}) {
  const res = await client<GetApiConstructionRolesQueryResponseSchema, GetApiConstructionRoles401Schema, unknown>({
    method: 'GET',
    url: `/api/construction_roles`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves potential changes
 * {@link /api/projects/:project_id/control_center/potential_changes}
 */
export async function getApiProjectsProjectIdControlCenterPotentialChanges(
  project_id: GetApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdControlCenterPotentialChangesQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
    | GetApiProjectsProjectIdControlCenterPotentialChanges401Schema
    | GetApiProjectsProjectIdControlCenterPotentialChanges404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/control_center/potential_changes`, params, ...config });
  return res.data;
}

/**
 * @summary Creates a potential change
 * {@link /api/projects/:project_id/control_center/potential_changes}
 */
export async function postApiProjectsProjectIdControlCenterPotentialChanges(
  project_id: PostApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdControlCenterPotentialChangesMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdControlCenterPotentialChangesMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdControlCenterPotentialChangesMutationResponseSchema,
    | PostApiProjectsProjectIdControlCenterPotentialChanges401Schema
    | PostApiProjectsProjectIdControlCenterPotentialChanges404Schema
    | PostApiProjectsProjectIdControlCenterPotentialChanges422Schema,
    PostApiProjectsProjectIdControlCenterPotentialChangesMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/control_center/potential_changes`, data, ...config });
  return res.data;
}

/**
 * @summary Updates a potential change
 * {@link /api/projects/:project_id/control_center/potential_changes/:potential_change_id}
 */
export async function patchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId(
  project_id: PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdPathParamsSchema['project_id'],
  potential_change_id: PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdPathParamsSchema['potential_change_id'],
  data?: PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationRequestSchema,
  config: Partial<
    RequestConfig<PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationResponseSchema,
    | PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId401Schema
    | PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId403Schema
    | PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId404Schema,
    PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationRequestSchema
  >({
    method: 'PATCH',
    url: `/api/projects/${project_id}/control_center/potential_changes/${potential_change_id}`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Archives a potential change
 * {@link /api/projects/:project_id/control_center/potential_changes/:potential_change_id/archive}
 */
export async function postApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive(
  project_id: PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchivePathParamsSchema['project_id'],
  potential_change_id: PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchivePathParamsSchema['potential_change_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveMutationResponseSchema,
    | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive401Schema
    | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive403Schema
    | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive404Schema
    | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive422Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/control_center/potential_changes/${potential_change_id}/archive`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves project custom fields
 * {@link /api/projects/:project_id/custom_fields}
 */
export async function getApiProjectsProjectIdCustomFields(
  project_id: GetApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
    | GetApiProjectsProjectIdCustomFields401Schema
    | GetApiProjectsProjectIdCustomFields403Schema
    | GetApiProjectsProjectIdCustomFields404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/custom_fields`, ...config });
  return res.data;
}

/**
 * @summary Creates a custom field
 * {@link /api/projects/:project_id/custom_fields}
 */
export async function postApiProjectsProjectIdCustomFields(
  project_id: PostApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id'],
  data: PostApiProjectsProjectIdCustomFieldsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdCustomFieldsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdCustomFieldsMutationResponseSchema,
    | PostApiProjectsProjectIdCustomFields401Schema
    | PostApiProjectsProjectIdCustomFields403Schema
    | PostApiProjectsProjectIdCustomFields404Schema
    | PostApiProjectsProjectIdCustomFields422Schema,
    PostApiProjectsProjectIdCustomFieldsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/custom_fields`, data, ...config });
  return res.data;
}

/**
 * @summary Updates a custom field
 * {@link /api/projects/:project_id/custom_fields/:custom_field_id}
 */
export async function patchApiProjectsProjectIdCustomFieldsCustomFieldId(
  project_id: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['project_id'],
  custom_field_id: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['custom_field_id'],
  data: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
    | PatchApiProjectsProjectIdCustomFieldsCustomFieldId401Schema
    | PatchApiProjectsProjectIdCustomFieldsCustomFieldId403Schema
    | PatchApiProjectsProjectIdCustomFieldsCustomFieldId404Schema
    | PatchApiProjectsProjectIdCustomFieldsCustomFieldId422Schema,
    PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/custom_fields/${custom_field_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes a custom field
 * {@link /api/projects/:project_id/custom_fields/:custom_field_id}
 */
export async function deleteApiProjectsProjectIdCustomFieldsCustomFieldId(
  project_id: DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['project_id'],
  custom_field_id: DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['custom_field_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdCustomFieldsCustomFieldId401Schema
    | DeleteApiProjectsProjectIdCustomFieldsCustomFieldId403Schema
    | DeleteApiProjectsProjectIdCustomFieldsCustomFieldId404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/custom_fields/${custom_field_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates issue custom fields
 * {@link /api/projects/:project_id/issues/:issue_id/custom_fields}
 */
export async function patchApiProjectsProjectIdIssuesIssueIdCustomFields(
  project_id: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsPathParamsSchema['project_id'],
  issue_id: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsPathParamsSchema['issue_id'],
  data?: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationResponseSchema,
    | PatchApiProjectsProjectIdIssuesIssueIdCustomFields401Schema
    | PatchApiProjectsProjectIdIssuesIssueIdCustomFields403Schema
    | PatchApiProjectsProjectIdIssuesIssueIdCustomFields404Schema
    | PatchApiProjectsProjectIdIssuesIssueIdCustomFields422Schema,
    PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/issues/${issue_id}/custom_fields`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves data health scores
 * {@link /api/projects/:project_id/dashboards/data_health_scores/:record_type}
 */
export async function getApiProjectsProjectIdDashboardsDataHealthScoresRecordType(
  project_id: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['project_id'],
  record_type: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['record_type'],
  params?: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
    | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType401Schema
    | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType403Schema
    | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/dashboards/data_health_scores/${record_type}`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Creates a direct upload
 * {@link /api/direct_uploads/:type}
 */
export async function postApiDirectUploadsType(
  type: PostApiDirectUploadsTypePathParamsSchema['type'],
  data: PostApiDirectUploadsTypeMutationRequestSchema,
  config: Partial<RequestConfig<PostApiDirectUploadsTypeMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiDirectUploadsTypeMutationResponseSchema,
    PostApiDirectUploadsType400Schema | PostApiDirectUploadsType401Schema | PostApiDirectUploadsType404Schema,
    PostApiDirectUploadsTypeMutationRequestSchema
  >({ method: 'POST', url: `/api/direct_uploads/${type}`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves disciplines
 * {@link /api/projects/:project_id/disciplines}
 */
export async function getApiProjectsProjectIdDisciplines(
  project_id: GetApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDisciplinesQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
    | GetApiProjectsProjectIdDisciplines401Schema
    | GetApiProjectsProjectIdDisciplines403Schema
    | GetApiProjectsProjectIdDisciplines404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/disciplines`, params, ...config });
  return res.data;
}

/**
 * @summary Creates a discipline
 * {@link /api/projects/:project_id/disciplines}
 */
export async function postApiProjectsProjectIdDisciplines(
  project_id: PostApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdDisciplinesMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdDisciplinesMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdDisciplinesMutationResponseSchema,
    | PostApiProjectsProjectIdDisciplines401Schema
    | PostApiProjectsProjectIdDisciplines403Schema
    | PostApiProjectsProjectIdDisciplines404Schema
    | PostApiProjectsProjectIdDisciplines422Schema,
    PostApiProjectsProjectIdDisciplinesMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/disciplines`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves a discipline
 * {@link /api/projects/:project_id/disciplines/:discipline_id}
 */
export async function getApiProjectsProjectIdDisciplinesDisciplineId(
  project_id: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'],
  discipline_id: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
    | GetApiProjectsProjectIdDisciplinesDisciplineId401Schema
    | GetApiProjectsProjectIdDisciplinesDisciplineId403Schema
    | GetApiProjectsProjectIdDisciplinesDisciplineId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/disciplines/${discipline_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates a discipline
 * {@link /api/projects/:project_id/disciplines/:discipline_id}
 */
export async function patchApiProjectsProjectIdDisciplinesDisciplineId(
  project_id: PatchApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'],
  discipline_id: PatchApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'],
  data?: PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
    | PatchApiProjectsProjectIdDisciplinesDisciplineId401Schema
    | PatchApiProjectsProjectIdDisciplinesDisciplineId403Schema
    | PatchApiProjectsProjectIdDisciplinesDisciplineId404Schema
    | PatchApiProjectsProjectIdDisciplinesDisciplineId422Schema,
    PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/disciplines/${discipline_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes a discipline
 * {@link /api/projects/:project_id/disciplines/:discipline_id}
 */
export async function deleteApiProjectsProjectIdDisciplinesDisciplineId(
  project_id: DeleteApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'],
  discipline_id: DeleteApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdDisciplinesDisciplineId401Schema
    | DeleteApiProjectsProjectIdDisciplinesDisciplineId403Schema
    | DeleteApiProjectsProjectIdDisciplinesDisciplineId404Schema
    | DeleteApiProjectsProjectIdDisciplinesDisciplineId422Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/disciplines/${discipline_id}`, ...config });
  return res.data;
}

/**
 * @summary Sorts disciplines
 * {@link /api/projects/:project_id/disciplines/sort}
 */
export async function postApiProjectsProjectIdDisciplinesSort(
  project_id: PostApiProjectsProjectIdDisciplinesSortPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdDisciplinesSortMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdDisciplinesSortMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdDisciplinesSortMutationResponseSchema,
    | PostApiProjectsProjectIdDisciplinesSort401Schema
    | PostApiProjectsProjectIdDisciplinesSort403Schema
    | PostApiProjectsProjectIdDisciplinesSort404Schema,
    PostApiProjectsProjectIdDisciplinesSortMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/disciplines/sort`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves document references
 * {@link /api/projects/:project_id/documents/:document_id/references}
 */
export async function getApiProjectsProjectIdDocumentsDocumentIdReferences(
  project_id: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['project_id'],
  document_id: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['document_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
    | GetApiProjectsProjectIdDocumentsDocumentIdReferences401Schema
    | GetApiProjectsProjectIdDocumentsDocumentIdReferences403Schema
    | GetApiProjectsProjectIdDocumentsDocumentIdReferences404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/documents/${document_id}/references`, ...config });
  return res.data;
}

/**
 * @summary Retrieves documents
 * {@link /api/projects/:project_id/documents}
 */
export async function getApiProjectsProjectIdDocuments(
  project_id: GetApiProjectsProjectIdDocumentsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdDocumentsQueryResponseSchema,
    | GetApiProjectsProjectIdDocuments400Schema
    | GetApiProjectsProjectIdDocuments401Schema
    | GetApiProjectsProjectIdDocuments403Schema
    | GetApiProjectsProjectIdDocuments404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/documents`, params, ...config });
  return res.data;
}

/**
 * @summary Creates a document
 * {@link /api/projects/:project_id/documents}
 */
export async function postApiProjectsProjectIdDocuments(
  project_id: PostApiProjectsProjectIdDocumentsPathParamsSchema['project_id'],
  data: PostApiProjectsProjectIdDocumentsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdDocumentsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdDocumentsMutationResponseSchema,
    | PostApiProjectsProjectIdDocuments400Schema
    | PostApiProjectsProjectIdDocuments401Schema
    | PostApiProjectsProjectIdDocuments403Schema
    | PostApiProjectsProjectIdDocuments404Schema
    | PostApiProjectsProjectIdDocuments422Schema,
    PostApiProjectsProjectIdDocumentsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/documents`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves a document
 * {@link /api/projects/:project_id/documents/:document_id}
 */
export async function getApiProjectsProjectIdDocumentsDocumentId(
  project_id: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'],
  document_id: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
    | GetApiProjectsProjectIdDocumentsDocumentId401Schema
    | GetApiProjectsProjectIdDocumentsDocumentId403Schema
    | GetApiProjectsProjectIdDocumentsDocumentId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/documents/${document_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates a document
 * {@link /api/projects/:project_id/documents/:document_id}
 */
export async function patchApiProjectsProjectIdDocumentsDocumentId(
  project_id: PatchApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'],
  document_id: PatchApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'],
  data?: PatchApiProjectsProjectIdDocumentsDocumentIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdDocumentsDocumentIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
    | PatchApiProjectsProjectIdDocumentsDocumentId401Schema
    | PatchApiProjectsProjectIdDocumentsDocumentId403Schema
    | PatchApiProjectsProjectIdDocumentsDocumentId404Schema
    | PatchApiProjectsProjectIdDocumentsDocumentId422Schema,
    PatchApiProjectsProjectIdDocumentsDocumentIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/documents/${document_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes a document
 * {@link /api/projects/:project_id/documents/:document_id}
 */
export async function deleteApiProjectsProjectIdDocumentsDocumentId(
  project_id: DeleteApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'],
  document_id: DeleteApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdDocumentsDocumentId401Schema
    | DeleteApiProjectsProjectIdDocumentsDocumentId403Schema
    | DeleteApiProjectsProjectIdDocumentsDocumentId404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/documents/${document_id}`, ...config });
  return res.data;
}

/**
 * @summary Retrieves events
 * {@link /api/projects/:project_id/events}
 */
export async function getApiProjectsProjectIdEvents(
  project_id: GetApiProjectsProjectIdEventsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdEventsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdEventsQueryResponseSchema,
    | GetApiProjectsProjectIdEvents401Schema
    | GetApiProjectsProjectIdEvents403Schema
    | GetApiProjectsProjectIdEvents404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/events`, params, ...config });
  return res.data;
}

/**
 * @summary Retrieves the list of feature flags
 * {@link /api/feature_flags}
 */
export async function getApiFeatureFlags(
  params?: GetApiFeatureFlagsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<GetApiFeatureFlagsQueryResponseSchema, GetApiFeatureFlags422Schema, unknown>({
    method: 'GET',
    url: `/api/feature_flags`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Creates a feedback
 * {@link /api/feedbacks}
 */
export async function postApiFeedbacks(
  data?: PostApiFeedbacksMutationRequestSchema,
  config: Partial<RequestConfig<PostApiFeedbacksMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiFeedbacksMutationResponseSchema,
    PostApiFeedbacks401Schema | PostApiFeedbacks422Schema,
    PostApiFeedbacksMutationRequestSchema
  >({ method: 'POST', url: `/api/feedbacks`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves the group channel configuration
 * {@link /api/projects/:project_id/groups/:group_id/channel_configuration}
 */
export async function getApiProjectsProjectIdGroupsGroupIdChannelConfiguration(
  project_id: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'],
  group_id: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
    | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
    | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
    | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/groups/${group_id}/channel_configuration`, ...config });
  return res.data;
}

/**
 * @summary Updates the group channel configuration
 * {@link /api/projects/:project_id/groups/:group_id/channel_configuration}
 */
export async function patchApiProjectsProjectIdGroupsGroupIdChannelConfiguration(
  project_id: PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'],
  group_id: PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id'],
  data?: PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationResponseSchema,
    | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
    | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
    | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema
    | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration422Schema,
    PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/groups/${group_id}/channel_configuration`, data, ...config });
  return res.data;
}

/**
 * @description Authenticated user will be added automatically as a group member and admin of the group.
 * @summary Creates a group
 * {@link /api/projects/:project_id/groups}
 */
export async function postApiProjectsProjectIdGroups(
  project_id: PostApiProjectsProjectIdGroupsPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdGroupsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdGroupsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdGroupsMutationResponseSchema,
    | PostApiProjectsProjectIdGroups400Schema
    | PostApiProjectsProjectIdGroups401Schema
    | PostApiProjectsProjectIdGroups403Schema
    | PostApiProjectsProjectIdGroups404Schema
    | PostApiProjectsProjectIdGroups422Schema,
    PostApiProjectsProjectIdGroupsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/groups`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves a group
 * {@link /api/projects/:project_id/groups/:group_id}
 */
export async function getApiProjectsProjectIdGroupsGroupId(
  project_id: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'],
  group_id: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
    | GetApiProjectsProjectIdGroupsGroupId401Schema
    | GetApiProjectsProjectIdGroupsGroupId403Schema
    | GetApiProjectsProjectIdGroupsGroupId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/groups/${group_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates a group
 * {@link /api/projects/:project_id/groups/:group_id}
 */
export async function patchApiProjectsProjectIdGroupsGroupId(
  project_id: PatchApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'],
  group_id: PatchApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'],
  data?: PatchApiProjectsProjectIdGroupsGroupIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdGroupsGroupIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
    | PatchApiProjectsProjectIdGroupsGroupId400Schema
    | PatchApiProjectsProjectIdGroupsGroupId401Schema
    | PatchApiProjectsProjectIdGroupsGroupId403Schema
    | PatchApiProjectsProjectIdGroupsGroupId404Schema
    | PatchApiProjectsProjectIdGroupsGroupId422Schema,
    PatchApiProjectsProjectIdGroupsGroupIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/groups/${group_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes a group
 * {@link /api/projects/:project_id/groups/:group_id}
 */
export async function deleteApiProjectsProjectIdGroupsGroupId(
  project_id: DeleteApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'],
  group_id: DeleteApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdGroupsGroupId401Schema
    | DeleteApiProjectsProjectIdGroupsGroupId403Schema
    | DeleteApiProjectsProjectIdGroupsGroupId404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/groups/${group_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates group members
 * {@link /api/projects/:project_id/groups/:group_id/members}
 */
export async function patchApiProjectsProjectIdGroupsGroupIdMembers(
  project_id: PatchApiProjectsProjectIdGroupsGroupIdMembersPathParamsSchema['project_id'],
  group_id: PatchApiProjectsProjectIdGroupsGroupIdMembersPathParamsSchema['group_id'],
  data?: PatchApiProjectsProjectIdGroupsGroupIdMembersMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdGroupsGroupIdMembersMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdGroupsGroupIdMembersMutationResponseSchema,
    | PatchApiProjectsProjectIdGroupsGroupIdMembers401Schema
    | PatchApiProjectsProjectIdGroupsGroupIdMembers403Schema
    | PatchApiProjectsProjectIdGroupsGroupIdMembers404Schema
    | PatchApiProjectsProjectIdGroupsGroupIdMembers422Schema,
    PatchApiProjectsProjectIdGroupsGroupIdMembersMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/groups/${group_id}/members`, data, ...config });
  return res.data;
}

/**
 * @summary Creates an issue assignment
 * {@link /api/projects/:project_id/issues/:issue_id/assignments}
 */
export async function postApiProjectsProjectIdIssuesIssueIdAssignments(
  project_id: PostApiProjectsProjectIdIssuesIssueIdAssignmentsPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdAssignmentsPathParamsSchema['issue_id'],
  data?: PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdAssignments400Schema
    | PostApiProjectsProjectIdIssuesIssueIdAssignments401Schema
    | PostApiProjectsProjectIdIssuesIssueIdAssignments403Schema
    | PostApiProjectsProjectIdIssuesIssueIdAssignments404Schema,
    PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/assignments`, data, ...config });
  return res.data;
}

/**
 * @summary Accepts issue assignment
 * {@link /api/projects/:project_id/issues/:issue_id/assignments/:issue_assignment_id/accept}
 */
export async function postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept(
  project_id: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema['issue_id'],
  issue_assignment_id: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema['issue_assignment_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept401Schema
    | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept403Schema
    | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept404Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/issues/${issue_id}/assignments/${issue_assignment_id}/accept`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Rejects issue assignment
 * {@link /api/projects/:project_id/issues/:issue_id/assignments/:issue_assignment_id/reject}
 */
export async function postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject(
  project_id: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema['issue_id'],
  issue_assignment_id: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema['issue_assignment_id'],
  data?: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject401Schema
    | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject403Schema
    | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject404Schema,
    PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/issues/${issue_id}/assignments/${issue_assignment_id}/reject`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Removes a document from an issue
 * {@link /api/projects/:project_id/issues/:issue_id/document_references/:document_reference_id}
 */
export async function deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId(
  project_id: DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema['project_id'],
  issue_id: DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema['issue_id'],
  document_reference_id: DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema['document_reference_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId401Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId403Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId404Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/issues/${issue_id}/document_references/${document_reference_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves issue documents
 * {@link /api/projects/:project_id/issues/:issue_id/documents}
 */
export async function getApiProjectsProjectIdIssuesIssueIdDocuments(
  project_id: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'],
  issue_id: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
    | GetApiProjectsProjectIdIssuesIssueIdDocuments400Schema
    | GetApiProjectsProjectIdIssuesIssueIdDocuments401Schema
    | GetApiProjectsProjectIdIssuesIssueIdDocuments403Schema
    | GetApiProjectsProjectIdIssuesIssueIdDocuments404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues/${issue_id}/documents`, params, ...config });
  return res.data;
}

/**
 * @summary Adds a document to an issue (from an upload or an existing document)
 * {@link /api/projects/:project_id/issues/:issue_id/documents}
 */
export async function postApiProjectsProjectIdIssuesIssueIdDocuments(
  project_id: PostApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'],
  data?: PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdDocuments400Schema
    | PostApiProjectsProjectIdIssuesIssueIdDocuments401Schema
    | PostApiProjectsProjectIdIssuesIssueIdDocuments403Schema
    | PostApiProjectsProjectIdIssuesIssueIdDocuments422Schema,
    PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/documents`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves team only issue events
 * {@link /api/projects/:project_id/issues/:issue_id/feed/team}
 */
export async function getApiProjectsProjectIdIssuesIssueIdFeedTeam(
  project_id: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['project_id'],
  issue_id: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
    | GetApiProjectsProjectIdIssuesIssueIdFeedTeam400Schema
    | GetApiProjectsProjectIdIssuesIssueIdFeedTeam401Schema
    | GetApiProjectsProjectIdIssuesIssueIdFeedTeam403Schema
    | GetApiProjectsProjectIdIssuesIssueIdFeedTeam404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues/${issue_id}/feed/team`, params, ...config });
  return res.data;
}

/**
 * @summary Retrieves public issue events
 * {@link /api/projects/:project_id/issues/:issue_id/feed/public}
 */
export async function getApiProjectsProjectIdIssuesIssueIdFeedPublic(
  project_id: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['project_id'],
  issue_id: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
    | GetApiProjectsProjectIdIssuesIssueIdFeedPublic400Schema
    | GetApiProjectsProjectIdIssuesIssueIdFeedPublic401Schema
    | GetApiProjectsProjectIdIssuesIssueIdFeedPublic404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues/${issue_id}/feed/public`, params, ...config });
  return res.data;
}

/**
 * @description Deprecated: use /api/projects/{project_id}/issues/{issue_id}/documents
 * @summary Retrieves issue images and evidence images
 * {@link /api/projects/:project_id/issues/:issue_id/issue_images}
 * @deprecated
 */
export async function getApiProjectsProjectIdIssuesIssueIdIssueImages(
  project_id: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'],
  issue_id: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
    | GetApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
    | GetApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
    | GetApiProjectsProjectIdIssuesIssueIdIssueImages404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues/${issue_id}/issue_images`, params, ...config });
  return res.data;
}

/**
 * @description Deprecated: use /api/projects/{project_id}/issues/{issue_id}/documents
 * @summary Uploads an image (from an upload or an existing document)
 * {@link /api/projects/:project_id/issues/:issue_id/issue_images}
 * @deprecated
 */
export async function postApiProjectsProjectIdIssuesIssueIdIssueImages(
  project_id: PostApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'],
  data?: PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdIssueImages400Schema
    | PostApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
    | PostApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
    | PostApiProjectsProjectIdIssuesIssueIdIssueImages422Schema,
    PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/issue_images`, data, ...config });
  return res.data;
}

/**
 * @description Deprecated: use /api/projects/{project_id}/issues/{issue_id}/document_references/{document_reference_id}
 * @summary Removes an image from an issue
 * {@link /api/projects/:project_id/issues/:issue_id/issue_images/:issue_image_id}
 * @deprecated
 */
export async function deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId(
  project_id: DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['project_id'],
  issue_id: DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_id'],
  issue_image_id: DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_image_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/issues/${issue_id}/issue_images/${issue_image_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @description Deprecated: use /api/projects/{project_id}/documents/{document_id}
 * @summary Updates an image
 * {@link /api/projects/:project_id/issues/:issue_id/issue_images/:issue_image_id}
 * @deprecated
 */
export async function patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId(
  project_id: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['project_id'],
  issue_id: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_id'],
  issue_image_id: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_image_id'],
  data?: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationRequestSchema,
  config: Partial<
    RequestConfig<PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
    | PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema
    | PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema
    | PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema,
    PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationRequestSchema
  >({
    method: 'PATCH',
    url: `/api/projects/${project_id}/issues/${issue_id}/issue_images/${issue_image_id}`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Approves an issue
 * {@link /api/projects/:project_id/issues/:issue_id/approve}
 */
export async function postApiProjectsProjectIdIssuesIssueIdApprove(
  project_id: PostApiProjectsProjectIdIssuesIssueIdApprovePathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdApprovePathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdApproveMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdApprove400Schema
    | PostApiProjectsProjectIdIssuesIssueIdApprove401Schema
    | PostApiProjectsProjectIdIssuesIssueIdApprove403Schema
    | PostApiProjectsProjectIdIssuesIssueIdApprove404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/approve`, ...config });
  return res.data;
}

/**
 * @summary Updates an issue to completed
 * {@link /api/projects/:project_id/issues/:issue_id/complete}
 */
export async function postApiProjectsProjectIdIssuesIssueIdComplete(
  project_id: PostApiProjectsProjectIdIssuesIssueIdCompletePathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdCompletePathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdCompleteMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdComplete400Schema
    | PostApiProjectsProjectIdIssuesIssueIdComplete401Schema
    | PostApiProjectsProjectIdIssuesIssueIdComplete403Schema
    | PostApiProjectsProjectIdIssuesIssueIdComplete404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/complete`, ...config });
  return res.data;
}

/**
 * @summary Rejects an issue
 * {@link /api/projects/:project_id/issues/:issue_id/reject}
 */
export async function postApiProjectsProjectIdIssuesIssueIdReject(
  project_id: PostApiProjectsProjectIdIssuesIssueIdRejectPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdRejectPathParamsSchema['issue_id'],
  data?: PostApiProjectsProjectIdIssuesIssueIdRejectMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdRejectMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdRejectMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdReject400Schema
    | PostApiProjectsProjectIdIssuesIssueIdReject401Schema
    | PostApiProjectsProjectIdIssuesIssueIdReject404Schema,
    PostApiProjectsProjectIdIssuesIssueIdRejectMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/reject`, data, ...config });
  return res.data;
}

/**
 * @summary Reopens an resolved issue
 * {@link /api/projects/:project_id/issues/:issue_id/reopen}
 */
export async function postApiProjectsProjectIdIssuesIssueIdReopen(
  project_id: PostApiProjectsProjectIdIssuesIssueIdReopenPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdReopenPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdReopenMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdReopen400Schema
    | PostApiProjectsProjectIdIssuesIssueIdReopen401Schema
    | PostApiProjectsProjectIdIssuesIssueIdReopen404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/reopen`, ...config });
  return res.data;
}

/**
 * @summary Updates an issue to in_progress
 * {@link /api/projects/:project_id/issues/:issue_id/start}
 */
export async function postApiProjectsProjectIdIssuesIssueIdStart(
  project_id: PostApiProjectsProjectIdIssuesIssueIdStartPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdStartPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdStartMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdStart400Schema
    | PostApiProjectsProjectIdIssuesIssueIdStart401Schema
    | PostApiProjectsProjectIdIssuesIssueIdStart404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/start`, ...config });
  return res.data;
}

/**
 * @summary Updates an issue to assigned
 * {@link /api/projects/:project_id/issues/:issue_id/stop}
 */
export async function postApiProjectsProjectIdIssuesIssueIdStop(
  project_id: PostApiProjectsProjectIdIssuesIssueIdStopPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdStopPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdStopMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdStop400Schema
    | PostApiProjectsProjectIdIssuesIssueIdStop401Schema
    | PostApiProjectsProjectIdIssuesIssueIdStop404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/stop`, ...config });
  return res.data;
}

/**
 * @summary Submits a draft issue
 * {@link /api/projects/:project_id/issues/:issue_id/submit}
 */
export async function postApiProjectsProjectIdIssuesIssueIdSubmit(
  project_id: PostApiProjectsProjectIdIssuesIssueIdSubmitPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdSubmitPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdSubmitMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdSubmit401Schema
    | PostApiProjectsProjectIdIssuesIssueIdSubmit403Schema
    | PostApiProjectsProjectIdIssuesIssueIdSubmit404Schema
    | PostApiProjectsProjectIdIssuesIssueIdSubmit422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/submit`, ...config });
  return res.data;
}

/**
 * @summary Creates an issue status statement
 * {@link /api/projects/:project_id/issues/:issue_id/status_statements}
 */
export async function postApiProjectsProjectIdIssuesIssueIdStatusStatements(
  project_id: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id'],
  data?: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
    | PostApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
    | PostApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema,
    PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/status_statements`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves issue status statements
 * {@link /api/projects/:project_id/issues/:issue_id/status_statements}
 */
export async function getApiProjectsProjectIdIssuesIssueIdStatusStatements(
  project_id: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'],
  issue_id: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
    | GetApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
    | GetApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
    | GetApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues/${issue_id}/status_statements`, ...config });
  return res.data;
}

/**
 * @summary Deletes an issue status statement
 * {@link /api/projects/:project_id/issues/:issue_id/status_statements/:status_statement_id}
 */
export async function deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId(
  project_id: DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema['project_id'],
  issue_id: DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema['issue_id'],
  status_statement_id: DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema['status_statement_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId401Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId403Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId404Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId422Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/issues/${issue_id}/status_statements/${status_statement_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves issue views
 * {@link /api/projects/:project_id/issue_views}
 */
export async function getApiProjectsProjectIdIssueViews(
  project_id: GetApiProjectsProjectIdIssueViewsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
    | GetApiProjectsProjectIdIssueViews401Schema
    | GetApiProjectsProjectIdIssueViews403Schema
    | GetApiProjectsProjectIdIssueViews404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issue_views`, ...config });
  return res.data;
}

/**
 * @summary Creates issue view
 * {@link /api/projects/:project_id/issue_views}
 */
export async function postApiProjectsProjectIdIssueViews(
  project_id: PostApiProjectsProjectIdIssueViewsPathParamsSchema['project_id'],
  data: PostApiProjectsProjectIdIssueViewsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssueViewsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssueViewsMutationResponseSchema,
    | PostApiProjectsProjectIdIssueViews401Schema
    | PostApiProjectsProjectIdIssueViews403Schema
    | PostApiProjectsProjectIdIssueViews404Schema
    | PostApiProjectsProjectIdIssueViews422Schema,
    PostApiProjectsProjectIdIssueViewsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issue_views`, data, ...config });
  return res.data;
}

/**
 * @summary Updates issue view
 * {@link /api/projects/:project_id/issue_views/:issue_view_id}
 */
export async function patchApiProjectsProjectIdIssueViewsIssueViewId(
  project_id: PatchApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['project_id'],
  issue_view_id: PatchApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['issue_view_id'],
  data?: PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
    | PatchApiProjectsProjectIdIssueViewsIssueViewId401Schema
    | PatchApiProjectsProjectIdIssueViewsIssueViewId404Schema
    | PatchApiProjectsProjectIdIssueViewsIssueViewId422Schema,
    PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/issue_views/${issue_view_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes an issue view
 * {@link /api/projects/:project_id/issue_views/:issue_view_id}
 */
export async function deleteApiProjectsProjectIdIssueViewsIssueViewId(
  project_id: DeleteApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['project_id'],
  issue_view_id: DeleteApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['issue_view_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdIssueViewsIssueViewId401Schema
    | DeleteApiProjectsProjectIdIssueViewsIssueViewId403Schema
    | DeleteApiProjectsProjectIdIssueViewsIssueViewId404Schema
    | DeleteApiProjectsProjectIdIssueViewsIssueViewId422Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/issue_views/${issue_view_id}`, ...config });
  return res.data;
}

/**
 * @summary Retrieve issue visit status
 * {@link /api/projects/:project_id/issues/:issue_id/visit}
 */
export async function getApiProjectsProjectIdIssuesIssueIdVisit(
  project_id: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'],
  issue_id: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
    | GetApiProjectsProjectIdIssuesIssueIdVisit401Schema
    | GetApiProjectsProjectIdIssuesIssueIdVisit403Schema
    | GetApiProjectsProjectIdIssuesIssueIdVisit404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues/${issue_id}/visit`, ...config });
  return res.data;
}

/**
 * @summary Saves issue visit
 * {@link /api/projects/:project_id/issues/:issue_id/visit}
 */
export async function postApiProjectsProjectIdIssuesIssueIdVisit(
  project_id: PostApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdVisitMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdVisit401Schema
    | PostApiProjectsProjectIdIssuesIssueIdVisit403Schema
    | PostApiProjectsProjectIdIssuesIssueIdVisit404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/visit`, ...config });
  return res.data;
}

/**
 * @summary Creates an issue
 * {@link /api/projects/:project_id/issues}
 */
export async function postApiProjectsProjectIdIssues(
  project_id: PostApiProjectsProjectIdIssuesPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdIssuesMutationRequestSchema,
  headers?: PostApiProjectsProjectIdIssuesHeaderParamsSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesMutationResponseSchema,
    | PostApiProjectsProjectIdIssues401Schema
    | PostApiProjectsProjectIdIssues403Schema
    | PostApiProjectsProjectIdIssues404Schema,
    PostApiProjectsProjectIdIssuesMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/issues`,
    data,
    headers: { ...headers, ...config.headers },
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves issues
 * {@link /api/projects/:project_id/issues}
 */
export async function getApiProjectsProjectIdIssues(
  project_id: GetApiProjectsProjectIdIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesQueryResponseSchema,
    | GetApiProjectsProjectIdIssues400Schema
    | GetApiProjectsProjectIdIssues401Schema
    | GetApiProjectsProjectIdIssues403Schema
    | GetApiProjectsProjectIdIssues404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues`, params, ...config });
  return res.data;
}

/**
 * @summary Retrieves issues group count
 * {@link /api/projects/:project_id/issues/group_count}
 */
export async function getApiProjectsProjectIdIssuesGroupCount(
  project_id: GetApiProjectsProjectIdIssuesGroupCountPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesGroupCountQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
    | GetApiProjectsProjectIdIssuesGroupCount400Schema
    | GetApiProjectsProjectIdIssuesGroupCount401Schema
    | GetApiProjectsProjectIdIssuesGroupCount403Schema
    | GetApiProjectsProjectIdIssuesGroupCount404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues/group_count`, params, ...config });
  return res.data;
}

/**
 * @summary Requests issue export
 * {@link /api/projects/:project_id/issues/export}
 */
export async function postApiProjectsProjectIdIssuesExport(
  project_id: PostApiProjectsProjectIdIssuesExportPathParamsSchema['project_id'],
  params: PostApiProjectsProjectIdIssuesExportQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesExportMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesExport400Schema
    | PostApiProjectsProjectIdIssuesExport401Schema
    | PostApiProjectsProjectIdIssuesExport403Schema
    | PostApiProjectsProjectIdIssuesExport404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/export`, params, ...config });
  return res.data;
}

/**
 * @summary Retrieves an issue
 * {@link /api/projects/:project_id/issues/:issue_id}
 */
export async function getApiProjectsProjectIdIssuesIssueId(
  project_id: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'],
  issue_id: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
    | GetApiProjectsProjectIdIssuesIssueId401Schema
    | GetApiProjectsProjectIdIssuesIssueId403Schema
    | GetApiProjectsProjectIdIssuesIssueId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues/${issue_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates an issue
 * {@link /api/projects/:project_id/issues/:issue_id}
 */
export async function patchApiProjectsProjectIdIssuesIssueId(
  project_id: PatchApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'],
  issue_id: PatchApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'],
  data?: PatchApiProjectsProjectIdIssuesIssueIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdIssuesIssueIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
    | PatchApiProjectsProjectIdIssuesIssueId401Schema
    | PatchApiProjectsProjectIdIssuesIssueId403Schema
    | PatchApiProjectsProjectIdIssuesIssueId404Schema
    | PatchApiProjectsProjectIdIssuesIssueId422Schema,
    PatchApiProjectsProjectIdIssuesIssueIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/issues/${issue_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes an issue
 * {@link /api/projects/:project_id/issues/:issue_id}
 */
export async function deleteApiProjectsProjectIdIssuesIssueId(
  project_id: DeleteApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'],
  issue_id: DeleteApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdIssuesIssueId400Schema
    | DeleteApiProjectsProjectIdIssuesIssueId401Schema
    | DeleteApiProjectsProjectIdIssuesIssueId403Schema
    | DeleteApiProjectsProjectIdIssuesIssueId404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/issues/${issue_id}`, ...config });
  return res.data;
}

/**
 * @summary Archives an issue
 * {@link /api/projects/:project_id/issues/:issue_id/archive}
 */
export async function postApiProjectsProjectIdIssuesIssueIdArchive(
  project_id: PostApiProjectsProjectIdIssuesIssueIdArchivePathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdArchivePathParamsSchema['issue_id'],
  data?: PostApiProjectsProjectIdIssuesIssueIdArchiveMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdArchiveMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdArchiveMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdArchive401Schema
    | PostApiProjectsProjectIdIssuesIssueIdArchive403Schema
    | PostApiProjectsProjectIdIssuesIssueIdArchive404Schema,
    PostApiProjectsProjectIdIssuesIssueIdArchiveMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/archive`, data, ...config });
  return res.data;
}

/**
 * @summary Requests issue export
 * {@link /api/projects/:project_id/issues/:issue_id/export}
 */
export async function postApiProjectsProjectIdIssuesIssueIdExport(
  project_id: PostApiProjectsProjectIdIssuesIssueIdExportPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdExportPathParamsSchema['issue_id'],
  data?: PostApiProjectsProjectIdIssuesIssueIdExportMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdExportMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdExportMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdExport401Schema
    | PostApiProjectsProjectIdIssuesIssueIdExport403Schema
    | PostApiProjectsProjectIdIssuesIssueIdExport404Schema,
    PostApiProjectsProjectIdIssuesIssueIdExportMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/export`, data, ...config });
  return res.data;
}

/**
 * @summary Restores an archived issue
 * {@link /api/projects/:project_id/issues/:issue_id/restore}
 */
export async function postApiProjectsProjectIdIssuesIssueIdRestore(
  project_id: PostApiProjectsProjectIdIssuesIssueIdRestorePathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdRestorePathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdRestoreMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdRestore401Schema
    | PostApiProjectsProjectIdIssuesIssueIdRestore403Schema
    | PostApiProjectsProjectIdIssuesIssueIdRestore404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/restore`, ...config });
  return res.data;
}

/**
 * @summary Updates impact
 * {@link /api/projects/:project_id/issues/:issue_id/update_impact}
 */
export async function postApiProjectsProjectIdIssuesIssueIdUpdateImpact(
  project_id: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactPathParamsSchema['issue_id'],
  data?: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact401Schema
    | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact403Schema
    | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact404Schema
    | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact422Schema,
    PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/update_impact`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves issues staleness count
 * {@link /api/projects/:project_id/dashboards/issues_staleness/count}
 */
export async function getApiProjectsProjectIdDashboardsIssuesStalenessCount(
  project_id: GetApiProjectsProjectIdDashboardsIssuesStalenessCountPathParamsSchema['project_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
    | GetApiProjectsProjectIdDashboardsIssuesStalenessCount401Schema
    | GetApiProjectsProjectIdDashboardsIssuesStalenessCount403Schema
    | GetApiProjectsProjectIdDashboardsIssuesStalenessCount404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/dashboards/issues_staleness/count`, ...config });
  return res.data;
}

/**
 * @summary Retrieves issues list
 * {@link /api/projects/:project_id/dashboards/issues_staleness/issues}
 */
export async function getApiProjectsProjectIdDashboardsIssuesStalenessIssues(
  project_id: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
    | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues400Schema
    | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues401Schema
    | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues403Schema
    | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/dashboards/issues_staleness/issues`, params, ...config });
  return res.data;
}

/**
 * @summary Retrieves locations
 * {@link /api/projects/:project_id/locations}
 */
export async function getApiProjectsProjectIdLocations(
  project_id: GetApiProjectsProjectIdLocationsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdLocationsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdLocationsQueryResponseSchema,
    | GetApiProjectsProjectIdLocations401Schema
    | GetApiProjectsProjectIdLocations403Schema
    | GetApiProjectsProjectIdLocations404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/locations`, params, ...config });
  return res.data;
}

/**
 * @summary Creates a location
 * {@link /api/projects/:project_id/locations}
 */
export async function postApiProjectsProjectIdLocations(
  project_id: PostApiProjectsProjectIdLocationsPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdLocationsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdLocationsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdLocationsMutationResponseSchema,
    | PostApiProjectsProjectIdLocations401Schema
    | PostApiProjectsProjectIdLocations403Schema
    | PostApiProjectsProjectIdLocations404Schema
    | PostApiProjectsProjectIdLocations422Schema,
    PostApiProjectsProjectIdLocationsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/locations`, data, ...config });
  return res.data;
}

/**
 * @summary Updates a location
 * {@link /api/projects/:project_id/locations/:location_id}
 */
export async function patchApiProjectsProjectIdLocationsLocationId(
  project_id: PatchApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'],
  location_id: PatchApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'],
  data?: PatchApiProjectsProjectIdLocationsLocationIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdLocationsLocationIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
    | PatchApiProjectsProjectIdLocationsLocationId401Schema
    | PatchApiProjectsProjectIdLocationsLocationId403Schema
    | PatchApiProjectsProjectIdLocationsLocationId404Schema
    | PatchApiProjectsProjectIdLocationsLocationId422Schema,
    PatchApiProjectsProjectIdLocationsLocationIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/locations/${location_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves a location
 * {@link /api/projects/:project_id/locations/:location_id}
 */
export async function getApiProjectsProjectIdLocationsLocationId(
  project_id: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'],
  location_id: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
    | GetApiProjectsProjectIdLocationsLocationId401Schema
    | GetApiProjectsProjectIdLocationsLocationId403Schema
    | GetApiProjectsProjectIdLocationsLocationId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/locations/${location_id}`, ...config });
  return res.data;
}

/**
 * @summary Deletes a location
 * {@link /api/projects/:project_id/locations/:location_id}
 */
export async function deleteApiProjectsProjectIdLocationsLocationId(
  project_id: DeleteApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'],
  location_id: DeleteApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdLocationsLocationId401Schema
    | DeleteApiProjectsProjectIdLocationsLocationId403Schema
    | DeleteApiProjectsProjectIdLocationsLocationId404Schema
    | DeleteApiProjectsProjectIdLocationsLocationId422Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/locations/${location_id}`, ...config });
  return res.data;
}

/**
 * @summary Sorts locations
 * {@link /api/projects/:project_id/locations/:location_id/sort}
 */
export async function postApiProjectsProjectIdLocationsLocationIdSort(
  project_id: PostApiProjectsProjectIdLocationsLocationIdSortPathParamsSchema['project_id'],
  location_id: PostApiProjectsProjectIdLocationsLocationIdSortPathParamsSchema['location_id'],
  data?: PostApiProjectsProjectIdLocationsLocationIdSortMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdLocationsLocationIdSortMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdLocationsLocationIdSortMutationResponseSchema,
    | PostApiProjectsProjectIdLocationsLocationIdSort401Schema
    | PostApiProjectsProjectIdLocationsLocationIdSort403Schema
    | PostApiProjectsProjectIdLocationsLocationIdSort404Schema,
    PostApiProjectsProjectIdLocationsLocationIdSortMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/locations/${location_id}/sort`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves notifications
 * {@link /api/notifications}
 */
export async function getApiNotifications(
  params?: GetApiNotificationsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiNotificationsQueryResponseSchema,
    GetApiNotifications400Schema | GetApiNotifications401Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/notifications`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves notifications overview
 * {@link /api/notifications/overview}
 */
export async function getApiNotificationsOverview(config: Partial<RequestConfig> = {}) {
  const res = await client<
    GetApiNotificationsOverviewQueryResponseSchema,
    GetApiNotificationsOverview401Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/notifications/overview`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Marks notification as read
 * {@link /api/notifications/:notification_id/mark_read}
 */
export async function postApiNotificationsNotificationIdMarkRead(
  notification_id: PostApiNotificationsNotificationIdMarkReadPathParamsSchema['notification_id'],
  data?: PostApiNotificationsNotificationIdMarkReadMutationRequestSchema,
  config: Partial<RequestConfig<PostApiNotificationsNotificationIdMarkReadMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiNotificationsNotificationIdMarkReadMutationResponseSchema,
    PostApiNotificationsNotificationIdMarkRead401Schema | PostApiNotificationsNotificationIdMarkRead404Schema,
    PostApiNotificationsNotificationIdMarkReadMutationRequestSchema
  >({ method: 'POST', url: `/api/notifications/${notification_id}/mark_read`, data, ...config });
  return res.data;
}

/**
 * @summary Marks all notifications as read
 * {@link /api/notifications/mark_all_read}
 */
export async function postApiNotificationsMarkAllRead(config: Partial<RequestConfig> = {}) {
  const res = await client<
    PostApiNotificationsMarkAllReadMutationResponseSchema,
    PostApiNotificationsMarkAllRead401Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/notifications/mark_all_read`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieve the current user onboarding data
 * {@link /api/onboarding}
 */
export async function getApiOnboarding(config: Partial<RequestConfig> = {}) {
  const res = await client<
    GetApiOnboardingQueryResponseSchema,
    GetApiOnboarding401Schema | GetApiOnboarding404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/onboarding`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Updates the current user onboarding
 * {@link /api/onboarding}
 */
export async function patchApiOnboarding(
  data?: PatchApiOnboardingMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiOnboardingMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiOnboardingMutationResponseSchema,
    PatchApiOnboarding401Schema | PatchApiOnboarding404Schema,
    PatchApiOnboardingMutationRequestSchema
  >({ method: 'PATCH', url: `/api/onboarding`, data, ...config });
  return res.data;
}

/**
 * @summary Finishes onboarding
 * {@link /api/onboarding/finish}
 */
export async function postApiOnboardingFinish(
  data?: PostApiOnboardingFinishMutationRequestSchema,
  config: Partial<RequestConfig<PostApiOnboardingFinishMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiOnboardingFinishMutationResponseSchema,
    PostApiOnboardingFinish401Schema | PostApiOnboardingFinish404Schema | PostApiOnboardingFinish422Schema,
    PostApiOnboardingFinishMutationRequestSchema
  >({ method: 'POST', url: `/api/onboarding/finish`, data, ...config });
  return res.data;
}

/**
 * @summary Creates an org
 * {@link /api/orgs}
 */
export async function postApiOrgs(
  data?: PostApiOrgsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiOrgsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiOrgsMutationResponseSchema,
    PostApiOrgs401Schema | PostApiOrgs422Schema,
    PostApiOrgsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/orgs`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves orgs
 * {@link /api/orgs}
 */
export async function getApiOrgs(config: Partial<RequestConfig> = {}) {
  const res = await client<GetApiOrgsQueryResponseSchema, GetApiOrgs401Schema, unknown>({
    method: 'GET',
    url: `/api/orgs`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Updates an org
 * {@link /api/orgs/:org_id}
 */
export async function patchApiOrgsOrgId(
  org_id: PatchApiOrgsOrgIdPathParamsSchema['org_id'],
  data?: PatchApiOrgsOrgIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiOrgsOrgIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiOrgsOrgIdMutationResponseSchema,
    PatchApiOrgsOrgId401Schema,
    PatchApiOrgsOrgIdMutationRequestSchema
  >({
    method: 'PATCH',
    url: `/api/orgs/${org_id}`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves an org
 * {@link /api/orgs/:org_id}
 */
export async function getApiOrgsOrgId(
  org_id: GetApiOrgsOrgIdPathParamsSchema['org_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiOrgsOrgIdQueryResponseSchema,
    GetApiOrgsOrgId401Schema | GetApiOrgsOrgId403Schema | GetApiOrgsOrgId404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/orgs/${org_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Checks org domain
 * {@link /api/orgs/check_domain}
 */
export async function postApiOrgsCheckDomain(
  data?: PostApiOrgsCheckDomainMutationRequestSchema,
  config: Partial<RequestConfig<PostApiOrgsCheckDomainMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiOrgsCheckDomainMutationResponseSchema,
    PostApiOrgsCheckDomain401Schema,
    PostApiOrgsCheckDomainMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/orgs/check_domain`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Resends verification email
 * {@link /api/orgs/:org_id/resend_verification_email}
 */
export async function postApiOrgsOrgIdResendVerificationEmail(
  org_id: PostApiOrgsOrgIdResendVerificationEmailPathParamsSchema['org_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiOrgsOrgIdResendVerificationEmailMutationResponseSchema,
    | PostApiOrgsOrgIdResendVerificationEmail401Schema
    | PostApiOrgsOrgIdResendVerificationEmail403Schema
    | PostApiOrgsOrgIdResendVerificationEmail404Schema,
    unknown
  >({ method: 'POST', url: `/api/orgs/${org_id}/resend_verification_email`, ...config });
  return res.data;
}

/**
 * @summary Retrieves the product tour status for the user
 * {@link /api/product_tours/:product_tour_key}
 */
export async function getApiProductToursProductTourKey(
  product_tour_key: GetApiProductToursProductTourKeyPathParamsSchema['product_tour_key'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProductToursProductTourKeyQueryResponseSchema,
    GetApiProductToursProductTourKey401Schema | GetApiProductToursProductTourKey404Schema,
    unknown
  >({ method: 'GET', url: `/api/product_tours/${product_tour_key}`, ...config });
  return res.data;
}

/**
 * @summary Updates the product tour status for the user
 * {@link /api/product_tours/:product_tour_key}
 */
export async function patchApiProductToursProductTourKey(
  product_tour_key: PatchApiProductToursProductTourKeyPathParamsSchema['product_tour_key'],
  data?: PatchApiProductToursProductTourKeyMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProductToursProductTourKeyMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProductToursProductTourKeyMutationResponseSchema,
    PatchApiProductToursProductTourKey401Schema | PatchApiProductToursProductTourKey404Schema,
    PatchApiProductToursProductTourKeyMutationRequestSchema
  >({ method: 'PATCH', url: `/api/product_tours/${product_tour_key}`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves project pending access requests
 * {@link /api/projects/:project_id/access_requests}
 */
export async function getApiProjectsProjectIdAccessRequests(
  project_id: GetApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdAccessRequestsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
    | GetApiProjectsProjectIdAccessRequests400Schema
    | GetApiProjectsProjectIdAccessRequests401Schema
    | GetApiProjectsProjectIdAccessRequests403Schema
    | GetApiProjectsProjectIdAccessRequests404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/access_requests`, params, ...config });
  return res.data;
}

/**
 * @summary Creates a project access request
 * {@link /api/projects/:project_id/access_requests}
 */
export async function postApiProjectsProjectIdAccessRequests(
  project_id: PostApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdAccessRequestsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdAccessRequestsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdAccessRequestsMutationResponseSchema,
    | PostApiProjectsProjectIdAccessRequests400Schema
    | PostApiProjectsProjectIdAccessRequests401Schema
    | PostApiProjectsProjectIdAccessRequests404Schema
    | PostApiProjectsProjectIdAccessRequests422Schema,
    PostApiProjectsProjectIdAccessRequestsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/access_requests`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves a project access request
 * {@link /api/projects/:project_id/access_requests/:project_access_request_id}
 */
export async function getApiProjectsProjectIdAccessRequestsProjectAccessRequestId(
  project_id: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_id'],
  project_access_request_id: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_access_request_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
    | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId401Schema
    | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId403Schema
    | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/access_requests/${project_access_request_id}`, ...config });
  return res.data;
}

/**
 * @summary Accepts a project access request
 * {@link /api/projects/:project_id/access_requests/:project_access_request_id/accept}
 */
export async function postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept(
  project_id: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptPathParamsSchema['project_id'],
  project_access_request_id: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptPathParamsSchema['project_access_request_id'],
  data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationResponseSchema,
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept401Schema
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept403Schema
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept404Schema
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept422Schema,
    PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/access_requests/${project_access_request_id}/accept`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Rejects a project access request
 * {@link /api/projects/:project_id/access_requests/:project_access_request_id/reject}
 */
export async function postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject(
  project_id: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectPathParamsSchema['project_id'],
  project_access_request_id: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectPathParamsSchema['project_access_request_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationResponseSchema,
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject401Schema
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject403Schema
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject404Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/access_requests/${project_access_request_id}/reject`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Redirects a project access request to another team
 * {@link /api/projects/:project_id/access_requests/:project_access_request_id/redirect}
 */
export async function postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect(
  project_id: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectPathParamsSchema['project_id'],
  project_access_request_id: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectPathParamsSchema['project_access_request_id'],
  data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationResponseSchema,
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect400Schema
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect401Schema
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect403Schema
    | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect404Schema,
    PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/access_requests/${project_access_request_id}/redirect`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @description Excludes archived team members by default, unless status is given
 * @summary Retrieves project people
 * {@link /api/projects/:project_id/people}
 */
export async function getApiProjectsProjectIdPeople(
  project_id: GetApiProjectsProjectIdPeoplePathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdPeopleQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdPeopleQueryResponseSchema,
    | GetApiProjectsProjectIdPeople400Schema
    | GetApiProjectsProjectIdPeople401Schema
    | GetApiProjectsProjectIdPeople404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/people`, params, ...config });
  return res.data;
}

/**
 * @summary Retrieves a team member
 * {@link /api/projects/:project_id/people/:team_member_id}
 */
export async function getApiProjectsProjectIdPeopleTeamMemberId(
  project_id: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['project_id'],
  team_member_id: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['team_member_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
    | GetApiProjectsProjectIdPeopleTeamMemberId401Schema
    | GetApiProjectsProjectIdPeopleTeamMemberId403Schema
    | GetApiProjectsProjectIdPeopleTeamMemberId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/people/${team_member_id}`, ...config });
  return res.data;
}

/**
 * @summary Creates a project
 * {@link /api/projects}
 */
export async function postApiProjects(
  data?: PostApiProjectsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsMutationResponseSchema,
    PostApiProjects401Schema | PostApiProjects403Schema,
    PostApiProjectsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves projects
 * {@link /api/projects}
 */
export async function getApiProjects(config: Partial<RequestConfig> = {}) {
  const res = await client<GetApiProjectsQueryResponseSchema, GetApiProjects401Schema, unknown>({
    method: 'GET',
    url: `/api/projects`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves a project
 * {@link /api/projects/:project_id}
 */
export async function getApiProjectsProjectId(
  project_id: GetApiProjectsProjectIdPathParamsSchema['project_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdQueryResponseSchema,
    GetApiProjectsProjectId401Schema | GetApiProjectsProjectId403Schema | GetApiProjectsProjectId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates a project
 * {@link /api/projects/:project_id}
 */
export async function patchApiProjectsProjectId(
  project_id: PatchApiProjectsProjectIdPathParamsSchema['project_id'],
  data?: PatchApiProjectsProjectIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdMutationResponseSchema,
    | PatchApiProjectsProjectId401Schema
    | PatchApiProjectsProjectId403Schema
    | PatchApiProjectsProjectId404Schema
    | PatchApiProjectsProjectId422Schema,
    PatchApiProjectsProjectIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Archives a project
 * {@link /api/projects/:project_id/archive}
 */
export async function postApiProjectsProjectIdArchive(
  project_id: PostApiProjectsProjectIdArchivePathParamsSchema['project_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdArchiveMutationResponseSchema,
    | PostApiProjectsProjectIdArchive401Schema
    | PostApiProjectsProjectIdArchive403Schema
    | PostApiProjectsProjectIdArchive404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/archive`, ...config });
  return res.data;
}

/**
 * @summary Set the default project
 * {@link /api/projects/:project_id/default}
 */
export async function postApiProjectsProjectIdDefault(
  project_id: PostApiProjectsProjectIdDefaultPathParamsSchema['project_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdDefaultMutationResponseSchema,
    | PostApiProjectsProjectIdDefault401Schema
    | PostApiProjectsProjectIdDefault403Schema
    | PostApiProjectsProjectIdDefault404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/default`, ...config });
  return res.data;
}

/**
 * @summary Creates a push subscription
 * {@link /api/push_subscriptions}
 */
export async function postApiPushSubscriptions(
  data: PostApiPushSubscriptionsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiPushSubscriptionsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiPushSubscriptionsMutationResponseSchema,
    PostApiPushSubscriptions401Schema | PostApiPushSubscriptions422Schema,
    PostApiPushSubscriptionsMutationRequestSchema
  >({ method: 'POST', url: `/api/push_subscriptions`, data, ...config });
  return res.data;
}

/**
 * @summary Updates a push subscription
 * {@link /api/push_subscriptions/:push_subscription_id}
 */
export async function patchApiPushSubscriptionsPushSubscriptionId(
  push_subscription_id: PatchApiPushSubscriptionsPushSubscriptionIdPathParamsSchema['push_subscription_id'],
  data: PatchApiPushSubscriptionsPushSubscriptionIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiPushSubscriptionsPushSubscriptionIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
    PatchApiPushSubscriptionsPushSubscriptionId401Schema | PatchApiPushSubscriptionsPushSubscriptionId404Schema,
    PatchApiPushSubscriptionsPushSubscriptionIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/push_subscriptions/${push_subscription_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes a push subscription
 * {@link /api/push_subscriptions/:push_subscription_id}
 */
export async function deleteApiPushSubscriptionsPushSubscriptionId(
  push_subscription_id: DeleteApiPushSubscriptionsPushSubscriptionIdPathParamsSchema['push_subscription_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
    DeleteApiPushSubscriptionsPushSubscriptionId401Schema | DeleteApiPushSubscriptionsPushSubscriptionId404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/push_subscriptions/${push_subscription_id}`, ...config });
  return res.data;
}

/**
 * @summary Requests a test message
 * {@link /api/push_subscriptions/ping}
 */
export async function postApiPushSubscriptionsPing(config: Partial<RequestConfig> = {}) {
  const res = await client<
    PostApiPushSubscriptionsPingMutationResponseSchema,
    PostApiPushSubscriptionsPing401Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/push_subscriptions/ping`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves queued tasks
 * {@link /api/queued_tasks}
 */
export async function getApiQueuedTasks(
  params: GetApiQueuedTasksQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiQueuedTasksQueryResponseSchema,
    GetApiQueuedTasks400Schema | GetApiQueuedTasks401Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/queued_tasks`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Refreshes user authentication
 * {@link /api/login/refresh}
 */
export async function postApiLoginRefresh(
  headers: PostApiLoginRefreshHeaderParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiLoginRefreshMutationResponseSchema,
    PostApiLoginRefresh400Schema | PostApiLoginRefresh401Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/login/refresh`,
    headers: { ...headers, ...config.headers },
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves shift activities
 * {@link /api/projects/:project_id/shift_activities}
 */
export async function getApiProjectsProjectIdShiftActivities(
  project_id: GetApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivities400Schema
    | GetApiProjectsProjectIdShiftActivities401Schema
    | GetApiProjectsProjectIdShiftActivities403Schema
    | GetApiProjectsProjectIdShiftActivities404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/shift_activities`, params, ...config });
  return res.data;
}

/**
 * @summary Creates a shift activity
 * {@link /api/projects/:project_id/shift_activities}
 */
export async function postApiProjectsProjectIdShiftActivities(
  project_id: PostApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'],
  data: PostApiProjectsProjectIdShiftActivitiesMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdShiftActivitiesMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivities401Schema
    | PostApiProjectsProjectIdShiftActivities403Schema
    | PostApiProjectsProjectIdShiftActivities404Schema
    | PostApiProjectsProjectIdShiftActivities422Schema,
    PostApiProjectsProjectIdShiftActivitiesMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_activities`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves a shift activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityId(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates a shift activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id}
 */
export async function patchApiProjectsProjectIdShiftActivitiesShiftActivityId(
  project_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'],
  shift_activity_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id'],
  data?: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationResponseSchema,
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId403Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId422Schema,
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Archives a shift activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/archive}
 */
export async function postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive(
  project_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchivePathParamsSchema['project_id'],
  shift_activity_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchivePathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive401Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive403Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive404Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/archive`, ...config });
  return res.data;
}

/**
 * @summary Restores a shift activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/restore}
 */
export async function postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore(
  project_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestorePathParamsSchema['project_id'],
  shift_activity_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestorePathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore401Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore403Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore404Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/restore`, ...config });
  return res.data;
}

/**
 * @summary Retrieves a shift activity weekly work plans
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/daily_progress}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/daily_progress`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves a shift activity documents
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/documents}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/documents`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Exports a list of shift activities
 * {@link /api/projects/:project_id/shift_activities/export}
 */
export async function postApiProjectsProjectIdShiftActivitiesExport(
  project_id: PostApiProjectsProjectIdShiftActivitiesExportPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdShiftActivitiesExportMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdShiftActivitiesExportMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesExportMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivitiesExport401Schema
    | PostApiProjectsProjectIdShiftActivitiesExport403Schema
    | PostApiProjectsProjectIdShiftActivitiesExport404Schema,
    PostApiProjectsProjectIdShiftActivitiesExportMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_activities/export`, data, ...config });
  return res.data;
}

/**
 * @summary Creates a shift activity import
 * {@link /api/projects/:project_id/shift_activities/imports}
 */
export async function postApiProjectsProjectIdShiftActivitiesImports(
  project_id: PostApiProjectsProjectIdShiftActivitiesImportsPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdShiftActivitiesImportsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdShiftActivitiesImportsMutationRequestSchema>> = {}
) {
  const formData = new FormData();
  if (data) {
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof typeof data];
      if (typeof key === 'string' && (typeof value === 'string' || value instanceof Blob)) {
        formData.append(key, value);
      }
    });
  }
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesImportsMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivitiesImports401Schema
    | PostApiProjectsProjectIdShiftActivitiesImports403Schema
    | PostApiProjectsProjectIdShiftActivitiesImports404Schema
    | PostApiProjectsProjectIdShiftActivitiesImports422Schema,
    PostApiProjectsProjectIdShiftActivitiesImportsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_activities/imports`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', ...config.headers },
    ...config,
  });
  return res.data;
}

/**
 * @summary Removes a document from a shift activity progress log
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id/document_references/:document_reference_id}
 */
export async function deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId(
  project_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['project_id'],
  shift_activity_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['shift_activity_id'],
  progress_log_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['progress_log_id'],
  document_reference_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['document_reference_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId401Schema
    | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId403Schema
    | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId404Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/progress_logs/${progress_log_id}/document_references/${document_reference_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves shift activity progress log documents
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id/documents}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'],
  progress_log_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/progress_logs/${progress_log_id}/documents`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Adds a document to a shift activity progress log (from an upload or an existing document)
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id/documents}
 */
export async function postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments(
  project_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'],
  shift_activity_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'],
  progress_log_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'],
  data?: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments422Schema,
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/progress_logs/${progress_log_id}/documents`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves a shift activity progress logs
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/progress_logs`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Creates a shift activity progress log
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs}
 */
export async function postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs(
  project_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'],
  shift_activity_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'],
  data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs403Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs422Schema,
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/progress_logs`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves a shift activity progress log
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'],
  progress_log_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/progress_logs/${progress_log_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Updates a shift activity progress log
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id}
 */
export async function patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId(
  project_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'],
  shift_activity_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'],
  progress_log_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id'],
  data?: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationRequestSchema,
  config: Partial<
    RequestConfig<PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationResponseSchema,
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId403Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId422Schema,
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationRequestSchema
  >({
    method: 'PATCH',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/progress_logs/${progress_log_id}`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Updates shift activity readiness
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/readiness}
 */
export async function patchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness(
  project_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessPathParamsSchema['project_id'],
  shift_activity_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessPathParamsSchema['shift_activity_id'],
  data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationRequestSchema,
  config: Partial<
    RequestConfig<PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationResponseSchema,
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness400Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness401Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness403Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness404Schema,
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationRequestSchema
  >({
    method: 'PATCH',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/readiness`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves the shift activity requirements
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/requirements`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Creates a shift activity requirement
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements}
 */
export async function postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements(
  project_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'],
  shift_activity_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id'],
  data?: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements403Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements422Schema,
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/requirements`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Sorts shift activity requirements
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/sort}
 */
export async function postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort(
  project_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortPathParamsSchema['project_id'],
  shift_activity_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortPathParamsSchema['shift_activity_id'],
  data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort400Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort401Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort403Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort404Schema,
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/requirements/sort`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Updates a shift activity requirement
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/:requirement_id}
 */
export async function patchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId(
  project_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['project_id'],
  shift_activity_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['shift_activity_id'],
  requirement_id: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['requirement_id'],
  data?: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationRequestSchema,
  config: Partial<
    RequestConfig<PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema
    | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId422Schema,
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationRequestSchema
  >({
    method: 'PATCH',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/requirements/${requirement_id}`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Deletes a shift activity requirement
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/:requirement_id}
 */
export async function deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId(
  project_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['project_id'],
  shift_activity_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['shift_activity_id'],
  requirement_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['requirement_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema
    | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema
    | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/requirements/${requirement_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Marks shift activity requirement as completed
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/:requirement_id/completion}
 */
export async function postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion(
  project_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['project_id'],
  shift_activity_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['shift_activity_id'],
  requirement_id: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['requirement_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema
    | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/requirements/${requirement_id}/completion`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Marks shift activity requirement as incomplete
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/:requirement_id/completion}
 */
export async function deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion(
  project_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['project_id'],
  shift_activity_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['shift_activity_id'],
  requirement_id: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['requirement_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
    | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema
    | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema
    | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/requirements/${requirement_id}/completion`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves a shift activity resources usage material list
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/resources_usage`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves a shift activity resources usage stats
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage/stats}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/resources_usage/stats`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves the weekly planning items of an activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/weekly_planning}
 */
export async function getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning(
  project_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['project_id'],
  shift_activity_id: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning401Schema
    | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_activities/${shift_activity_id}/weekly_planning`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves shift report collaborators comments
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/collaborators}
 */
export async function getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators(
  project_id: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'],
  shift_report_id: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/comments/collaborators`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Creates a shift report collaborators comment
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/collaborators}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'],
  data?: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators422Schema,
    PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/comments/collaborators`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves shift report public comments
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/public}
 */
export async function getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic(
  project_id: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'],
  shift_report_id: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/comments/public`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Creates a shift report public comment
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/public}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'],
  data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema,
    PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/comments/public`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Deletes a shift report comment
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/:comment_id}
 */
export async function deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId(
  project_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema['project_id'],
  shift_report_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema['shift_report_id'],
  comment_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema['comment_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId401Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId403Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId404Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId422Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/comments/${comment_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Removes a document from a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/document_references/:document_reference_id}
 */
export async function deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId(
  project_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema['project_id'],
  shift_report_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema['shift_report_id'],
  document_reference_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema['document_reference_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId401Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId403Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId404Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/document_references/${document_reference_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves shift report documents
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/documents}
 */
export async function getApiProjectsProjectIdShiftReportsShiftReportIdDocuments(
  project_id: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'],
  shift_report_id: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/documents`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Adds a document to a shift report (from an upload or an existing document)
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/documents}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdDocuments(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'],
  data?: PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments422Schema,
    PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/documents`, data, ...config });
  return res.data;
}

/**
 * @summary Exports a list of shift reports
 * {@link /api/projects/:project_id/shift_reports/export}
 */
export async function postApiProjectsProjectIdShiftReportsExport(
  project_id: PostApiProjectsProjectIdShiftReportsExportPathParamsSchema['project_id'],
  data: PostApiProjectsProjectIdShiftReportsExportMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdShiftReportsExportMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsExportMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsExport400Schema
    | PostApiProjectsProjectIdShiftReportsExport401Schema
    | PostApiProjectsProjectIdShiftReportsExport403Schema
    | PostApiProjectsProjectIdShiftReportsExport404Schema,
    PostApiProjectsProjectIdShiftReportsExportMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_reports/export`, data, ...config });
  return res.data;
}

/**
 * @summary Exports a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/export}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdExport(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdExportPathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdExportPathParamsSchema['shift_report_id'],
  params: PostApiProjectsProjectIdShiftReportsShiftReportIdExportQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdExport400Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdExport401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdExport403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdExport404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/export`, params, ...config });
  return res.data;
}

/**
 * @summary Imports data from an XLSX file to a draft shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/import}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdImport(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdImportPathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdImportPathParamsSchema['shift_report_id'],
  data?: PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationRequestSchema>> = {}
) {
  const formData = new FormData();
  if (data) {
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof typeof data];
      if (typeof key === 'string' && (typeof value === 'string' || value instanceof Blob)) {
        formData.append(key, value);
      }
    });
  }
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdImport400Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdImport401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdImport403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdImport404Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdImport422Schema,
    PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/import`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', ...config.headers },
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves team members with access to shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/people}
 */
export async function getApiProjectsProjectIdShiftReportsShiftReportIdPeople(
  project_id: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['project_id'],
  shift_report_id: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople401Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople403Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/people`, params, ...config });
  return res.data;
}

/**
 * @summary Retrieves shift report resource documents
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/:resource_type/:resource_id/documents}
 */
export async function getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments(
  project_id: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'],
  shift_report_id: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'],
  resource_type: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'],
  resource_id: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/${resource_type}/${resource_id}/documents`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Adds a document to a shift report resource (from an upload or an existing document)
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/:resource_type/:resource_id/documents}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'],
  resource_type: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'],
  resource_id: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'],
  data?: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments422Schema,
    PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/${resource_type}/${resource_id}/documents`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Removes a document from a shift report resource
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/:resource_type/:resource_id/documents/:document_id}
 */
export async function deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId(
  project_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['project_id'],
  shift_report_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['shift_report_id'],
  document_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['document_id'],
  resource_type: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['resource_type'],
  resource_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['resource_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId401Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId403Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId404Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/${resource_type}/${resource_id}/documents/${document_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves resources
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/resources/:kind}
 */
export async function getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind(
  project_id: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'],
  shift_report_id: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'],
  kind: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/resources/${kind}`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Create resource
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/resources/:kind}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'],
  kind: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'],
  data: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind422Schema,
    PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/resources/${kind}`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves published shift reports
 * {@link /api/projects/:project_id/shift_reports/completions}
 */
export async function getApiProjectsProjectIdShiftReportsCompletions(
  project_id: GetApiProjectsProjectIdShiftReportsCompletionsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
    GetApiProjectsProjectIdShiftReportsCompletions401Schema | GetApiProjectsProjectIdShiftReportsCompletions403Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/shift_reports/completions`, ...config });
  return res.data;
}

/**
 * @summary Retrieves all quality indicators for a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/quality_indicators}
 */
export async function getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators(
  project_id: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['project_id'],
  shift_report_id: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['shift_report_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators401Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators403Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/quality_indicators`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves published shift reports
 * {@link /api/projects/:project_id/shift_reports}
 */
export async function getApiProjectsProjectIdShiftReports(
  project_id: GetApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReports400Schema
    | GetApiProjectsProjectIdShiftReports401Schema
    | GetApiProjectsProjectIdShiftReports403Schema
    | GetApiProjectsProjectIdShiftReports404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/shift_reports`, params, ...config });
  return res.data;
}

/**
 * @summary Creates a shift report
 * {@link /api/projects/:project_id/shift_reports}
 */
export async function postApiProjectsProjectIdShiftReports(
  project_id: PostApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'],
  data: PostApiProjectsProjectIdShiftReportsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdShiftReportsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReports401Schema
    | PostApiProjectsProjectIdShiftReports403Schema
    | PostApiProjectsProjectIdShiftReports404Schema
    | PostApiProjectsProjectIdShiftReports422Schema,
    PostApiProjectsProjectIdShiftReportsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_reports`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves archived shift reports
 * {@link /api/projects/:project_id/shift_reports/archived}
 */
export async function getApiProjectsProjectIdShiftReportsArchived(
  project_id: GetApiProjectsProjectIdShiftReportsArchivedPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsArchivedQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsArchived400Schema
    | GetApiProjectsProjectIdShiftReportsArchived401Schema
    | GetApiProjectsProjectIdShiftReportsArchived403Schema
    | GetApiProjectsProjectIdShiftReportsArchived404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/shift_reports/archived`, params, ...config });
  return res.data;
}

/**
 * @summary Retrieves draft shift reports where the user is an owner or a collaborator
 * {@link /api/projects/:project_id/shift_reports/draft}
 */
export async function getApiProjectsProjectIdShiftReportsDraft(
  project_id: GetApiProjectsProjectIdShiftReportsDraftPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsDraftQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsDraft400Schema
    | GetApiProjectsProjectIdShiftReportsDraft401Schema
    | GetApiProjectsProjectIdShiftReportsDraft403Schema
    | GetApiProjectsProjectIdShiftReportsDraft404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/shift_reports/draft`, params, ...config });
  return res.data;
}

/**
 * @summary Retrieves a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id}
 */
export async function getApiProjectsProjectIdShiftReportsShiftReportId(
  project_id: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'],
  shift_report_id: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
    | GetApiProjectsProjectIdShiftReportsShiftReportId401Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportId403Schema
    | GetApiProjectsProjectIdShiftReportsShiftReportId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/shift_reports/${shift_report_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates a draft shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id}
 */
export async function patchApiProjectsProjectIdShiftReportsShiftReportId(
  project_id: PatchApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'],
  shift_report_id: PatchApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'],
  data?: PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
    | PatchApiProjectsProjectIdShiftReportsShiftReportId400Schema
    | PatchApiProjectsProjectIdShiftReportsShiftReportId401Schema
    | PatchApiProjectsProjectIdShiftReportsShiftReportId403Schema
    | PatchApiProjectsProjectIdShiftReportsShiftReportId404Schema
    | PatchApiProjectsProjectIdShiftReportsShiftReportId422Schema,
    PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/shift_reports/${shift_report_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes a draft shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id}
 */
export async function deleteApiProjectsProjectIdShiftReportsShiftReportId(
  project_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'],
  shift_report_id: DeleteApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdShiftReportsShiftReportId400Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportId401Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportId403Schema
    | DeleteApiProjectsProjectIdShiftReportsShiftReportId404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/shift_reports/${shift_report_id}`, ...config });
  return res.data;
}

/**
 * @summary Archives a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/archive}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdArchive(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdArchivePathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdArchivePathParamsSchema['shift_report_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive404Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/archive`, ...config });
  return res.data;
}

/**
 * @summary Publishes a draft shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/publish}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdPublish(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishPathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishPathParamsSchema['shift_report_id'],
  params?: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish400Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/publish`, params, ...config });
  return res.data;
}

/**
 * @summary Deletes all elements from a shift report section
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/reset_section}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdResetSection(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionPathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionPathParamsSchema['shift_report_id'],
  params?: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection400Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection401Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/reset_section`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Restores a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/restore}
 */
export async function postApiProjectsProjectIdShiftReportsShiftReportIdRestore(
  project_id: PostApiProjectsProjectIdShiftReportsShiftReportIdRestorePathParamsSchema['project_id'],
  shift_report_id: PostApiProjectsProjectIdShiftReportsShiftReportIdRestorePathParamsSchema['shift_report_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationResponseSchema,
    | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore401Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore403Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore404Schema
    | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/shift_reports/${shift_report_id}/restore`, ...config });
  return res.data;
}

/**
 * @summary Creates a Showcase project
 * {@link /api/projects/showcases}
 */
export async function postApiProjectsShowcases(
  data: PostApiProjectsShowcasesMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsShowcasesMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsShowcasesMutationResponseSchema,
    PostApiProjectsShowcases401Schema | PostApiProjectsShowcases403Schema,
    PostApiProjectsShowcasesMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/showcases`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves the team channel configuration
 * {@link /api/projects/:project_id/teams/:team_id/channel_configuration}
 */
export async function getApiProjectsProjectIdTeamsTeamIdChannelConfiguration(
  project_id: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'],
  team_id: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
    | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
    | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
    | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/teams/${team_id}/channel_configuration`, ...config });
  return res.data;
}

/**
 * @summary Updates the team channel configuration
 * {@link /api/projects/:project_id/teams/:team_id/channel_configuration}
 */
export async function patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration(
  project_id: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'],
  team_id: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id'],
  data?: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationResponseSchema,
    | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
    | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
    | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema
    | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration422Schema,
    PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/teams/${team_id}/channel_configuration`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves a team join token
 * {@link /api/projects/:project_id/teams/:team_id/join_token}
 */
export async function getApiProjectsProjectIdTeamsTeamIdJoinToken(
  project_id: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'],
  team_id: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
    | GetApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
    | GetApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
    | GetApiProjectsProjectIdTeamsTeamIdJoinToken404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/teams/${team_id}/join_token`, ...config });
  return res.data;
}

/**
 * @summary Creates a new team join token (and expires existing join token)
 * {@link /api/projects/:project_id/teams/:team_id/join_token}
 */
export async function postApiProjectsProjectIdTeamsTeamIdJoinToken(
  project_id: PostApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'],
  data: PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
    | PostApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
    | PostApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
    | PostApiProjectsProjectIdTeamsTeamIdJoinToken422Schema,
    PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/teams/${team_id}/join_token`, data, ...config });
  return res.data;
}

/**
 * @summary Expires a team join token
 * {@link /api/projects/:project_id/teams/:team_id/join_token}
 */
export async function deleteApiProjectsProjectIdTeamsTeamIdJoinToken(
  project_id: DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'],
  team_id: DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
    | DeleteApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
    | DeleteApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
    | DeleteApiProjectsProjectIdTeamsTeamIdJoinToken404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/teams/${team_id}/join_token`, ...config });
  return res.data;
}

/**
 * @summary Retrieves public team join token
 * {@link /api/team_join_tokens/:token}
 */
export async function getApiTeamJoinTokensToken(
  token: GetApiTeamJoinTokensTokenPathParamsSchema['token'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<GetApiTeamJoinTokensTokenQueryResponseSchema, GetApiTeamJoinTokensToken404Schema, unknown>({
    method: 'GET',
    url: `/api/team_join_tokens/${token}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Adds a user to a team using a team join token
 * {@link /api/team_members}
 */
export async function postApiTeamMembers(
  data: PostApiTeamMembersMutationRequestSchema,
  config: Partial<RequestConfig<PostApiTeamMembersMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiTeamMembersMutationResponseSchema,
    PostApiTeamMembers401Schema | PostApiTeamMembers422Schema,
    PostApiTeamMembersMutationRequestSchema
  >({ method: 'POST', url: `/api/team_members`, data, ...config });
  return res.data;
}

/**
 * @summary Invites a user to the team
 * {@link /api/projects/:project_id/teams/:team_id/members}
 */
export async function postApiProjectsProjectIdTeamsTeamIdMembers(
  project_id: PostApiProjectsProjectIdTeamsTeamIdMembersPathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdMembersPathParamsSchema['team_id'],
  data?: PostApiProjectsProjectIdTeamsTeamIdMembersMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdMembersMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdMembersMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdMembers401Schema
    | PostApiProjectsProjectIdTeamsTeamIdMembers403Schema
    | PostApiProjectsProjectIdTeamsTeamIdMembers404Schema
    | PostApiProjectsProjectIdTeamsTeamIdMembers422Schema,
    PostApiProjectsProjectIdTeamsTeamIdMembersMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/teams/${team_id}/members`, data, ...config });
  return res.data;
}

/**
 * @summary Updates a team member
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id}
 */
export async function patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId(
  project_id: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['project_id'],
  team_id: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_id'],
  team_member_id: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_member_id'],
  data?: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
    | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema
    | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema
    | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema
    | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId422Schema,
    PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationRequestSchema
  >({
    method: 'PATCH',
    url: `/api/projects/${project_id}/teams/${team_id}/members/${team_member_id}`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Deletes an invited team member
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id}
 */
export async function deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId(
  project_id: DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['project_id'],
  team_id: DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_id'],
  team_member_id: DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_member_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema
    | DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema
    | DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/teams/${team_id}/members/${team_member_id}`, ...config });
  return res.data;
}

/**
 * @summary Archives a team member
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id/archive}
 */
export async function postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive(
  project_id: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema['team_id'],
  team_member_id: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema['team_member_id'],
  data?: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive401Schema
    | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive403Schema
    | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive404Schema
    | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive422Schema,
    PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/teams/${team_id}/members/${team_member_id}/archive`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves team member issue dependencies
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id/issue_dependencies}
 */
export async function getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies(
  project_id: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['project_id'],
  team_id: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_id'],
  team_member_id: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_member_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
    | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies401Schema
    | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies403Schema
    | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/teams/${team_id}/members/${team_member_id}/issue_dependencies`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Resends invite email to an invited user
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id/resend_invite_email}
 */
export async function postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail(
  project_id: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema['team_id'],
  team_member_id: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema['team_member_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail401Schema
    | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail403Schema
    | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail404Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/teams/${team_id}/members/${team_member_id}/resend_invite_email`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieve metabase dashboard token
 * {@link /api/projects/:project_id/teams/:team_id/metabase_dashboard}
 */
export async function getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard(
  project_id: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['project_id'],
  team_id: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['team_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
    | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard401Schema
    | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard403Schema
    | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard503Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/teams/${team_id}/metabase_dashboard`, ...config });
  return res.data;
}

/**
 * @summary Enables a resource
 * {@link /api/projects/:project_id/teams/:team_id/resources/:resource_id/enable}
 */
export async function postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable(
  project_id: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema['team_id'],
  resource_id: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema['resource_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable401Schema
    | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable403Schema
    | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/teams/${team_id}/resources/${resource_id}/enable`, ...config });
  return res.data;
}

/**
 * @summary Disables a resource
 * {@link /api/projects/:project_id/teams/:team_id/resources/:resource_id/disable}
 */
export async function postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable(
  project_id: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema['team_id'],
  resource_id: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema['resource_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable401Schema
    | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable403Schema
    | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable404Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/teams/${team_id}/resources/${resource_id}/disable`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves resources
 * {@link /api/projects/:project_id/teams/:team_id/resources/:kind}
 */
export async function getApiProjectsProjectIdTeamsTeamIdResourcesKind(
  project_id: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'],
  team_id: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'],
  kind: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
    | GetApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
    | GetApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
    | GetApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/teams/${team_id}/resources/${kind}`, params, ...config });
  return res.data;
}

/**
 * @summary Create resource
 * {@link /api/projects/:project_id/teams/:team_id/resources/:kind}
 */
export async function postApiProjectsProjectIdTeamsTeamIdResourcesKind(
  project_id: PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'],
  kind: PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'],
  data: PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
    | PostApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
    | PostApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema
    | PostApiProjectsProjectIdTeamsTeamIdResourcesKind422Schema,
    PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/teams/${team_id}/resources/${kind}`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves team current subscription plan
 * {@link /api/projects/:project_id/teams/:team_id/subscription_plan}
 */
export async function getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan(
  project_id: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['project_id'],
  team_id: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['team_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
    | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan401Schema
    | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan403Schema
    | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/teams/${team_id}/subscription_plan`, ...config });
  return res.data;
}

/**
 * @summary Start a team subscription update
 * {@link /api/projects/:project_id/teams/:team_id/subscription}
 */
export async function postApiProjectsProjectIdTeamsTeamIdSubscription(
  project_id: PostApiProjectsProjectIdTeamsTeamIdSubscriptionPathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdSubscriptionPathParamsSchema['team_id'],
  data?: PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdSubscription401Schema
    | PostApiProjectsProjectIdTeamsTeamIdSubscription403Schema
    | PostApiProjectsProjectIdTeamsTeamIdSubscription404Schema
    | PostApiProjectsProjectIdTeamsTeamIdSubscription422Schema,
    PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/teams/${team_id}/subscription`, data, ...config });
  return res.data;
}

/**
 * @summary Confirm a Stripe Billing subscription
 * {@link /api/projects/:project_id/teams/:team_id/subscription/confirm}
 */
export async function postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm(
  project_id: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmPathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmPathParamsSchema['team_id'],
  data?: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm401Schema
    | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm403Schema
    | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm404Schema
    | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm422Schema,
    PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/teams/${team_id}/subscription/confirm`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves an URL for the billing portal
 * {@link /api/projects/:project_id/teams/:team_id/subscription/billing_portal}
 */
export async function postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal(
  project_id: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalPathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalPathParamsSchema['team_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal401Schema
    | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal403Schema
    | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal404Schema
    | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/teams/${team_id}/subscription/billing_portal`, ...config });
  return res.data;
}

/**
 * @summary Retrieves teams
 * {@link /api/projects/:project_id/teams}
 */
export async function getApiProjectsProjectIdTeams(
  project_id: GetApiProjectsProjectIdTeamsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdTeamsQueryResponseSchema,
    | GetApiProjectsProjectIdTeams401Schema
    | GetApiProjectsProjectIdTeams403Schema
    | GetApiProjectsProjectIdTeams404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/teams`, ...config });
  return res.data;
}

/**
 * @summary Creates a team
 * {@link /api/projects/:project_id/teams}
 */
export async function postApiProjectsProjectIdTeams(
  project_id: PostApiProjectsProjectIdTeamsPathParamsSchema['project_id'],
  data?: PostApiProjectsProjectIdTeamsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdTeamsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsMutationResponseSchema,
    | PostApiProjectsProjectIdTeams401Schema
    | PostApiProjectsProjectIdTeams403Schema
    | PostApiProjectsProjectIdTeams404Schema
    | PostApiProjectsProjectIdTeams422Schema,
    PostApiProjectsProjectIdTeamsMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/teams`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves a team
 * {@link /api/projects/:project_id/teams/:team_id}
 */
export async function getApiProjectsProjectIdTeamsTeamId(
  project_id: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'],
  team_id: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
    | GetApiProjectsProjectIdTeamsTeamId401Schema
    | GetApiProjectsProjectIdTeamsTeamId403Schema
    | GetApiProjectsProjectIdTeamsTeamId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/teams/${team_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates a team
 * {@link /api/projects/:project_id/teams/:team_id}
 */
export async function patchApiProjectsProjectIdTeamsTeamId(
  project_id: PatchApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'],
  team_id: PatchApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'],
  data?: PatchApiProjectsProjectIdTeamsTeamIdMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiProjectsProjectIdTeamsTeamIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
    | PatchApiProjectsProjectIdTeamsTeamId401Schema
    | PatchApiProjectsProjectIdTeamsTeamId403Schema
    | PatchApiProjectsProjectIdTeamsTeamId404Schema,
    PatchApiProjectsProjectIdTeamsTeamIdMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/teams/${team_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Deletes a team
 * {@link /api/projects/:project_id/teams/:team_id}
 */
export async function deleteApiProjectsProjectIdTeamsTeamId(
  project_id: DeleteApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'],
  team_id: DeleteApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdTeamsTeamId401Schema
    | DeleteApiProjectsProjectIdTeamsTeamId403Schema
    | DeleteApiProjectsProjectIdTeamsTeamId404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/teams/${team_id}`, ...config });
  return res.data;
}

/**
 * @summary Resends invite email to all invited users on the team
 * {@link /api/projects/:project_id/teams/:team_id/resend_members_invites}
 */
export async function postApiProjectsProjectIdTeamsTeamIdResendMembersInvites(
  project_id: PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesPathParamsSchema['project_id'],
  team_id: PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesPathParamsSchema['team_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationResponseSchema,
    | PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites401Schema
    | PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/teams/${team_id}/resend_members_invites`, ...config });
  return res.data;
}

/**
 * @summary Retrieves time zones
 * {@link /api/time_zones}
 */
export async function getApiTimeZones(config: Partial<RequestConfig> = {}) {
  const res = await client<GetApiTimeZonesQueryResponseSchema, GetApiTimeZones401Schema, unknown>({
    method: 'GET',
    url: `/api/time_zones`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Sends a confirmation email
 * {@link /api/users/confirmation/instructions}
 */
export async function postApiUsersConfirmationInstructions(
  data: PostApiUsersConfirmationInstructionsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiUsersConfirmationInstructionsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiUsersConfirmationInstructionsMutationResponseSchema,
    Error,
    PostApiUsersConfirmationInstructionsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/users/confirmation/instructions`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Confirms a user
 * {@link /api/users/confirmation}
 */
export async function postApiUsersConfirmation(
  data: PostApiUsersConfirmationMutationRequestSchema,
  config: Partial<RequestConfig<PostApiUsersConfirmationMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiUsersConfirmationMutationResponseSchema,
    PostApiUsersConfirmation400Schema | PostApiUsersConfirmation422Schema,
    PostApiUsersConfirmationMutationRequestSchema
  >({ method: 'POST', url: `/api/users/confirmation`, data, ...config });
  return res.data;
}

/**
 * @summary Creates a user
 * {@link /api/users}
 */
export async function postApiUsers(
  data: PostApiUsersMutationRequestSchema,
  config: Partial<RequestConfig<PostApiUsersMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiUsersMutationResponseSchema,
    PostApiUsers422Schema,
    PostApiUsersMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/users`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves current user
 * {@link /api/users/me}
 */
export async function getApiUsersMe(config: Partial<RequestConfig> = {}) {
  const res = await client<GetApiUsersMeQueryResponseSchema, GetApiUsersMe401Schema, unknown>({
    method: 'GET',
    url: `/api/users/me`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Updates current user
 * {@link /api/users/me}
 */
export async function patchApiUsersMe(
  data: PatchApiUsersMeMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiUsersMeMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiUsersMeMutationResponseSchema,
    PatchApiUsersMe401Schema | PatchApiUsersMe422Schema,
    PatchApiUsersMeMutationRequestSchema
  >({
    method: 'PATCH',
    url: `/api/users/me`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Sends password reset instructions
 * {@link /api/users/password/instructions}
 */
export async function postApiUsersPasswordInstructions(
  data: PostApiUsersPasswordInstructionsMutationRequestSchema,
  config: Partial<RequestConfig<PostApiUsersPasswordInstructionsMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiUsersPasswordInstructionsMutationResponseSchema,
    Error,
    PostApiUsersPasswordInstructionsMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/users/password/instructions`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Updates password with reset token
 * {@link /api/users/password}
 */
export async function patchApiUsersPassword(
  data: PatchApiUsersPasswordMutationRequestSchema,
  config: Partial<RequestConfig<PatchApiUsersPasswordMutationRequestSchema>> = {}
) {
  const res = await client<
    PatchApiUsersPasswordMutationResponseSchema,
    PatchApiUsersPassword400Schema | PatchApiUsersPassword422Schema,
    PatchApiUsersPasswordMutationRequestSchema
  >({ method: 'PATCH', url: `/api/users/password`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves issue watchings
 * {@link /api/projects/:project_id/issues/:issue_id/watchings}
 */
export async function getApiProjectsProjectIdIssuesIssueIdWatchings(
  project_id: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'],
  issue_id: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
    GetApiProjectsProjectIdIssuesIssueIdWatchings401Schema | GetApiProjectsProjectIdIssuesIssueIdWatchings404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/issues/${issue_id}/watchings`, ...config });
  return res.data;
}

/**
 * @summary Watches an issue
 * {@link /api/projects/:project_id/issues/:issue_id/watchings}
 */
export async function postApiProjectsProjectIdIssuesIssueIdWatchings(
  project_id: PostApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'],
  issue_id: PostApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
    | PostApiProjectsProjectIdIssuesIssueIdWatchings401Schema
    | PostApiProjectsProjectIdIssuesIssueIdWatchings403Schema
    | PostApiProjectsProjectIdIssuesIssueIdWatchings404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/issues/${issue_id}/watchings`, ...config });
  return res.data;
}

/**
 * @summary Unwatches an issue
 * {@link /api/projects/:project_id/issues/:issue_id/watchings}
 */
export async function deleteApiProjectsProjectIdIssuesIssueIdWatchings(
  project_id: DeleteApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'],
  issue_id: DeleteApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
    | DeleteApiProjectsProjectIdIssuesIssueIdWatchings401Schema
    | DeleteApiProjectsProjectIdIssuesIssueIdWatchings404Schema,
    unknown
  >({ method: 'DELETE', url: `/api/projects/${project_id}/issues/${issue_id}/watchings`, ...config });
  return res.data;
}

/**
 * @summary Retrieves a weekly work plan activities
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities}
 */
export async function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities(
  project_id: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'],
  weekly_work_plan_id: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/activities`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Creates a weekly work plan activity
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'],
  data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities400Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities422Schema,
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/activities`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Creates a batch of weekly work plan activities
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/batch}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchPathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchPathParamsSchema['weekly_work_plan_id'],
  data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch404Schema,
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/activities/batch`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves a weekly work plan activity
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id}
 */
export async function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(
  project_id: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'],
  weekly_work_plan_id: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'],
  activity_id: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/activities/${activity_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Updates a weekly work plan activity
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id}
 */
export async function patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(
  project_id: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'],
  weekly_work_plan_id: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'],
  activity_id: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'],
  data?: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationRequestSchema,
  config: Partial<
    RequestConfig<PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
    | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
    | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
    | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
    | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId422Schema,
    PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationRequestSchema
  >({
    method: 'PATCH',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/activities/${activity_id}`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Deletes a weekly work plan activity
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id}
 */
export async function deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(
  project_id: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'],
  weekly_work_plan_id: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'],
  activity_id: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
    | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
    | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
    | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/activities/${activity_id}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Sorts weekly work plan activities
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/sort}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortPathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortPathParamsSchema['weekly_work_plan_id'],
  data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort400Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort404Schema,
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/activities/sort`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Creates a weekly work plan activity scheduled day
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id/scheduled_days}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema['weekly_work_plan_id'],
  activity_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema['activity_id'],
  data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays404Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays422Schema,
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/activities/${activity_id}/scheduled_days`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Deletes a weekly work plan activity scheduled day
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id/scheduled_days/:date}
 */
export async function deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate(
  project_id: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['project_id'],
  weekly_work_plan_id: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['weekly_work_plan_id'],
  activity_id: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['activity_id'],
  date: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['date'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationResponseSchema,
    | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate401Schema
    | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate403Schema
    | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate404Schema,
    unknown
  >({
    method: 'DELETE',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/activities/${activity_id}/scheduled_days/${date}`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves a weekly work plan progress logs
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/progress_logs}
 */
export async function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs(
  project_id: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['project_id'],
  weekly_work_plan_id: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs401Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs403Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs404Schema,
    unknown
  >({
    method: 'GET',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/progress_logs`,
    params,
    ...config,
  });
  return res.data;
}

/**
 * @summary Retrieves shift activities finder options for weekly work plans
 * {@link /api/projects/:project_id/weekly_work_plans/shift_activities_finder}
 */
export async function getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder(
  project_id: GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
    | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/weekly_work_plans/shift_activities_finder`, ...config });
  return res.data;
}

/**
 * @summary Updates shift activities finder options for weekly work plans
 * {@link /api/projects/:project_id/weekly_work_plans/shift_activities_finder}
 */
export async function patchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder(
  project_id: PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id'],
  data: PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationRequestSchema,
  config: Partial<
    RequestConfig<PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationResponseSchema,
    | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
    | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
    | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema
    | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder422Schema,
    PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationRequestSchema
  >({ method: 'PATCH', url: `/api/projects/${project_id}/weekly_work_plans/shift_activities_finder`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves all team weekly work plans
 * {@link /api/projects/:project_id/weekly_work_plans}
 */
export async function getApiProjectsProjectIdWeeklyWorkPlans(
  project_id: GetApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansQueryParamsSchema,
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
    | GetApiProjectsProjectIdWeeklyWorkPlans400Schema
    | GetApiProjectsProjectIdWeeklyWorkPlans401Schema
    | GetApiProjectsProjectIdWeeklyWorkPlans403Schema
    | GetApiProjectsProjectIdWeeklyWorkPlans404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/weekly_work_plans`, params, ...config });
  return res.data;
}

/**
 * @summary Creates a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlans(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'],
  data: PostApiProjectsProjectIdWeeklyWorkPlansMutationRequestSchema,
  config: Partial<RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansMutationRequestSchema>> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlans400Schema
    | PostApiProjectsProjectIdWeeklyWorkPlans401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlans403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlans404Schema
    | PostApiProjectsProjectIdWeeklyWorkPlans422Schema,
    PostApiProjectsProjectIdWeeklyWorkPlansMutationRequestSchema
  >({ method: 'POST', url: `/api/projects/${project_id}/weekly_work_plans`, data, ...config });
  return res.data;
}

/**
 * @summary Retrieves a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id}
 */
export async function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId(
  project_id: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'],
  weekly_work_plan_id: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
    | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema,
    unknown
  >({ method: 'GET', url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}`, ...config });
  return res.data;
}

/**
 * @summary Updates a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id}
 */
export async function putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId(
  project_id: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'],
  weekly_work_plan_id: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id'],
  data?: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationRequestSchema,
  config: Partial<RequestConfig<PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationRequestSchema>> = {}
) {
  const res = await client<
    PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationResponseSchema,
    | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId400Schema
    | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
    | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
    | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema
    | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId422Schema,
    PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationRequestSchema
  >({ method: 'PUT', url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}`, data, ...config });
  return res.data;
}

/**
 * @summary Closes a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/close}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClosePathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClosePathParamsSchema['weekly_work_plan_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose404Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/close`, ...config });
  return res.data;
}

/**
 * @summary Duplicates the weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/duplicate}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicatePathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicatePathParamsSchema['weekly_work_plan_id'],
  data?: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate400Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate404Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate422Schema,
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/duplicate`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Prefills the weekly work plan (and deletes existing activities)
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/prefill}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillPathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillPathParamsSchema['weekly_work_plan_id'],
  data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationRequestSchema,
  config: Partial<
    RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationRequestSchema>
  > = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill400Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill404Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill422Schema,
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationRequestSchema
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/prefill`,
    data,
    ...config,
  });
  return res.data;
}

/**
 * @summary Requests a weekly work plan export
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/export}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportPathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportPathParamsSchema['weekly_work_plan_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport404Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/export`, ...config });
  return res.data;
}

/**
 * @summary Requests a weekly work plan lookback export
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/lookback/export}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportPathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportPathParamsSchema['weekly_work_plan_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport404Schema,
    unknown
  >({
    method: 'POST',
    url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/lookback/export`,
    ...config,
  });
  return res.data;
}

/**
 * @summary Publishes a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/publish}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishPathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishPathParamsSchema['weekly_work_plan_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish404Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/publish`, ...config });
  return res.data;
}

/**
 * @summary Archives a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/archive}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchivePathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchivePathParamsSchema['weekly_work_plan_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive404Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/archive`, ...config });
  return res.data;
}

/**
 * @summary Restores an archived weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/restore}
 */
export async function postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore(
  project_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestorePathParamsSchema['project_id'],
  weekly_work_plan_id: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestorePathParamsSchema['weekly_work_plan_id'],
  config: Partial<RequestConfig> = {}
) {
  const res = await client<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreMutationResponseSchema,
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore401Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore403Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore404Schema
    | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore422Schema,
    unknown
  >({ method: 'POST', url: `/api/projects/${project_id}/weekly_work_plans/${weekly_work_plan_id}/restore`, ...config });
  return res.data;
}
