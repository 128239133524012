import type {
  PostApiProjectsProjectIdIssuesMutationRequestSchema,
  ProjectSchema,
} from '@shape-construction/api/src/types';
import UtilsStats from 'app/components/Utils/UtilsStats';
import { useLiveQuery } from 'dexie-react-hooks';
import db from './db';

export const buildIssue = (
  issue: PostApiProjectsProjectIdIssuesMutationRequestSchema['issue'],
  projectId: ProjectSchema['id']
) => ({
  ...issue,
  id: UtilsStats.uuidv4(),
  projectId,
});

export type Issue = PostApiProjectsProjectIdIssuesMutationRequestSchema['issue'] & {
  id: string;
  projectId: ProjectSchema['id'];
};

export const createIssue = async (issue: Issue) => {
  await db.issues.add(issue);
};

export const deleteIssue = async (id: Issue['id']) => {
  await db.issues.delete(id);
};

export const useIssues = () => useLiveQuery(() => db.issues.toArray(), []);

export const useLocalProjectIssues = (projectId: ProjectSchema['id']) =>
  useLiveQuery(() => db.issues.where({ projectId }).toArray(), [projectId]);
