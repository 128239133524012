import * as React from 'react';
import type { SVGProps } from 'react';
const MapPinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/map-pin">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6281 22.7195L11.6314 22.721C11.868 22.8239 12 22.7999 12 22.7999C12 22.7999 12.132 22.8239 12.3695 22.7206L12.3719 22.7195L12.3788 22.7163L12.4005 22.7063C12.4186 22.6979 12.4436 22.6862 12.4751 22.671C12.538 22.6406 12.627 22.5966 12.7384 22.5385C12.9609 22.4225 13.2734 22.2501 13.646 22.0193C14.3895 21.5585 15.3808 20.859 16.3746 19.9012C18.3626 17.985 20.4 14.9909 20.4 10.7999C20.4 6.16071 16.6392 2.3999 12 2.3999C7.36078 2.3999 3.59998 6.16071 3.59998 10.7999C3.59998 14.9909 5.63737 17.985 7.6254 19.9012C8.61914 20.859 9.6105 21.5585 10.354 22.0193C10.7265 22.2501 11.039 22.4225 11.2616 22.5385C11.3729 22.5966 11.4619 22.6406 11.5249 22.671C11.5564 22.6862 11.5814 22.6979 11.5994 22.7063L11.6212 22.7163L11.6281 22.7195ZM12 13.4999C13.4911 13.4999 14.7 12.2911 14.7 10.7999C14.7 9.30873 13.4911 8.0999 12 8.0999C10.5088 8.0999 9.29998 9.30873 9.29998 10.7999C9.29998 12.2911 10.5088 13.4999 12 13.4999Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default MapPinIcon;
