import {
  type QueuedTaskResultFileDownloadSchema,
  type QueuedTaskSchema,
  queuedTaskResultFileDownloadOperationEnum,
} from '@shape-construction/api/src/types';

export type ExportQueuedTask = QueuedTaskSchema & QueuedTaskResultFileDownloadSchema;

export const isExportJob = (job: QueuedTaskSchema): job is ExportQueuedTask => {
  const exportOperationNames: string[] = Object.values(queuedTaskResultFileDownloadOperationEnum);
  return exportOperationNames.includes(job.operation);
};
