/**
 * Generated by kubb.
 * Do not edit manually.
 * Shape API
 */

export const teamMemberRoleEnum = {
  viewer: 'viewer',
  contributor: 'contributor',
  admin: 'admin',
  owner: 'owner',
} as const;

export type TeamMemberRoleEnumSchema = (typeof teamMemberRoleEnum)[keyof typeof teamMemberRoleEnum];

export type TeamMemberRoleSchema = TeamMemberRoleEnumSchema;
