import { useMessageGetter } from '@messageformat/react';
import ModalBase from '@shape-construction/arch-ui/src/ModalBase';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { environment } from 'app/config/environment';
import { useCurrentUser } from 'app/queries/users/users';
import React, { useEffect } from 'react';

// Global hubspot api injected by script
declare const hbspt:
  | {
      meetings?: {
        create?: (selector: string) => void;
      };
    }
  | undefined;

const SCRIPT_ID = 'hubspot-book-session-script';

interface BookSessionModalProps {
  open: boolean;
  onClose: () => void;
}

export const BookSessionModal: React.FC<BookSessionModalProps> = ({ open, onClose }) => {
  const messages = useMessageGetter('onboarding.bookSession');
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const currentUser = useCurrentUser();

  useEffect(() => {
    // If the script is already loaded, create the meeting explicitly
    if (document.getElementById(SCRIPT_ID)) {
      hbspt?.meetings?.create?.('.meetings-iframe-container');
      return;
    }

    const script = Object.assign(document.createElement('script'), {
      id: SCRIPT_ID,
      src: 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
      type: 'text/javascript',
    });
    script.dataset.testid = SCRIPT_ID;
    document.body.appendChild(script);
  }, []);

  if (!environment.HUBSPOT_ONBOARDING_MEETING_LINK)
    throw new Error('BuildError: No HUBSPOT_ONBOARDING_MEETING_LINK in env');

  const hubspotMeetingLink = `${environment.HUBSPOT_ONBOARDING_MEETING_LINK}&firstName=${currentUser?.firstName}&lastName=${currentUser?.lastName}&email=${currentUser?.email}`;

  return (
    <ModalBase.Root
      open={open}
      onClose={onClose}
      transition="fade"
      roundBorders={isLargeScreen}
      outsidePad={isLargeScreen}
      fullScreen={!isLargeScreen}
      className="w-screen md:max-w-[1000px]"
    >
      <ModalBase.Header bottomBorder onClose={onClose}>
        <ModalBase.Title>{messages('title')}</ModalBase.Title>
      </ModalBase.Header>

      <ModalBase.Content className="flex px-2 min-h-[400px]">
        <div className="meetings-iframe-container flex-1" data-src={hubspotMeetingLink} />
      </ModalBase.Content>
    </ModalBase.Root>
  );
};
