import { useMediaQuery } from '@shape-construction/hooks';
import React, { type ComponentProps } from 'react';
import ModalBase from '../ModalBase';
import type { Transition } from '../ModalBase/config/transitions';
import { breakpoints } from '../utils/breakpoints';

export const FullScreenModalRoot = ({
  onClose,
  open,
  isPadded: isPaddedProp,
  hasRoundBorders: hasRoundBordersProp,
  ...props
}: Pick<ComponentProps<typeof ModalBase.Root>, 'children' | 'open' | 'onClose' | 'initialFocus' | 'zIndex'> & {
  isPadded?: boolean;
  hasRoundBorders?: boolean;
}) => {
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const fullScreenMode = true;
  const isPadded = isPaddedProp ?? !isSmallScreen;
  const hasRoundBorders = hasRoundBordersProp ?? !isSmallScreen;
  const transition: Transition = isSmallScreen ? 'bottom-to-top' : 'fade';

  return (
    <ModalBase.Root
      {...props}
      onClose={onClose}
      open={open}
      fullScreen={fullScreenMode}
      outsidePad={isPadded}
      roundBorders={hasRoundBorders}
      transition={transition}
    />
  );
};

export const FullScreenModalHeader = (props: Omit<ComponentProps<typeof ModalBase.Header>, 'bottomBorder'>) => (
  <ModalBase.Header bottomBorder {...props} />
);

export const FullScreenModalFooter = (props: Omit<ComponentProps<typeof ModalBase.Footer>, 'topBorder'>) => (
  <ModalBase.Footer topBorder {...props} />
);

export const FullScreenModalTitle = ModalBase.Title;
export const FullScreenModalContent = ModalBase.Content;
