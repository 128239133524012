import * as React from 'react';
import type { SVGProps } from 'react';
const ExclamationTriangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/exclamation-triangle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1815 2.99385C10.9897 1.59385 13.0105 1.59385 13.8188 2.99385L21.3532 16.0438C22.1615 17.4439 21.1511 19.1938 19.5345 19.1938H4.46568C2.8491 19.1938 1.83874 17.4438 2.64703 16.0438L10.1815 2.99385ZM12 5.99985C12.4971 5.99985 12.9 6.4028 12.9 6.89985V11.0999C12.9 11.5969 12.4971 11.9999 12 11.9999C11.503 11.9999 11.1 11.5969 11.1 11.0999V6.89985C11.1 6.4028 11.503 5.99985 12 5.99985ZM12 16.7999C12.6628 16.7999 13.2 16.2626 13.2 15.5999C13.2 14.9371 12.6628 14.3999 12 14.3999C11.3373 14.3999 10.8 14.9371 10.8 15.5999C10.8 16.2626 11.3373 16.7999 12 16.7999Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ExclamationTriangleIcon;
