import {
  DrawerContent as Content,
  DrawerFooter as Footer,
  DrawerHeader as Header,
  DrawerPersistent as Persistent,
  DrawerRoot as Root,
  DrawerSubtitle as Subtitle,
  DrawerTitle as Title,
} from './Drawer';

export default { Content, Footer, Header, Persistent, Root, Subtitle, Title };
