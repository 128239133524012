import * as React from 'react';
import type { SVGProps } from 'react';
const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/arrow-left">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4 12.0001C20.4 12.4971 19.997 12.9001 19.5 12.9001H6.73447L11.7238 17.6513C12.0821 17.9958 12.0932 18.5656 11.7487 18.9239C11.4042 19.2821 10.8345 19.2933 10.4762 18.9488L3.87618 12.6488C3.69971 12.4791 3.59998 12.2449 3.59998 12.0001C3.59998 11.7552 3.69971 11.521 3.87618 11.3513L10.4762 5.0513C10.8345 4.70679 11.4042 4.71796 11.7487 5.07625C12.0932 5.43455 12.0821 6.00429 11.7238 6.3488L6.73447 11.1001L19.5 11.1001C19.997 11.1001 20.4 11.503 20.4 12.0001Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ArrowLeftIcon;
