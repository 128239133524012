import { useGetApiQueuedTasks } from '@shape-construction/api/src/hooks';
import { useEffect } from 'react';
import useExports from './useExports';
import { isExportJob } from './utils';

const useSetupExports = () => {
  const { initToastsFromStorage, addToCompletedExports, dismissFailedExport, pendingExportIds } = useExports();

  const { data } = useGetApiQueuedTasks(
    { id: pendingExportIds },
    {
      query: {
        refetchInterval: 10000,
        enabled: pendingExportIds.length > 0,
      },
    }
  );

  useEffect(() => {
    initToastsFromStorage();
  }, []);

  useEffect(() => {
    data?.entries.forEach((task) => {
      if (!isExportJob(task)) return;

      if (task.status === 'completed') addToCompletedExports(task);
      if (task.status === 'failed') dismissFailedExport(task);
      if (task.status === 'expired') dismissFailedExport(task);
    });
  }, [data]);
};

export default useSetupExports;
