/**
 * Generated by kubb.
 * Do not edit manually.
 * Shape API
 */

export const documentKindEnum = {
  file: 'file',
  image: 'image',
} as const;

export type DocumentKindEnumSchema = (typeof documentKindEnum)[keyof typeof documentKindEnum];

export type DocumentKindSchema = DocumentKindEnumSchema;
