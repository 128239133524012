/**
 * Generated by kubb.
 * Do not edit manually.
 * Shape API
 */

export const queuedTaskResultFileDownloadOperationEnum = {
  issue_export: 'issue_export',
  issue_list_export: 'issue_list_export',
  shift_activity_list_export: 'shift_activity_list_export',
  shift_report_export: 'shift_report_export',
  shift_report_list_export: 'shift_report_list_export',
  weekly_work_plan_export: 'weekly_work_plan_export',
  weekly_work_plan_lookback_export: 'weekly_work_plan_lookback_export',
} as const;

export type QueuedTaskResultFileDownloadOperationEnumSchema =
  (typeof queuedTaskResultFileDownloadOperationEnum)[keyof typeof queuedTaskResultFileDownloadOperationEnum];

export type QueuedTaskResultFileDownloadSchema = {
  /**
   * @type string
   */
  operation: QueuedTaskResultFileDownloadOperationEnumSchema;
  /**
   * @type object
   */
  result: {
    /**
     * @type string, date-time
     */
    expiresAt: string;
    /**
     * @type string
     */
    fileUrl: string;
    /**
     * @type string
     */
    filename: string;
  } | null;
};
