import type { DateRange } from '@shape-construction/arch-ui/src/DatePickerCalendar';
import { type DateType, endOfWeek, now, parseDate, startOfWeek } from '@shape-construction/utils/DateTime';

export type PredefinedOptionKey =
  | 'all'
  | 'today'
  | 'yesterday'
  | 'tomorrow'
  | 'thisWeek'
  | 'thisMonth'
  | 'sevenDaysAgo'
  | 'thirtyDaysAgo'
  | 'nextWeek'
  | 'nextTwoWeeks'
  | 'nextFourWeeks'
  | 'nextEightWeeks'
  | 'custom';

export type PredefinedOption = {
  label: string;
  key: PredefinedOptionKey;
  custom?: boolean;
  value: (from?: DateType, to?: DateType) => DateRange;
};

export const datePickerPredefinedOptionsMap: Record<PredefinedOptionKey, PredefinedOption> = {
  all: {
    key: 'all',
    label: 'All',
    value: () => ({
      from: undefined,
      to: undefined,
    }),
  },
  today: {
    key: 'today',
    label: 'Today',
    value: () => {
      return {
        from: now().startOf('day').toDate(),
        to: now().endOf('day').toDate(),
      };
    },
  },
  yesterday: {
    key: 'yesterday',
    label: 'Yesterday',
    value: () => {
      const yesterday = now().subtract(1, 'day');
      return {
        from: yesterday.startOf('day').toDate(),
        to: yesterday.endOf('day').toDate(),
      };
    },
  },
  tomorrow: {
    key: 'tomorrow',
    label: 'Tomorrow',
    value: () => ({
      from: now().add(1, 'day').startOf('day').toDate(),
      to: now().add(1, 'day').endOf('day').toDate(),
    }),
  },
  thisWeek: {
    key: 'thisWeek',
    label: 'This week',
    value: () => ({
      from: startOfWeek(now()).toDate(),
      to: endOfWeek(now()).toDate(),
    }),
  },
  thisMonth: {
    key: 'thisMonth',
    label: 'This month',
    value: () => ({
      from: now().startOf('month').toDate(),
      to: now().endOf('month').toDate(),
    }),
  },
  sevenDaysAgo: {
    key: 'sevenDaysAgo',
    label: 'Last 7 days',
    value: () => {
      const sevenDaysAgo = now().subtract(6, 'day');
      return {
        from: sevenDaysAgo.startOf('day').toDate(),
        to: now().endOf('day').toDate(),
      };
    },
  },
  thirtyDaysAgo: {
    key: 'thirtyDaysAgo',
    label: 'Last 30 days',
    value: () => {
      const thirtyDaysAgo = now().subtract(29, 'day');
      return {
        from: thirtyDaysAgo.startOf('day').toDate(),
        to: now().endOf('day').toDate(),
      };
    },
  },
  nextWeek: {
    key: 'nextWeek',
    label: 'Next week',
    value: () => ({
      from: startOfWeek(now().add(1, 'week')).toDate(),
      to: endOfWeek(now().add(1, 'week')).toDate(),
    }),
  },
  nextTwoWeeks: {
    key: 'nextTwoWeeks',
    label: 'Next 2 weeks',
    value: () => ({
      from: startOfWeek(now().add(1, 'week')).toDate(),
      to: endOfWeek(now().add(2, 'week')).toDate(),
    }),
  },
  nextFourWeeks: {
    key: 'nextFourWeeks',
    label: 'Next 4 weeks',
    value: () => ({
      from: startOfWeek(now().add(1, 'week')).toDate(),
      to: endOfWeek(now().add(4, 'week')).toDate(),
    }),
  },
  nextEightWeeks: {
    key: 'nextEightWeeks',
    label: 'Next 8 weeks',
    value: () => ({
      from: startOfWeek(now().add(1, 'week')).toDate(),
      to: endOfWeek(now().add(8, 'week')).toDate(),
    }),
  },
  custom: {
    key: 'custom',
    label: 'Custom',
    custom: true,
    value: (from?: DateType, to?: DateType) => ({
      from: from ? parseDate(from).startOf('day').toDate() : undefined,
      to: to ? parseDate(to).endOf('day').toDate() : undefined,
    }),
  },
};

export const datePickerPredefinedOptions: PredefinedOption[] = Object.values(datePickerPredefinedOptionsMap);

// Utility to generate options dynamically
export const getDatePickerOptions = (keys: PredefinedOptionKey[]): PredefinedOption[] => {
  return keys.map((key) => datePickerPredefinedOptionsMap[key]);
};
