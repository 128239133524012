import { getApiFeatureFlagsQueryOptions } from '@shape-construction/api/src/hooks';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useFeatureFlagContext } from './context';
import type { FeatureFlagsByTarget } from './types';
import { mapValues, toFeatureFlags } from './utils/flagValue';

export const useFeatureFlagsByTarget = () => {
  const { deviceId } = useFeatureFlagContext();
  const { data: flagsByTarget, refetch } = useQuery({
    ...getApiFeatureFlagsQueryOptions({ device_session_id: deviceId }),
    refetchOnWindowFocus: process.env.NODE_ENV === 'development', // Refetch on focus only during development
    refetchOnMount: false,
    refetchOnReconnect: true,
    placeholderData: keepPreviousData,
    staleTime: process.env.NODE_ENV === 'development' ? 5 * 1000 : 5 * 60 * 1000, // 5 seconds on development, 5 minutes otherwise
    refetchInterval: 10 * 60 * 1000, // 10 minutes

    select: (featureFlagsData): FeatureFlagsByTarget => ({
      user: featureFlagsData.user && toFeatureFlags(featureFlagsData.user),
      session: featureFlagsData.session && toFeatureFlags(featureFlagsData.session),
      teams: featureFlagsData.teams && mapValues(featureFlagsData.teams, toFeatureFlags),
    }),
  });

  return { flagsByTarget, refetch };
};
