import * as React from 'react';
import type { SVGProps } from 'react';
const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/eye">
      <g id="Icon">
        <path
          d="M12 15.0001C13.6569 15.0001 15 13.6569 15 12.0001C15 10.3432 13.6569 9.0001 12 9.0001C10.3431 9.0001 9 10.3432 9 12.0001C9 13.6569 10.3431 15.0001 12 15.0001Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.797106 12.7085C0.62087 12.2506 0.621022 11.7429 0.797532 11.285C2.53054 6.78955 6.89195 3.6001 11.9983 3.6001C17.1072 3.6001 21.4704 6.7927 23.2016 11.2916C23.3779 11.7496 23.3777 12.2573 23.2012 12.7152C21.4682 17.2106 17.1068 20.4001 12.0004 20.4001C6.89157 20.4001 2.52837 17.2075 0.797106 12.7085ZM16.8004 12.0001C16.8004 14.6511 14.6514 16.8001 12.0004 16.8001C9.34948 16.8001 7.20045 14.6511 7.20045 12.0001C7.20045 9.34913 9.34948 7.2001 12.0004 7.2001C14.6514 7.2001 16.8004 9.34913 16.8004 12.0001Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default EyeIcon;
