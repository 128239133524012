/**
 * Generated by kubb.
 * Do not edit manually.
 * Shape API
 */

import client from '../../client';
import type { RequestConfig, ResponseErrorConfig } from '../../client';
import type { DeleteApiLogoutMutationResponseSchema, DeleteApiLogout401Schema } from '../types/deleteApiLogoutSchema';
import type {
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema,
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldId401Schema,
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldId403Schema,
  DeleteApiProjectsProjectIdCustomFieldsCustomFieldId404Schema,
} from '../types/deleteApiProjectsProjectIdCustomFieldsCustomFieldIdSchema';
import type {
  DeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineId401Schema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineId403Schema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineId404Schema,
  DeleteApiProjectsProjectIdDisciplinesDisciplineId422Schema,
} from '../types/deleteApiProjectsProjectIdDisciplinesDisciplineIdSchema';
import type {
  DeleteApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
  DeleteApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema,
  DeleteApiProjectsProjectIdDocumentsDocumentId401Schema,
  DeleteApiProjectsProjectIdDocumentsDocumentId403Schema,
  DeleteApiProjectsProjectIdDocumentsDocumentId404Schema,
} from '../types/deleteApiProjectsProjectIdDocumentsDocumentIdSchema';
import type {
  DeleteApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
  DeleteApiProjectsProjectIdGroupsGroupIdPathParamsSchema,
  DeleteApiProjectsProjectIdGroupsGroupId401Schema,
  DeleteApiProjectsProjectIdGroupsGroupId403Schema,
  DeleteApiProjectsProjectIdGroupsGroupId404Schema,
} from '../types/deleteApiProjectsProjectIdGroupsGroupIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId404Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId422Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId404Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueId400Schema,
  DeleteApiProjectsProjectIdIssuesIssueId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueId404Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId403Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId404Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId422Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdSchema';
import type {
  DeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema,
  DeleteApiProjectsProjectIdIssuesIssueIdWatchings401Schema,
  DeleteApiProjectsProjectIdIssuesIssueIdWatchings404Schema,
} from '../types/deleteApiProjectsProjectIdIssuesIssueIdWatchingsSchema';
import type {
  DeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewId401Schema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewId403Schema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewId404Schema,
  DeleteApiProjectsProjectIdIssueViewsIssueViewId422Schema,
} from '../types/deleteApiProjectsProjectIdIssueViewsIssueViewIdSchema';
import type {
  DeleteApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
  DeleteApiProjectsProjectIdLocationsLocationIdPathParamsSchema,
  DeleteApiProjectsProjectIdLocationsLocationId401Schema,
  DeleteApiProjectsProjectIdLocationsLocationId403Schema,
  DeleteApiProjectsProjectIdLocationsLocationId404Schema,
  DeleteApiProjectsProjectIdLocationsLocationId422Schema,
} from '../types/deleteApiProjectsProjectIdLocationsLocationIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId401Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId403Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema,
} from '../types/deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionSchema';
import type {
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema,
  DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId401Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId403Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId404Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId422Schema,
} from '../types/deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId401Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId403Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId401Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId403Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdSchema';
import type {
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportId400Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportId401Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportId403Schema,
  DeleteApiProjectsProjectIdShiftReportsShiftReportId404Schema,
} from '../types/deleteApiProjectsProjectIdShiftReportsShiftReportIdSchema';
import type {
  DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdJoinToken401Schema,
  DeleteApiProjectsProjectIdTeamsTeamIdJoinToken403Schema,
  DeleteApiProjectsProjectIdTeamsTeamIdJoinToken404Schema,
} from '../types/deleteApiProjectsProjectIdTeamsTeamIdJoinTokenSchema';
import type {
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema,
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema,
  DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema,
} from '../types/deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdSchema';
import type {
  DeleteApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
  DeleteApiProjectsProjectIdTeamsTeamIdPathParamsSchema,
  DeleteApiProjectsProjectIdTeamsTeamId401Schema,
  DeleteApiProjectsProjectIdTeamsTeamId403Schema,
  DeleteApiProjectsProjectIdTeamsTeamId404Schema,
} from '../types/deleteApiProjectsProjectIdTeamsTeamIdSchema';
import type {
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationResponseSchema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate401Schema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate403Schema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate404Schema,
} from '../types/deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateSchema';
import type {
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema,
  DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema,
} from '../types/deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSchema';
import type {
  DeleteApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
  DeleteApiPushSubscriptionsPushSubscriptionIdPathParamsSchema,
  DeleteApiPushSubscriptionsPushSubscriptionId401Schema,
  DeleteApiPushSubscriptionsPushSubscriptionId404Schema,
} from '../types/deleteApiPushSubscriptionsPushSubscriptionIdSchema';
import type {
  GetApiAgreementsLatestEuaQueryResponseSchema,
  GetApiAgreementsLatestEua404Schema,
} from '../types/getApiAgreementsLatestEuaSchema';
import type {
  GetApiConstructionRolesQueryResponseSchema,
  GetApiConstructionRoles401Schema,
} from '../types/getApiConstructionRolesSchema';
import type {
  GetApiFeatureFlagsQueryResponseSchema,
  GetApiFeatureFlagsQueryParamsSchema,
  GetApiFeatureFlags422Schema,
} from '../types/getApiFeatureFlagsSchema';
import type {
  GetApiNotificationsOverviewQueryResponseSchema,
  GetApiNotificationsOverview401Schema,
} from '../types/getApiNotificationsOverviewSchema';
import type {
  GetApiNotificationsQueryResponseSchema,
  GetApiNotificationsQueryParamsSchema,
  GetApiNotifications400Schema,
  GetApiNotifications401Schema,
} from '../types/getApiNotificationsSchema';
import type {
  GetApiOnboardingQueryResponseSchema,
  GetApiOnboarding401Schema,
  GetApiOnboarding404Schema,
} from '../types/getApiOnboardingSchema';
import type {
  GetApiOrgsOrgIdQueryResponseSchema,
  GetApiOrgsOrgIdPathParamsSchema,
  GetApiOrgsOrgId401Schema,
  GetApiOrgsOrgId403Schema,
  GetApiOrgsOrgId404Schema,
} from '../types/getApiOrgsOrgIdSchema';
import type { GetApiOrgsQueryResponseSchema, GetApiOrgs401Schema } from '../types/getApiOrgsSchema';
import type {
  GetApiProductToursProductTourKeyQueryResponseSchema,
  GetApiProductToursProductTourKeyPathParamsSchema,
  GetApiProductToursProductTourKey401Schema,
  GetApiProductToursProductTourKey404Schema,
} from '../types/getApiProductToursProductTourKeySchema';
import type {
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema,
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId401Schema,
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId403Schema,
  GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId404Schema,
} from '../types/getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSchema';
import type {
  GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
  GetApiProjectsProjectIdAccessRequestsPathParamsSchema,
  GetApiProjectsProjectIdAccessRequestsQueryParamsSchema,
  GetApiProjectsProjectIdAccessRequests400Schema,
  GetApiProjectsProjectIdAccessRequests401Schema,
  GetApiProjectsProjectIdAccessRequests403Schema,
  GetApiProjectsProjectIdAccessRequests404Schema,
} from '../types/getApiProjectsProjectIdAccessRequestsSchema';
import type {
  GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
  GetApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema,
  GetApiProjectsProjectIdControlCenterPotentialChangesQueryParamsSchema,
  GetApiProjectsProjectIdControlCenterPotentialChanges401Schema,
  GetApiProjectsProjectIdControlCenterPotentialChanges404Schema,
} from '../types/getApiProjectsProjectIdControlCenterPotentialChangesSchema';
import type {
  GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
  GetApiProjectsProjectIdCustomFieldsPathParamsSchema,
  GetApiProjectsProjectIdCustomFields401Schema,
  GetApiProjectsProjectIdCustomFields403Schema,
  GetApiProjectsProjectIdCustomFields404Schema,
} from '../types/getApiProjectsProjectIdCustomFieldsSchema';
import type {
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryParamsSchema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType401Schema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType403Schema,
  GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType404Schema,
} from '../types/getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSchema';
import type {
  GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessCountPathParamsSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessCount401Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessCount403Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessCount404Schema,
} from '../types/getApiProjectsProjectIdDashboardsIssuesStalenessCountSchema';
import type {
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesPathParamsSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryParamsSchema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssues400Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssues401Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssues403Schema,
  GetApiProjectsProjectIdDashboardsIssuesStalenessIssues404Schema,
} from '../types/getApiProjectsProjectIdDashboardsIssuesStalenessIssuesSchema';
import type {
  GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
  GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema,
  GetApiProjectsProjectIdDisciplinesDisciplineId401Schema,
  GetApiProjectsProjectIdDisciplinesDisciplineId403Schema,
  GetApiProjectsProjectIdDisciplinesDisciplineId404Schema,
} from '../types/getApiProjectsProjectIdDisciplinesDisciplineIdSchema';
import type {
  GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
  GetApiProjectsProjectIdDisciplinesPathParamsSchema,
  GetApiProjectsProjectIdDisciplinesQueryParamsSchema,
  GetApiProjectsProjectIdDisciplines401Schema,
  GetApiProjectsProjectIdDisciplines403Schema,
  GetApiProjectsProjectIdDisciplines404Schema,
} from '../types/getApiProjectsProjectIdDisciplinesSchema';
import type {
  GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
  GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema,
  GetApiProjectsProjectIdDocumentsDocumentIdReferences401Schema,
  GetApiProjectsProjectIdDocumentsDocumentIdReferences403Schema,
  GetApiProjectsProjectIdDocumentsDocumentIdReferences404Schema,
} from '../types/getApiProjectsProjectIdDocumentsDocumentIdReferencesSchema';
import type {
  GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
  GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema,
  GetApiProjectsProjectIdDocumentsDocumentId401Schema,
  GetApiProjectsProjectIdDocumentsDocumentId403Schema,
  GetApiProjectsProjectIdDocumentsDocumentId404Schema,
} from '../types/getApiProjectsProjectIdDocumentsDocumentIdSchema';
import type {
  GetApiProjectsProjectIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdDocuments400Schema,
  GetApiProjectsProjectIdDocuments401Schema,
  GetApiProjectsProjectIdDocuments403Schema,
  GetApiProjectsProjectIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdEventsQueryResponseSchema,
  GetApiProjectsProjectIdEventsPathParamsSchema,
  GetApiProjectsProjectIdEventsQueryParamsSchema,
  GetApiProjectsProjectIdEvents401Schema,
  GetApiProjectsProjectIdEvents403Schema,
  GetApiProjectsProjectIdEvents404Schema,
} from '../types/getApiProjectsProjectIdEventsSchema';
import type {
  GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
  GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema,
  GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema,
  GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema,
  GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema,
} from '../types/getApiProjectsProjectIdGroupsGroupIdChannelConfigurationSchema';
import type {
  GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
  GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema,
  GetApiProjectsProjectIdGroupsGroupId401Schema,
  GetApiProjectsProjectIdGroupsGroupId403Schema,
  GetApiProjectsProjectIdGroupsGroupId404Schema,
} from '../types/getApiProjectsProjectIdGroupsGroupIdSchema';
import type {
  GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
  GetApiProjectsProjectIdIssuesGroupCountPathParamsSchema,
  GetApiProjectsProjectIdIssuesGroupCountQueryParamsSchema,
  GetApiProjectsProjectIdIssuesGroupCount400Schema,
  GetApiProjectsProjectIdIssuesGroupCount401Schema,
  GetApiProjectsProjectIdIssuesGroupCount403Schema,
  GetApiProjectsProjectIdIssuesGroupCount404Schema,
} from '../types/getApiProjectsProjectIdIssuesGroupCountSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdDocuments400Schema,
  GetApiProjectsProjectIdIssuesIssueIdDocuments401Schema,
  GetApiProjectsProjectIdIssuesIssueIdDocuments403Schema,
  GetApiProjectsProjectIdIssuesIssueIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublic400Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublic401Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedPublic404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdFeedPublicSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeam400Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeam401Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeam403Schema,
  GetApiProjectsProjectIdIssuesIssueIdFeedTeam404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdFeedTeamSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImages401Schema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImages403Schema,
  GetApiProjectsProjectIdIssuesIssueIdIssueImages404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdIssueImagesSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueId401Schema,
  GetApiProjectsProjectIdIssuesIssueId403Schema,
  GetApiProjectsProjectIdIssuesIssueId404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema,
  GetApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema,
  GetApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdStatusStatementsSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdVisit401Schema,
  GetApiProjectsProjectIdIssuesIssueIdVisit403Schema,
  GetApiProjectsProjectIdIssuesIssueIdVisit404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdVisitSchema';
import type {
  GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
  GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema,
  GetApiProjectsProjectIdIssuesIssueIdWatchings401Schema,
  GetApiProjectsProjectIdIssuesIssueIdWatchings404Schema,
} from '../types/getApiProjectsProjectIdIssuesIssueIdWatchingsSchema';
import type {
  GetApiProjectsProjectIdIssuesQueryResponseSchema,
  GetApiProjectsProjectIdIssuesPathParamsSchema,
  GetApiProjectsProjectIdIssuesQueryParamsSchema,
  GetApiProjectsProjectIdIssues400Schema,
  GetApiProjectsProjectIdIssues401Schema,
  GetApiProjectsProjectIdIssues403Schema,
  GetApiProjectsProjectIdIssues404Schema,
} from '../types/getApiProjectsProjectIdIssuesSchema';
import type {
  GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
  GetApiProjectsProjectIdIssueViewsPathParamsSchema,
  GetApiProjectsProjectIdIssueViews401Schema,
  GetApiProjectsProjectIdIssueViews403Schema,
  GetApiProjectsProjectIdIssueViews404Schema,
} from '../types/getApiProjectsProjectIdIssueViewsSchema';
import type {
  GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
  GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema,
  GetApiProjectsProjectIdLocationsLocationId401Schema,
  GetApiProjectsProjectIdLocationsLocationId403Schema,
  GetApiProjectsProjectIdLocationsLocationId404Schema,
} from '../types/getApiProjectsProjectIdLocationsLocationIdSchema';
import type {
  GetApiProjectsProjectIdLocationsQueryResponseSchema,
  GetApiProjectsProjectIdLocationsPathParamsSchema,
  GetApiProjectsProjectIdLocationsQueryParamsSchema,
  GetApiProjectsProjectIdLocations401Schema,
  GetApiProjectsProjectIdLocations403Schema,
  GetApiProjectsProjectIdLocations404Schema,
} from '../types/getApiProjectsProjectIdLocationsSchema';
import type {
  GetApiProjectsProjectIdPeopleQueryResponseSchema,
  GetApiProjectsProjectIdPeoplePathParamsSchema,
  GetApiProjectsProjectIdPeopleQueryParamsSchema,
  GetApiProjectsProjectIdPeople400Schema,
  GetApiProjectsProjectIdPeople401Schema,
  GetApiProjectsProjectIdPeople404Schema,
} from '../types/getApiProjectsProjectIdPeopleSchema';
import type {
  GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
  GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema,
  GetApiProjectsProjectIdPeopleTeamMemberId401Schema,
  GetApiProjectsProjectIdPeopleTeamMemberId403Schema,
  GetApiProjectsProjectIdPeopleTeamMemberId404Schema,
} from '../types/getApiProjectsProjectIdPeopleTeamMemberIdSchema';
import type {
  GetApiProjectsProjectIdQueryResponseSchema,
  GetApiProjectsProjectIdPathParamsSchema,
  GetApiProjectsProjectId401Schema,
  GetApiProjectsProjectId403Schema,
  GetApiProjectsProjectId404Schema,
} from '../types/getApiProjectsProjectIdSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesQueryParamsSchema,
  GetApiProjectsProjectIdShiftActivities400Schema,
  GetApiProjectsProjectIdShiftActivities401Schema,
  GetApiProjectsProjectIdShiftActivities403Schema,
  GetApiProjectsProjectIdShiftActivities404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdSchema';
import type {
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning401Schema,
  GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning404Schema,
} from '../types/getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSchema';
import type {
  GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsArchivedPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsArchivedQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsArchived400Schema,
  GetApiProjectsProjectIdShiftReportsArchived401Schema,
  GetApiProjectsProjectIdShiftReportsArchived403Schema,
  GetApiProjectsProjectIdShiftReportsArchived404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsArchivedSchema';
import type {
  GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsCompletionsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsCompletions401Schema,
  GetApiProjectsProjectIdShiftReportsCompletions403Schema,
} from '../types/getApiProjectsProjectIdShiftReportsCompletionsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsDraftPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsDraftQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsDraft400Schema,
  GetApiProjectsProjectIdShiftReportsDraft401Schema,
  GetApiProjectsProjectIdShiftReportsDraft403Schema,
  GetApiProjectsProjectIdShiftReportsDraft404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsDraftSchema';
import type {
  GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsQueryParamsSchema,
  GetApiProjectsProjectIdShiftReports400Schema,
  GetApiProjectsProjectIdShiftReports401Schema,
  GetApiProjectsProjectIdShiftReports403Schema,
  GetApiProjectsProjectIdShiftReports404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeople401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeople403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPeople404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdPeopleSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators403Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSchema';
import type {
  GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema,
  GetApiProjectsProjectIdShiftReportsShiftReportId401Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportId403Schema,
  GetApiProjectsProjectIdShiftReportsShiftReportId404Schema,
} from '../types/getApiProjectsProjectIdShiftReportsShiftReportIdSchema';
import type {
  GetApiProjectsProjectIdTeamsQueryResponseSchema,
  GetApiProjectsProjectIdTeamsPathParamsSchema,
  GetApiProjectsProjectIdTeams401Schema,
  GetApiProjectsProjectIdTeams403Schema,
  GetApiProjectsProjectIdTeams404Schema,
} from '../types/getApiProjectsProjectIdTeamsSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema,
  GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema,
  GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdChannelConfigurationSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdJoinToken401Schema,
  GetApiProjectsProjectIdTeamsTeamIdJoinToken403Schema,
  GetApiProjectsProjectIdTeamsTeamIdJoinToken404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdJoinTokenSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies401Schema,
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies403Schema,
  GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard401Schema,
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard403Schema,
  GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard503Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema,
  GetApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdResourcesKindSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamId401Schema,
  GetApiProjectsProjectIdTeamsTeamId403Schema,
  GetApiProjectsProjectIdTeamsTeamId404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdSchema';
import type {
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema,
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan401Schema,
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan403Schema,
  GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan404Schema,
} from '../types/getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansQueryParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlans400Schema,
  GetApiProjectsProjectIdWeeklyWorkPlans401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlans403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlans404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSchema';
import type {
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema,
  GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema,
} from '../types/getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSchema';
import type { GetApiProjectsQueryResponseSchema, GetApiProjects401Schema } from '../types/getApiProjectsSchema';
import type {
  GetApiQueuedTasksQueryResponseSchema,
  GetApiQueuedTasksQueryParamsSchema,
  GetApiQueuedTasks400Schema,
  GetApiQueuedTasks401Schema,
} from '../types/getApiQueuedTasksSchema';
import type {
  GetApiTeamJoinTokensTokenQueryResponseSchema,
  GetApiTeamJoinTokensTokenPathParamsSchema,
  GetApiTeamJoinTokensToken404Schema,
} from '../types/getApiTeamJoinTokensTokenSchema';
import type { GetApiTimeZonesQueryResponseSchema, GetApiTimeZones401Schema } from '../types/getApiTimeZonesSchema';
import type { GetApiUsersMeQueryResponseSchema, GetApiUsersMe401Schema } from '../types/getApiUsersMeSchema';
import type {
  PatchApiOnboardingMutationRequestSchema,
  PatchApiOnboardingMutationResponseSchema,
  PatchApiOnboarding401Schema,
  PatchApiOnboarding404Schema,
} from '../types/patchApiOnboardingSchema';
import type {
  PatchApiOrgsOrgIdMutationRequestSchema,
  PatchApiOrgsOrgIdMutationResponseSchema,
  PatchApiOrgsOrgIdPathParamsSchema,
  PatchApiOrgsOrgId401Schema,
} from '../types/patchApiOrgsOrgIdSchema';
import type {
  PatchApiProductToursProductTourKeyMutationRequestSchema,
  PatchApiProductToursProductTourKeyMutationResponseSchema,
  PatchApiProductToursProductTourKeyPathParamsSchema,
  PatchApiProductToursProductTourKey401Schema,
  PatchApiProductToursProductTourKey404Schema,
} from '../types/patchApiProductToursProductTourKeySchema';
import type {
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationRequestSchema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationResponseSchema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdPathParamsSchema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId401Schema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId403Schema,
  PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId404Schema,
} from '../types/patchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdSchema';
import type {
  PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationRequestSchema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldId401Schema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldId403Schema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldId404Schema,
  PatchApiProjectsProjectIdCustomFieldsCustomFieldId422Schema,
} from '../types/patchApiProjectsProjectIdCustomFieldsCustomFieldIdSchema';
import type {
  PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationRequestSchema,
  PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
  PatchApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema,
  PatchApiProjectsProjectIdDisciplinesDisciplineId401Schema,
  PatchApiProjectsProjectIdDisciplinesDisciplineId403Schema,
  PatchApiProjectsProjectIdDisciplinesDisciplineId404Schema,
  PatchApiProjectsProjectIdDisciplinesDisciplineId422Schema,
} from '../types/patchApiProjectsProjectIdDisciplinesDisciplineIdSchema';
import type {
  PatchApiProjectsProjectIdDocumentsDocumentIdMutationRequestSchema,
  PatchApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
  PatchApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema,
  PatchApiProjectsProjectIdDocumentsDocumentId401Schema,
  PatchApiProjectsProjectIdDocumentsDocumentId403Schema,
  PatchApiProjectsProjectIdDocumentsDocumentId404Schema,
  PatchApiProjectsProjectIdDocumentsDocumentId422Schema,
} from '../types/patchApiProjectsProjectIdDocumentsDocumentIdSchema';
import type {
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationRequestSchema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationResponseSchema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema,
  PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration422Schema,
} from '../types/patchApiProjectsProjectIdGroupsGroupIdChannelConfigurationSchema';
import type {
  PatchApiProjectsProjectIdGroupsGroupIdMembersMutationRequestSchema,
  PatchApiProjectsProjectIdGroupsGroupIdMembersMutationResponseSchema,
  PatchApiProjectsProjectIdGroupsGroupIdMembersPathParamsSchema,
  PatchApiProjectsProjectIdGroupsGroupIdMembers401Schema,
  PatchApiProjectsProjectIdGroupsGroupIdMembers403Schema,
  PatchApiProjectsProjectIdGroupsGroupIdMembers404Schema,
  PatchApiProjectsProjectIdGroupsGroupIdMembers422Schema,
} from '../types/patchApiProjectsProjectIdGroupsGroupIdMembersSchema';
import type {
  PatchApiProjectsProjectIdGroupsGroupIdMutationRequestSchema,
  PatchApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
  PatchApiProjectsProjectIdGroupsGroupIdPathParamsSchema,
  PatchApiProjectsProjectIdGroupsGroupId400Schema,
  PatchApiProjectsProjectIdGroupsGroupId401Schema,
  PatchApiProjectsProjectIdGroupsGroupId403Schema,
  PatchApiProjectsProjectIdGroupsGroupId404Schema,
  PatchApiProjectsProjectIdGroupsGroupId422Schema,
} from '../types/patchApiProjectsProjectIdGroupsGroupIdSchema';
import type {
  PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationRequestSchema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationResponseSchema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsPathParamsSchema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFields401Schema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFields403Schema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFields404Schema,
  PatchApiProjectsProjectIdIssuesIssueIdCustomFields422Schema,
} from '../types/patchApiProjectsProjectIdIssuesIssueIdCustomFieldsSchema';
import type {
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationRequestSchema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema,
  PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema,
} from '../types/patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdSchema';
import type {
  PatchApiProjectsProjectIdIssuesIssueIdMutationRequestSchema,
  PatchApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
  PatchApiProjectsProjectIdIssuesIssueIdPathParamsSchema,
  PatchApiProjectsProjectIdIssuesIssueId401Schema,
  PatchApiProjectsProjectIdIssuesIssueId403Schema,
  PatchApiProjectsProjectIdIssuesIssueId404Schema,
  PatchApiProjectsProjectIdIssuesIssueId422Schema,
} from '../types/patchApiProjectsProjectIdIssuesIssueIdSchema';
import type {
  PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationRequestSchema,
  PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
  PatchApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema,
  PatchApiProjectsProjectIdIssueViewsIssueViewId401Schema,
  PatchApiProjectsProjectIdIssueViewsIssueViewId404Schema,
  PatchApiProjectsProjectIdIssueViewsIssueViewId422Schema,
} from '../types/patchApiProjectsProjectIdIssueViewsIssueViewIdSchema';
import type {
  PatchApiProjectsProjectIdLocationsLocationIdMutationRequestSchema,
  PatchApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
  PatchApiProjectsProjectIdLocationsLocationIdPathParamsSchema,
  PatchApiProjectsProjectIdLocationsLocationId401Schema,
  PatchApiProjectsProjectIdLocationsLocationId403Schema,
  PatchApiProjectsProjectIdLocationsLocationId404Schema,
  PatchApiProjectsProjectIdLocationsLocationId422Schema,
} from '../types/patchApiProjectsProjectIdLocationsLocationIdSchema';
import type {
  PatchApiProjectsProjectIdMutationRequestSchema,
  PatchApiProjectsProjectIdMutationResponseSchema,
  PatchApiProjectsProjectIdPathParamsSchema,
  PatchApiProjectsProjectId401Schema,
  PatchApiProjectsProjectId403Schema,
  PatchApiProjectsProjectId404Schema,
  PatchApiProjectsProjectId422Schema,
} from '../types/patchApiProjectsProjectIdSchema';
import type {
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationRequestSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationResponseSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId403Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId422Schema,
} from '../types/patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSchema';
import type {
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationRequestSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationResponseSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessPathParamsSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness400Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness401Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness403Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness404Schema,
} from '../types/patchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessSchema';
import type {
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationRequestSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId422Schema,
} from '../types/patchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdSchema';
import type {
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationRequestSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationResponseSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityId403Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema,
  PatchApiProjectsProjectIdShiftActivitiesShiftActivityId422Schema,
} from '../types/patchApiProjectsProjectIdShiftActivitiesShiftActivityIdSchema';
import type {
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema,
  PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
  PatchApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId400Schema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId401Schema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId403Schema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId404Schema,
  PatchApiProjectsProjectIdShiftReportsShiftReportId422Schema,
} from '../types/patchApiProjectsProjectIdShiftReportsShiftReportIdSchema';
import type {
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationRequestSchema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationResponseSchema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema,
  PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration422Schema,
} from '../types/patchApiProjectsProjectIdTeamsTeamIdChannelConfigurationSchema';
import type {
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationRequestSchema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema,
  PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId422Schema,
} from '../types/patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdSchema';
import type {
  PatchApiProjectsProjectIdTeamsTeamIdMutationRequestSchema,
  PatchApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
  PatchApiProjectsProjectIdTeamsTeamIdPathParamsSchema,
  PatchApiProjectsProjectIdTeamsTeamId401Schema,
  PatchApiProjectsProjectIdTeamsTeamId403Schema,
  PatchApiProjectsProjectIdTeamsTeamId404Schema,
} from '../types/patchApiProjectsProjectIdTeamsTeamIdSchema';
import type {
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationRequestSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationResponseSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder422Schema,
} from '../types/patchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSchema';
import type {
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationRequestSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema,
  PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId422Schema,
} from '../types/patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSchema';
import type {
  PatchApiPushSubscriptionsPushSubscriptionIdMutationRequestSchema,
  PatchApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
  PatchApiPushSubscriptionsPushSubscriptionIdPathParamsSchema,
  PatchApiPushSubscriptionsPushSubscriptionId401Schema,
  PatchApiPushSubscriptionsPushSubscriptionId404Schema,
} from '../types/patchApiPushSubscriptionsPushSubscriptionIdSchema';
import type {
  PatchApiUsersMeMutationRequestSchema,
  PatchApiUsersMeMutationResponseSchema,
  PatchApiUsersMe401Schema,
  PatchApiUsersMe422Schema,
} from '../types/patchApiUsersMeSchema';
import type {
  PatchApiUsersPasswordMutationRequestSchema,
  PatchApiUsersPasswordMutationResponseSchema,
  PatchApiUsersPassword400Schema,
  PatchApiUsersPassword422Schema,
} from '../types/patchApiUsersPasswordSchema';
import type {
  PostApiAgreementsAcceptEuaMutationResponseSchema,
  PostApiAgreementsAcceptEuaQueryParamsSchema,
  PostApiAgreementsAcceptEua400Schema,
  PostApiAgreementsAcceptEua401Schema,
} from '../types/postApiAgreementsAcceptEuaSchema';
import type {
  PostApiAnalyticalEventsMutationRequestSchema,
  PostApiAnalyticalEventsMutationResponseSchema,
  PostApiAnalyticalEvents400Schema,
  PostApiAnalyticalEvents401Schema,
} from '../types/postApiAnalyticalEventsSchema';
import type {
  PostApiAuthenticationMutationRequestSchema,
  PostApiAuthenticationMutationResponseSchema,
  PostApiAuthentication400Schema,
} from '../types/postApiAuthenticationSchema';
import type {
  PostApiChannelsTokenMutationResponseSchema,
  PostApiChannelsToken401Schema,
  PostApiChannelsToken422Schema,
  PostApiChannelsToken503Schema,
} from '../types/postApiChannelsTokenSchema';
import type {
  PostApiDirectUploadsTypeMutationRequestSchema,
  PostApiDirectUploadsTypeMutationResponseSchema,
  PostApiDirectUploadsTypePathParamsSchema,
  PostApiDirectUploadsType400Schema,
  PostApiDirectUploadsType401Schema,
  PostApiDirectUploadsType404Schema,
} from '../types/postApiDirectUploadsTypeSchema';
import type {
  PostApiFeedbacksMutationRequestSchema,
  PostApiFeedbacksMutationResponseSchema,
  PostApiFeedbacks401Schema,
  PostApiFeedbacks422Schema,
} from '../types/postApiFeedbacksSchema';
import type {
  PostApiLoginRefreshMutationResponseSchema,
  PostApiLoginRefreshHeaderParamsSchema,
  PostApiLoginRefresh400Schema,
  PostApiLoginRefresh401Schema,
} from '../types/postApiLoginRefreshSchema';
import type {
  PostApiLoginMutationRequestSchema,
  PostApiLoginMutationResponseSchema,
  PostApiLogin401Schema,
} from '../types/postApiLoginSchema';
import type {
  PostApiNotificationsMarkAllReadMutationResponseSchema,
  PostApiNotificationsMarkAllRead401Schema,
} from '../types/postApiNotificationsMarkAllReadSchema';
import type {
  PostApiNotificationsNotificationIdMarkReadMutationRequestSchema,
  PostApiNotificationsNotificationIdMarkReadMutationResponseSchema,
  PostApiNotificationsNotificationIdMarkReadPathParamsSchema,
  PostApiNotificationsNotificationIdMarkRead401Schema,
  PostApiNotificationsNotificationIdMarkRead404Schema,
} from '../types/postApiNotificationsNotificationIdMarkReadSchema';
import type {
  PostApiOnboardingFinishMutationRequestSchema,
  PostApiOnboardingFinishMutationResponseSchema,
  PostApiOnboardingFinish401Schema,
  PostApiOnboardingFinish404Schema,
  PostApiOnboardingFinish422Schema,
} from '../types/postApiOnboardingFinishSchema';
import type {
  PostApiOrgsCheckDomainMutationRequestSchema,
  PostApiOrgsCheckDomainMutationResponseSchema,
  PostApiOrgsCheckDomain401Schema,
} from '../types/postApiOrgsCheckDomainSchema';
import type {
  PostApiOrgsOrgIdResendVerificationEmailMutationResponseSchema,
  PostApiOrgsOrgIdResendVerificationEmailPathParamsSchema,
  PostApiOrgsOrgIdResendVerificationEmail401Schema,
  PostApiOrgsOrgIdResendVerificationEmail403Schema,
  PostApiOrgsOrgIdResendVerificationEmail404Schema,
} from '../types/postApiOrgsOrgIdResendVerificationEmailSchema';
import type {
  PostApiOrgsMutationRequestSchema,
  PostApiOrgsMutationResponseSchema,
  PostApiOrgs401Schema,
  PostApiOrgs422Schema,
} from '../types/postApiOrgsSchema';
import type {
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationRequestSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationResponseSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptPathParamsSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept401Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept403Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept404Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept422Schema,
} from '../types/postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptSchema';
import type {
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationRequestSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationResponseSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectPathParamsSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect400Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect401Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect403Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect404Schema,
} from '../types/postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectSchema';
import type {
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationResponseSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectPathParamsSchema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject401Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject403Schema,
  PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject404Schema,
} from '../types/postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectSchema';
import type {
  PostApiProjectsProjectIdAccessRequestsMutationRequestSchema,
  PostApiProjectsProjectIdAccessRequestsMutationResponseSchema,
  PostApiProjectsProjectIdAccessRequestsPathParamsSchema,
  PostApiProjectsProjectIdAccessRequests400Schema,
  PostApiProjectsProjectIdAccessRequests401Schema,
  PostApiProjectsProjectIdAccessRequests404Schema,
  PostApiProjectsProjectIdAccessRequests422Schema,
} from '../types/postApiProjectsProjectIdAccessRequestsSchema';
import type {
  PostApiProjectsProjectIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdArchivePathParamsSchema,
  PostApiProjectsProjectIdArchive401Schema,
  PostApiProjectsProjectIdArchive403Schema,
  PostApiProjectsProjectIdArchive404Schema,
} from '../types/postApiProjectsProjectIdArchiveSchema';
import type {
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationResponseSchema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsPathParamsSchema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments401Schema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments403Schema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments404Schema,
  PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments422Schema,
} from '../types/postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsSchema';
import type {
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchivePathParamsSchema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive401Schema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive403Schema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive404Schema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive422Schema,
} from '../types/postApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveSchema';
import type {
  PostApiProjectsProjectIdControlCenterPotentialChangesMutationRequestSchema,
  PostApiProjectsProjectIdControlCenterPotentialChangesMutationResponseSchema,
  PostApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema,
  PostApiProjectsProjectIdControlCenterPotentialChanges401Schema,
  PostApiProjectsProjectIdControlCenterPotentialChanges404Schema,
  PostApiProjectsProjectIdControlCenterPotentialChanges422Schema,
} from '../types/postApiProjectsProjectIdControlCenterPotentialChangesSchema';
import type {
  PostApiProjectsProjectIdCustomFieldsMutationRequestSchema,
  PostApiProjectsProjectIdCustomFieldsMutationResponseSchema,
  PostApiProjectsProjectIdCustomFieldsPathParamsSchema,
  PostApiProjectsProjectIdCustomFields401Schema,
  PostApiProjectsProjectIdCustomFields403Schema,
  PostApiProjectsProjectIdCustomFields404Schema,
  PostApiProjectsProjectIdCustomFields422Schema,
} from '../types/postApiProjectsProjectIdCustomFieldsSchema';
import type {
  PostApiProjectsProjectIdDefaultMutationResponseSchema,
  PostApiProjectsProjectIdDefaultPathParamsSchema,
  PostApiProjectsProjectIdDefault401Schema,
  PostApiProjectsProjectIdDefault403Schema,
  PostApiProjectsProjectIdDefault404Schema,
} from '../types/postApiProjectsProjectIdDefaultSchema';
import type {
  PostApiProjectsProjectIdDisciplinesMutationRequestSchema,
  PostApiProjectsProjectIdDisciplinesMutationResponseSchema,
  PostApiProjectsProjectIdDisciplinesPathParamsSchema,
  PostApiProjectsProjectIdDisciplines401Schema,
  PostApiProjectsProjectIdDisciplines403Schema,
  PostApiProjectsProjectIdDisciplines404Schema,
  PostApiProjectsProjectIdDisciplines422Schema,
} from '../types/postApiProjectsProjectIdDisciplinesSchema';
import type {
  PostApiProjectsProjectIdDisciplinesSortMutationRequestSchema,
  PostApiProjectsProjectIdDisciplinesSortMutationResponseSchema,
  PostApiProjectsProjectIdDisciplinesSortPathParamsSchema,
  PostApiProjectsProjectIdDisciplinesSort401Schema,
  PostApiProjectsProjectIdDisciplinesSort403Schema,
  PostApiProjectsProjectIdDisciplinesSort404Schema,
} from '../types/postApiProjectsProjectIdDisciplinesSortSchema';
import type {
  PostApiProjectsProjectIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdDocuments400Schema,
  PostApiProjectsProjectIdDocuments401Schema,
  PostApiProjectsProjectIdDocuments403Schema,
  PostApiProjectsProjectIdDocuments404Schema,
  PostApiProjectsProjectIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdGroupsMutationRequestSchema,
  PostApiProjectsProjectIdGroupsMutationResponseSchema,
  PostApiProjectsProjectIdGroupsPathParamsSchema,
  PostApiProjectsProjectIdGroups400Schema,
  PostApiProjectsProjectIdGroups401Schema,
  PostApiProjectsProjectIdGroups403Schema,
  PostApiProjectsProjectIdGroups404Schema,
  PostApiProjectsProjectIdGroups422Schema,
} from '../types/postApiProjectsProjectIdGroupsSchema';
import type {
  PostApiProjectsProjectIdIssuesExportMutationResponseSchema,
  PostApiProjectsProjectIdIssuesExportPathParamsSchema,
  PostApiProjectsProjectIdIssuesExportQueryParamsSchema,
  PostApiProjectsProjectIdIssuesExport400Schema,
  PostApiProjectsProjectIdIssuesExport401Schema,
  PostApiProjectsProjectIdIssuesExport403Schema,
  PostApiProjectsProjectIdIssuesExport404Schema,
} from '../types/postApiProjectsProjectIdIssuesExportSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdApproveMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdApprovePathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdApprove400Schema,
  PostApiProjectsProjectIdIssuesIssueIdApprove401Schema,
  PostApiProjectsProjectIdIssuesIssueIdApprove403Schema,
  PostApiProjectsProjectIdIssuesIssueIdApprove404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdApproveSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdArchiveMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdArchivePathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdArchive401Schema,
  PostApiProjectsProjectIdIssuesIssueIdArchive403Schema,
  PostApiProjectsProjectIdIssuesIssueIdArchive404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdArchiveSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept401Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept403Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject401Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject403Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignmentsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdAssignments400Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignments401Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignments403Schema,
  PostApiProjectsProjectIdIssuesIssueIdAssignments404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdAssignmentsSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdCommentsMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdCommentsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdCommentsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdComments401Schema,
  PostApiProjectsProjectIdIssuesIssueIdComments403Schema,
  PostApiProjectsProjectIdIssuesIssueIdComments404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdCommentsSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdCompleteMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdCompletePathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdComplete400Schema,
  PostApiProjectsProjectIdIssuesIssueIdComplete401Schema,
  PostApiProjectsProjectIdIssuesIssueIdComplete403Schema,
  PostApiProjectsProjectIdIssuesIssueIdComplete404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdCompleteSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdDocuments400Schema,
  PostApiProjectsProjectIdIssuesIssueIdDocuments401Schema,
  PostApiProjectsProjectIdIssuesIssueIdDocuments403Schema,
  PostApiProjectsProjectIdIssuesIssueIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdExportMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdExportMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdExportPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdExport401Schema,
  PostApiProjectsProjectIdIssuesIssueIdExport403Schema,
  PostApiProjectsProjectIdIssuesIssueIdExport404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdExportSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImages400Schema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImages401Schema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImages403Schema,
  PostApiProjectsProjectIdIssuesIssueIdIssueImages422Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdIssueImagesSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdRejectMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdRejectMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdRejectPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdReject400Schema,
  PostApiProjectsProjectIdIssuesIssueIdReject401Schema,
  PostApiProjectsProjectIdIssuesIssueIdReject404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdRejectSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdReopenMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdReopenPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdReopen400Schema,
  PostApiProjectsProjectIdIssuesIssueIdReopen401Schema,
  PostApiProjectsProjectIdIssuesIssueIdReopen404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdReopenSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdRestoreMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdRestorePathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdRestore401Schema,
  PostApiProjectsProjectIdIssuesIssueIdRestore403Schema,
  PostApiProjectsProjectIdIssuesIssueIdRestore404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdRestoreSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdStartMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdStartPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdStart400Schema,
  PostApiProjectsProjectIdIssuesIssueIdStart401Schema,
  PostApiProjectsProjectIdIssuesIssueIdStart404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdStartSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema,
  PostApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdStatusStatementsSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdStopMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdStopPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdStop400Schema,
  PostApiProjectsProjectIdIssuesIssueIdStop401Schema,
  PostApiProjectsProjectIdIssuesIssueIdStop404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdStopSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdSubmitMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdSubmitPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdSubmit401Schema,
  PostApiProjectsProjectIdIssuesIssueIdSubmit403Schema,
  PostApiProjectsProjectIdIssuesIssueIdSubmit404Schema,
  PostApiProjectsProjectIdIssuesIssueIdSubmit422Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdSubmitSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationRequestSchema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpactPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpact401Schema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpact403Schema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpact404Schema,
  PostApiProjectsProjectIdIssuesIssueIdUpdateImpact422Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdUpdateImpactSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdVisitMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdVisit401Schema,
  PostApiProjectsProjectIdIssuesIssueIdVisit403Schema,
  PostApiProjectsProjectIdIssuesIssueIdVisit404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdVisitSchema';
import type {
  PostApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
  PostApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema,
  PostApiProjectsProjectIdIssuesIssueIdWatchings401Schema,
  PostApiProjectsProjectIdIssuesIssueIdWatchings403Schema,
  PostApiProjectsProjectIdIssuesIssueIdWatchings404Schema,
} from '../types/postApiProjectsProjectIdIssuesIssueIdWatchingsSchema';
import type {
  PostApiProjectsProjectIdIssuesMutationRequestSchema,
  PostApiProjectsProjectIdIssuesMutationResponseSchema,
  PostApiProjectsProjectIdIssuesPathParamsSchema,
  PostApiProjectsProjectIdIssuesHeaderParamsSchema,
  PostApiProjectsProjectIdIssues401Schema,
  PostApiProjectsProjectIdIssues403Schema,
  PostApiProjectsProjectIdIssues404Schema,
} from '../types/postApiProjectsProjectIdIssuesSchema';
import type {
  PostApiProjectsProjectIdIssueViewsMutationRequestSchema,
  PostApiProjectsProjectIdIssueViewsMutationResponseSchema,
  PostApiProjectsProjectIdIssueViewsPathParamsSchema,
  PostApiProjectsProjectIdIssueViews401Schema,
  PostApiProjectsProjectIdIssueViews403Schema,
  PostApiProjectsProjectIdIssueViews404Schema,
  PostApiProjectsProjectIdIssueViews422Schema,
} from '../types/postApiProjectsProjectIdIssueViewsSchema';
import type {
  PostApiProjectsProjectIdLocationsLocationIdSortMutationRequestSchema,
  PostApiProjectsProjectIdLocationsLocationIdSortMutationResponseSchema,
  PostApiProjectsProjectIdLocationsLocationIdSortPathParamsSchema,
  PostApiProjectsProjectIdLocationsLocationIdSort401Schema,
  PostApiProjectsProjectIdLocationsLocationIdSort403Schema,
  PostApiProjectsProjectIdLocationsLocationIdSort404Schema,
} from '../types/postApiProjectsProjectIdLocationsLocationIdSortSchema';
import type {
  PostApiProjectsProjectIdLocationsMutationRequestSchema,
  PostApiProjectsProjectIdLocationsMutationResponseSchema,
  PostApiProjectsProjectIdLocationsPathParamsSchema,
  PostApiProjectsProjectIdLocations401Schema,
  PostApiProjectsProjectIdLocations403Schema,
  PostApiProjectsProjectIdLocations404Schema,
  PostApiProjectsProjectIdLocations422Schema,
} from '../types/postApiProjectsProjectIdLocationsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesExportMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesExportMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesExportPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesExport401Schema,
  PostApiProjectsProjectIdShiftActivitiesExport403Schema,
  PostApiProjectsProjectIdShiftActivitiesExport404Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesExportSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesImportsMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesImportsMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesImportsPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesImports401Schema,
  PostApiProjectsProjectIdShiftActivitiesImports403Schema,
  PostApiProjectsProjectIdShiftActivitiesImports404Schema,
  PostApiProjectsProjectIdShiftActivitiesImports422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesImportsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesPathParamsSchema,
  PostApiProjectsProjectIdShiftActivities401Schema,
  PostApiProjectsProjectIdShiftActivities403Schema,
  PostApiProjectsProjectIdShiftActivities404Schema,
  PostApiProjectsProjectIdShiftActivities422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchivePathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationRequestSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortPathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort400Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort404Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortSchema';
import type {
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationResponseSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestorePathParamsSchema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore401Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore403Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore404Schema,
  PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore422Schema,
} from '../types/postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreSchema';
import type {
  PostApiProjectsProjectIdShiftReportsExportMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsExportMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsExportPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsExport400Schema,
  PostApiProjectsProjectIdShiftReportsExport401Schema,
  PostApiProjectsProjectIdShiftReportsExport403Schema,
  PostApiProjectsProjectIdShiftReportsExport404Schema,
} from '../types/postApiProjectsProjectIdShiftReportsExportSchema';
import type {
  PostApiProjectsProjectIdShiftReportsMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsPathParamsSchema,
  PostApiProjectsProjectIdShiftReports401Schema,
  PostApiProjectsProjectIdShiftReports403Schema,
  PostApiProjectsProjectIdShiftReports404Schema,
  PostApiProjectsProjectIdShiftReports422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchivePathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchive401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchive403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchive404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdArchive422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdArchiveSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExportPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExportQueryParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExport400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExport401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExport403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdExport404Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdExportSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImportPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdImport422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdImportSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublishPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublishQueryParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublish400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublish401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublish403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdPublish404Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdPublishSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionQueryParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection401Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdResetSectionSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationRequestSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSchema';
import type {
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationResponseSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestorePathParamsSchema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestore401Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestore403Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestore404Schema,
  PostApiProjectsProjectIdShiftReportsShiftReportIdRestore422Schema,
} from '../types/postApiProjectsProjectIdShiftReportsShiftReportIdRestoreSchema';
import type {
  PostApiProjectsProjectIdTeamsMutationRequestSchema,
  PostApiProjectsProjectIdTeamsMutationResponseSchema,
  PostApiProjectsProjectIdTeamsPathParamsSchema,
  PostApiProjectsProjectIdTeams401Schema,
  PostApiProjectsProjectIdTeams403Schema,
  PostApiProjectsProjectIdTeams404Schema,
  PostApiProjectsProjectIdTeams422Schema,
} from '../types/postApiProjectsProjectIdTeamsSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdJoinToken401Schema,
  PostApiProjectsProjectIdTeamsTeamIdJoinToken403Schema,
  PostApiProjectsProjectIdTeamsTeamIdJoinToken404Schema,
  PostApiProjectsProjectIdTeamsTeamIdJoinToken422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdJoinTokenSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdMembersMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembers401Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembers403Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembers404Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembers422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdMembersSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive401Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive403Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive404Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail401Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail403Schema,
  PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail404Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites401Schema,
  PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites404Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdResendMembersInvitesSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesKind422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdResourcesKindSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable401Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable403Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable404Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable401Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable403Schema,
  PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable404Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal401Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal403Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal404Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm401Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm403Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm404Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmSchema';
import type {
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationRequestSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationResponseSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscriptionPathParamsSchema,
  PostApiProjectsProjectIdTeamsTeamIdSubscription401Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscription403Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscription404Schema,
  PostApiProjectsProjectIdTeamsTeamIdSubscription422Schema,
} from '../types/postApiProjectsProjectIdTeamsTeamIdSubscriptionSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlans400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlans401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlans403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlans404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlans422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch404Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort404Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchivePathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClosePathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicatePathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport404Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport404Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationRequestSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill400Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishPathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishSchema';
import type {
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreMutationResponseSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestorePathParamsSchema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore401Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore403Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore404Schema,
  PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore422Schema,
} from '../types/postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreSchema';
import type {
  PostApiProjectsMutationRequestSchema,
  PostApiProjectsMutationResponseSchema,
  PostApiProjects401Schema,
  PostApiProjects403Schema,
} from '../types/postApiProjectsSchema';
import type {
  PostApiProjectsShowcasesMutationRequestSchema,
  PostApiProjectsShowcasesMutationResponseSchema,
  PostApiProjectsShowcases401Schema,
  PostApiProjectsShowcases403Schema,
} from '../types/postApiProjectsShowcasesSchema';
import type {
  PostApiPushSubscriptionsPingMutationResponseSchema,
  PostApiPushSubscriptionsPing401Schema,
} from '../types/postApiPushSubscriptionsPingSchema';
import type {
  PostApiPushSubscriptionsMutationRequestSchema,
  PostApiPushSubscriptionsMutationResponseSchema,
  PostApiPushSubscriptions401Schema,
  PostApiPushSubscriptions422Schema,
} from '../types/postApiPushSubscriptionsSchema';
import type {
  PostApiTeamMembersMutationRequestSchema,
  PostApiTeamMembersMutationResponseSchema,
  PostApiTeamMembers401Schema,
  PostApiTeamMembers422Schema,
} from '../types/postApiTeamMembersSchema';
import type {
  PostApiUsersConfirmationInstructionsMutationRequestSchema,
  PostApiUsersConfirmationInstructionsMutationResponseSchema,
} from '../types/postApiUsersConfirmationInstructionsSchema';
import type {
  PostApiUsersConfirmationMutationRequestSchema,
  PostApiUsersConfirmationMutationResponseSchema,
  PostApiUsersConfirmation400Schema,
  PostApiUsersConfirmation422Schema,
} from '../types/postApiUsersConfirmationSchema';
import type {
  PostApiUsersPasswordInstructionsMutationRequestSchema,
  PostApiUsersPasswordInstructionsMutationResponseSchema,
} from '../types/postApiUsersPasswordInstructionsSchema';
import type {
  PostApiUsersMutationRequestSchema,
  PostApiUsersMutationResponseSchema,
  PostApiUsers422Schema,
} from '../types/postApiUsersSchema';
import type {
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationRequestSchema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationResponseSchema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId400Schema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema,
  PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId422Schema,
} from '../types/putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSchema';
import type {
  QueryKey,
  QueryObserverOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import {
  getApiAgreementsLatestEua,
  postApiAgreementsAcceptEua,
  postApiAnalyticalEvents,
  postApiLogin,
  deleteApiLogout,
  postApiAuthentication,
  postApiChannelsToken,
  postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments,
  postApiProjectsProjectIdIssuesIssueIdComments,
  deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId,
  getApiConstructionRoles,
  getApiProjectsProjectIdControlCenterPotentialChanges,
  postApiProjectsProjectIdControlCenterPotentialChanges,
  patchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId,
  postApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive,
  getApiProjectsProjectIdCustomFields,
  postApiProjectsProjectIdCustomFields,
  patchApiProjectsProjectIdCustomFieldsCustomFieldId,
  deleteApiProjectsProjectIdCustomFieldsCustomFieldId,
  patchApiProjectsProjectIdIssuesIssueIdCustomFields,
  getApiProjectsProjectIdDashboardsDataHealthScoresRecordType,
  postApiDirectUploadsType,
  getApiProjectsProjectIdDisciplines,
  postApiProjectsProjectIdDisciplines,
  getApiProjectsProjectIdDisciplinesDisciplineId,
  patchApiProjectsProjectIdDisciplinesDisciplineId,
  deleteApiProjectsProjectIdDisciplinesDisciplineId,
  postApiProjectsProjectIdDisciplinesSort,
  getApiProjectsProjectIdDocumentsDocumentIdReferences,
  getApiProjectsProjectIdDocuments,
  postApiProjectsProjectIdDocuments,
  getApiProjectsProjectIdDocumentsDocumentId,
  patchApiProjectsProjectIdDocumentsDocumentId,
  deleteApiProjectsProjectIdDocumentsDocumentId,
  getApiProjectsProjectIdEvents,
  getApiFeatureFlags,
  postApiFeedbacks,
  getApiProjectsProjectIdGroupsGroupIdChannelConfiguration,
  patchApiProjectsProjectIdGroupsGroupIdChannelConfiguration,
  postApiProjectsProjectIdGroups,
  getApiProjectsProjectIdGroupsGroupId,
  patchApiProjectsProjectIdGroupsGroupId,
  deleteApiProjectsProjectIdGroupsGroupId,
  patchApiProjectsProjectIdGroupsGroupIdMembers,
  postApiProjectsProjectIdIssuesIssueIdAssignments,
  postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept,
  postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject,
  deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId,
  getApiProjectsProjectIdIssuesIssueIdDocuments,
  postApiProjectsProjectIdIssuesIssueIdDocuments,
  getApiProjectsProjectIdIssuesIssueIdFeedTeam,
  getApiProjectsProjectIdIssuesIssueIdFeedPublic,
  getApiProjectsProjectIdIssuesIssueIdIssueImages,
  postApiProjectsProjectIdIssuesIssueIdIssueImages,
  deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId,
  patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId,
  postApiProjectsProjectIdIssuesIssueIdApprove,
  postApiProjectsProjectIdIssuesIssueIdComplete,
  postApiProjectsProjectIdIssuesIssueIdReject,
  postApiProjectsProjectIdIssuesIssueIdReopen,
  postApiProjectsProjectIdIssuesIssueIdStart,
  postApiProjectsProjectIdIssuesIssueIdStop,
  postApiProjectsProjectIdIssuesIssueIdSubmit,
  postApiProjectsProjectIdIssuesIssueIdStatusStatements,
  getApiProjectsProjectIdIssuesIssueIdStatusStatements,
  deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId,
  getApiProjectsProjectIdIssueViews,
  postApiProjectsProjectIdIssueViews,
  patchApiProjectsProjectIdIssueViewsIssueViewId,
  deleteApiProjectsProjectIdIssueViewsIssueViewId,
  getApiProjectsProjectIdIssuesIssueIdVisit,
  postApiProjectsProjectIdIssuesIssueIdVisit,
  postApiProjectsProjectIdIssues,
  getApiProjectsProjectIdIssues,
  getApiProjectsProjectIdIssuesGroupCount,
  postApiProjectsProjectIdIssuesExport,
  getApiProjectsProjectIdIssuesIssueId,
  patchApiProjectsProjectIdIssuesIssueId,
  deleteApiProjectsProjectIdIssuesIssueId,
  postApiProjectsProjectIdIssuesIssueIdArchive,
  postApiProjectsProjectIdIssuesIssueIdExport,
  postApiProjectsProjectIdIssuesIssueIdRestore,
  postApiProjectsProjectIdIssuesIssueIdUpdateImpact,
  getApiProjectsProjectIdDashboardsIssuesStalenessCount,
  getApiProjectsProjectIdDashboardsIssuesStalenessIssues,
  getApiProjectsProjectIdLocations,
  postApiProjectsProjectIdLocations,
  patchApiProjectsProjectIdLocationsLocationId,
  getApiProjectsProjectIdLocationsLocationId,
  deleteApiProjectsProjectIdLocationsLocationId,
  postApiProjectsProjectIdLocationsLocationIdSort,
  getApiNotifications,
  getApiNotificationsOverview,
  postApiNotificationsNotificationIdMarkRead,
  postApiNotificationsMarkAllRead,
  getApiOnboarding,
  patchApiOnboarding,
  postApiOnboardingFinish,
  postApiOrgs,
  getApiOrgs,
  patchApiOrgsOrgId,
  getApiOrgsOrgId,
  postApiOrgsCheckDomain,
  postApiOrgsOrgIdResendVerificationEmail,
  getApiProductToursProductTourKey,
  patchApiProductToursProductTourKey,
  getApiProjectsProjectIdAccessRequests,
  postApiProjectsProjectIdAccessRequests,
  getApiProjectsProjectIdAccessRequestsProjectAccessRequestId,
  postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept,
  postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject,
  postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect,
  getApiProjectsProjectIdPeople,
  getApiProjectsProjectIdPeopleTeamMemberId,
  postApiProjects,
  getApiProjects,
  getApiProjectsProjectId,
  patchApiProjectsProjectId,
  postApiProjectsProjectIdArchive,
  postApiProjectsProjectIdDefault,
  postApiPushSubscriptions,
  patchApiPushSubscriptionsPushSubscriptionId,
  deleteApiPushSubscriptionsPushSubscriptionId,
  postApiPushSubscriptionsPing,
  getApiQueuedTasks,
  postApiLoginRefresh,
  getApiProjectsProjectIdShiftActivities,
  postApiProjectsProjectIdShiftActivities,
  getApiProjectsProjectIdShiftActivitiesShiftActivityId,
  patchApiProjectsProjectIdShiftActivitiesShiftActivityId,
  postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive,
  postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore,
  getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress,
  getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments,
  postApiProjectsProjectIdShiftActivitiesExport,
  postApiProjectsProjectIdShiftActivitiesImports,
  deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId,
  getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments,
  postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments,
  getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs,
  postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs,
  getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId,
  patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId,
  patchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness,
  getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements,
  postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements,
  postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort,
  patchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId,
  deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId,
  postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion,
  deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion,
  getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage,
  getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats,
  getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning,
  getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators,
  postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators,
  getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic,
  postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic,
  deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId,
  deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId,
  getApiProjectsProjectIdShiftReportsShiftReportIdDocuments,
  postApiProjectsProjectIdShiftReportsShiftReportIdDocuments,
  postApiProjectsProjectIdShiftReportsExport,
  postApiProjectsProjectIdShiftReportsShiftReportIdExport,
  postApiProjectsProjectIdShiftReportsShiftReportIdImport,
  getApiProjectsProjectIdShiftReportsShiftReportIdPeople,
  getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments,
  postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments,
  deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId,
  getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind,
  postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind,
  getApiProjectsProjectIdShiftReportsCompletions,
  getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators,
  getApiProjectsProjectIdShiftReports,
  postApiProjectsProjectIdShiftReports,
  getApiProjectsProjectIdShiftReportsArchived,
  getApiProjectsProjectIdShiftReportsDraft,
  getApiProjectsProjectIdShiftReportsShiftReportId,
  patchApiProjectsProjectIdShiftReportsShiftReportId,
  deleteApiProjectsProjectIdShiftReportsShiftReportId,
  postApiProjectsProjectIdShiftReportsShiftReportIdArchive,
  postApiProjectsProjectIdShiftReportsShiftReportIdPublish,
  postApiProjectsProjectIdShiftReportsShiftReportIdResetSection,
  postApiProjectsProjectIdShiftReportsShiftReportIdRestore,
  postApiProjectsShowcases,
  getApiProjectsProjectIdTeamsTeamIdChannelConfiguration,
  patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration,
  getApiProjectsProjectIdTeamsTeamIdJoinToken,
  postApiProjectsProjectIdTeamsTeamIdJoinToken,
  deleteApiProjectsProjectIdTeamsTeamIdJoinToken,
  getApiTeamJoinTokensToken,
  postApiTeamMembers,
  postApiProjectsProjectIdTeamsTeamIdMembers,
  patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId,
  deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId,
  postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive,
  getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies,
  postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail,
  getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard,
  postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable,
  postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable,
  getApiProjectsProjectIdTeamsTeamIdResourcesKind,
  postApiProjectsProjectIdTeamsTeamIdResourcesKind,
  getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan,
  postApiProjectsProjectIdTeamsTeamIdSubscription,
  postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm,
  postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal,
  getApiProjectsProjectIdTeams,
  postApiProjectsProjectIdTeams,
  getApiProjectsProjectIdTeamsTeamId,
  patchApiProjectsProjectIdTeamsTeamId,
  deleteApiProjectsProjectIdTeamsTeamId,
  postApiProjectsProjectIdTeamsTeamIdResendMembersInvites,
  getApiTimeZones,
  postApiUsersConfirmationInstructions,
  postApiUsersConfirmation,
  postApiUsers,
  getApiUsersMe,
  patchApiUsersMe,
  postApiUsersPasswordInstructions,
  patchApiUsersPassword,
  getApiProjectsProjectIdIssuesIssueIdWatchings,
  postApiProjectsProjectIdIssuesIssueIdWatchings,
  deleteApiProjectsProjectIdIssuesIssueIdWatchings,
  getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch,
  getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId,
  patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId,
  deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays,
  deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate,
  getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs,
  getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder,
  patchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder,
  getApiProjectsProjectIdWeeklyWorkPlans,
  postApiProjectsProjectIdWeeklyWorkPlans,
  getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId,
  putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive,
  postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore,
} from '../api/index';
import { queryOptions, useQuery, useSuspenseQuery, useMutation } from '@tanstack/react-query';

export const getApiAgreementsLatestEuaQueryKey = () => [{ url: '/api/agreements/latest_eua' }] as const;

export type GetApiAgreementsLatestEuaQueryKey = ReturnType<typeof getApiAgreementsLatestEuaQueryKey>;

export function getApiAgreementsLatestEuaQueryOptions(
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiAgreementsLatestEuaQueryKey();
  return queryOptions<
    GetApiAgreementsLatestEuaQueryResponseSchema,
    ResponseErrorConfig<GetApiAgreementsLatestEua404Schema>,
    GetApiAgreementsLatestEuaQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiAgreementsLatestEua(config);
    },
  });
}

/**
 * @summary Retrieves the latest End User Agreement
 * {@link /api/agreements/latest_eua}
 */
export function useGetApiAgreementsLatestEua<
  TData = GetApiAgreementsLatestEuaQueryResponseSchema,
  TQueryData = GetApiAgreementsLatestEuaQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiAgreementsLatestEuaQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiAgreementsLatestEuaQueryResponseSchema,
        ResponseErrorConfig<GetApiAgreementsLatestEua404Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiAgreementsLatestEuaQueryKey();

  const query = useQuery({
    ...(getApiAgreementsLatestEuaQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiAgreementsLatestEua404Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiAgreementsLatestEuaSuspenseQueryKey = () => [{ url: '/api/agreements/latest_eua' }] as const;

export type GetApiAgreementsLatestEuaSuspenseQueryKey = ReturnType<typeof getApiAgreementsLatestEuaSuspenseQueryKey>;

export function getApiAgreementsLatestEuaSuspenseQueryOptions(
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiAgreementsLatestEuaSuspenseQueryKey();
  return queryOptions<
    GetApiAgreementsLatestEuaQueryResponseSchema,
    ResponseErrorConfig<GetApiAgreementsLatestEua404Schema>,
    GetApiAgreementsLatestEuaQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiAgreementsLatestEua(config);
    },
  });
}

/**
 * @summary Retrieves the latest End User Agreement
 * {@link /api/agreements/latest_eua}
 */
export function useGetApiAgreementsLatestEuaSuspense<
  TData = GetApiAgreementsLatestEuaQueryResponseSchema,
  TQueryData = GetApiAgreementsLatestEuaQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiAgreementsLatestEuaSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiAgreementsLatestEuaQueryResponseSchema,
        ResponseErrorConfig<GetApiAgreementsLatestEua404Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiAgreementsLatestEuaSuspenseQueryKey();

  const query = useSuspenseQuery({
    ...(getApiAgreementsLatestEuaSuspenseQueryOptions(config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiAgreementsLatestEua404Schema>> & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiAgreementsAcceptEuaMutationKey = () => [{ url: '/api/agreements/accept_eua' }] as const;

export type PostApiAgreementsAcceptEuaMutationKey = ReturnType<typeof postApiAgreementsAcceptEuaMutationKey>;

/**
 * @summary Accepts the End User Agreement for the current user
 * {@link /api/agreements/accept_eua}
 */
export function usePostApiAgreementsAcceptEua(
  options: {
    mutation?: UseMutationOptions<
      PostApiAgreementsAcceptEuaMutationResponseSchema,
      ResponseErrorConfig<PostApiAgreementsAcceptEua400Schema | PostApiAgreementsAcceptEua401Schema>,
      { params?: PostApiAgreementsAcceptEuaQueryParamsSchema }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiAgreementsAcceptEuaMutationKey();

  return useMutation<
    PostApiAgreementsAcceptEuaMutationResponseSchema,
    ResponseErrorConfig<PostApiAgreementsAcceptEua400Schema | PostApiAgreementsAcceptEua401Schema>,
    { params?: PostApiAgreementsAcceptEuaQueryParamsSchema }
  >({
    mutationFn: async ({ params }) => {
      return postApiAgreementsAcceptEua(params, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiAnalyticalEventsMutationKey = () => [{ url: '/api/analytical/events' }] as const;

export type PostApiAnalyticalEventsMutationKey = ReturnType<typeof postApiAnalyticalEventsMutationKey>;

/**
 * @summary Tracks analytical events
 * {@link /api/analytical/events}
 */
export function usePostApiAnalyticalEvents(
  options: {
    mutation?: UseMutationOptions<
      PostApiAnalyticalEventsMutationResponseSchema,
      ResponseErrorConfig<PostApiAnalyticalEvents400Schema | PostApiAnalyticalEvents401Schema>,
      { data: PostApiAnalyticalEventsMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiAnalyticalEventsMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiAnalyticalEventsMutationKey();

  return useMutation<
    PostApiAnalyticalEventsMutationResponseSchema,
    ResponseErrorConfig<PostApiAnalyticalEvents400Schema | PostApiAnalyticalEvents401Schema>,
    { data: PostApiAnalyticalEventsMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiAnalyticalEvents(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiLoginMutationKey = () => [{ url: '/api/login' }] as const;

export type PostApiLoginMutationKey = ReturnType<typeof postApiLoginMutationKey>;

/**
 * @summary Authenticates a user
 * {@link /api/login}
 */
export function usePostApiLogin(
  options: {
    mutation?: UseMutationOptions<
      PostApiLoginMutationResponseSchema,
      ResponseErrorConfig<PostApiLogin401Schema>,
      { data: PostApiLoginMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiLoginMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiLoginMutationKey();

  return useMutation<
    PostApiLoginMutationResponseSchema,
    ResponseErrorConfig<PostApiLogin401Schema>,
    { data: PostApiLoginMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiLogin(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiLogoutMutationKey = () => [{ url: '/api/logout' }] as const;

export type DeleteApiLogoutMutationKey = ReturnType<typeof deleteApiLogoutMutationKey>;

/**
 * @summary Logs out a user
 * {@link /api/logout}
 */
export function useDeleteApiLogout(
  options: {
    mutation?: UseMutationOptions<DeleteApiLogoutMutationResponseSchema, ResponseErrorConfig<DeleteApiLogout401Schema>>;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiLogoutMutationKey();

  return useMutation<DeleteApiLogoutMutationResponseSchema, ResponseErrorConfig<DeleteApiLogout401Schema>>({
    mutationFn: async () => {
      return deleteApiLogout(config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiAuthenticationMutationKey = () => [{ url: '/api/authentication' }] as const;

export type PostApiAuthenticationMutationKey = ReturnType<typeof postApiAuthenticationMutationKey>;

/**
 * @summary Attempts a login with a provider or checks if the account exists
 * {@link /api/authentication}
 */
export function usePostApiAuthentication(
  options: {
    mutation?: UseMutationOptions<
      PostApiAuthenticationMutationResponseSchema,
      ResponseErrorConfig<PostApiAuthentication400Schema>,
      { data: PostApiAuthenticationMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiAuthenticationMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiAuthenticationMutationKey();

  return useMutation<
    PostApiAuthenticationMutationResponseSchema,
    ResponseErrorConfig<PostApiAuthentication400Schema>,
    { data: PostApiAuthenticationMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiAuthentication(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiChannelsTokenMutationKey = () => [{ url: '/api/channels/token' }] as const;

export type PostApiChannelsTokenMutationKey = ReturnType<typeof postApiChannelsTokenMutationKey>;

/**
 * @summary Requests a new Channels token
 * {@link /api/channels/token}
 */
export function usePostApiChannelsToken(
  options: {
    mutation?: UseMutationOptions<
      PostApiChannelsTokenMutationResponseSchema,
      ResponseErrorConfig<PostApiChannelsToken401Schema | PostApiChannelsToken422Schema | PostApiChannelsToken503Schema>
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiChannelsTokenMutationKey();

  return useMutation<
    PostApiChannelsTokenMutationResponseSchema,
    ResponseErrorConfig<PostApiChannelsToken401Schema | PostApiChannelsToken422Schema | PostApiChannelsToken503Schema>
  >({
    mutationFn: async () => {
      return postApiChannelsToken(config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/channels/messages/{message_id}/save_attachments' }] as const;

export type PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationKey
>;

/**
 * @summary Save message attachments to project gallery
 * {@link /api/projects/:project_id/channels/messages/:message_id/save_attachments}
 */
export function usePostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments401Schema
        | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments403Schema
        | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments404Schema
        | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsPathParamsSchema['project_id'];
        messageId: PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsPathParamsSchema['message_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments401Schema
      | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments403Schema
      | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments404Schema
      | PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsPathParamsSchema['project_id'];
      messageId: PostApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachmentsPathParamsSchema['message_id'];
    }
  >({
    mutationFn: async ({ projectId, messageId }) => {
      return postApiProjectsProjectIdChannelsMessagesMessageIdSaveAttachments(projectId, messageId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdCommentsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/comments' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdCommentsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdCommentsMutationKey
>;

/**
 * @summary Creates a comment
 * {@link /api/projects/:project_id/issues/:issue_id/comments}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdComments(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdCommentsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdComments401Schema
        | PostApiProjectsProjectIdIssuesIssueIdComments403Schema
        | PostApiProjectsProjectIdIssuesIssueIdComments404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdCommentsPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdCommentsPathParamsSchema['issue_id'];
        data: PostApiProjectsProjectIdIssuesIssueIdCommentsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdCommentsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdCommentsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdCommentsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdComments401Schema
      | PostApiProjectsProjectIdIssuesIssueIdComments403Schema
      | PostApiProjectsProjectIdIssuesIssueIdComments404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdCommentsPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdCommentsPathParamsSchema['issue_id'];
      data: PostApiProjectsProjectIdIssuesIssueIdCommentsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdComments(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/comments/{comment_id}' }] as const;

export type DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationKey
>;

/**
 * @summary Deletes a comment
 * {@link /api/projects/:project_id/issues/:issue_id/comments/:comment_id}
 */
export function useDeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId401Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId403Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId404Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId422Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema['project_id'];
        issueId: DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema['issue_id'];
        commentId: DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema['comment_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId401Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId403Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId404Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId422Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema['project_id'];
      issueId: DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema['issue_id'];
      commentId: DeleteApiProjectsProjectIdIssuesIssueIdCommentsCommentIdPathParamsSchema['comment_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId, commentId }) => {
      return deleteApiProjectsProjectIdIssuesIssueIdCommentsCommentId(projectId, issueId, commentId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiConstructionRolesQueryKey = () => [{ url: '/api/construction_roles' }] as const;

export type GetApiConstructionRolesQueryKey = ReturnType<typeof getApiConstructionRolesQueryKey>;

export function getApiConstructionRolesQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiConstructionRolesQueryKey();
  return queryOptions<
    GetApiConstructionRolesQueryResponseSchema,
    ResponseErrorConfig<GetApiConstructionRoles401Schema>,
    GetApiConstructionRolesQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiConstructionRoles(config);
    },
  });
}

/**
 * @summary Retrieves construction roles
 * {@link /api/construction_roles}
 */
export function useGetApiConstructionRoles<
  TData = GetApiConstructionRolesQueryResponseSchema,
  TQueryData = GetApiConstructionRolesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiConstructionRolesQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiConstructionRolesQueryResponseSchema,
        ResponseErrorConfig<GetApiConstructionRoles401Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiConstructionRolesQueryKey();

  const query = useQuery({
    ...(getApiConstructionRolesQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiConstructionRoles401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiConstructionRolesSuspenseQueryKey = () => [{ url: '/api/construction_roles' }] as const;

export type GetApiConstructionRolesSuspenseQueryKey = ReturnType<typeof getApiConstructionRolesSuspenseQueryKey>;

export function getApiConstructionRolesSuspenseQueryOptions(
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiConstructionRolesSuspenseQueryKey();
  return queryOptions<
    GetApiConstructionRolesQueryResponseSchema,
    ResponseErrorConfig<GetApiConstructionRoles401Schema>,
    GetApiConstructionRolesQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiConstructionRoles(config);
    },
  });
}

/**
 * @summary Retrieves construction roles
 * {@link /api/construction_roles}
 */
export function useGetApiConstructionRolesSuspense<
  TData = GetApiConstructionRolesQueryResponseSchema,
  TQueryData = GetApiConstructionRolesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiConstructionRolesSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiConstructionRolesQueryResponseSchema,
        ResponseErrorConfig<GetApiConstructionRoles401Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiConstructionRolesSuspenseQueryKey();

  const query = useSuspenseQuery({
    ...(getApiConstructionRolesSuspenseQueryOptions(config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiConstructionRoles401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdControlCenterPotentialChangesQueryKey = (
  projectId: GetApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdControlCenterPotentialChangesQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/control_center/potential_changes', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdControlCenterPotentialChangesQueryKey = ReturnType<
  typeof getApiProjectsProjectIdControlCenterPotentialChangesQueryKey
>;

export function getApiProjectsProjectIdControlCenterPotentialChangesQueryOptions(
  projectId: GetApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdControlCenterPotentialChangesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdControlCenterPotentialChangesQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdControlCenterPotentialChanges401Schema
      | GetApiProjectsProjectIdControlCenterPotentialChanges404Schema
    >,
    GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdControlCenterPotentialChanges(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves potential changes
 * {@link /api/projects/:project_id/control_center/potential_changes}
 */
export function useGetApiProjectsProjectIdControlCenterPotentialChanges<
  TData = GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdControlCenterPotentialChangesQueryKey,
>(
  projectId: GetApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdControlCenterPotentialChangesQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdControlCenterPotentialChanges401Schema
          | GetApiProjectsProjectIdControlCenterPotentialChanges404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdControlCenterPotentialChangesQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdControlCenterPotentialChangesQueryOptions(
      projectId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdControlCenterPotentialChanges401Schema
      | GetApiProjectsProjectIdControlCenterPotentialChanges404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdControlCenterPotentialChangesSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdControlCenterPotentialChangesQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/control_center/potential_changes', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdControlCenterPotentialChangesSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdControlCenterPotentialChangesSuspenseQueryKey
>;

export function getApiProjectsProjectIdControlCenterPotentialChangesSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdControlCenterPotentialChangesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdControlCenterPotentialChangesSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdControlCenterPotentialChanges401Schema
      | GetApiProjectsProjectIdControlCenterPotentialChanges404Schema
    >,
    GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdControlCenterPotentialChanges(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves potential changes
 * {@link /api/projects/:project_id/control_center/potential_changes}
 */
export function useGetApiProjectsProjectIdControlCenterPotentialChangesSuspense<
  TData = GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdControlCenterPotentialChangesSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdControlCenterPotentialChangesQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdControlCenterPotentialChangesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdControlCenterPotentialChanges401Schema
          | GetApiProjectsProjectIdControlCenterPotentialChanges404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdControlCenterPotentialChangesSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdControlCenterPotentialChangesSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdControlCenterPotentialChanges401Schema
      | GetApiProjectsProjectIdControlCenterPotentialChanges404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdControlCenterPotentialChangesMutationKey = () =>
  [{ url: '/api/projects/{project_id}/control_center/potential_changes' }] as const;

export type PostApiProjectsProjectIdControlCenterPotentialChangesMutationKey = ReturnType<
  typeof postApiProjectsProjectIdControlCenterPotentialChangesMutationKey
>;

/**
 * @summary Creates a potential change
 * {@link /api/projects/:project_id/control_center/potential_changes}
 */
export function usePostApiProjectsProjectIdControlCenterPotentialChanges(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdControlCenterPotentialChangesMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdControlCenterPotentialChanges401Schema
        | PostApiProjectsProjectIdControlCenterPotentialChanges404Schema
        | PostApiProjectsProjectIdControlCenterPotentialChanges422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdControlCenterPotentialChangesMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdControlCenterPotentialChangesMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdControlCenterPotentialChangesMutationKey();

  return useMutation<
    PostApiProjectsProjectIdControlCenterPotentialChangesMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdControlCenterPotentialChanges401Schema
      | PostApiProjectsProjectIdControlCenterPotentialChanges404Schema
      | PostApiProjectsProjectIdControlCenterPotentialChanges422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdControlCenterPotentialChangesPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdControlCenterPotentialChangesMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdControlCenterPotentialChanges(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/control_center/potential_changes/{potential_change_id}' }] as const;

export type PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationKey
>;

/**
 * @summary Updates a potential change
 * {@link /api/projects/:project_id/control_center/potential_changes/:potential_change_id}
 */
export function usePatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId401Schema
        | PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId403Schema
        | PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId404Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdPathParamsSchema['project_id'];
        potentialChangeId: PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdPathParamsSchema['potential_change_id'];
        data?: PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    patchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId401Schema
      | PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId403Schema
      | PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId404Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdPathParamsSchema['project_id'];
      potentialChangeId: PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdPathParamsSchema['potential_change_id'];
      data?: PatchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, potentialChangeId, data }) => {
      return patchApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeId(
        projectId,
        potentialChangeId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveMutationKey = () =>
  [{ url: '/api/projects/{project_id}/control_center/potential_changes/{potential_change_id}/archive' }] as const;

export type PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveMutationKey = ReturnType<
  typeof postApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveMutationKey
>;

/**
 * @summary Archives a potential change
 * {@link /api/projects/:project_id/control_center/potential_changes/:potential_change_id/archive}
 */
export function usePostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive401Schema
        | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive403Schema
        | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive404Schema
        | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchivePathParamsSchema['project_id'];
        potentialChangeId: PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchivePathParamsSchema['potential_change_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    postApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveMutationKey();

  return useMutation<
    PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchiveMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive401Schema
      | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive403Schema
      | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive404Schema
      | PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchivePathParamsSchema['project_id'];
      potentialChangeId: PostApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchivePathParamsSchema['potential_change_id'];
    }
  >({
    mutationFn: async ({ projectId, potentialChangeId }) => {
      return postApiProjectsProjectIdControlCenterPotentialChangesPotentialChangeIdArchive(
        projectId,
        potentialChangeId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdCustomFieldsQueryKey = (
  projectId: GetApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id']
) => [{ url: '/api/projects/:project_id/custom_fields', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdCustomFieldsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdCustomFieldsQueryKey
>;

export function getApiProjectsProjectIdCustomFieldsQueryOptions(
  projectId: GetApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdCustomFieldsQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdCustomFields401Schema
      | GetApiProjectsProjectIdCustomFields403Schema
      | GetApiProjectsProjectIdCustomFields404Schema
    >,
    GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdCustomFields(projectId, config);
    },
  });
}

/**
 * @summary Retrieves project custom fields
 * {@link /api/projects/:project_id/custom_fields}
 */
export function useGetApiProjectsProjectIdCustomFields<
  TData = GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdCustomFieldsQueryKey,
>(
  projectId: GetApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdCustomFields401Schema
          | GetApiProjectsProjectIdCustomFields403Schema
          | GetApiProjectsProjectIdCustomFields404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdCustomFieldsQueryKey(projectId);

  const query = useQuery({
    ...(getApiProjectsProjectIdCustomFieldsQueryOptions(projectId, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdCustomFields401Schema
      | GetApiProjectsProjectIdCustomFields403Schema
      | GetApiProjectsProjectIdCustomFields404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdCustomFieldsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id']
) => [{ url: '/api/projects/:project_id/custom_fields', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdCustomFieldsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdCustomFieldsSuspenseQueryKey
>;

export function getApiProjectsProjectIdCustomFieldsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdCustomFieldsSuspenseQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdCustomFields401Schema
      | GetApiProjectsProjectIdCustomFields403Schema
      | GetApiProjectsProjectIdCustomFields404Schema
    >,
    GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdCustomFields(projectId, config);
    },
  });
}

/**
 * @summary Retrieves project custom fields
 * {@link /api/projects/:project_id/custom_fields}
 */
export function useGetApiProjectsProjectIdCustomFieldsSuspense<
  TData = GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdCustomFieldsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdCustomFieldsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdCustomFields401Schema
          | GetApiProjectsProjectIdCustomFields403Schema
          | GetApiProjectsProjectIdCustomFields404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdCustomFieldsSuspenseQueryKey(projectId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdCustomFieldsSuspenseQueryOptions(
      projectId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdCustomFields401Schema
      | GetApiProjectsProjectIdCustomFields403Schema
      | GetApiProjectsProjectIdCustomFields404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdCustomFieldsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/custom_fields' }] as const;

export type PostApiProjectsProjectIdCustomFieldsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdCustomFieldsMutationKey
>;

/**
 * @summary Creates a custom field
 * {@link /api/projects/:project_id/custom_fields}
 */
export function usePostApiProjectsProjectIdCustomFields(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdCustomFieldsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdCustomFields401Schema
        | PostApiProjectsProjectIdCustomFields403Schema
        | PostApiProjectsProjectIdCustomFields404Schema
        | PostApiProjectsProjectIdCustomFields422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id'];
        data: PostApiProjectsProjectIdCustomFieldsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdCustomFieldsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdCustomFieldsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdCustomFieldsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdCustomFields401Schema
      | PostApiProjectsProjectIdCustomFields403Schema
      | PostApiProjectsProjectIdCustomFields404Schema
      | PostApiProjectsProjectIdCustomFields422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdCustomFieldsPathParamsSchema['project_id'];
      data: PostApiProjectsProjectIdCustomFieldsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdCustomFields(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/custom_fields/{custom_field_id}' }] as const;

export type PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationKey
>;

/**
 * @summary Updates a custom field
 * {@link /api/projects/:project_id/custom_fields/:custom_field_id}
 */
export function usePatchApiProjectsProjectIdCustomFieldsCustomFieldId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdCustomFieldsCustomFieldId401Schema
        | PatchApiProjectsProjectIdCustomFieldsCustomFieldId403Schema
        | PatchApiProjectsProjectIdCustomFieldsCustomFieldId404Schema
        | PatchApiProjectsProjectIdCustomFieldsCustomFieldId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['project_id'];
        customFieldId: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['custom_field_id'];
        data: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdCustomFieldsCustomFieldId401Schema
      | PatchApiProjectsProjectIdCustomFieldsCustomFieldId403Schema
      | PatchApiProjectsProjectIdCustomFieldsCustomFieldId404Schema
      | PatchApiProjectsProjectIdCustomFieldsCustomFieldId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['project_id'];
      customFieldId: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['custom_field_id'];
      data: PatchApiProjectsProjectIdCustomFieldsCustomFieldIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, customFieldId, data }) => {
      return patchApiProjectsProjectIdCustomFieldsCustomFieldId(projectId, customFieldId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/custom_fields/{custom_field_id}' }] as const;

export type DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationKey
>;

/**
 * @summary Deletes a custom field
 * {@link /api/projects/:project_id/custom_fields/:custom_field_id}
 */
export function useDeleteApiProjectsProjectIdCustomFieldsCustomFieldId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdCustomFieldsCustomFieldId401Schema
        | DeleteApiProjectsProjectIdCustomFieldsCustomFieldId403Schema
        | DeleteApiProjectsProjectIdCustomFieldsCustomFieldId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['project_id'];
        customFieldId: DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['custom_field_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdCustomFieldsCustomFieldId401Schema
      | DeleteApiProjectsProjectIdCustomFieldsCustomFieldId403Schema
      | DeleteApiProjectsProjectIdCustomFieldsCustomFieldId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['project_id'];
      customFieldId: DeleteApiProjectsProjectIdCustomFieldsCustomFieldIdPathParamsSchema['custom_field_id'];
    }
  >({
    mutationFn: async ({ projectId, customFieldId }) => {
      return deleteApiProjectsProjectIdCustomFieldsCustomFieldId(projectId, customFieldId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/custom_fields' }] as const;

export type PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationKey
>;

/**
 * @summary Updates issue custom fields
 * {@link /api/projects/:project_id/issues/:issue_id/custom_fields}
 */
export function usePatchApiProjectsProjectIdIssuesIssueIdCustomFields(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdIssuesIssueIdCustomFields401Schema
        | PatchApiProjectsProjectIdIssuesIssueIdCustomFields403Schema
        | PatchApiProjectsProjectIdIssuesIssueIdCustomFields404Schema
        | PatchApiProjectsProjectIdIssuesIssueIdCustomFields422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsPathParamsSchema['project_id'];
        issueId: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsPathParamsSchema['issue_id'];
        data?: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdIssuesIssueIdCustomFields401Schema
      | PatchApiProjectsProjectIdIssuesIssueIdCustomFields403Schema
      | PatchApiProjectsProjectIdIssuesIssueIdCustomFields404Schema
      | PatchApiProjectsProjectIdIssuesIssueIdCustomFields422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsPathParamsSchema['project_id'];
      issueId: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsPathParamsSchema['issue_id'];
      data?: PatchApiProjectsProjectIdIssuesIssueIdCustomFieldsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return patchApiProjectsProjectIdIssuesIssueIdCustomFields(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryKey = (
  projectId: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['project_id'],
  recordType: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['record_type'],
  params?: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/dashboards/data_health_scores/:record_type',
      params: { projectId: projectId, recordType: recordType },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryKey
>;

export function getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryOptions(
  projectId: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['project_id'],
  recordType: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['record_type'],
  params?: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryKey(projectId, recordType, params);
  return queryOptions<
    GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType401Schema
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType403Schema
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType404Schema
    >,
    GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && recordType),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDashboardsDataHealthScoresRecordType(projectId, recordType, params, config);
    },
  });
}

/**
 * @summary Retrieves data health scores
 * {@link /api/projects/:project_id/dashboards/data_health_scores/:record_type}
 */
export function useGetApiProjectsProjectIdDashboardsDataHealthScoresRecordType<
  TData = GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryKey,
>(
  projectId: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['project_id'],
  recordType: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['record_type'],
  params?: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType401Schema
          | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType403Schema
          | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryKey(projectId, recordType, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryOptions(
      projectId,
      recordType,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType401Schema
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType403Schema
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['project_id'],
  recordType: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['record_type'],
  params?: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/dashboards/data_health_scores/:record_type',
      params: { projectId: projectId, recordType: recordType },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSuspenseQueryKey
>;

export function getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['project_id'],
  recordType: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['record_type'],
  params?: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSuspenseQueryKey(
    projectId,
    recordType,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType401Schema
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType403Schema
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType404Schema
    >,
    GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && recordType),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDashboardsDataHealthScoresRecordType(projectId, recordType, params, config);
    },
  });
}

/**
 * @summary Retrieves data health scores
 * {@link /api/projects/:project_id/dashboards/data_health_scores/:record_type}
 */
export function useGetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSuspense<
  TData = GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['project_id'],
  recordType: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypePathParamsSchema['record_type'],
  params?: GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType401Schema
          | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType403Schema
          | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSuspenseQueryKey(projectId, recordType, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdDashboardsDataHealthScoresRecordTypeSuspenseQueryOptions(
      projectId,
      recordType,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType401Schema
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType403Schema
      | GetApiProjectsProjectIdDashboardsDataHealthScoresRecordType404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiDirectUploadsTypeMutationKey = () => [{ url: '/api/direct_uploads/{type}' }] as const;

export type PostApiDirectUploadsTypeMutationKey = ReturnType<typeof postApiDirectUploadsTypeMutationKey>;

/**
 * @summary Creates a direct upload
 * {@link /api/direct_uploads/:type}
 */
export function usePostApiDirectUploadsType(
  options: {
    mutation?: UseMutationOptions<
      PostApiDirectUploadsTypeMutationResponseSchema,
      ResponseErrorConfig<
        PostApiDirectUploadsType400Schema | PostApiDirectUploadsType401Schema | PostApiDirectUploadsType404Schema
      >,
      { type: PostApiDirectUploadsTypePathParamsSchema['type']; data: PostApiDirectUploadsTypeMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiDirectUploadsTypeMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiDirectUploadsTypeMutationKey();

  return useMutation<
    PostApiDirectUploadsTypeMutationResponseSchema,
    ResponseErrorConfig<
      PostApiDirectUploadsType400Schema | PostApiDirectUploadsType401Schema | PostApiDirectUploadsType404Schema
    >,
    { type: PostApiDirectUploadsTypePathParamsSchema['type']; data: PostApiDirectUploadsTypeMutationRequestSchema }
  >({
    mutationFn: async ({ type, data }) => {
      return postApiDirectUploadsType(type, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdDisciplinesQueryKey = (
  projectId: GetApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDisciplinesQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/disciplines', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdDisciplinesQueryKey = ReturnType<typeof getApiProjectsProjectIdDisciplinesQueryKey>;

export function getApiProjectsProjectIdDisciplinesQueryOptions(
  projectId: GetApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDisciplinesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDisciplinesQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDisciplines401Schema
      | GetApiProjectsProjectIdDisciplines403Schema
      | GetApiProjectsProjectIdDisciplines404Schema
    >,
    GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDisciplines(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves disciplines
 * {@link /api/projects/:project_id/disciplines}
 */
export function useGetApiProjectsProjectIdDisciplines<
  TData = GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDisciplinesQueryKey,
>(
  projectId: GetApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDisciplinesQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDisciplines401Schema
          | GetApiProjectsProjectIdDisciplines403Schema
          | GetApiProjectsProjectIdDisciplines404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdDisciplinesQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdDisciplinesQueryOptions(projectId, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDisciplines401Schema
      | GetApiProjectsProjectIdDisciplines403Schema
      | GetApiProjectsProjectIdDisciplines404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDisciplinesSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDisciplinesQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/disciplines', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdDisciplinesSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDisciplinesSuspenseQueryKey
>;

export function getApiProjectsProjectIdDisciplinesSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDisciplinesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDisciplinesSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDisciplines401Schema
      | GetApiProjectsProjectIdDisciplines403Schema
      | GetApiProjectsProjectIdDisciplines404Schema
    >,
    GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDisciplines(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves disciplines
 * {@link /api/projects/:project_id/disciplines}
 */
export function useGetApiProjectsProjectIdDisciplinesSuspense<
  TData = GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDisciplinesSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDisciplinesQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdDisciplinesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDisciplines401Schema
          | GetApiProjectsProjectIdDisciplines403Schema
          | GetApiProjectsProjectIdDisciplines404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdDisciplinesSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdDisciplinesSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDisciplines401Schema
      | GetApiProjectsProjectIdDisciplines403Schema
      | GetApiProjectsProjectIdDisciplines404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdDisciplinesMutationKey = () =>
  [{ url: '/api/projects/{project_id}/disciplines' }] as const;

export type PostApiProjectsProjectIdDisciplinesMutationKey = ReturnType<
  typeof postApiProjectsProjectIdDisciplinesMutationKey
>;

/**
 * @summary Creates a discipline
 * {@link /api/projects/:project_id/disciplines}
 */
export function usePostApiProjectsProjectIdDisciplines(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdDisciplinesMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdDisciplines401Schema
        | PostApiProjectsProjectIdDisciplines403Schema
        | PostApiProjectsProjectIdDisciplines404Schema
        | PostApiProjectsProjectIdDisciplines422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdDisciplinesMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdDisciplinesMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdDisciplinesMutationKey();

  return useMutation<
    PostApiProjectsProjectIdDisciplinesMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdDisciplines401Schema
      | PostApiProjectsProjectIdDisciplines403Schema
      | PostApiProjectsProjectIdDisciplines404Schema
      | PostApiProjectsProjectIdDisciplines422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdDisciplinesPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdDisciplinesMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdDisciplines(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdDisciplinesDisciplineIdQueryKey = (
  projectId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'],
  disciplineId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id']
) =>
  [
    {
      url: '/api/projects/:project_id/disciplines/:discipline_id',
      params: { projectId: projectId, disciplineId: disciplineId },
    },
  ] as const;

export type GetApiProjectsProjectIdDisciplinesDisciplineIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDisciplinesDisciplineIdQueryKey
>;

export function getApiProjectsProjectIdDisciplinesDisciplineIdQueryOptions(
  projectId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'],
  disciplineId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDisciplinesDisciplineIdQueryKey(projectId, disciplineId);
  return queryOptions<
    GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDisciplinesDisciplineId401Schema
      | GetApiProjectsProjectIdDisciplinesDisciplineId403Schema
      | GetApiProjectsProjectIdDisciplinesDisciplineId404Schema
    >,
    GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && disciplineId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDisciplinesDisciplineId(projectId, disciplineId, config);
    },
  });
}

/**
 * @summary Retrieves a discipline
 * {@link /api/projects/:project_id/disciplines/:discipline_id}
 */
export function useGetApiProjectsProjectIdDisciplinesDisciplineId<
  TData = GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDisciplinesDisciplineIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'],
  disciplineId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDisciplinesDisciplineId401Schema
          | GetApiProjectsProjectIdDisciplinesDisciplineId403Schema
          | GetApiProjectsProjectIdDisciplinesDisciplineId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdDisciplinesDisciplineIdQueryKey(projectId, disciplineId);

  const query = useQuery({
    ...(getApiProjectsProjectIdDisciplinesDisciplineIdQueryOptions(
      projectId,
      disciplineId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDisciplinesDisciplineId401Schema
      | GetApiProjectsProjectIdDisciplinesDisciplineId403Schema
      | GetApiProjectsProjectIdDisciplinesDisciplineId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDisciplinesDisciplineIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'],
  disciplineId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id']
) =>
  [
    {
      url: '/api/projects/:project_id/disciplines/:discipline_id',
      params: { projectId: projectId, disciplineId: disciplineId },
    },
  ] as const;

export type GetApiProjectsProjectIdDisciplinesDisciplineIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDisciplinesDisciplineIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdDisciplinesDisciplineIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'],
  disciplineId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDisciplinesDisciplineIdSuspenseQueryKey(projectId, disciplineId);
  return queryOptions<
    GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDisciplinesDisciplineId401Schema
      | GetApiProjectsProjectIdDisciplinesDisciplineId403Schema
      | GetApiProjectsProjectIdDisciplinesDisciplineId404Schema
    >,
    GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && disciplineId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDisciplinesDisciplineId(projectId, disciplineId, config);
    },
  });
}

/**
 * @summary Retrieves a discipline
 * {@link /api/projects/:project_id/disciplines/:discipline_id}
 */
export function useGetApiProjectsProjectIdDisciplinesDisciplineIdSuspense<
  TData = GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDisciplinesDisciplineIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'],
  disciplineId: GetApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdDisciplinesDisciplineIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDisciplinesDisciplineId401Schema
          | GetApiProjectsProjectIdDisciplinesDisciplineId403Schema
          | GetApiProjectsProjectIdDisciplinesDisciplineId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdDisciplinesDisciplineIdSuspenseQueryKey(projectId, disciplineId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdDisciplinesDisciplineIdSuspenseQueryOptions(
      projectId,
      disciplineId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDisciplinesDisciplineId401Schema
      | GetApiProjectsProjectIdDisciplinesDisciplineId403Schema
      | GetApiProjectsProjectIdDisciplinesDisciplineId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdDisciplinesDisciplineIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/disciplines/{discipline_id}' }] as const;

export type PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdDisciplinesDisciplineIdMutationKey
>;

/**
 * @summary Updates a discipline
 * {@link /api/projects/:project_id/disciplines/:discipline_id}
 */
export function usePatchApiProjectsProjectIdDisciplinesDisciplineId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdDisciplinesDisciplineId401Schema
        | PatchApiProjectsProjectIdDisciplinesDisciplineId403Schema
        | PatchApiProjectsProjectIdDisciplinesDisciplineId404Schema
        | PatchApiProjectsProjectIdDisciplinesDisciplineId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'];
        disciplineId: PatchApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'];
        data?: PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdDisciplinesDisciplineIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdDisciplinesDisciplineId401Schema
      | PatchApiProjectsProjectIdDisciplinesDisciplineId403Schema
      | PatchApiProjectsProjectIdDisciplinesDisciplineId404Schema
      | PatchApiProjectsProjectIdDisciplinesDisciplineId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'];
      disciplineId: PatchApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'];
      data?: PatchApiProjectsProjectIdDisciplinesDisciplineIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, disciplineId, data }) => {
      return patchApiProjectsProjectIdDisciplinesDisciplineId(projectId, disciplineId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdDisciplinesDisciplineIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/disciplines/{discipline_id}' }] as const;

export type DeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdDisciplinesDisciplineIdMutationKey
>;

/**
 * @summary Deletes a discipline
 * {@link /api/projects/:project_id/disciplines/:discipline_id}
 */
export function useDeleteApiProjectsProjectIdDisciplinesDisciplineId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdDisciplinesDisciplineId401Schema
        | DeleteApiProjectsProjectIdDisciplinesDisciplineId403Schema
        | DeleteApiProjectsProjectIdDisciplinesDisciplineId404Schema
        | DeleteApiProjectsProjectIdDisciplinesDisciplineId422Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'];
        disciplineId: DeleteApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdDisciplinesDisciplineIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdDisciplinesDisciplineIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdDisciplinesDisciplineId401Schema
      | DeleteApiProjectsProjectIdDisciplinesDisciplineId403Schema
      | DeleteApiProjectsProjectIdDisciplinesDisciplineId404Schema
      | DeleteApiProjectsProjectIdDisciplinesDisciplineId422Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['project_id'];
      disciplineId: DeleteApiProjectsProjectIdDisciplinesDisciplineIdPathParamsSchema['discipline_id'];
    }
  >({
    mutationFn: async ({ projectId, disciplineId }) => {
      return deleteApiProjectsProjectIdDisciplinesDisciplineId(projectId, disciplineId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdDisciplinesSortMutationKey = () =>
  [{ url: '/api/projects/{project_id}/disciplines/sort' }] as const;

export type PostApiProjectsProjectIdDisciplinesSortMutationKey = ReturnType<
  typeof postApiProjectsProjectIdDisciplinesSortMutationKey
>;

/**
 * @summary Sorts disciplines
 * {@link /api/projects/:project_id/disciplines/sort}
 */
export function usePostApiProjectsProjectIdDisciplinesSort(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdDisciplinesSortMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdDisciplinesSort401Schema
        | PostApiProjectsProjectIdDisciplinesSort403Schema
        | PostApiProjectsProjectIdDisciplinesSort404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdDisciplinesSortPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdDisciplinesSortMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdDisciplinesSortMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdDisciplinesSortMutationKey();

  return useMutation<
    PostApiProjectsProjectIdDisciplinesSortMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdDisciplinesSort401Schema
      | PostApiProjectsProjectIdDisciplinesSort403Schema
      | PostApiProjectsProjectIdDisciplinesSort404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdDisciplinesSortPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdDisciplinesSortMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdDisciplinesSort(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdDocumentsDocumentIdReferencesQueryKey = (
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['document_id']
) =>
  [
    {
      url: '/api/projects/:project_id/documents/:document_id/references',
      params: { projectId: projectId, documentId: documentId },
    },
  ] as const;

export type GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDocumentsDocumentIdReferencesQueryKey
>;

export function getApiProjectsProjectIdDocumentsDocumentIdReferencesQueryOptions(
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['document_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDocumentsDocumentIdReferencesQueryKey(projectId, documentId);
  return queryOptions<
    GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences401Schema
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences403Schema
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences404Schema
    >,
    GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && documentId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDocumentsDocumentIdReferences(projectId, documentId, config);
    },
  });
}

/**
 * @summary Retrieves document references
 * {@link /api/projects/:project_id/documents/:document_id/references}
 */
export function useGetApiProjectsProjectIdDocumentsDocumentIdReferences<
  TData = GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryKey,
>(
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['document_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDocumentsDocumentIdReferences401Schema
          | GetApiProjectsProjectIdDocumentsDocumentIdReferences403Schema
          | GetApiProjectsProjectIdDocumentsDocumentIdReferences404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdDocumentsDocumentIdReferencesQueryKey(projectId, documentId);

  const query = useQuery({
    ...(getApiProjectsProjectIdDocumentsDocumentIdReferencesQueryOptions(
      projectId,
      documentId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences401Schema
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences403Schema
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDocumentsDocumentIdReferencesSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['document_id']
) =>
  [
    {
      url: '/api/projects/:project_id/documents/:document_id/references',
      params: { projectId: projectId, documentId: documentId },
    },
  ] as const;

export type GetApiProjectsProjectIdDocumentsDocumentIdReferencesSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDocumentsDocumentIdReferencesSuspenseQueryKey
>;

export function getApiProjectsProjectIdDocumentsDocumentIdReferencesSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['document_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDocumentsDocumentIdReferencesSuspenseQueryKey(projectId, documentId);
  return queryOptions<
    GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences401Schema
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences403Schema
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences404Schema
    >,
    GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && documentId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDocumentsDocumentIdReferences(projectId, documentId, config);
    },
  });
}

/**
 * @summary Retrieves document references
 * {@link /api/projects/:project_id/documents/:document_id/references}
 */
export function useGetApiProjectsProjectIdDocumentsDocumentIdReferencesSuspense<
  TData = GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDocumentsDocumentIdReferencesSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdReferencesPathParamsSchema['document_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdDocumentsDocumentIdReferencesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDocumentsDocumentIdReferences401Schema
          | GetApiProjectsProjectIdDocumentsDocumentIdReferences403Schema
          | GetApiProjectsProjectIdDocumentsDocumentIdReferences404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdDocumentsDocumentIdReferencesSuspenseQueryKey(projectId, documentId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdDocumentsDocumentIdReferencesSuspenseQueryOptions(
      projectId,
      documentId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences401Schema
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences403Schema
      | GetApiProjectsProjectIdDocumentsDocumentIdReferences404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDocumentsQueryKey = (
  projectId: GetApiProjectsProjectIdDocumentsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDocumentsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/documents', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdDocumentsQueryKey = ReturnType<typeof getApiProjectsProjectIdDocumentsQueryKey>;

export function getApiProjectsProjectIdDocumentsQueryOptions(
  projectId: GetApiProjectsProjectIdDocumentsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDocumentsQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocuments400Schema
      | GetApiProjectsProjectIdDocuments401Schema
      | GetApiProjectsProjectIdDocuments403Schema
      | GetApiProjectsProjectIdDocuments404Schema
    >,
    GetApiProjectsProjectIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDocuments(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves documents
 * {@link /api/projects/:project_id/documents}
 */
export function useGetApiProjectsProjectIdDocuments<
  TData = GetApiProjectsProjectIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDocumentsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDocumentsQueryKey,
>(
  projectId: GetApiProjectsProjectIdDocumentsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDocuments400Schema
          | GetApiProjectsProjectIdDocuments401Schema
          | GetApiProjectsProjectIdDocuments403Schema
          | GetApiProjectsProjectIdDocuments404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdDocumentsQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdDocumentsQueryOptions(projectId, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocuments400Schema
      | GetApiProjectsProjectIdDocuments401Schema
      | GetApiProjectsProjectIdDocuments403Schema
      | GetApiProjectsProjectIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDocumentsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdDocumentsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDocumentsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/documents', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdDocumentsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDocumentsSuspenseQueryKey
>;

export function getApiProjectsProjectIdDocumentsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdDocumentsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDocumentsSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocuments400Schema
      | GetApiProjectsProjectIdDocuments401Schema
      | GetApiProjectsProjectIdDocuments403Schema
      | GetApiProjectsProjectIdDocuments404Schema
    >,
    GetApiProjectsProjectIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDocuments(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves documents
 * {@link /api/projects/:project_id/documents}
 */
export function useGetApiProjectsProjectIdDocumentsSuspense<
  TData = GetApiProjectsProjectIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDocumentsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDocumentsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdDocumentsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDocuments400Schema
          | GetApiProjectsProjectIdDocuments401Schema
          | GetApiProjectsProjectIdDocuments403Schema
          | GetApiProjectsProjectIdDocuments404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdDocumentsSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdDocumentsSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocuments400Schema
      | GetApiProjectsProjectIdDocuments401Schema
      | GetApiProjectsProjectIdDocuments403Schema
      | GetApiProjectsProjectIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdDocumentsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/documents' }] as const;

export type PostApiProjectsProjectIdDocumentsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdDocumentsMutationKey
>;

/**
 * @summary Creates a document
 * {@link /api/projects/:project_id/documents}
 */
export function usePostApiProjectsProjectIdDocuments(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdDocumentsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdDocuments400Schema
        | PostApiProjectsProjectIdDocuments401Schema
        | PostApiProjectsProjectIdDocuments403Schema
        | PostApiProjectsProjectIdDocuments404Schema
        | PostApiProjectsProjectIdDocuments422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdDocumentsPathParamsSchema['project_id'];
        data: PostApiProjectsProjectIdDocumentsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdDocumentsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdDocumentsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdDocumentsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdDocuments400Schema
      | PostApiProjectsProjectIdDocuments401Schema
      | PostApiProjectsProjectIdDocuments403Schema
      | PostApiProjectsProjectIdDocuments404Schema
      | PostApiProjectsProjectIdDocuments422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdDocumentsPathParamsSchema['project_id'];
      data: PostApiProjectsProjectIdDocumentsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdDocuments(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdDocumentsDocumentIdQueryKey = (
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id']
) =>
  [
    {
      url: '/api/projects/:project_id/documents/:document_id',
      params: { projectId: projectId, documentId: documentId },
    },
  ] as const;

export type GetApiProjectsProjectIdDocumentsDocumentIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDocumentsDocumentIdQueryKey
>;

export function getApiProjectsProjectIdDocumentsDocumentIdQueryOptions(
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDocumentsDocumentIdQueryKey(projectId, documentId);
  return queryOptions<
    GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocumentsDocumentId401Schema
      | GetApiProjectsProjectIdDocumentsDocumentId403Schema
      | GetApiProjectsProjectIdDocumentsDocumentId404Schema
    >,
    GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && documentId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDocumentsDocumentId(projectId, documentId, config);
    },
  });
}

/**
 * @summary Retrieves a document
 * {@link /api/projects/:project_id/documents/:document_id}
 */
export function useGetApiProjectsProjectIdDocumentsDocumentId<
  TData = GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDocumentsDocumentIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDocumentsDocumentId401Schema
          | GetApiProjectsProjectIdDocumentsDocumentId403Schema
          | GetApiProjectsProjectIdDocumentsDocumentId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdDocumentsDocumentIdQueryKey(projectId, documentId);

  const query = useQuery({
    ...(getApiProjectsProjectIdDocumentsDocumentIdQueryOptions(
      projectId,
      documentId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocumentsDocumentId401Schema
      | GetApiProjectsProjectIdDocumentsDocumentId403Schema
      | GetApiProjectsProjectIdDocumentsDocumentId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDocumentsDocumentIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id']
) =>
  [
    {
      url: '/api/projects/:project_id/documents/:document_id',
      params: { projectId: projectId, documentId: documentId },
    },
  ] as const;

export type GetApiProjectsProjectIdDocumentsDocumentIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDocumentsDocumentIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdDocumentsDocumentIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDocumentsDocumentIdSuspenseQueryKey(projectId, documentId);
  return queryOptions<
    GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocumentsDocumentId401Schema
      | GetApiProjectsProjectIdDocumentsDocumentId403Schema
      | GetApiProjectsProjectIdDocumentsDocumentId404Schema
    >,
    GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && documentId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDocumentsDocumentId(projectId, documentId, config);
    },
  });
}

/**
 * @summary Retrieves a document
 * {@link /api/projects/:project_id/documents/:document_id}
 */
export function useGetApiProjectsProjectIdDocumentsDocumentIdSuspense<
  TData = GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDocumentsDocumentIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'],
  documentId: GetApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdDocumentsDocumentIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDocumentsDocumentId401Schema
          | GetApiProjectsProjectIdDocumentsDocumentId403Schema
          | GetApiProjectsProjectIdDocumentsDocumentId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdDocumentsDocumentIdSuspenseQueryKey(projectId, documentId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdDocumentsDocumentIdSuspenseQueryOptions(
      projectId,
      documentId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDocumentsDocumentId401Schema
      | GetApiProjectsProjectIdDocumentsDocumentId403Schema
      | GetApiProjectsProjectIdDocumentsDocumentId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdDocumentsDocumentIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/documents/{document_id}' }] as const;

export type PatchApiProjectsProjectIdDocumentsDocumentIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdDocumentsDocumentIdMutationKey
>;

/**
 * @summary Updates a document
 * {@link /api/projects/:project_id/documents/:document_id}
 */
export function usePatchApiProjectsProjectIdDocumentsDocumentId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdDocumentsDocumentId401Schema
        | PatchApiProjectsProjectIdDocumentsDocumentId403Schema
        | PatchApiProjectsProjectIdDocumentsDocumentId404Schema
        | PatchApiProjectsProjectIdDocumentsDocumentId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'];
        documentId: PatchApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'];
        data?: PatchApiProjectsProjectIdDocumentsDocumentIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdDocumentsDocumentIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdDocumentsDocumentIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdDocumentsDocumentId401Schema
      | PatchApiProjectsProjectIdDocumentsDocumentId403Schema
      | PatchApiProjectsProjectIdDocumentsDocumentId404Schema
      | PatchApiProjectsProjectIdDocumentsDocumentId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'];
      documentId: PatchApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'];
      data?: PatchApiProjectsProjectIdDocumentsDocumentIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, documentId, data }) => {
      return patchApiProjectsProjectIdDocumentsDocumentId(projectId, documentId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdDocumentsDocumentIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/documents/{document_id}' }] as const;

export type DeleteApiProjectsProjectIdDocumentsDocumentIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdDocumentsDocumentIdMutationKey
>;

/**
 * @summary Deletes a document
 * {@link /api/projects/:project_id/documents/:document_id}
 */
export function useDeleteApiProjectsProjectIdDocumentsDocumentId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdDocumentsDocumentId401Schema
        | DeleteApiProjectsProjectIdDocumentsDocumentId403Schema
        | DeleteApiProjectsProjectIdDocumentsDocumentId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'];
        documentId: DeleteApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdDocumentsDocumentIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdDocumentsDocumentIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdDocumentsDocumentId401Schema
      | DeleteApiProjectsProjectIdDocumentsDocumentId403Schema
      | DeleteApiProjectsProjectIdDocumentsDocumentId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['project_id'];
      documentId: DeleteApiProjectsProjectIdDocumentsDocumentIdPathParamsSchema['document_id'];
    }
  >({
    mutationFn: async ({ projectId, documentId }) => {
      return deleteApiProjectsProjectIdDocumentsDocumentId(projectId, documentId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdEventsQueryKey = (
  projectId: GetApiProjectsProjectIdEventsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdEventsQueryParamsSchema
) =>
  [{ url: '/api/projects/:project_id/events', params: { projectId: projectId } }, ...(params ? [params] : [])] as const;

export type GetApiProjectsProjectIdEventsQueryKey = ReturnType<typeof getApiProjectsProjectIdEventsQueryKey>;

export function getApiProjectsProjectIdEventsQueryOptions(
  projectId: GetApiProjectsProjectIdEventsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdEventsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdEventsQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdEventsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdEvents401Schema
      | GetApiProjectsProjectIdEvents403Schema
      | GetApiProjectsProjectIdEvents404Schema
    >,
    GetApiProjectsProjectIdEventsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdEvents(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves events
 * {@link /api/projects/:project_id/events}
 */
export function useGetApiProjectsProjectIdEvents<
  TData = GetApiProjectsProjectIdEventsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdEventsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdEventsQueryKey,
>(
  projectId: GetApiProjectsProjectIdEventsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdEventsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdEventsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdEvents401Schema
          | GetApiProjectsProjectIdEvents403Schema
          | GetApiProjectsProjectIdEvents404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdEventsQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdEventsQueryOptions(projectId, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdEvents401Schema
      | GetApiProjectsProjectIdEvents403Schema
      | GetApiProjectsProjectIdEvents404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdEventsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdEventsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdEventsQueryParamsSchema
) =>
  [{ url: '/api/projects/:project_id/events', params: { projectId: projectId } }, ...(params ? [params] : [])] as const;

export type GetApiProjectsProjectIdEventsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdEventsSuspenseQueryKey
>;

export function getApiProjectsProjectIdEventsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdEventsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdEventsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdEventsSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdEventsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdEvents401Schema
      | GetApiProjectsProjectIdEvents403Schema
      | GetApiProjectsProjectIdEvents404Schema
    >,
    GetApiProjectsProjectIdEventsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdEvents(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves events
 * {@link /api/projects/:project_id/events}
 */
export function useGetApiProjectsProjectIdEventsSuspense<
  TData = GetApiProjectsProjectIdEventsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdEventsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdEventsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdEventsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdEventsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdEventsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdEvents401Schema
          | GetApiProjectsProjectIdEvents403Schema
          | GetApiProjectsProjectIdEvents404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdEventsSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdEventsSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdEvents401Schema
      | GetApiProjectsProjectIdEvents403Schema
      | GetApiProjectsProjectIdEvents404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiFeatureFlagsQueryKey = (params?: GetApiFeatureFlagsQueryParamsSchema) =>
  [{ url: '/api/feature_flags' }, ...(params ? [params] : [])] as const;

export type GetApiFeatureFlagsQueryKey = ReturnType<typeof getApiFeatureFlagsQueryKey>;

export function getApiFeatureFlagsQueryOptions(
  params?: GetApiFeatureFlagsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiFeatureFlagsQueryKey(params);
  return queryOptions<
    GetApiFeatureFlagsQueryResponseSchema,
    ResponseErrorConfig<GetApiFeatureFlags422Schema>,
    GetApiFeatureFlagsQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiFeatureFlags(params, config);
    },
  });
}

/**
 * @summary Retrieves the list of feature flags
 * {@link /api/feature_flags}
 */
export function useGetApiFeatureFlags<
  TData = GetApiFeatureFlagsQueryResponseSchema,
  TQueryData = GetApiFeatureFlagsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiFeatureFlagsQueryKey,
>(
  params?: GetApiFeatureFlagsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiFeatureFlagsQueryResponseSchema,
        ResponseErrorConfig<GetApiFeatureFlags422Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiFeatureFlagsQueryKey(params);

  const query = useQuery({
    ...(getApiFeatureFlagsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiFeatureFlags422Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiFeatureFlagsSuspenseQueryKey = (params?: GetApiFeatureFlagsQueryParamsSchema) =>
  [{ url: '/api/feature_flags' }, ...(params ? [params] : [])] as const;

export type GetApiFeatureFlagsSuspenseQueryKey = ReturnType<typeof getApiFeatureFlagsSuspenseQueryKey>;

export function getApiFeatureFlagsSuspenseQueryOptions(
  params?: GetApiFeatureFlagsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiFeatureFlagsSuspenseQueryKey(params);
  return queryOptions<
    GetApiFeatureFlagsQueryResponseSchema,
    ResponseErrorConfig<GetApiFeatureFlags422Schema>,
    GetApiFeatureFlagsQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiFeatureFlags(params, config);
    },
  });
}

/**
 * @summary Retrieves the list of feature flags
 * {@link /api/feature_flags}
 */
export function useGetApiFeatureFlagsSuspense<
  TData = GetApiFeatureFlagsQueryResponseSchema,
  TQueryData = GetApiFeatureFlagsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiFeatureFlagsSuspenseQueryKey,
>(
  params?: GetApiFeatureFlagsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiFeatureFlagsQueryResponseSchema,
        ResponseErrorConfig<GetApiFeatureFlags422Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiFeatureFlagsSuspenseQueryKey(params);

  const query = useSuspenseQuery({
    ...(getApiFeatureFlagsSuspenseQueryOptions(params, config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiFeatureFlags422Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiFeedbacksMutationKey = () => [{ url: '/api/feedbacks' }] as const;

export type PostApiFeedbacksMutationKey = ReturnType<typeof postApiFeedbacksMutationKey>;

/**
 * @summary Creates a feedback
 * {@link /api/feedbacks}
 */
export function usePostApiFeedbacks(
  options: {
    mutation?: UseMutationOptions<
      PostApiFeedbacksMutationResponseSchema,
      ResponseErrorConfig<PostApiFeedbacks401Schema | PostApiFeedbacks422Schema>,
      { data?: PostApiFeedbacksMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiFeedbacksMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiFeedbacksMutationKey();

  return useMutation<
    PostApiFeedbacksMutationResponseSchema,
    ResponseErrorConfig<PostApiFeedbacks401Schema | PostApiFeedbacks422Schema>,
    { data?: PostApiFeedbacksMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiFeedbacks(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryKey = (
  projectId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id']
) =>
  [
    {
      url: '/api/projects/:project_id/groups/:group_id/channel_configuration',
      params: { projectId: projectId, groupId: groupId },
    },
  ] as const;

export type GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryKey = ReturnType<
  typeof getApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryKey
>;

export function getApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryOptions(
  projectId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryKey(projectId, groupId);
  return queryOptions<
    GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema
    >,
    GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && groupId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdGroupsGroupIdChannelConfiguration(projectId, groupId, config);
    },
  });
}

/**
 * @summary Retrieves the group channel configuration
 * {@link /api/projects/:project_id/groups/:group_id/channel_configuration}
 */
export function useGetApiProjectsProjectIdGroupsGroupIdChannelConfiguration<
  TData = GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryKey,
>(
  projectId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
          | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
          | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryKey(projectId, groupId);

  const query = useQuery({
    ...(getApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryOptions(
      projectId,
      groupId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdGroupsGroupIdChannelConfigurationSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id']
) =>
  [
    {
      url: '/api/projects/:project_id/groups/:group_id/channel_configuration',
      params: { projectId: projectId, groupId: groupId },
    },
  ] as const;

export type GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdGroupsGroupIdChannelConfigurationSuspenseQueryKey
>;

export function getApiProjectsProjectIdGroupsGroupIdChannelConfigurationSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdGroupsGroupIdChannelConfigurationSuspenseQueryKey(projectId, groupId);
  return queryOptions<
    GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema
    >,
    GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && groupId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdGroupsGroupIdChannelConfiguration(projectId, groupId, config);
    },
  });
}

/**
 * @summary Retrieves the group channel configuration
 * {@link /api/projects/:project_id/groups/:group_id/channel_configuration}
 */
export function useGetApiProjectsProjectIdGroupsGroupIdChannelConfigurationSuspense<
  TData = GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdGroupsGroupIdChannelConfigurationQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
          | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
          | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdGroupsGroupIdChannelConfigurationSuspenseQueryKey(projectId, groupId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdGroupsGroupIdChannelConfigurationSuspenseQueryOptions(
      projectId,
      groupId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
      | GetApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationKey = () =>
  [{ url: '/api/projects/{project_id}/groups/{group_id}/channel_configuration' }] as const;

export type PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationKey
>;

/**
 * @summary Updates the group channel configuration
 * {@link /api/projects/:project_id/groups/:group_id/channel_configuration}
 */
export function usePatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
        | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
        | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema
        | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'];
        groupId: PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id'];
        data?: PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? patchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration401Schema
      | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration403Schema
      | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration404Schema
      | PatchApiProjectsProjectIdGroupsGroupIdChannelConfiguration422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['project_id'];
      groupId: PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationPathParamsSchema['group_id'];
      data?: PatchApiProjectsProjectIdGroupsGroupIdChannelConfigurationMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, groupId, data }) => {
      return patchApiProjectsProjectIdGroupsGroupIdChannelConfiguration(projectId, groupId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdGroupsMutationKey = () => [{ url: '/api/projects/{project_id}/groups' }] as const;

export type PostApiProjectsProjectIdGroupsMutationKey = ReturnType<typeof postApiProjectsProjectIdGroupsMutationKey>;

/**
 * @description Authenticated user will be added automatically as a group member and admin of the group.
 * @summary Creates a group
 * {@link /api/projects/:project_id/groups}
 */
export function usePostApiProjectsProjectIdGroups(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdGroupsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdGroups400Schema
        | PostApiProjectsProjectIdGroups401Schema
        | PostApiProjectsProjectIdGroups403Schema
        | PostApiProjectsProjectIdGroups404Schema
        | PostApiProjectsProjectIdGroups422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdGroupsPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdGroupsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdGroupsMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdGroupsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdGroupsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdGroups400Schema
      | PostApiProjectsProjectIdGroups401Schema
      | PostApiProjectsProjectIdGroups403Schema
      | PostApiProjectsProjectIdGroups404Schema
      | PostApiProjectsProjectIdGroups422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdGroupsPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdGroupsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdGroups(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdGroupsGroupIdQueryKey = (
  projectId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id']
) =>
  [{ url: '/api/projects/:project_id/groups/:group_id', params: { projectId: projectId, groupId: groupId } }] as const;

export type GetApiProjectsProjectIdGroupsGroupIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdGroupsGroupIdQueryKey
>;

export function getApiProjectsProjectIdGroupsGroupIdQueryOptions(
  projectId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdGroupsGroupIdQueryKey(projectId, groupId);
  return queryOptions<
    GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdGroupsGroupId401Schema
      | GetApiProjectsProjectIdGroupsGroupId403Schema
      | GetApiProjectsProjectIdGroupsGroupId404Schema
    >,
    GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && groupId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdGroupsGroupId(projectId, groupId, config);
    },
  });
}

/**
 * @summary Retrieves a group
 * {@link /api/projects/:project_id/groups/:group_id}
 */
export function useGetApiProjectsProjectIdGroupsGroupId<
  TData = GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdGroupsGroupIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdGroupsGroupId401Schema
          | GetApiProjectsProjectIdGroupsGroupId403Schema
          | GetApiProjectsProjectIdGroupsGroupId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdGroupsGroupIdQueryKey(projectId, groupId);

  const query = useQuery({
    ...(getApiProjectsProjectIdGroupsGroupIdQueryOptions(
      projectId,
      groupId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdGroupsGroupId401Schema
      | GetApiProjectsProjectIdGroupsGroupId403Schema
      | GetApiProjectsProjectIdGroupsGroupId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdGroupsGroupIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id']
) =>
  [{ url: '/api/projects/:project_id/groups/:group_id', params: { projectId: projectId, groupId: groupId } }] as const;

export type GetApiProjectsProjectIdGroupsGroupIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdGroupsGroupIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdGroupsGroupIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdGroupsGroupIdSuspenseQueryKey(projectId, groupId);
  return queryOptions<
    GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdGroupsGroupId401Schema
      | GetApiProjectsProjectIdGroupsGroupId403Schema
      | GetApiProjectsProjectIdGroupsGroupId404Schema
    >,
    GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && groupId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdGroupsGroupId(projectId, groupId, config);
    },
  });
}

/**
 * @summary Retrieves a group
 * {@link /api/projects/:project_id/groups/:group_id}
 */
export function useGetApiProjectsProjectIdGroupsGroupIdSuspense<
  TData = GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdGroupsGroupIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'],
  groupId: GetApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdGroupsGroupIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdGroupsGroupId401Schema
          | GetApiProjectsProjectIdGroupsGroupId403Schema
          | GetApiProjectsProjectIdGroupsGroupId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdGroupsGroupIdSuspenseQueryKey(projectId, groupId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdGroupsGroupIdSuspenseQueryOptions(
      projectId,
      groupId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdGroupsGroupId401Schema
      | GetApiProjectsProjectIdGroupsGroupId403Schema
      | GetApiProjectsProjectIdGroupsGroupId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdGroupsGroupIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/groups/{group_id}' }] as const;

export type PatchApiProjectsProjectIdGroupsGroupIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdGroupsGroupIdMutationKey
>;

/**
 * @summary Updates a group
 * {@link /api/projects/:project_id/groups/:group_id}
 */
export function usePatchApiProjectsProjectIdGroupsGroupId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdGroupsGroupId400Schema
        | PatchApiProjectsProjectIdGroupsGroupId401Schema
        | PatchApiProjectsProjectIdGroupsGroupId403Schema
        | PatchApiProjectsProjectIdGroupsGroupId404Schema
        | PatchApiProjectsProjectIdGroupsGroupId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'];
        groupId: PatchApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'];
        data?: PatchApiProjectsProjectIdGroupsGroupIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdGroupsGroupIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdGroupsGroupIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdGroupsGroupId400Schema
      | PatchApiProjectsProjectIdGroupsGroupId401Schema
      | PatchApiProjectsProjectIdGroupsGroupId403Schema
      | PatchApiProjectsProjectIdGroupsGroupId404Schema
      | PatchApiProjectsProjectIdGroupsGroupId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'];
      groupId: PatchApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'];
      data?: PatchApiProjectsProjectIdGroupsGroupIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, groupId, data }) => {
      return patchApiProjectsProjectIdGroupsGroupId(projectId, groupId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdGroupsGroupIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/groups/{group_id}' }] as const;

export type DeleteApiProjectsProjectIdGroupsGroupIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdGroupsGroupIdMutationKey
>;

/**
 * @summary Deletes a group
 * {@link /api/projects/:project_id/groups/:group_id}
 */
export function useDeleteApiProjectsProjectIdGroupsGroupId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdGroupsGroupId401Schema
        | DeleteApiProjectsProjectIdGroupsGroupId403Schema
        | DeleteApiProjectsProjectIdGroupsGroupId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'];
        groupId: DeleteApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdGroupsGroupIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdGroupsGroupIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdGroupsGroupId401Schema
      | DeleteApiProjectsProjectIdGroupsGroupId403Schema
      | DeleteApiProjectsProjectIdGroupsGroupId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdGroupsGroupIdPathParamsSchema['project_id'];
      groupId: DeleteApiProjectsProjectIdGroupsGroupIdPathParamsSchema['group_id'];
    }
  >({
    mutationFn: async ({ projectId, groupId }) => {
      return deleteApiProjectsProjectIdGroupsGroupId(projectId, groupId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdGroupsGroupIdMembersMutationKey = () =>
  [{ url: '/api/projects/{project_id}/groups/{group_id}/members' }] as const;

export type PatchApiProjectsProjectIdGroupsGroupIdMembersMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdGroupsGroupIdMembersMutationKey
>;

/**
 * @summary Updates group members
 * {@link /api/projects/:project_id/groups/:group_id/members}
 */
export function usePatchApiProjectsProjectIdGroupsGroupIdMembers(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdGroupsGroupIdMembersMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdGroupsGroupIdMembers401Schema
        | PatchApiProjectsProjectIdGroupsGroupIdMembers403Schema
        | PatchApiProjectsProjectIdGroupsGroupIdMembers404Schema
        | PatchApiProjectsProjectIdGroupsGroupIdMembers422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdGroupsGroupIdMembersPathParamsSchema['project_id'];
        groupId: PatchApiProjectsProjectIdGroupsGroupIdMembersPathParamsSchema['group_id'];
        data?: PatchApiProjectsProjectIdGroupsGroupIdMembersMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdGroupsGroupIdMembersMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdGroupsGroupIdMembersMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdGroupsGroupIdMembersMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdGroupsGroupIdMembers401Schema
      | PatchApiProjectsProjectIdGroupsGroupIdMembers403Schema
      | PatchApiProjectsProjectIdGroupsGroupIdMembers404Schema
      | PatchApiProjectsProjectIdGroupsGroupIdMembers422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdGroupsGroupIdMembersPathParamsSchema['project_id'];
      groupId: PatchApiProjectsProjectIdGroupsGroupIdMembersPathParamsSchema['group_id'];
      data?: PatchApiProjectsProjectIdGroupsGroupIdMembersMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, groupId, data }) => {
      return patchApiProjectsProjectIdGroupsGroupIdMembers(projectId, groupId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdAssignmentsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/assignments' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsMutationKey
>;

/**
 * @summary Creates an issue assignment
 * {@link /api/projects/:project_id/issues/:issue_id/assignments}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdAssignments(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdAssignments400Schema
        | PostApiProjectsProjectIdIssuesIssueIdAssignments401Schema
        | PostApiProjectsProjectIdIssuesIssueIdAssignments403Schema
        | PostApiProjectsProjectIdIssuesIssueIdAssignments404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsPathParamsSchema['issue_id'];
        data?: PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdAssignmentsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdAssignments400Schema
      | PostApiProjectsProjectIdIssuesIssueIdAssignments401Schema
      | PostApiProjectsProjectIdIssuesIssueIdAssignments403Schema
      | PostApiProjectsProjectIdIssuesIssueIdAssignments404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsPathParamsSchema['issue_id'];
      data?: PostApiProjectsProjectIdIssuesIssueIdAssignmentsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdAssignments(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/assignments/{issue_assignment_id}/accept' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationKey
>;

/**
 * @summary Accepts issue assignment
 * {@link /api/projects/:project_id/issues/:issue_id/assignments/:issue_assignment_id/accept}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept401Schema
        | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept403Schema
        | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema['issue_id'];
        issueAssignmentId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema['issue_assignment_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept401Schema
      | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept403Schema
      | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema['issue_id'];
      issueAssignmentId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAcceptPathParamsSchema['issue_assignment_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId, issueAssignmentId }) => {
      return postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdAccept(
        projectId,
        issueId,
        issueAssignmentId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/assignments/{issue_assignment_id}/reject' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationKey
>;

/**
 * @summary Rejects issue assignment
 * {@link /api/projects/:project_id/issues/:issue_id/assignments/:issue_assignment_id/reject}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject401Schema
        | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject403Schema
        | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema['issue_id'];
        issueAssignmentId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema['issue_assignment_id'];
        data?: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject401Schema
      | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject403Schema
      | PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema['issue_id'];
      issueAssignmentId: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectPathParamsSchema['issue_assignment_id'];
      data?: PostApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdRejectMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, issueAssignmentId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdAssignmentsIssueAssignmentIdReject(
        projectId,
        issueId,
        issueAssignmentId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/document_references/{document_reference_id}' }] as const;

export type DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationKey
>;

/**
 * @summary Removes a document from an issue
 * {@link /api/projects/:project_id/issues/:issue_id/document_references/:document_reference_id}
 */
export function useDeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId401Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId403Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema['project_id'];
        issueId: DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema['issue_id'];
        documentReferenceId: DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema['document_reference_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId401Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId403Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema['project_id'];
      issueId: DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema['issue_id'];
      documentReferenceId: DeleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceIdPathParamsSchema['document_reference_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId, documentReferenceId }) => {
      return deleteApiProjectsProjectIdIssuesIssueIdDocumentReferencesDocumentReferenceId(
        projectId,
        issueId,
        documentReferenceId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdIssuesIssueIdDocumentsQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/issues/:issue_id/documents', params: { projectId: projectId, issueId: issueId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdDocumentsQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdDocumentsQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdDocumentsQueryKey(projectId, issueId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdDocuments400Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments401Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments403Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdDocuments(projectId, issueId, params, config);
    },
  });
}

/**
 * @summary Retrieves issue documents
 * {@link /api/projects/:project_id/issues/:issue_id/documents}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdDocuments<
  TData = GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdDocuments400Schema
          | GetApiProjectsProjectIdIssuesIssueIdDocuments401Schema
          | GetApiProjectsProjectIdIssuesIssueIdDocuments403Schema
          | GetApiProjectsProjectIdIssuesIssueIdDocuments404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdDocumentsQueryKey(projectId, issueId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdDocumentsQueryOptions(
      projectId,
      issueId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdDocuments400Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments401Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments403Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdDocumentsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/issues/:issue_id/documents', params: { projectId: projectId, issueId: issueId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdDocumentsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdDocumentsSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdDocumentsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdDocumentsSuspenseQueryKey(projectId, issueId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdDocuments400Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments401Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments403Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdDocuments(projectId, issueId, params, config);
    },
  });
}

/**
 * @summary Retrieves issue documents
 * {@link /api/projects/:project_id/issues/:issue_id/documents}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdDocumentsSuspense<
  TData = GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdDocumentsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesIssueIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdDocuments400Schema
          | GetApiProjectsProjectIdIssuesIssueIdDocuments401Schema
          | GetApiProjectsProjectIdIssuesIssueIdDocuments403Schema
          | GetApiProjectsProjectIdIssuesIssueIdDocuments404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdDocumentsSuspenseQueryKey(projectId, issueId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdDocumentsSuspenseQueryOptions(
      projectId,
      issueId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdDocuments400Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments401Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments403Schema
      | GetApiProjectsProjectIdIssuesIssueIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdIssuesIssueIdDocumentsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/documents' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdDocumentsMutationKey
>;

/**
 * @summary Adds a document to an issue (from an upload or an existing document)
 * {@link /api/projects/:project_id/issues/:issue_id/documents}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdDocuments(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdDocuments400Schema
        | PostApiProjectsProjectIdIssuesIssueIdDocuments401Schema
        | PostApiProjectsProjectIdIssuesIssueIdDocuments403Schema
        | PostApiProjectsProjectIdIssuesIssueIdDocuments422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'];
        data?: PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdDocumentsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdDocuments400Schema
      | PostApiProjectsProjectIdIssuesIssueIdDocuments401Schema
      | PostApiProjectsProjectIdIssuesIssueIdDocuments403Schema
      | PostApiProjectsProjectIdIssuesIssueIdDocuments422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdDocumentsPathParamsSchema['issue_id'];
      data?: PostApiProjectsProjectIdIssuesIssueIdDocumentsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdDocuments(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdIssuesIssueIdFeedTeamQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/issues/:issue_id/feed/team', params: { projectId: projectId, issueId: issueId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdFeedTeamQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdFeedTeamQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdFeedTeamQueryKey(projectId, issueId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam400Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam401Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam403Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdFeedTeam(projectId, issueId, params, config);
    },
  });
}

/**
 * @summary Retrieves team only issue events
 * {@link /api/projects/:project_id/issues/:issue_id/feed/team}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeam<
  TData = GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdFeedTeam400Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedTeam401Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedTeam403Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedTeam404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdFeedTeamQueryKey(projectId, issueId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdFeedTeamQueryOptions(
      projectId,
      issueId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam400Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam401Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam403Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdFeedTeamSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/issues/:issue_id/feed/team', params: { projectId: projectId, issueId: issueId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdFeedTeamSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdFeedTeamSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdFeedTeamSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdFeedTeamSuspenseQueryKey(projectId, issueId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam400Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam401Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam403Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdFeedTeam(projectId, issueId, params, config);
    },
  });
}

/**
 * @summary Retrieves team only issue events
 * {@link /api/projects/:project_id/issues/:issue_id/feed/team}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdFeedTeamSuspense<
  TData = GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdFeedTeamSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedTeamPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesIssueIdFeedTeamQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdFeedTeam400Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedTeam401Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedTeam403Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedTeam404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdFeedTeamSuspenseQueryKey(projectId, issueId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdFeedTeamSuspenseQueryOptions(
      projectId,
      issueId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam400Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam401Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam403Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedTeam404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdFeedPublicQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/issues/:issue_id/feed/public',
      params: { projectId: projectId, issueId: issueId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdFeedPublicQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdFeedPublicQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdFeedPublicQueryKey(projectId, issueId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic400Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic401Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdFeedPublic(projectId, issueId, params, config);
    },
  });
}

/**
 * @summary Retrieves public issue events
 * {@link /api/projects/:project_id/issues/:issue_id/feed/public}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublic<
  TData = GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdFeedPublic400Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedPublic401Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedPublic404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdFeedPublicQueryKey(projectId, issueId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdFeedPublicQueryOptions(
      projectId,
      issueId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic400Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic401Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdFeedPublicSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/issues/:issue_id/feed/public',
      params: { projectId: projectId, issueId: issueId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdFeedPublicSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdFeedPublicSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdFeedPublicSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdFeedPublicSuspenseQueryKey(projectId, issueId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic400Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic401Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdFeedPublic(projectId, issueId, params, config);
    },
  });
}

/**
 * @summary Retrieves public issue events
 * {@link /api/projects/:project_id/issues/:issue_id/feed/public}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdFeedPublicSuspense<
  TData = GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdFeedPublicSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdFeedPublicPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesIssueIdFeedPublicQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdFeedPublic400Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedPublic401Schema
          | GetApiProjectsProjectIdIssuesIssueIdFeedPublic404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdIssuesIssueIdFeedPublicSuspenseQueryKey(projectId, issueId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdFeedPublicSuspenseQueryOptions(
      projectId,
      issueId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic400Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic401Schema
      | GetApiProjectsProjectIdIssuesIssueIdFeedPublic404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/issues/:issue_id/issue_images',
      params: { projectId: projectId, issueId: issueId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryKey(projectId, issueId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdIssueImages(projectId, issueId, params, config);
    },
  });
}

/**
 * @description Deprecated: use /api/projects/{project_id}/issues/{issue_id}/documents
 * @summary Retrieves issue images and evidence images
 * {@link /api/projects/:project_id/issues/:issue_id/issue_images}
 * @deprecated
 */
export function useGetApiProjectsProjectIdIssuesIssueIdIssueImages<
  TData = GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
          | GetApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
          | GetApiProjectsProjectIdIssuesIssueIdIssueImages404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryKey(projectId, issueId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdIssueImagesQueryOptions(
      projectId,
      issueId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdIssueImagesSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/issues/:issue_id/issue_images',
      params: { projectId: projectId, issueId: issueId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdIssueImagesSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdIssueImagesSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdIssueImagesSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdIssueImagesSuspenseQueryKey(projectId, issueId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdIssueImages(projectId, issueId, params, config);
    },
  });
}

/**
 * @description Deprecated: use /api/projects/{project_id}/issues/{issue_id}/documents
 * @summary Retrieves issue images and evidence images
 * {@link /api/projects/:project_id/issues/:issue_id/issue_images}
 * @deprecated
 */
export function useGetApiProjectsProjectIdIssuesIssueIdIssueImagesSuspense<
  TData = GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdIssueImagesSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'],
  params?: GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesIssueIdIssueImagesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
          | GetApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
          | GetApiProjectsProjectIdIssuesIssueIdIssueImages404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdIssuesIssueIdIssueImagesSuspenseQueryKey(projectId, issueId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdIssueImagesSuspenseQueryOptions(
      projectId,
      issueId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
      | GetApiProjectsProjectIdIssuesIssueIdIssueImages404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdIssuesIssueIdIssueImagesMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/issue_images' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdIssueImagesMutationKey
>;

/**
 * @description Deprecated: use /api/projects/{project_id}/issues/{issue_id}/documents
 * @summary Uploads an image (from an upload or an existing document)
 * {@link /api/projects/:project_id/issues/:issue_id/issue_images}
 * @deprecated
 */
export function usePostApiProjectsProjectIdIssuesIssueIdIssueImages(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdIssueImages400Schema
        | PostApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
        | PostApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
        | PostApiProjectsProjectIdIssuesIssueIdIssueImages422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'];
        data?: PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdIssueImagesMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdIssueImages400Schema
      | PostApiProjectsProjectIdIssuesIssueIdIssueImages401Schema
      | PostApiProjectsProjectIdIssuesIssueIdIssueImages403Schema
      | PostApiProjectsProjectIdIssuesIssueIdIssueImages422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdIssueImagesPathParamsSchema['issue_id'];
      data?: PostApiProjectsProjectIdIssuesIssueIdIssueImagesMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdIssueImages(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/issue_images/{issue_image_id}' }] as const;

export type DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationKey
>;

/**
 * @description Deprecated: use /api/projects/{project_id}/issues/{issue_id}/document_references/{document_reference_id}
 * @summary Removes an image from an issue
 * {@link /api/projects/:project_id/issues/:issue_id/issue_images/:issue_image_id}
 * @deprecated
 */
export function useDeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['project_id'];
        issueId: DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_id'];
        issueImageId: DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_image_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['project_id'];
      issueId: DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_id'];
      issueImageId: DeleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_image_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId, issueImageId }) => {
      return deleteApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId(projectId, issueId, issueImageId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/issue_images/{issue_image_id}' }] as const;

export type PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationKey
>;

/**
 * @description Deprecated: use /api/projects/{project_id}/documents/{document_id}
 * @summary Updates an image
 * {@link /api/projects/:project_id/issues/:issue_id/issue_images/:issue_image_id}
 * @deprecated
 */
export function usePatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema
        | PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema
        | PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['project_id'];
        issueId: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_id'];
        issueImageId: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_image_id'];
        data?: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId401Schema
      | PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId403Schema
      | PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId404Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['project_id'];
      issueId: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_id'];
      issueImageId: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdPathParamsSchema['issue_image_id'];
      data?: PatchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, issueImageId, data }) => {
      return patchApiProjectsProjectIdIssuesIssueIdIssueImagesIssueImageId(
        projectId,
        issueId,
        issueImageId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdApproveMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/approve' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdApproveMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdApproveMutationKey
>;

/**
 * @summary Approves an issue
 * {@link /api/projects/:project_id/issues/:issue_id/approve}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdApprove(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdApproveMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdApprove400Schema
        | PostApiProjectsProjectIdIssuesIssueIdApprove401Schema
        | PostApiProjectsProjectIdIssuesIssueIdApprove403Schema
        | PostApiProjectsProjectIdIssuesIssueIdApprove404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdApprovePathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdApprovePathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdApproveMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdApproveMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdApprove400Schema
      | PostApiProjectsProjectIdIssuesIssueIdApprove401Schema
      | PostApiProjectsProjectIdIssuesIssueIdApprove403Schema
      | PostApiProjectsProjectIdIssuesIssueIdApprove404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdApprovePathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdApprovePathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return postApiProjectsProjectIdIssuesIssueIdApprove(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdCompleteMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/complete' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdCompleteMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdCompleteMutationKey
>;

/**
 * @summary Updates an issue to completed
 * {@link /api/projects/:project_id/issues/:issue_id/complete}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdComplete(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdCompleteMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdComplete400Schema
        | PostApiProjectsProjectIdIssuesIssueIdComplete401Schema
        | PostApiProjectsProjectIdIssuesIssueIdComplete403Schema
        | PostApiProjectsProjectIdIssuesIssueIdComplete404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdCompletePathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdCompletePathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdCompleteMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdCompleteMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdComplete400Schema
      | PostApiProjectsProjectIdIssuesIssueIdComplete401Schema
      | PostApiProjectsProjectIdIssuesIssueIdComplete403Schema
      | PostApiProjectsProjectIdIssuesIssueIdComplete404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdCompletePathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdCompletePathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return postApiProjectsProjectIdIssuesIssueIdComplete(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdRejectMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/reject' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdRejectMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdRejectMutationKey
>;

/**
 * @summary Rejects an issue
 * {@link /api/projects/:project_id/issues/:issue_id/reject}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdReject(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdRejectMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdReject400Schema
        | PostApiProjectsProjectIdIssuesIssueIdReject401Schema
        | PostApiProjectsProjectIdIssuesIssueIdReject404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdRejectPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdRejectPathParamsSchema['issue_id'];
        data?: PostApiProjectsProjectIdIssuesIssueIdRejectMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdRejectMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdRejectMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdRejectMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdReject400Schema
      | PostApiProjectsProjectIdIssuesIssueIdReject401Schema
      | PostApiProjectsProjectIdIssuesIssueIdReject404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdRejectPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdRejectPathParamsSchema['issue_id'];
      data?: PostApiProjectsProjectIdIssuesIssueIdRejectMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdReject(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdReopenMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/reopen' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdReopenMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdReopenMutationKey
>;

/**
 * @summary Reopens an resolved issue
 * {@link /api/projects/:project_id/issues/:issue_id/reopen}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdReopen(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdReopenMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdReopen400Schema
        | PostApiProjectsProjectIdIssuesIssueIdReopen401Schema
        | PostApiProjectsProjectIdIssuesIssueIdReopen404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdReopenPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdReopenPathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdReopenMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdReopenMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdReopen400Schema
      | PostApiProjectsProjectIdIssuesIssueIdReopen401Schema
      | PostApiProjectsProjectIdIssuesIssueIdReopen404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdReopenPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdReopenPathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return postApiProjectsProjectIdIssuesIssueIdReopen(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdStartMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/start' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdStartMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdStartMutationKey
>;

/**
 * @summary Updates an issue to in_progress
 * {@link /api/projects/:project_id/issues/:issue_id/start}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdStart(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdStartMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdStart400Schema
        | PostApiProjectsProjectIdIssuesIssueIdStart401Schema
        | PostApiProjectsProjectIdIssuesIssueIdStart404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdStartPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdStartPathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdStartMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdStartMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdStart400Schema
      | PostApiProjectsProjectIdIssuesIssueIdStart401Schema
      | PostApiProjectsProjectIdIssuesIssueIdStart404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdStartPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdStartPathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return postApiProjectsProjectIdIssuesIssueIdStart(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdStopMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/stop' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdStopMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdStopMutationKey
>;

/**
 * @summary Updates an issue to assigned
 * {@link /api/projects/:project_id/issues/:issue_id/stop}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdStop(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdStopMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdStop400Schema
        | PostApiProjectsProjectIdIssuesIssueIdStop401Schema
        | PostApiProjectsProjectIdIssuesIssueIdStop404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdStopPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdStopPathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdStopMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdStopMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdStop400Schema
      | PostApiProjectsProjectIdIssuesIssueIdStop401Schema
      | PostApiProjectsProjectIdIssuesIssueIdStop404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdStopPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdStopPathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return postApiProjectsProjectIdIssuesIssueIdStop(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdSubmitMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/submit' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdSubmitMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdSubmitMutationKey
>;

/**
 * @summary Submits a draft issue
 * {@link /api/projects/:project_id/issues/:issue_id/submit}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdSubmit(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdSubmitMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdSubmit401Schema
        | PostApiProjectsProjectIdIssuesIssueIdSubmit403Schema
        | PostApiProjectsProjectIdIssuesIssueIdSubmit404Schema
        | PostApiProjectsProjectIdIssuesIssueIdSubmit422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdSubmitPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdSubmitPathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdSubmitMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdSubmitMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdSubmit401Schema
      | PostApiProjectsProjectIdIssuesIssueIdSubmit403Schema
      | PostApiProjectsProjectIdIssuesIssueIdSubmit404Schema
      | PostApiProjectsProjectIdIssuesIssueIdSubmit422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdSubmitPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdSubmitPathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return postApiProjectsProjectIdIssuesIssueIdSubmit(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/status_statements' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationKey
>;

/**
 * @summary Creates an issue status statement
 * {@link /api/projects/:project_id/issues/:issue_id/status_statements}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdStatusStatements(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
        | PostApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
        | PostApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id'];
        data?: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
      | PostApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
      | PostApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id'];
      data?: PostApiProjectsProjectIdIssuesIssueIdStatusStatementsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdStatusStatements(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id']
) =>
  [
    {
      url: '/api/projects/:project_id/issues/:issue_id/status_statements',
      params: { projectId: projectId, issueId: issueId },
    },
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryKey(projectId, issueId);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdStatusStatements(projectId, issueId, config);
    },
  });
}

/**
 * @summary Retrieves issue status statements
 * {@link /api/projects/:project_id/issues/:issue_id/status_statements}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatements<
  TData = GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
          | GetApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
          | GetApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryKey(projectId, issueId);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryOptions(
      projectId,
      issueId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdStatusStatementsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id']
) =>
  [
    {
      url: '/api/projects/:project_id/issues/:issue_id/status_statements',
      params: { projectId: projectId, issueId: issueId },
    },
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdStatusStatementsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdStatusStatementsSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdStatusStatementsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdStatusStatementsSuspenseQueryKey(projectId, issueId);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdStatusStatements(projectId, issueId, config);
    },
  });
}

/**
 * @summary Retrieves issue status statements
 * {@link /api/projects/:project_id/issues/:issue_id/status_statements}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdStatusStatementsSuspense<
  TData = GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdStatusStatementsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdStatusStatementsPathParamsSchema['issue_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesIssueIdStatusStatementsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
          | GetApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
          | GetApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdStatusStatementsSuspenseQueryKey(projectId, issueId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdStatusStatementsSuspenseQueryOptions(
      projectId,
      issueId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements401Schema
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements403Schema
      | GetApiProjectsProjectIdIssuesIssueIdStatusStatements404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/status_statements/{status_statement_id}' }] as const;

export type DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationKey
>;

/**
 * @summary Deletes an issue status statement
 * {@link /api/projects/:project_id/issues/:issue_id/status_statements/:status_statement_id}
 */
export function useDeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId401Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId403Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId404Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId422Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema['project_id'];
        issueId: DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema['issue_id'];
        statusStatementId: DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema['status_statement_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId401Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId403Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId404Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId422Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema['project_id'];
      issueId: DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema['issue_id'];
      statusStatementId: DeleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementIdPathParamsSchema['status_statement_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId, statusStatementId }) => {
      return deleteApiProjectsProjectIdIssuesIssueIdStatusStatementsStatusStatementId(
        projectId,
        issueId,
        statusStatementId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdIssueViewsQueryKey = (
  projectId: GetApiProjectsProjectIdIssueViewsPathParamsSchema['project_id']
) => [{ url: '/api/projects/:project_id/issue_views', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdIssueViewsQueryKey = ReturnType<typeof getApiProjectsProjectIdIssueViewsQueryKey>;

export function getApiProjectsProjectIdIssueViewsQueryOptions(
  projectId: GetApiProjectsProjectIdIssueViewsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssueViewsQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssueViews401Schema
      | GetApiProjectsProjectIdIssueViews403Schema
      | GetApiProjectsProjectIdIssueViews404Schema
    >,
    GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssueViews(projectId, config);
    },
  });
}

/**
 * @summary Retrieves issue views
 * {@link /api/projects/:project_id/issue_views}
 */
export function useGetApiProjectsProjectIdIssueViews<
  TData = GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssueViewsQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssueViewsPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssueViews401Schema
          | GetApiProjectsProjectIdIssueViews403Schema
          | GetApiProjectsProjectIdIssueViews404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssueViewsQueryKey(projectId);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssueViewsQueryOptions(projectId, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssueViews401Schema
      | GetApiProjectsProjectIdIssueViews403Schema
      | GetApiProjectsProjectIdIssueViews404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssueViewsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssueViewsPathParamsSchema['project_id']
) => [{ url: '/api/projects/:project_id/issue_views', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdIssueViewsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssueViewsSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssueViewsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssueViewsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssueViewsSuspenseQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssueViews401Schema
      | GetApiProjectsProjectIdIssueViews403Schema
      | GetApiProjectsProjectIdIssueViews404Schema
    >,
    GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssueViews(projectId, config);
    },
  });
}

/**
 * @summary Retrieves issue views
 * {@link /api/projects/:project_id/issue_views}
 */
export function useGetApiProjectsProjectIdIssueViewsSuspense<
  TData = GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssueViewsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssueViewsPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssueViewsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssueViews401Schema
          | GetApiProjectsProjectIdIssueViews403Schema
          | GetApiProjectsProjectIdIssueViews404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssueViewsSuspenseQueryKey(projectId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssueViewsSuspenseQueryOptions(projectId, config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssueViews401Schema
      | GetApiProjectsProjectIdIssueViews403Schema
      | GetApiProjectsProjectIdIssueViews404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdIssueViewsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issue_views' }] as const;

export type PostApiProjectsProjectIdIssueViewsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssueViewsMutationKey
>;

/**
 * @summary Creates issue view
 * {@link /api/projects/:project_id/issue_views}
 */
export function usePostApiProjectsProjectIdIssueViews(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssueViewsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssueViews401Schema
        | PostApiProjectsProjectIdIssueViews403Schema
        | PostApiProjectsProjectIdIssueViews404Schema
        | PostApiProjectsProjectIdIssueViews422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssueViewsPathParamsSchema['project_id'];
        data: PostApiProjectsProjectIdIssueViewsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssueViewsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssueViewsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssueViewsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssueViews401Schema
      | PostApiProjectsProjectIdIssueViews403Schema
      | PostApiProjectsProjectIdIssueViews404Schema
      | PostApiProjectsProjectIdIssueViews422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssueViewsPathParamsSchema['project_id'];
      data: PostApiProjectsProjectIdIssueViewsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdIssueViews(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdIssueViewsIssueViewIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issue_views/{issue_view_id}' }] as const;

export type PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdIssueViewsIssueViewIdMutationKey
>;

/**
 * @summary Updates issue view
 * {@link /api/projects/:project_id/issue_views/:issue_view_id}
 */
export function usePatchApiProjectsProjectIdIssueViewsIssueViewId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdIssueViewsIssueViewId401Schema
        | PatchApiProjectsProjectIdIssueViewsIssueViewId404Schema
        | PatchApiProjectsProjectIdIssueViewsIssueViewId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['project_id'];
        issueViewId: PatchApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['issue_view_id'];
        data?: PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdIssueViewsIssueViewIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdIssueViewsIssueViewId401Schema
      | PatchApiProjectsProjectIdIssueViewsIssueViewId404Schema
      | PatchApiProjectsProjectIdIssueViewsIssueViewId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['project_id'];
      issueViewId: PatchApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['issue_view_id'];
      data?: PatchApiProjectsProjectIdIssueViewsIssueViewIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueViewId, data }) => {
      return patchApiProjectsProjectIdIssueViewsIssueViewId(projectId, issueViewId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdIssueViewsIssueViewIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issue_views/{issue_view_id}' }] as const;

export type DeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdIssueViewsIssueViewIdMutationKey
>;

/**
 * @summary Deletes an issue view
 * {@link /api/projects/:project_id/issue_views/:issue_view_id}
 */
export function useDeleteApiProjectsProjectIdIssueViewsIssueViewId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdIssueViewsIssueViewId401Schema
        | DeleteApiProjectsProjectIdIssueViewsIssueViewId403Schema
        | DeleteApiProjectsProjectIdIssueViewsIssueViewId404Schema
        | DeleteApiProjectsProjectIdIssueViewsIssueViewId422Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['project_id'];
        issueViewId: DeleteApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['issue_view_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdIssueViewsIssueViewIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdIssueViewsIssueViewIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdIssueViewsIssueViewId401Schema
      | DeleteApiProjectsProjectIdIssueViewsIssueViewId403Schema
      | DeleteApiProjectsProjectIdIssueViewsIssueViewId404Schema
      | DeleteApiProjectsProjectIdIssueViewsIssueViewId422Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['project_id'];
      issueViewId: DeleteApiProjectsProjectIdIssueViewsIssueViewIdPathParamsSchema['issue_view_id'];
    }
  >({
    mutationFn: async ({ projectId, issueViewId }) => {
      return deleteApiProjectsProjectIdIssueViewsIssueViewId(projectId, issueViewId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdIssuesIssueIdVisitQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id']
) =>
  [
    { url: '/api/projects/:project_id/issues/:issue_id/visit', params: { projectId: projectId, issueId: issueId } },
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdVisitQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdVisitQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdVisitQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdVisitQueryKey(projectId, issueId);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdVisit401Schema
      | GetApiProjectsProjectIdIssuesIssueIdVisit403Schema
      | GetApiProjectsProjectIdIssuesIssueIdVisit404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdVisit(projectId, issueId, config);
    },
  });
}

/**
 * @summary Retrieve issue visit status
 * {@link /api/projects/:project_id/issues/:issue_id/visit}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdVisit<
  TData = GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdVisitQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdVisit401Schema
          | GetApiProjectsProjectIdIssuesIssueIdVisit403Schema
          | GetApiProjectsProjectIdIssuesIssueIdVisit404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdVisitQueryKey(projectId, issueId);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdVisitQueryOptions(
      projectId,
      issueId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdVisit401Schema
      | GetApiProjectsProjectIdIssuesIssueIdVisit403Schema
      | GetApiProjectsProjectIdIssuesIssueIdVisit404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdVisitSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id']
) =>
  [
    { url: '/api/projects/:project_id/issues/:issue_id/visit', params: { projectId: projectId, issueId: issueId } },
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdVisitSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdVisitSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdVisitSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdVisitSuspenseQueryKey(projectId, issueId);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdVisit401Schema
      | GetApiProjectsProjectIdIssuesIssueIdVisit403Schema
      | GetApiProjectsProjectIdIssuesIssueIdVisit404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdVisit(projectId, issueId, config);
    },
  });
}

/**
 * @summary Retrieve issue visit status
 * {@link /api/projects/:project_id/issues/:issue_id/visit}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdVisitSuspense<
  TData = GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdVisitSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesIssueIdVisitQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdVisit401Schema
          | GetApiProjectsProjectIdIssuesIssueIdVisit403Schema
          | GetApiProjectsProjectIdIssuesIssueIdVisit404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdVisitSuspenseQueryKey(projectId, issueId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdVisitSuspenseQueryOptions(
      projectId,
      issueId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueIdVisit401Schema
      | GetApiProjectsProjectIdIssuesIssueIdVisit403Schema
      | GetApiProjectsProjectIdIssuesIssueIdVisit404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdIssuesIssueIdVisitMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/visit' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdVisitMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdVisitMutationKey
>;

/**
 * @summary Saves issue visit
 * {@link /api/projects/:project_id/issues/:issue_id/visit}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdVisit(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdVisitMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdVisit401Schema
        | PostApiProjectsProjectIdIssuesIssueIdVisit403Schema
        | PostApiProjectsProjectIdIssuesIssueIdVisit404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdVisitMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdVisitMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdVisit401Schema
      | PostApiProjectsProjectIdIssuesIssueIdVisit403Schema
      | PostApiProjectsProjectIdIssuesIssueIdVisit404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdVisitPathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return postApiProjectsProjectIdIssuesIssueIdVisit(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesMutationKey = () => [{ url: '/api/projects/{project_id}/issues' }] as const;

export type PostApiProjectsProjectIdIssuesMutationKey = ReturnType<typeof postApiProjectsProjectIdIssuesMutationKey>;

/**
 * @summary Creates an issue
 * {@link /api/projects/:project_id/issues}
 */
export function usePostApiProjectsProjectIdIssues(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssues401Schema
        | PostApiProjectsProjectIdIssues403Schema
        | PostApiProjectsProjectIdIssues404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdIssuesMutationRequestSchema;
        headers?: PostApiProjectsProjectIdIssuesHeaderParamsSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssues401Schema
      | PostApiProjectsProjectIdIssues403Schema
      | PostApiProjectsProjectIdIssues404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdIssuesMutationRequestSchema;
      headers?: PostApiProjectsProjectIdIssuesHeaderParamsSchema;
    }
  >({
    mutationFn: async ({ projectId, data, headers }) => {
      return postApiProjectsProjectIdIssues(projectId, data, headers, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdIssuesQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesQueryParamsSchema
) =>
  [{ url: '/api/projects/:project_id/issues', params: { projectId: projectId } }, ...(params ? [params] : [])] as const;

export type GetApiProjectsProjectIdIssuesQueryKey = ReturnType<typeof getApiProjectsProjectIdIssuesQueryKey>;

export function getApiProjectsProjectIdIssuesQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssues400Schema
      | GetApiProjectsProjectIdIssues401Schema
      | GetApiProjectsProjectIdIssues403Schema
      | GetApiProjectsProjectIdIssues404Schema
    >,
    GetApiProjectsProjectIdIssuesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssues(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves issues
 * {@link /api/projects/:project_id/issues}
 */
export function useGetApiProjectsProjectIdIssues<
  TData = GetApiProjectsProjectIdIssuesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssues400Schema
          | GetApiProjectsProjectIdIssues401Schema
          | GetApiProjectsProjectIdIssues403Schema
          | GetApiProjectsProjectIdIssues404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesQueryOptions(projectId, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssues400Schema
      | GetApiProjectsProjectIdIssues401Schema
      | GetApiProjectsProjectIdIssues403Schema
      | GetApiProjectsProjectIdIssues404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesQueryParamsSchema
) =>
  [{ url: '/api/projects/:project_id/issues', params: { projectId: projectId } }, ...(params ? [params] : [])] as const;

export type GetApiProjectsProjectIdIssuesSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssues400Schema
      | GetApiProjectsProjectIdIssues401Schema
      | GetApiProjectsProjectIdIssues403Schema
      | GetApiProjectsProjectIdIssues404Schema
    >,
    GetApiProjectsProjectIdIssuesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssues(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves issues
 * {@link /api/projects/:project_id/issues}
 */
export function useGetApiProjectsProjectIdIssuesSuspense<
  TData = GetApiProjectsProjectIdIssuesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssues400Schema
          | GetApiProjectsProjectIdIssues401Schema
          | GetApiProjectsProjectIdIssues403Schema
          | GetApiProjectsProjectIdIssues404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssues400Schema
      | GetApiProjectsProjectIdIssues401Schema
      | GetApiProjectsProjectIdIssues403Schema
      | GetApiProjectsProjectIdIssues404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesGroupCountQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesGroupCountPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesGroupCountQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/issues/group_count', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesGroupCountQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesGroupCountQueryKey
>;

export function getApiProjectsProjectIdIssuesGroupCountQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesGroupCountPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesGroupCountQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesGroupCountQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesGroupCount400Schema
      | GetApiProjectsProjectIdIssuesGroupCount401Schema
      | GetApiProjectsProjectIdIssuesGroupCount403Schema
      | GetApiProjectsProjectIdIssuesGroupCount404Schema
    >,
    GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesGroupCount(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves issues group count
 * {@link /api/projects/:project_id/issues/group_count}
 */
export function useGetApiProjectsProjectIdIssuesGroupCount<
  TData = GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesGroupCountQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesGroupCountPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesGroupCountQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesGroupCount400Schema
          | GetApiProjectsProjectIdIssuesGroupCount401Schema
          | GetApiProjectsProjectIdIssuesGroupCount403Schema
          | GetApiProjectsProjectIdIssuesGroupCount404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesGroupCountQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesGroupCountQueryOptions(
      projectId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesGroupCount400Schema
      | GetApiProjectsProjectIdIssuesGroupCount401Schema
      | GetApiProjectsProjectIdIssuesGroupCount403Schema
      | GetApiProjectsProjectIdIssuesGroupCount404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesGroupCountSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesGroupCountPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesGroupCountQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/issues/group_count', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdIssuesGroupCountSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesGroupCountSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesGroupCountSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesGroupCountPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesGroupCountQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesGroupCountSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesGroupCount400Schema
      | GetApiProjectsProjectIdIssuesGroupCount401Schema
      | GetApiProjectsProjectIdIssuesGroupCount403Schema
      | GetApiProjectsProjectIdIssuesGroupCount404Schema
    >,
    GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesGroupCount(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves issues group count
 * {@link /api/projects/:project_id/issues/group_count}
 */
export function useGetApiProjectsProjectIdIssuesGroupCountSuspense<
  TData = GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesGroupCountSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesGroupCountPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdIssuesGroupCountQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesGroupCountQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesGroupCount400Schema
          | GetApiProjectsProjectIdIssuesGroupCount401Schema
          | GetApiProjectsProjectIdIssuesGroupCount403Schema
          | GetApiProjectsProjectIdIssuesGroupCount404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesGroupCountSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesGroupCountSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesGroupCount400Schema
      | GetApiProjectsProjectIdIssuesGroupCount401Schema
      | GetApiProjectsProjectIdIssuesGroupCount403Schema
      | GetApiProjectsProjectIdIssuesGroupCount404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdIssuesExportMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/export' }] as const;

export type PostApiProjectsProjectIdIssuesExportMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesExportMutationKey
>;

/**
 * @summary Requests issue export
 * {@link /api/projects/:project_id/issues/export}
 */
export function usePostApiProjectsProjectIdIssuesExport(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesExportMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesExport400Schema
        | PostApiProjectsProjectIdIssuesExport401Schema
        | PostApiProjectsProjectIdIssuesExport403Schema
        | PostApiProjectsProjectIdIssuesExport404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesExportPathParamsSchema['project_id'];
        params: PostApiProjectsProjectIdIssuesExportQueryParamsSchema;
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesExportMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesExportMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesExport400Schema
      | PostApiProjectsProjectIdIssuesExport401Schema
      | PostApiProjectsProjectIdIssuesExport403Schema
      | PostApiProjectsProjectIdIssuesExport404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesExportPathParamsSchema['project_id'];
      params: PostApiProjectsProjectIdIssuesExportQueryParamsSchema;
    }
  >({
    mutationFn: async ({ projectId, params }) => {
      return postApiProjectsProjectIdIssuesExport(projectId, params, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdIssuesIssueIdQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id']
) =>
  [{ url: '/api/projects/:project_id/issues/:issue_id', params: { projectId: projectId, issueId: issueId } }] as const;

export type GetApiProjectsProjectIdIssuesIssueIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueId401Schema
      | GetApiProjectsProjectIdIssuesIssueId403Schema
      | GetApiProjectsProjectIdIssuesIssueId404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueId(projectId, issueId, config);
    },
  });
}

/**
 * @summary Retrieves an issue
 * {@link /api/projects/:project_id/issues/:issue_id}
 */
export function useGetApiProjectsProjectIdIssuesIssueId<
  TData = GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueId401Schema
          | GetApiProjectsProjectIdIssuesIssueId403Schema
          | GetApiProjectsProjectIdIssuesIssueId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdQueryOptions(
      projectId,
      issueId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueId401Schema
      | GetApiProjectsProjectIdIssuesIssueId403Schema
      | GetApiProjectsProjectIdIssuesIssueId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id']
) =>
  [{ url: '/api/projects/:project_id/issues/:issue_id', params: { projectId: projectId, issueId: issueId } }] as const;

export type GetApiProjectsProjectIdIssuesIssueIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdSuspenseQueryKey(projectId, issueId);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueId401Schema
      | GetApiProjectsProjectIdIssuesIssueId403Schema
      | GetApiProjectsProjectIdIssuesIssueId404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueId(projectId, issueId, config);
    },
  });
}

/**
 * @summary Retrieves an issue
 * {@link /api/projects/:project_id/issues/:issue_id}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdSuspense<
  TData = GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesIssueIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueId401Schema
          | GetApiProjectsProjectIdIssuesIssueId403Schema
          | GetApiProjectsProjectIdIssuesIssueId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdSuspenseQueryKey(projectId, issueId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdSuspenseQueryOptions(
      projectId,
      issueId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdIssuesIssueId401Schema
      | GetApiProjectsProjectIdIssuesIssueId403Schema
      | GetApiProjectsProjectIdIssuesIssueId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdIssuesIssueIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}' }] as const;

export type PatchApiProjectsProjectIdIssuesIssueIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdIssuesIssueIdMutationKey
>;

/**
 * @summary Updates an issue
 * {@link /api/projects/:project_id/issues/:issue_id}
 */
export function usePatchApiProjectsProjectIdIssuesIssueId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdIssuesIssueId401Schema
        | PatchApiProjectsProjectIdIssuesIssueId403Schema
        | PatchApiProjectsProjectIdIssuesIssueId404Schema
        | PatchApiProjectsProjectIdIssuesIssueId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'];
        issueId: PatchApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'];
        data?: PatchApiProjectsProjectIdIssuesIssueIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdIssuesIssueIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdIssuesIssueIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdIssuesIssueId401Schema
      | PatchApiProjectsProjectIdIssuesIssueId403Schema
      | PatchApiProjectsProjectIdIssuesIssueId404Schema
      | PatchApiProjectsProjectIdIssuesIssueId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'];
      issueId: PatchApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'];
      data?: PatchApiProjectsProjectIdIssuesIssueIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return patchApiProjectsProjectIdIssuesIssueId(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdIssuesIssueIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}' }] as const;

export type DeleteApiProjectsProjectIdIssuesIssueIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdIssuesIssueIdMutationKey
>;

/**
 * @summary Deletes an issue
 * {@link /api/projects/:project_id/issues/:issue_id}
 */
export function useDeleteApiProjectsProjectIdIssuesIssueId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdIssuesIssueId400Schema
        | DeleteApiProjectsProjectIdIssuesIssueId401Schema
        | DeleteApiProjectsProjectIdIssuesIssueId403Schema
        | DeleteApiProjectsProjectIdIssuesIssueId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'];
        issueId: DeleteApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdIssuesIssueIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdIssuesIssueIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdIssuesIssueId400Schema
      | DeleteApiProjectsProjectIdIssuesIssueId401Schema
      | DeleteApiProjectsProjectIdIssuesIssueId403Schema
      | DeleteApiProjectsProjectIdIssuesIssueId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdIssuesIssueIdPathParamsSchema['project_id'];
      issueId: DeleteApiProjectsProjectIdIssuesIssueIdPathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return deleteApiProjectsProjectIdIssuesIssueId(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdArchiveMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/archive' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdArchiveMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdArchiveMutationKey
>;

/**
 * @summary Archives an issue
 * {@link /api/projects/:project_id/issues/:issue_id/archive}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdArchive(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdArchiveMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdArchive401Schema
        | PostApiProjectsProjectIdIssuesIssueIdArchive403Schema
        | PostApiProjectsProjectIdIssuesIssueIdArchive404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdArchivePathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdArchivePathParamsSchema['issue_id'];
        data?: PostApiProjectsProjectIdIssuesIssueIdArchiveMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdArchiveMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdArchiveMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdArchiveMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdArchive401Schema
      | PostApiProjectsProjectIdIssuesIssueIdArchive403Schema
      | PostApiProjectsProjectIdIssuesIssueIdArchive404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdArchivePathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdArchivePathParamsSchema['issue_id'];
      data?: PostApiProjectsProjectIdIssuesIssueIdArchiveMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdArchive(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdExportMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/export' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdExportMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdExportMutationKey
>;

/**
 * @summary Requests issue export
 * {@link /api/projects/:project_id/issues/:issue_id/export}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdExport(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdExportMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdExport401Schema
        | PostApiProjectsProjectIdIssuesIssueIdExport403Schema
        | PostApiProjectsProjectIdIssuesIssueIdExport404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdExportPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdExportPathParamsSchema['issue_id'];
        data?: PostApiProjectsProjectIdIssuesIssueIdExportMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdExportMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdExportMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdExportMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdExport401Schema
      | PostApiProjectsProjectIdIssuesIssueIdExport403Schema
      | PostApiProjectsProjectIdIssuesIssueIdExport404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdExportPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdExportPathParamsSchema['issue_id'];
      data?: PostApiProjectsProjectIdIssuesIssueIdExportMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdExport(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdRestoreMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/restore' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdRestoreMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdRestoreMutationKey
>;

/**
 * @summary Restores an archived issue
 * {@link /api/projects/:project_id/issues/:issue_id/restore}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdRestore(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdRestoreMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdRestore401Schema
        | PostApiProjectsProjectIdIssuesIssueIdRestore403Schema
        | PostApiProjectsProjectIdIssuesIssueIdRestore404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdRestorePathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdRestorePathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdRestoreMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdRestoreMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdRestore401Schema
      | PostApiProjectsProjectIdIssuesIssueIdRestore403Schema
      | PostApiProjectsProjectIdIssuesIssueIdRestore404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdRestorePathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdRestorePathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return postApiProjectsProjectIdIssuesIssueIdRestore(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/update_impact' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationKey
>;

/**
 * @summary Updates impact
 * {@link /api/projects/:project_id/issues/:issue_id/update_impact}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdUpdateImpact(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact401Schema
        | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact403Schema
        | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact404Schema
        | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactPathParamsSchema['issue_id'];
        data?: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact401Schema
      | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact403Schema
      | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact404Schema
      | PostApiProjectsProjectIdIssuesIssueIdUpdateImpact422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactPathParamsSchema['issue_id'];
      data?: PostApiProjectsProjectIdIssuesIssueIdUpdateImpactMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, issueId, data }) => {
      return postApiProjectsProjectIdIssuesIssueIdUpdateImpact(projectId, issueId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdDashboardsIssuesStalenessCountQueryKey = (
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessCountPathParamsSchema['project_id']
) =>
  [{ url: '/api/projects/:project_id/dashboards/issues_staleness/count', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDashboardsIssuesStalenessCountQueryKey
>;

export function getApiProjectsProjectIdDashboardsIssuesStalenessCountQueryOptions(
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessCountPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDashboardsIssuesStalenessCountQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount401Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount403Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount404Schema
    >,
    GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDashboardsIssuesStalenessCount(projectId, config);
    },
  });
}

/**
 * @summary Retrieves issues staleness count
 * {@link /api/projects/:project_id/dashboards/issues_staleness/count}
 */
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCount<
  TData = GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryKey,
>(
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessCountPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDashboardsIssuesStalenessCount401Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessCount403Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessCount404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdDashboardsIssuesStalenessCountQueryKey(projectId);

  const query = useQuery({
    ...(getApiProjectsProjectIdDashboardsIssuesStalenessCountQueryOptions(
      projectId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount401Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount403Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDashboardsIssuesStalenessCountSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessCountPathParamsSchema['project_id']
) =>
  [{ url: '/api/projects/:project_id/dashboards/issues_staleness/count', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdDashboardsIssuesStalenessCountSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDashboardsIssuesStalenessCountSuspenseQueryKey
>;

export function getApiProjectsProjectIdDashboardsIssuesStalenessCountSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessCountPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDashboardsIssuesStalenessCountSuspenseQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount401Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount403Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount404Schema
    >,
    GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDashboardsIssuesStalenessCount(projectId, config);
    },
  });
}

/**
 * @summary Retrieves issues staleness count
 * {@link /api/projects/:project_id/dashboards/issues_staleness/count}
 */
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessCountSuspense<
  TData = GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDashboardsIssuesStalenessCountSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessCountPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdDashboardsIssuesStalenessCountQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDashboardsIssuesStalenessCount401Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessCount403Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessCount404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdDashboardsIssuesStalenessCountSuspenseQueryKey(projectId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdDashboardsIssuesStalenessCountSuspenseQueryOptions(
      projectId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount401Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount403Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessCount404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryKey = (
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/dashboards/issues_staleness/issues', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryKey
>;

export function getApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryOptions(
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues400Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues401Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues403Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues404Schema
    >,
    GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDashboardsIssuesStalenessIssues(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves issues list
 * {@link /api/projects/:project_id/dashboards/issues_staleness/issues}
 */
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssues<
  TData = GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryKey,
>(
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues400Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues401Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues403Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryOptions(
      projectId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues400Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues401Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues403Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdDashboardsIssuesStalenessIssuesSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/dashboards/issues_staleness/issues', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdDashboardsIssuesStalenessIssuesSuspenseQueryKey
>;

export function getApiProjectsProjectIdDashboardsIssuesStalenessIssuesSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdDashboardsIssuesStalenessIssuesSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues400Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues401Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues403Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues404Schema
    >,
    GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdDashboardsIssuesStalenessIssues(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves issues list
 * {@link /api/projects/:project_id/dashboards/issues_staleness/issues}
 */
export function useGetApiProjectsProjectIdDashboardsIssuesStalenessIssuesSuspense<
  TData = GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdDashboardsIssuesStalenessIssuesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues400Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues401Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues403Schema
          | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdDashboardsIssuesStalenessIssuesSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdDashboardsIssuesStalenessIssuesSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues400Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues401Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues403Schema
      | GetApiProjectsProjectIdDashboardsIssuesStalenessIssues404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdLocationsQueryKey = (
  projectId: GetApiProjectsProjectIdLocationsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdLocationsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/locations', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdLocationsQueryKey = ReturnType<typeof getApiProjectsProjectIdLocationsQueryKey>;

export function getApiProjectsProjectIdLocationsQueryOptions(
  projectId: GetApiProjectsProjectIdLocationsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdLocationsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdLocationsQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdLocationsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdLocations401Schema
      | GetApiProjectsProjectIdLocations403Schema
      | GetApiProjectsProjectIdLocations404Schema
    >,
    GetApiProjectsProjectIdLocationsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdLocations(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves locations
 * {@link /api/projects/:project_id/locations}
 */
export function useGetApiProjectsProjectIdLocations<
  TData = GetApiProjectsProjectIdLocationsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdLocationsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdLocationsQueryKey,
>(
  projectId: GetApiProjectsProjectIdLocationsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdLocationsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdLocationsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdLocations401Schema
          | GetApiProjectsProjectIdLocations403Schema
          | GetApiProjectsProjectIdLocations404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdLocationsQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdLocationsQueryOptions(projectId, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdLocations401Schema
      | GetApiProjectsProjectIdLocations403Schema
      | GetApiProjectsProjectIdLocations404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdLocationsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdLocationsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdLocationsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/locations', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdLocationsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdLocationsSuspenseQueryKey
>;

export function getApiProjectsProjectIdLocationsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdLocationsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdLocationsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdLocationsSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdLocationsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdLocations401Schema
      | GetApiProjectsProjectIdLocations403Schema
      | GetApiProjectsProjectIdLocations404Schema
    >,
    GetApiProjectsProjectIdLocationsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdLocations(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves locations
 * {@link /api/projects/:project_id/locations}
 */
export function useGetApiProjectsProjectIdLocationsSuspense<
  TData = GetApiProjectsProjectIdLocationsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdLocationsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdLocationsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdLocationsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdLocationsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdLocationsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdLocations401Schema
          | GetApiProjectsProjectIdLocations403Schema
          | GetApiProjectsProjectIdLocations404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdLocationsSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdLocationsSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdLocations401Schema
      | GetApiProjectsProjectIdLocations403Schema
      | GetApiProjectsProjectIdLocations404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdLocationsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/locations' }] as const;

export type PostApiProjectsProjectIdLocationsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdLocationsMutationKey
>;

/**
 * @summary Creates a location
 * {@link /api/projects/:project_id/locations}
 */
export function usePostApiProjectsProjectIdLocations(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdLocationsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdLocations401Schema
        | PostApiProjectsProjectIdLocations403Schema
        | PostApiProjectsProjectIdLocations404Schema
        | PostApiProjectsProjectIdLocations422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdLocationsPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdLocationsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdLocationsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdLocationsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdLocationsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdLocations401Schema
      | PostApiProjectsProjectIdLocations403Schema
      | PostApiProjectsProjectIdLocations404Schema
      | PostApiProjectsProjectIdLocations422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdLocationsPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdLocationsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdLocations(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdLocationsLocationIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/locations/{location_id}' }] as const;

export type PatchApiProjectsProjectIdLocationsLocationIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdLocationsLocationIdMutationKey
>;

/**
 * @summary Updates a location
 * {@link /api/projects/:project_id/locations/:location_id}
 */
export function usePatchApiProjectsProjectIdLocationsLocationId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdLocationsLocationId401Schema
        | PatchApiProjectsProjectIdLocationsLocationId403Schema
        | PatchApiProjectsProjectIdLocationsLocationId404Schema
        | PatchApiProjectsProjectIdLocationsLocationId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'];
        locationId: PatchApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'];
        data?: PatchApiProjectsProjectIdLocationsLocationIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdLocationsLocationIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdLocationsLocationIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdLocationsLocationId401Schema
      | PatchApiProjectsProjectIdLocationsLocationId403Schema
      | PatchApiProjectsProjectIdLocationsLocationId404Schema
      | PatchApiProjectsProjectIdLocationsLocationId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'];
      locationId: PatchApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'];
      data?: PatchApiProjectsProjectIdLocationsLocationIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, locationId, data }) => {
      return patchApiProjectsProjectIdLocationsLocationId(projectId, locationId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdLocationsLocationIdQueryKey = (
  projectId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'],
  locationId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id']
) =>
  [
    {
      url: '/api/projects/:project_id/locations/:location_id',
      params: { projectId: projectId, locationId: locationId },
    },
  ] as const;

export type GetApiProjectsProjectIdLocationsLocationIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdLocationsLocationIdQueryKey
>;

export function getApiProjectsProjectIdLocationsLocationIdQueryOptions(
  projectId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'],
  locationId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdLocationsLocationIdQueryKey(projectId, locationId);
  return queryOptions<
    GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdLocationsLocationId401Schema
      | GetApiProjectsProjectIdLocationsLocationId403Schema
      | GetApiProjectsProjectIdLocationsLocationId404Schema
    >,
    GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && locationId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdLocationsLocationId(projectId, locationId, config);
    },
  });
}

/**
 * @summary Retrieves a location
 * {@link /api/projects/:project_id/locations/:location_id}
 */
export function useGetApiProjectsProjectIdLocationsLocationId<
  TData = GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdLocationsLocationIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'],
  locationId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdLocationsLocationId401Schema
          | GetApiProjectsProjectIdLocationsLocationId403Schema
          | GetApiProjectsProjectIdLocationsLocationId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdLocationsLocationIdQueryKey(projectId, locationId);

  const query = useQuery({
    ...(getApiProjectsProjectIdLocationsLocationIdQueryOptions(
      projectId,
      locationId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdLocationsLocationId401Schema
      | GetApiProjectsProjectIdLocationsLocationId403Schema
      | GetApiProjectsProjectIdLocationsLocationId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdLocationsLocationIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'],
  locationId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id']
) =>
  [
    {
      url: '/api/projects/:project_id/locations/:location_id',
      params: { projectId: projectId, locationId: locationId },
    },
  ] as const;

export type GetApiProjectsProjectIdLocationsLocationIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdLocationsLocationIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdLocationsLocationIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'],
  locationId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdLocationsLocationIdSuspenseQueryKey(projectId, locationId);
  return queryOptions<
    GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdLocationsLocationId401Schema
      | GetApiProjectsProjectIdLocationsLocationId403Schema
      | GetApiProjectsProjectIdLocationsLocationId404Schema
    >,
    GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && locationId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdLocationsLocationId(projectId, locationId, config);
    },
  });
}

/**
 * @summary Retrieves a location
 * {@link /api/projects/:project_id/locations/:location_id}
 */
export function useGetApiProjectsProjectIdLocationsLocationIdSuspense<
  TData = GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdLocationsLocationIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'],
  locationId: GetApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdLocationsLocationIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdLocationsLocationId401Schema
          | GetApiProjectsProjectIdLocationsLocationId403Schema
          | GetApiProjectsProjectIdLocationsLocationId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdLocationsLocationIdSuspenseQueryKey(projectId, locationId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdLocationsLocationIdSuspenseQueryOptions(
      projectId,
      locationId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdLocationsLocationId401Schema
      | GetApiProjectsProjectIdLocationsLocationId403Schema
      | GetApiProjectsProjectIdLocationsLocationId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const deleteApiProjectsProjectIdLocationsLocationIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/locations/{location_id}' }] as const;

export type DeleteApiProjectsProjectIdLocationsLocationIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdLocationsLocationIdMutationKey
>;

/**
 * @summary Deletes a location
 * {@link /api/projects/:project_id/locations/:location_id}
 */
export function useDeleteApiProjectsProjectIdLocationsLocationId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdLocationsLocationId401Schema
        | DeleteApiProjectsProjectIdLocationsLocationId403Schema
        | DeleteApiProjectsProjectIdLocationsLocationId404Schema
        | DeleteApiProjectsProjectIdLocationsLocationId422Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'];
        locationId: DeleteApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdLocationsLocationIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdLocationsLocationIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdLocationsLocationId401Schema
      | DeleteApiProjectsProjectIdLocationsLocationId403Schema
      | DeleteApiProjectsProjectIdLocationsLocationId404Schema
      | DeleteApiProjectsProjectIdLocationsLocationId422Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdLocationsLocationIdPathParamsSchema['project_id'];
      locationId: DeleteApiProjectsProjectIdLocationsLocationIdPathParamsSchema['location_id'];
    }
  >({
    mutationFn: async ({ projectId, locationId }) => {
      return deleteApiProjectsProjectIdLocationsLocationId(projectId, locationId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdLocationsLocationIdSortMutationKey = () =>
  [{ url: '/api/projects/{project_id}/locations/{location_id}/sort' }] as const;

export type PostApiProjectsProjectIdLocationsLocationIdSortMutationKey = ReturnType<
  typeof postApiProjectsProjectIdLocationsLocationIdSortMutationKey
>;

/**
 * @summary Sorts locations
 * {@link /api/projects/:project_id/locations/:location_id/sort}
 */
export function usePostApiProjectsProjectIdLocationsLocationIdSort(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdLocationsLocationIdSortMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdLocationsLocationIdSort401Schema
        | PostApiProjectsProjectIdLocationsLocationIdSort403Schema
        | PostApiProjectsProjectIdLocationsLocationIdSort404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdLocationsLocationIdSortPathParamsSchema['project_id'];
        locationId: PostApiProjectsProjectIdLocationsLocationIdSortPathParamsSchema['location_id'];
        data?: PostApiProjectsProjectIdLocationsLocationIdSortMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdLocationsLocationIdSortMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdLocationsLocationIdSortMutationKey();

  return useMutation<
    PostApiProjectsProjectIdLocationsLocationIdSortMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdLocationsLocationIdSort401Schema
      | PostApiProjectsProjectIdLocationsLocationIdSort403Schema
      | PostApiProjectsProjectIdLocationsLocationIdSort404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdLocationsLocationIdSortPathParamsSchema['project_id'];
      locationId: PostApiProjectsProjectIdLocationsLocationIdSortPathParamsSchema['location_id'];
      data?: PostApiProjectsProjectIdLocationsLocationIdSortMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, locationId, data }) => {
      return postApiProjectsProjectIdLocationsLocationIdSort(projectId, locationId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiNotificationsQueryKey = (params?: GetApiNotificationsQueryParamsSchema) =>
  [{ url: '/api/notifications' }, ...(params ? [params] : [])] as const;

export type GetApiNotificationsQueryKey = ReturnType<typeof getApiNotificationsQueryKey>;

export function getApiNotificationsQueryOptions(
  params?: GetApiNotificationsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiNotificationsQueryKey(params);
  return queryOptions<
    GetApiNotificationsQueryResponseSchema,
    ResponseErrorConfig<GetApiNotifications400Schema | GetApiNotifications401Schema>,
    GetApiNotificationsQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiNotifications(params, config);
    },
  });
}

/**
 * @summary Retrieves notifications
 * {@link /api/notifications}
 */
export function useGetApiNotifications<
  TData = GetApiNotificationsQueryResponseSchema,
  TQueryData = GetApiNotificationsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiNotificationsQueryKey,
>(
  params?: GetApiNotificationsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiNotificationsQueryResponseSchema,
        ResponseErrorConfig<GetApiNotifications400Schema | GetApiNotifications401Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiNotificationsQueryKey(params);

  const query = useQuery({
    ...(getApiNotificationsQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiNotifications400Schema | GetApiNotifications401Schema>> & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiNotificationsSuspenseQueryKey = (params?: GetApiNotificationsQueryParamsSchema) =>
  [{ url: '/api/notifications' }, ...(params ? [params] : [])] as const;

export type GetApiNotificationsSuspenseQueryKey = ReturnType<typeof getApiNotificationsSuspenseQueryKey>;

export function getApiNotificationsSuspenseQueryOptions(
  params?: GetApiNotificationsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiNotificationsSuspenseQueryKey(params);
  return queryOptions<
    GetApiNotificationsQueryResponseSchema,
    ResponseErrorConfig<GetApiNotifications400Schema | GetApiNotifications401Schema>,
    GetApiNotificationsQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiNotifications(params, config);
    },
  });
}

/**
 * @summary Retrieves notifications
 * {@link /api/notifications}
 */
export function useGetApiNotificationsSuspense<
  TData = GetApiNotificationsQueryResponseSchema,
  TQueryData = GetApiNotificationsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiNotificationsSuspenseQueryKey,
>(
  params?: GetApiNotificationsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiNotificationsQueryResponseSchema,
        ResponseErrorConfig<GetApiNotifications400Schema | GetApiNotifications401Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiNotificationsSuspenseQueryKey(params);

  const query = useSuspenseQuery({
    ...(getApiNotificationsSuspenseQueryOptions(params, config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<GetApiNotifications400Schema | GetApiNotifications401Schema>
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiNotificationsOverviewQueryKey = () => [{ url: '/api/notifications/overview' }] as const;

export type GetApiNotificationsOverviewQueryKey = ReturnType<typeof getApiNotificationsOverviewQueryKey>;

export function getApiNotificationsOverviewQueryOptions(
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiNotificationsOverviewQueryKey();
  return queryOptions<
    GetApiNotificationsOverviewQueryResponseSchema,
    ResponseErrorConfig<GetApiNotificationsOverview401Schema>,
    GetApiNotificationsOverviewQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiNotificationsOverview(config);
    },
  });
}

/**
 * @summary Retrieves notifications overview
 * {@link /api/notifications/overview}
 */
export function useGetApiNotificationsOverview<
  TData = GetApiNotificationsOverviewQueryResponseSchema,
  TQueryData = GetApiNotificationsOverviewQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiNotificationsOverviewQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiNotificationsOverviewQueryResponseSchema,
        ResponseErrorConfig<GetApiNotificationsOverview401Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiNotificationsOverviewQueryKey();

  const query = useQuery({
    ...(getApiNotificationsOverviewQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiNotificationsOverview401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiNotificationsOverviewSuspenseQueryKey = () => [{ url: '/api/notifications/overview' }] as const;

export type GetApiNotificationsOverviewSuspenseQueryKey = ReturnType<
  typeof getApiNotificationsOverviewSuspenseQueryKey
>;

export function getApiNotificationsOverviewSuspenseQueryOptions(
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiNotificationsOverviewSuspenseQueryKey();
  return queryOptions<
    GetApiNotificationsOverviewQueryResponseSchema,
    ResponseErrorConfig<GetApiNotificationsOverview401Schema>,
    GetApiNotificationsOverviewQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiNotificationsOverview(config);
    },
  });
}

/**
 * @summary Retrieves notifications overview
 * {@link /api/notifications/overview}
 */
export function useGetApiNotificationsOverviewSuspense<
  TData = GetApiNotificationsOverviewQueryResponseSchema,
  TQueryData = GetApiNotificationsOverviewQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiNotificationsOverviewSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiNotificationsOverviewQueryResponseSchema,
        ResponseErrorConfig<GetApiNotificationsOverview401Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiNotificationsOverviewSuspenseQueryKey();

  const query = useSuspenseQuery({
    ...(getApiNotificationsOverviewSuspenseQueryOptions(config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiNotificationsOverview401Schema>> & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiNotificationsNotificationIdMarkReadMutationKey = () =>
  [{ url: '/api/notifications/{notification_id}/mark_read' }] as const;

export type PostApiNotificationsNotificationIdMarkReadMutationKey = ReturnType<
  typeof postApiNotificationsNotificationIdMarkReadMutationKey
>;

/**
 * @summary Marks notification as read
 * {@link /api/notifications/:notification_id/mark_read}
 */
export function usePostApiNotificationsNotificationIdMarkRead(
  options: {
    mutation?: UseMutationOptions<
      PostApiNotificationsNotificationIdMarkReadMutationResponseSchema,
      ResponseErrorConfig<
        PostApiNotificationsNotificationIdMarkRead401Schema | PostApiNotificationsNotificationIdMarkRead404Schema
      >,
      {
        notificationId: PostApiNotificationsNotificationIdMarkReadPathParamsSchema['notification_id'];
        data?: PostApiNotificationsNotificationIdMarkReadMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiNotificationsNotificationIdMarkReadMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiNotificationsNotificationIdMarkReadMutationKey();

  return useMutation<
    PostApiNotificationsNotificationIdMarkReadMutationResponseSchema,
    ResponseErrorConfig<
      PostApiNotificationsNotificationIdMarkRead401Schema | PostApiNotificationsNotificationIdMarkRead404Schema
    >,
    {
      notificationId: PostApiNotificationsNotificationIdMarkReadPathParamsSchema['notification_id'];
      data?: PostApiNotificationsNotificationIdMarkReadMutationRequestSchema;
    }
  >({
    mutationFn: async ({ notificationId, data }) => {
      return postApiNotificationsNotificationIdMarkRead(notificationId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiNotificationsMarkAllReadMutationKey = () => [{ url: '/api/notifications/mark_all_read' }] as const;

export type PostApiNotificationsMarkAllReadMutationKey = ReturnType<typeof postApiNotificationsMarkAllReadMutationKey>;

/**
 * @summary Marks all notifications as read
 * {@link /api/notifications/mark_all_read}
 */
export function usePostApiNotificationsMarkAllRead(
  options: {
    mutation?: UseMutationOptions<
      PostApiNotificationsMarkAllReadMutationResponseSchema,
      ResponseErrorConfig<PostApiNotificationsMarkAllRead401Schema>
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiNotificationsMarkAllReadMutationKey();

  return useMutation<
    PostApiNotificationsMarkAllReadMutationResponseSchema,
    ResponseErrorConfig<PostApiNotificationsMarkAllRead401Schema>
  >({
    mutationFn: async () => {
      return postApiNotificationsMarkAllRead(config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiOnboardingQueryKey = () => [{ url: '/api/onboarding' }] as const;

export type GetApiOnboardingQueryKey = ReturnType<typeof getApiOnboardingQueryKey>;

export function getApiOnboardingQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiOnboardingQueryKey();
  return queryOptions<
    GetApiOnboardingQueryResponseSchema,
    ResponseErrorConfig<GetApiOnboarding401Schema | GetApiOnboarding404Schema>,
    GetApiOnboardingQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiOnboarding(config);
    },
  });
}

/**
 * @summary Retrieve the current user onboarding data
 * {@link /api/onboarding}
 */
export function useGetApiOnboarding<
  TData = GetApiOnboardingQueryResponseSchema,
  TQueryData = GetApiOnboardingQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiOnboardingQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiOnboardingQueryResponseSchema,
        ResponseErrorConfig<GetApiOnboarding401Schema | GetApiOnboarding404Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiOnboardingQueryKey();

  const query = useQuery({
    ...(getApiOnboardingQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiOnboarding401Schema | GetApiOnboarding404Schema>> & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiOnboardingSuspenseQueryKey = () => [{ url: '/api/onboarding' }] as const;

export type GetApiOnboardingSuspenseQueryKey = ReturnType<typeof getApiOnboardingSuspenseQueryKey>;

export function getApiOnboardingSuspenseQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiOnboardingSuspenseQueryKey();
  return queryOptions<
    GetApiOnboardingQueryResponseSchema,
    ResponseErrorConfig<GetApiOnboarding401Schema | GetApiOnboarding404Schema>,
    GetApiOnboardingQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiOnboarding(config);
    },
  });
}

/**
 * @summary Retrieve the current user onboarding data
 * {@link /api/onboarding}
 */
export function useGetApiOnboardingSuspense<
  TData = GetApiOnboardingQueryResponseSchema,
  TQueryData = GetApiOnboardingQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiOnboardingSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiOnboardingQueryResponseSchema,
        ResponseErrorConfig<GetApiOnboarding401Schema | GetApiOnboarding404Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiOnboardingSuspenseQueryKey();

  const query = useSuspenseQuery({
    ...(getApiOnboardingSuspenseQueryOptions(config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiOnboarding401Schema | GetApiOnboarding404Schema>> & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiOnboardingMutationKey = () => [{ url: '/api/onboarding' }] as const;

export type PatchApiOnboardingMutationKey = ReturnType<typeof patchApiOnboardingMutationKey>;

/**
 * @summary Updates the current user onboarding
 * {@link /api/onboarding}
 */
export function usePatchApiOnboarding(
  options: {
    mutation?: UseMutationOptions<
      PatchApiOnboardingMutationResponseSchema,
      ResponseErrorConfig<PatchApiOnboarding401Schema | PatchApiOnboarding404Schema>,
      { data?: PatchApiOnboardingMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PatchApiOnboardingMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiOnboardingMutationKey();

  return useMutation<
    PatchApiOnboardingMutationResponseSchema,
    ResponseErrorConfig<PatchApiOnboarding401Schema | PatchApiOnboarding404Schema>,
    { data?: PatchApiOnboardingMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return patchApiOnboarding(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiOnboardingFinishMutationKey = () => [{ url: '/api/onboarding/finish' }] as const;

export type PostApiOnboardingFinishMutationKey = ReturnType<typeof postApiOnboardingFinishMutationKey>;

/**
 * @summary Finishes onboarding
 * {@link /api/onboarding/finish}
 */
export function usePostApiOnboardingFinish(
  options: {
    mutation?: UseMutationOptions<
      PostApiOnboardingFinishMutationResponseSchema,
      ResponseErrorConfig<
        PostApiOnboardingFinish401Schema | PostApiOnboardingFinish404Schema | PostApiOnboardingFinish422Schema
      >,
      { data?: PostApiOnboardingFinishMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiOnboardingFinishMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiOnboardingFinishMutationKey();

  return useMutation<
    PostApiOnboardingFinishMutationResponseSchema,
    ResponseErrorConfig<
      PostApiOnboardingFinish401Schema | PostApiOnboardingFinish404Schema | PostApiOnboardingFinish422Schema
    >,
    { data?: PostApiOnboardingFinishMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiOnboardingFinish(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiOrgsMutationKey = () => [{ url: '/api/orgs' }] as const;

export type PostApiOrgsMutationKey = ReturnType<typeof postApiOrgsMutationKey>;

/**
 * @summary Creates an org
 * {@link /api/orgs}
 */
export function usePostApiOrgs(
  options: {
    mutation?: UseMutationOptions<
      PostApiOrgsMutationResponseSchema,
      ResponseErrorConfig<PostApiOrgs401Schema | PostApiOrgs422Schema>,
      { data?: PostApiOrgsMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiOrgsMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiOrgsMutationKey();

  return useMutation<
    PostApiOrgsMutationResponseSchema,
    ResponseErrorConfig<PostApiOrgs401Schema | PostApiOrgs422Schema>,
    { data?: PostApiOrgsMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiOrgs(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiOrgsQueryKey = () => [{ url: '/api/orgs' }] as const;

export type GetApiOrgsQueryKey = ReturnType<typeof getApiOrgsQueryKey>;

export function getApiOrgsQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiOrgsQueryKey();
  return queryOptions<
    GetApiOrgsQueryResponseSchema,
    ResponseErrorConfig<GetApiOrgs401Schema>,
    GetApiOrgsQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiOrgs(config);
    },
  });
}

/**
 * @summary Retrieves orgs
 * {@link /api/orgs}
 */
export function useGetApiOrgs<
  TData = GetApiOrgsQueryResponseSchema,
  TQueryData = GetApiOrgsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiOrgsQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiOrgsQueryResponseSchema,
        ResponseErrorConfig<GetApiOrgs401Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiOrgsQueryKey();

  const query = useQuery({
    ...(getApiOrgsQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiOrgs401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiOrgsSuspenseQueryKey = () => [{ url: '/api/orgs' }] as const;

export type GetApiOrgsSuspenseQueryKey = ReturnType<typeof getApiOrgsSuspenseQueryKey>;

export function getApiOrgsSuspenseQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiOrgsSuspenseQueryKey();
  return queryOptions<
    GetApiOrgsQueryResponseSchema,
    ResponseErrorConfig<GetApiOrgs401Schema>,
    GetApiOrgsQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiOrgs(config);
    },
  });
}

/**
 * @summary Retrieves orgs
 * {@link /api/orgs}
 */
export function useGetApiOrgsSuspense<
  TData = GetApiOrgsQueryResponseSchema,
  TQueryData = GetApiOrgsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiOrgsSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<GetApiOrgsQueryResponseSchema, ResponseErrorConfig<GetApiOrgs401Schema>, TData, TQueryKey>
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiOrgsSuspenseQueryKey();

  const query = useSuspenseQuery({
    ...(getApiOrgsSuspenseQueryOptions(config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiOrgs401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiOrgsOrgIdMutationKey = () => [{ url: '/api/orgs/{org_id}' }] as const;

export type PatchApiOrgsOrgIdMutationKey = ReturnType<typeof patchApiOrgsOrgIdMutationKey>;

/**
 * @summary Updates an org
 * {@link /api/orgs/:org_id}
 */
export function usePatchApiOrgsOrgId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiOrgsOrgIdMutationResponseSchema,
      ResponseErrorConfig<PatchApiOrgsOrgId401Schema>,
      { orgId: PatchApiOrgsOrgIdPathParamsSchema['org_id']; data?: PatchApiOrgsOrgIdMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PatchApiOrgsOrgIdMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiOrgsOrgIdMutationKey();

  return useMutation<
    PatchApiOrgsOrgIdMutationResponseSchema,
    ResponseErrorConfig<PatchApiOrgsOrgId401Schema>,
    { orgId: PatchApiOrgsOrgIdPathParamsSchema['org_id']; data?: PatchApiOrgsOrgIdMutationRequestSchema }
  >({
    mutationFn: async ({ orgId, data }) => {
      return patchApiOrgsOrgId(orgId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiOrgsOrgIdQueryKey = (orgId: GetApiOrgsOrgIdPathParamsSchema['org_id']) =>
  [{ url: '/api/orgs/:org_id', params: { orgId: orgId } }] as const;

export type GetApiOrgsOrgIdQueryKey = ReturnType<typeof getApiOrgsOrgIdQueryKey>;

export function getApiOrgsOrgIdQueryOptions(
  orgId: GetApiOrgsOrgIdPathParamsSchema['org_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiOrgsOrgIdQueryKey(orgId);
  return queryOptions<
    GetApiOrgsOrgIdQueryResponseSchema,
    ResponseErrorConfig<GetApiOrgsOrgId401Schema | GetApiOrgsOrgId403Schema | GetApiOrgsOrgId404Schema>,
    GetApiOrgsOrgIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!orgId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiOrgsOrgId(orgId, config);
    },
  });
}

/**
 * @summary Retrieves an org
 * {@link /api/orgs/:org_id}
 */
export function useGetApiOrgsOrgId<
  TData = GetApiOrgsOrgIdQueryResponseSchema,
  TQueryData = GetApiOrgsOrgIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiOrgsOrgIdQueryKey,
>(
  orgId: GetApiOrgsOrgIdPathParamsSchema['org_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiOrgsOrgIdQueryResponseSchema,
        ResponseErrorConfig<GetApiOrgsOrgId401Schema | GetApiOrgsOrgId403Schema | GetApiOrgsOrgId404Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiOrgsOrgIdQueryKey(orgId);

  const query = useQuery({
    ...(getApiOrgsOrgIdQueryOptions(orgId, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<GetApiOrgsOrgId401Schema | GetApiOrgsOrgId403Schema | GetApiOrgsOrgId404Schema>
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiOrgsOrgIdSuspenseQueryKey = (orgId: GetApiOrgsOrgIdPathParamsSchema['org_id']) =>
  [{ url: '/api/orgs/:org_id', params: { orgId: orgId } }] as const;

export type GetApiOrgsOrgIdSuspenseQueryKey = ReturnType<typeof getApiOrgsOrgIdSuspenseQueryKey>;

export function getApiOrgsOrgIdSuspenseQueryOptions(
  orgId: GetApiOrgsOrgIdPathParamsSchema['org_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiOrgsOrgIdSuspenseQueryKey(orgId);
  return queryOptions<
    GetApiOrgsOrgIdQueryResponseSchema,
    ResponseErrorConfig<GetApiOrgsOrgId401Schema | GetApiOrgsOrgId403Schema | GetApiOrgsOrgId404Schema>,
    GetApiOrgsOrgIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!orgId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiOrgsOrgId(orgId, config);
    },
  });
}

/**
 * @summary Retrieves an org
 * {@link /api/orgs/:org_id}
 */
export function useGetApiOrgsOrgIdSuspense<
  TData = GetApiOrgsOrgIdQueryResponseSchema,
  TQueryData = GetApiOrgsOrgIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiOrgsOrgIdSuspenseQueryKey,
>(
  orgId: GetApiOrgsOrgIdPathParamsSchema['org_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiOrgsOrgIdQueryResponseSchema,
        ResponseErrorConfig<GetApiOrgsOrgId401Schema | GetApiOrgsOrgId403Schema | GetApiOrgsOrgId404Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiOrgsOrgIdSuspenseQueryKey(orgId);

  const query = useSuspenseQuery({
    ...(getApiOrgsOrgIdSuspenseQueryOptions(orgId, config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<GetApiOrgsOrgId401Schema | GetApiOrgsOrgId403Schema | GetApiOrgsOrgId404Schema>
  > & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiOrgsCheckDomainMutationKey = () => [{ url: '/api/orgs/check_domain' }] as const;

export type PostApiOrgsCheckDomainMutationKey = ReturnType<typeof postApiOrgsCheckDomainMutationKey>;

/**
 * @summary Checks org domain
 * {@link /api/orgs/check_domain}
 */
export function usePostApiOrgsCheckDomain(
  options: {
    mutation?: UseMutationOptions<
      PostApiOrgsCheckDomainMutationResponseSchema,
      ResponseErrorConfig<PostApiOrgsCheckDomain401Schema>,
      { data?: PostApiOrgsCheckDomainMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiOrgsCheckDomainMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiOrgsCheckDomainMutationKey();

  return useMutation<
    PostApiOrgsCheckDomainMutationResponseSchema,
    ResponseErrorConfig<PostApiOrgsCheckDomain401Schema>,
    { data?: PostApiOrgsCheckDomainMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiOrgsCheckDomain(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiOrgsOrgIdResendVerificationEmailMutationKey = () =>
  [{ url: '/api/orgs/{org_id}/resend_verification_email' }] as const;

export type PostApiOrgsOrgIdResendVerificationEmailMutationKey = ReturnType<
  typeof postApiOrgsOrgIdResendVerificationEmailMutationKey
>;

/**
 * @summary Resends verification email
 * {@link /api/orgs/:org_id/resend_verification_email}
 */
export function usePostApiOrgsOrgIdResendVerificationEmail(
  options: {
    mutation?: UseMutationOptions<
      PostApiOrgsOrgIdResendVerificationEmailMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiOrgsOrgIdResendVerificationEmail401Schema
        | PostApiOrgsOrgIdResendVerificationEmail403Schema
        | PostApiOrgsOrgIdResendVerificationEmail404Schema
      >,
      { orgId: PostApiOrgsOrgIdResendVerificationEmailPathParamsSchema['org_id'] }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiOrgsOrgIdResendVerificationEmailMutationKey();

  return useMutation<
    PostApiOrgsOrgIdResendVerificationEmailMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiOrgsOrgIdResendVerificationEmail401Schema
      | PostApiOrgsOrgIdResendVerificationEmail403Schema
      | PostApiOrgsOrgIdResendVerificationEmail404Schema
    >,
    { orgId: PostApiOrgsOrgIdResendVerificationEmailPathParamsSchema['org_id'] }
  >({
    mutationFn: async ({ orgId }) => {
      return postApiOrgsOrgIdResendVerificationEmail(orgId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProductToursProductTourKeyQueryKey = (
  productTourKey: GetApiProductToursProductTourKeyPathParamsSchema['product_tour_key']
) => [{ url: '/api/product_tours/:product_tour_key', params: { productTourKey: productTourKey } }] as const;

export type GetApiProductToursProductTourKeyQueryKey = ReturnType<typeof getApiProductToursProductTourKeyQueryKey>;

export function getApiProductToursProductTourKeyQueryOptions(
  productTourKey: GetApiProductToursProductTourKeyPathParamsSchema['product_tour_key'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProductToursProductTourKeyQueryKey(productTourKey);
  return queryOptions<
    GetApiProductToursProductTourKeyQueryResponseSchema,
    ResponseErrorConfig<GetApiProductToursProductTourKey401Schema | GetApiProductToursProductTourKey404Schema>,
    GetApiProductToursProductTourKeyQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!productTourKey,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProductToursProductTourKey(productTourKey, config);
    },
  });
}

/**
 * @summary Retrieves the product tour status for the user
 * {@link /api/product_tours/:product_tour_key}
 */
export function useGetApiProductToursProductTourKey<
  TData = GetApiProductToursProductTourKeyQueryResponseSchema,
  TQueryData = GetApiProductToursProductTourKeyQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProductToursProductTourKeyQueryKey,
>(
  productTourKey: GetApiProductToursProductTourKeyPathParamsSchema['product_tour_key'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProductToursProductTourKeyQueryResponseSchema,
        ResponseErrorConfig<GetApiProductToursProductTourKey401Schema | GetApiProductToursProductTourKey404Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProductToursProductTourKeyQueryKey(productTourKey);

  const query = useQuery({
    ...(getApiProductToursProductTourKeyQueryOptions(productTourKey, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<GetApiProductToursProductTourKey401Schema | GetApiProductToursProductTourKey404Schema>
  > & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProductToursProductTourKeySuspenseQueryKey = (
  productTourKey: GetApiProductToursProductTourKeyPathParamsSchema['product_tour_key']
) => [{ url: '/api/product_tours/:product_tour_key', params: { productTourKey: productTourKey } }] as const;

export type GetApiProductToursProductTourKeySuspenseQueryKey = ReturnType<
  typeof getApiProductToursProductTourKeySuspenseQueryKey
>;

export function getApiProductToursProductTourKeySuspenseQueryOptions(
  productTourKey: GetApiProductToursProductTourKeyPathParamsSchema['product_tour_key'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProductToursProductTourKeySuspenseQueryKey(productTourKey);
  return queryOptions<
    GetApiProductToursProductTourKeyQueryResponseSchema,
    ResponseErrorConfig<GetApiProductToursProductTourKey401Schema | GetApiProductToursProductTourKey404Schema>,
    GetApiProductToursProductTourKeyQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!productTourKey,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProductToursProductTourKey(productTourKey, config);
    },
  });
}

/**
 * @summary Retrieves the product tour status for the user
 * {@link /api/product_tours/:product_tour_key}
 */
export function useGetApiProductToursProductTourKeySuspense<
  TData = GetApiProductToursProductTourKeyQueryResponseSchema,
  TQueryData = GetApiProductToursProductTourKeyQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProductToursProductTourKeySuspenseQueryKey,
>(
  productTourKey: GetApiProductToursProductTourKeyPathParamsSchema['product_tour_key'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProductToursProductTourKeyQueryResponseSchema,
        ResponseErrorConfig<GetApiProductToursProductTourKey401Schema | GetApiProductToursProductTourKey404Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProductToursProductTourKeySuspenseQueryKey(productTourKey);

  const query = useSuspenseQuery({
    ...(getApiProductToursProductTourKeySuspenseQueryOptions(
      productTourKey,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<GetApiProductToursProductTourKey401Schema | GetApiProductToursProductTourKey404Schema>
  > & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProductToursProductTourKeyMutationKey = () =>
  [{ url: '/api/product_tours/{product_tour_key}' }] as const;

export type PatchApiProductToursProductTourKeyMutationKey = ReturnType<
  typeof patchApiProductToursProductTourKeyMutationKey
>;

/**
 * @summary Updates the product tour status for the user
 * {@link /api/product_tours/:product_tour_key}
 */
export function usePatchApiProductToursProductTourKey(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProductToursProductTourKeyMutationResponseSchema,
      ResponseErrorConfig<PatchApiProductToursProductTourKey401Schema | PatchApiProductToursProductTourKey404Schema>,
      {
        productTourKey: PatchApiProductToursProductTourKeyPathParamsSchema['product_tour_key'];
        data?: PatchApiProductToursProductTourKeyMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProductToursProductTourKeyMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProductToursProductTourKeyMutationKey();

  return useMutation<
    PatchApiProductToursProductTourKeyMutationResponseSchema,
    ResponseErrorConfig<PatchApiProductToursProductTourKey401Schema | PatchApiProductToursProductTourKey404Schema>,
    {
      productTourKey: PatchApiProductToursProductTourKeyPathParamsSchema['product_tour_key'];
      data?: PatchApiProductToursProductTourKeyMutationRequestSchema;
    }
  >({
    mutationFn: async ({ productTourKey, data }) => {
      return patchApiProductToursProductTourKey(productTourKey, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdAccessRequestsQueryKey = (
  projectId: GetApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdAccessRequestsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/access_requests', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdAccessRequestsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdAccessRequestsQueryKey
>;

export function getApiProjectsProjectIdAccessRequestsQueryOptions(
  projectId: GetApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdAccessRequestsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdAccessRequestsQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdAccessRequests400Schema
      | GetApiProjectsProjectIdAccessRequests401Schema
      | GetApiProjectsProjectIdAccessRequests403Schema
      | GetApiProjectsProjectIdAccessRequests404Schema
    >,
    GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdAccessRequests(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves project pending access requests
 * {@link /api/projects/:project_id/access_requests}
 */
export function useGetApiProjectsProjectIdAccessRequests<
  TData = GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdAccessRequestsQueryKey,
>(
  projectId: GetApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdAccessRequestsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdAccessRequests400Schema
          | GetApiProjectsProjectIdAccessRequests401Schema
          | GetApiProjectsProjectIdAccessRequests403Schema
          | GetApiProjectsProjectIdAccessRequests404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdAccessRequestsQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdAccessRequestsQueryOptions(
      projectId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdAccessRequests400Schema
      | GetApiProjectsProjectIdAccessRequests401Schema
      | GetApiProjectsProjectIdAccessRequests403Schema
      | GetApiProjectsProjectIdAccessRequests404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdAccessRequestsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdAccessRequestsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/access_requests', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdAccessRequestsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdAccessRequestsSuspenseQueryKey
>;

export function getApiProjectsProjectIdAccessRequestsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdAccessRequestsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdAccessRequestsSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdAccessRequests400Schema
      | GetApiProjectsProjectIdAccessRequests401Schema
      | GetApiProjectsProjectIdAccessRequests403Schema
      | GetApiProjectsProjectIdAccessRequests404Schema
    >,
    GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdAccessRequests(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves project pending access requests
 * {@link /api/projects/:project_id/access_requests}
 */
export function useGetApiProjectsProjectIdAccessRequestsSuspense<
  TData = GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdAccessRequestsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdAccessRequestsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdAccessRequestsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdAccessRequests400Schema
          | GetApiProjectsProjectIdAccessRequests401Schema
          | GetApiProjectsProjectIdAccessRequests403Schema
          | GetApiProjectsProjectIdAccessRequests404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdAccessRequestsSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdAccessRequestsSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdAccessRequests400Schema
      | GetApiProjectsProjectIdAccessRequests401Schema
      | GetApiProjectsProjectIdAccessRequests403Schema
      | GetApiProjectsProjectIdAccessRequests404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdAccessRequestsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/access_requests' }] as const;

export type PostApiProjectsProjectIdAccessRequestsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdAccessRequestsMutationKey
>;

/**
 * @summary Creates a project access request
 * {@link /api/projects/:project_id/access_requests}
 */
export function usePostApiProjectsProjectIdAccessRequests(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdAccessRequestsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdAccessRequests400Schema
        | PostApiProjectsProjectIdAccessRequests401Schema
        | PostApiProjectsProjectIdAccessRequests404Schema
        | PostApiProjectsProjectIdAccessRequests422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdAccessRequestsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdAccessRequestsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdAccessRequestsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdAccessRequestsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdAccessRequests400Schema
      | PostApiProjectsProjectIdAccessRequests401Schema
      | PostApiProjectsProjectIdAccessRequests404Schema
      | PostApiProjectsProjectIdAccessRequests422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdAccessRequestsPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdAccessRequestsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdAccessRequests(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryKey = (
  projectId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_id'],
  projectAccessRequestId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_access_request_id']
) =>
  [
    {
      url: '/api/projects/:project_id/access_requests/:project_access_request_id',
      params: { projectId: projectId, projectAccessRequestId: projectAccessRequestId },
    },
  ] as const;

export type GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryKey
>;

export function getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryOptions(
  projectId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_id'],
  projectAccessRequestId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_access_request_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryKey(
    projectId,
    projectAccessRequestId
  );
  return queryOptions<
    GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId401Schema
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId403Schema
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId404Schema
    >,
    GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && projectAccessRequestId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdAccessRequestsProjectAccessRequestId(projectId, projectAccessRequestId, config);
    },
  });
}

/**
 * @summary Retrieves a project access request
 * {@link /api/projects/:project_id/access_requests/:project_access_request_id}
 */
export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestId<
  TData = GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_id'],
  projectAccessRequestId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_access_request_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId401Schema
          | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId403Schema
          | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryKey(projectId, projectAccessRequestId);

  const query = useQuery({
    ...(getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryOptions(
      projectId,
      projectAccessRequestId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId401Schema
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId403Schema
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_id'],
  projectAccessRequestId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_access_request_id']
) =>
  [
    {
      url: '/api/projects/:project_id/access_requests/:project_access_request_id',
      params: { projectId: projectId, projectAccessRequestId: projectAccessRequestId },
    },
  ] as const;

export type GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_id'],
  projectAccessRequestId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_access_request_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSuspenseQueryKey(
    projectId,
    projectAccessRequestId
  );
  return queryOptions<
    GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId401Schema
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId403Schema
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId404Schema
    >,
    GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && projectAccessRequestId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdAccessRequestsProjectAccessRequestId(projectId, projectAccessRequestId, config);
    },
  });
}

/**
 * @summary Retrieves a project access request
 * {@link /api/projects/:project_id/access_requests/:project_access_request_id}
 */
export function useGetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSuspense<
  TData = GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_id'],
  projectAccessRequestId: GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdPathParamsSchema['project_access_request_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdAccessRequestsProjectAccessRequestIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId401Schema
          | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId403Schema
          | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSuspenseQueryKey(projectId, projectAccessRequestId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdAccessRequestsProjectAccessRequestIdSuspenseQueryOptions(
      projectId,
      projectAccessRequestId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId401Schema
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId403Schema
      | GetApiProjectsProjectIdAccessRequestsProjectAccessRequestId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationKey = () =>
  [{ url: '/api/projects/{project_id}/access_requests/{project_access_request_id}/accept' }] as const;

export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationKey = ReturnType<
  typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationKey
>;

/**
 * @summary Accepts a project access request
 * {@link /api/projects/:project_id/access_requests/:project_access_request_id/accept}
 */
export function usePostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept401Schema
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept403Schema
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept404Schema
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptPathParamsSchema['project_id'];
        projectAccessRequestId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptPathParamsSchema['project_access_request_id'];
        data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationKey();

  return useMutation<
    PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept401Schema
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept403Schema
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept404Schema
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptPathParamsSchema['project_id'];
      projectAccessRequestId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptPathParamsSchema['project_access_request_id'];
      data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAcceptMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, projectAccessRequestId, data }) => {
      return postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdAccept(
        projectId,
        projectAccessRequestId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationKey = () =>
  [{ url: '/api/projects/{project_id}/access_requests/{project_access_request_id}/reject' }] as const;

export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationKey = ReturnType<
  typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationKey
>;

/**
 * @summary Rejects a project access request
 * {@link /api/projects/:project_id/access_requests/:project_access_request_id/reject}
 */
export function usePostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject401Schema
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject403Schema
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectPathParamsSchema['project_id'];
        projectAccessRequestId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectPathParamsSchema['project_access_request_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationKey();

  return useMutation<
    PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject401Schema
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject403Schema
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectPathParamsSchema['project_id'];
      projectAccessRequestId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRejectPathParamsSchema['project_access_request_id'];
    }
  >({
    mutationFn: async ({ projectId, projectAccessRequestId }) => {
      return postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdReject(
        projectId,
        projectAccessRequestId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationKey = () =>
  [{ url: '/api/projects/{project_id}/access_requests/{project_access_request_id}/redirect' }] as const;

export type PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationKey = ReturnType<
  typeof postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationKey
>;

/**
 * @summary Redirects a project access request to another team
 * {@link /api/projects/:project_id/access_requests/:project_access_request_id/redirect}
 */
export function usePostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect400Schema
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect401Schema
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect403Schema
        | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectPathParamsSchema['project_id'];
        projectAccessRequestId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectPathParamsSchema['project_access_request_id'];
        data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationKey();

  return useMutation<
    PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect400Schema
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect401Schema
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect403Schema
      | PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectPathParamsSchema['project_id'];
      projectAccessRequestId: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectPathParamsSchema['project_access_request_id'];
      data: PostApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirectMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, projectAccessRequestId, data }) => {
      return postApiProjectsProjectIdAccessRequestsProjectAccessRequestIdRedirect(
        projectId,
        projectAccessRequestId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdPeopleQueryKey = (
  projectId: GetApiProjectsProjectIdPeoplePathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdPeopleQueryParamsSchema
) =>
  [{ url: '/api/projects/:project_id/people', params: { projectId: projectId } }, ...(params ? [params] : [])] as const;

export type GetApiProjectsProjectIdPeopleQueryKey = ReturnType<typeof getApiProjectsProjectIdPeopleQueryKey>;

export function getApiProjectsProjectIdPeopleQueryOptions(
  projectId: GetApiProjectsProjectIdPeoplePathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdPeopleQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdPeopleQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdPeopleQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdPeople400Schema
      | GetApiProjectsProjectIdPeople401Schema
      | GetApiProjectsProjectIdPeople404Schema
    >,
    GetApiProjectsProjectIdPeopleQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdPeople(projectId, params, config);
    },
  });
}

/**
 * @description Excludes archived team members by default, unless status is given
 * @summary Retrieves project people
 * {@link /api/projects/:project_id/people}
 */
export function useGetApiProjectsProjectIdPeople<
  TData = GetApiProjectsProjectIdPeopleQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdPeopleQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdPeopleQueryKey,
>(
  projectId: GetApiProjectsProjectIdPeoplePathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdPeopleQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdPeopleQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdPeople400Schema
          | GetApiProjectsProjectIdPeople401Schema
          | GetApiProjectsProjectIdPeople404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdPeopleQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdPeopleQueryOptions(projectId, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdPeople400Schema
      | GetApiProjectsProjectIdPeople401Schema
      | GetApiProjectsProjectIdPeople404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdPeopleSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdPeoplePathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdPeopleQueryParamsSchema
) =>
  [{ url: '/api/projects/:project_id/people', params: { projectId: projectId } }, ...(params ? [params] : [])] as const;

export type GetApiProjectsProjectIdPeopleSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdPeopleSuspenseQueryKey
>;

export function getApiProjectsProjectIdPeopleSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdPeoplePathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdPeopleQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdPeopleSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdPeopleQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdPeople400Schema
      | GetApiProjectsProjectIdPeople401Schema
      | GetApiProjectsProjectIdPeople404Schema
    >,
    GetApiProjectsProjectIdPeopleQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdPeople(projectId, params, config);
    },
  });
}

/**
 * @description Excludes archived team members by default, unless status is given
 * @summary Retrieves project people
 * {@link /api/projects/:project_id/people}
 */
export function useGetApiProjectsProjectIdPeopleSuspense<
  TData = GetApiProjectsProjectIdPeopleQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdPeopleQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdPeopleSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdPeoplePathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdPeopleQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdPeopleQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdPeople400Schema
          | GetApiProjectsProjectIdPeople401Schema
          | GetApiProjectsProjectIdPeople404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdPeopleSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdPeopleSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdPeople400Schema
      | GetApiProjectsProjectIdPeople401Schema
      | GetApiProjectsProjectIdPeople404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdPeopleTeamMemberIdQueryKey = (
  projectId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['project_id'],
  teamMemberId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['team_member_id']
) =>
  [
    {
      url: '/api/projects/:project_id/people/:team_member_id',
      params: { projectId: projectId, teamMemberId: teamMemberId },
    },
  ] as const;

export type GetApiProjectsProjectIdPeopleTeamMemberIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdPeopleTeamMemberIdQueryKey
>;

export function getApiProjectsProjectIdPeopleTeamMemberIdQueryOptions(
  projectId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['project_id'],
  teamMemberId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['team_member_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdPeopleTeamMemberIdQueryKey(projectId, teamMemberId);
  return queryOptions<
    GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdPeopleTeamMemberId401Schema
      | GetApiProjectsProjectIdPeopleTeamMemberId403Schema
      | GetApiProjectsProjectIdPeopleTeamMemberId404Schema
    >,
    GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamMemberId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdPeopleTeamMemberId(projectId, teamMemberId, config);
    },
  });
}

/**
 * @summary Retrieves a team member
 * {@link /api/projects/:project_id/people/:team_member_id}
 */
export function useGetApiProjectsProjectIdPeopleTeamMemberId<
  TData = GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdPeopleTeamMemberIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['project_id'],
  teamMemberId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['team_member_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdPeopleTeamMemberId401Schema
          | GetApiProjectsProjectIdPeopleTeamMemberId403Schema
          | GetApiProjectsProjectIdPeopleTeamMemberId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdPeopleTeamMemberIdQueryKey(projectId, teamMemberId);

  const query = useQuery({
    ...(getApiProjectsProjectIdPeopleTeamMemberIdQueryOptions(
      projectId,
      teamMemberId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdPeopleTeamMemberId401Schema
      | GetApiProjectsProjectIdPeopleTeamMemberId403Schema
      | GetApiProjectsProjectIdPeopleTeamMemberId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdPeopleTeamMemberIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['project_id'],
  teamMemberId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['team_member_id']
) =>
  [
    {
      url: '/api/projects/:project_id/people/:team_member_id',
      params: { projectId: projectId, teamMemberId: teamMemberId },
    },
  ] as const;

export type GetApiProjectsProjectIdPeopleTeamMemberIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdPeopleTeamMemberIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdPeopleTeamMemberIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['project_id'],
  teamMemberId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['team_member_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdPeopleTeamMemberIdSuspenseQueryKey(projectId, teamMemberId);
  return queryOptions<
    GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdPeopleTeamMemberId401Schema
      | GetApiProjectsProjectIdPeopleTeamMemberId403Schema
      | GetApiProjectsProjectIdPeopleTeamMemberId404Schema
    >,
    GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamMemberId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdPeopleTeamMemberId(projectId, teamMemberId, config);
    },
  });
}

/**
 * @summary Retrieves a team member
 * {@link /api/projects/:project_id/people/:team_member_id}
 */
export function useGetApiProjectsProjectIdPeopleTeamMemberIdSuspense<
  TData = GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdPeopleTeamMemberIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['project_id'],
  teamMemberId: GetApiProjectsProjectIdPeopleTeamMemberIdPathParamsSchema['team_member_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdPeopleTeamMemberIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdPeopleTeamMemberId401Schema
          | GetApiProjectsProjectIdPeopleTeamMemberId403Schema
          | GetApiProjectsProjectIdPeopleTeamMemberId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdPeopleTeamMemberIdSuspenseQueryKey(projectId, teamMemberId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdPeopleTeamMemberIdSuspenseQueryOptions(
      projectId,
      teamMemberId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdPeopleTeamMemberId401Schema
      | GetApiProjectsProjectIdPeopleTeamMemberId403Schema
      | GetApiProjectsProjectIdPeopleTeamMemberId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsMutationKey = () => [{ url: '/api/projects' }] as const;

export type PostApiProjectsMutationKey = ReturnType<typeof postApiProjectsMutationKey>;

/**
 * @summary Creates a project
 * {@link /api/projects}
 */
export function usePostApiProjects(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsMutationResponseSchema,
      ResponseErrorConfig<PostApiProjects401Schema | PostApiProjects403Schema>,
      { data?: PostApiProjectsMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiProjectsMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsMutationKey();

  return useMutation<
    PostApiProjectsMutationResponseSchema,
    ResponseErrorConfig<PostApiProjects401Schema | PostApiProjects403Schema>,
    { data?: PostApiProjectsMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiProjects(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsQueryKey = () => [{ url: '/api/projects' }] as const;

export type GetApiProjectsQueryKey = ReturnType<typeof getApiProjectsQueryKey>;

export function getApiProjectsQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiProjectsQueryKey();
  return queryOptions<
    GetApiProjectsQueryResponseSchema,
    ResponseErrorConfig<GetApiProjects401Schema>,
    GetApiProjectsQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjects(config);
    },
  });
}

/**
 * @summary Retrieves projects
 * {@link /api/projects}
 */
export function useGetApiProjects<
  TData = GetApiProjectsQueryResponseSchema,
  TQueryData = GetApiProjectsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsQueryResponseSchema,
        ResponseErrorConfig<GetApiProjects401Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsQueryKey();

  const query = useQuery({
    ...(getApiProjectsQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiProjects401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsSuspenseQueryKey = () => [{ url: '/api/projects' }] as const;

export type GetApiProjectsSuspenseQueryKey = ReturnType<typeof getApiProjectsSuspenseQueryKey>;

export function getApiProjectsSuspenseQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiProjectsSuspenseQueryKey();
  return queryOptions<
    GetApiProjectsQueryResponseSchema,
    ResponseErrorConfig<GetApiProjects401Schema>,
    GetApiProjectsQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjects(config);
    },
  });
}

/**
 * @summary Retrieves projects
 * {@link /api/projects}
 */
export function useGetApiProjectsSuspense<
  TData = GetApiProjectsQueryResponseSchema,
  TQueryData = GetApiProjectsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsQueryResponseSchema,
        ResponseErrorConfig<GetApiProjects401Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsSuspenseQueryKey();

  const query = useSuspenseQuery({
    ...(getApiProjectsSuspenseQueryOptions(config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiProjects401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdQueryKey = (projectId: GetApiProjectsProjectIdPathParamsSchema['project_id']) =>
  [{ url: '/api/projects/:project_id', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdQueryKey = ReturnType<typeof getApiProjectsProjectIdQueryKey>;

export function getApiProjectsProjectIdQueryOptions(
  projectId: GetApiProjectsProjectIdPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdQueryResponseSchema,
    ResponseErrorConfig<
      GetApiProjectsProjectId401Schema | GetApiProjectsProjectId403Schema | GetApiProjectsProjectId404Schema
    >,
    GetApiProjectsProjectIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectId(projectId, config);
    },
  });
}

/**
 * @summary Retrieves a project
 * {@link /api/projects/:project_id}
 */
export function useGetApiProjectsProjectId<
  TData = GetApiProjectsProjectIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdQueryResponseSchema,
        ResponseErrorConfig<
          GetApiProjectsProjectId401Schema | GetApiProjectsProjectId403Schema | GetApiProjectsProjectId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdQueryKey(projectId);

  const query = useQuery({
    ...(getApiProjectsProjectIdQueryOptions(projectId, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      GetApiProjectsProjectId401Schema | GetApiProjectsProjectId403Schema | GetApiProjectsProjectId404Schema
    >
  > & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdPathParamsSchema['project_id']
) => [{ url: '/api/projects/:project_id', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdSuspenseQueryKey = ReturnType<typeof getApiProjectsProjectIdSuspenseQueryKey>;

export function getApiProjectsProjectIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdSuspenseQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdQueryResponseSchema,
    ResponseErrorConfig<
      GetApiProjectsProjectId401Schema | GetApiProjectsProjectId403Schema | GetApiProjectsProjectId404Schema
    >,
    GetApiProjectsProjectIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectId(projectId, config);
    },
  });
}

/**
 * @summary Retrieves a project
 * {@link /api/projects/:project_id}
 */
export function useGetApiProjectsProjectIdSuspense<
  TData = GetApiProjectsProjectIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdQueryResponseSchema,
        ResponseErrorConfig<
          GetApiProjectsProjectId401Schema | GetApiProjectsProjectId403Schema | GetApiProjectsProjectId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdSuspenseQueryKey(projectId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdSuspenseQueryOptions(projectId, config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      GetApiProjectsProjectId401Schema | GetApiProjectsProjectId403Schema | GetApiProjectsProjectId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdMutationKey = () => [{ url: '/api/projects/{project_id}' }] as const;

export type PatchApiProjectsProjectIdMutationKey = ReturnType<typeof patchApiProjectsProjectIdMutationKey>;

/**
 * @summary Updates a project
 * {@link /api/projects/:project_id}
 */
export function usePatchApiProjectsProjectId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectId401Schema
        | PatchApiProjectsProjectId403Schema
        | PatchApiProjectsProjectId404Schema
        | PatchApiProjectsProjectId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdPathParamsSchema['project_id'];
        data?: PatchApiProjectsProjectIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectId401Schema
      | PatchApiProjectsProjectId403Schema
      | PatchApiProjectsProjectId404Schema
      | PatchApiProjectsProjectId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdPathParamsSchema['project_id'];
      data?: PatchApiProjectsProjectIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return patchApiProjectsProjectId(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdArchiveMutationKey = () =>
  [{ url: '/api/projects/{project_id}/archive' }] as const;

export type PostApiProjectsProjectIdArchiveMutationKey = ReturnType<typeof postApiProjectsProjectIdArchiveMutationKey>;

/**
 * @summary Archives a project
 * {@link /api/projects/:project_id/archive}
 */
export function usePostApiProjectsProjectIdArchive(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdArchiveMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdArchive401Schema
        | PostApiProjectsProjectIdArchive403Schema
        | PostApiProjectsProjectIdArchive404Schema
      >,
      { projectId: PostApiProjectsProjectIdArchivePathParamsSchema['project_id'] }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdArchiveMutationKey();

  return useMutation<
    PostApiProjectsProjectIdArchiveMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdArchive401Schema
      | PostApiProjectsProjectIdArchive403Schema
      | PostApiProjectsProjectIdArchive404Schema
    >,
    { projectId: PostApiProjectsProjectIdArchivePathParamsSchema['project_id'] }
  >({
    mutationFn: async ({ projectId }) => {
      return postApiProjectsProjectIdArchive(projectId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdDefaultMutationKey = () =>
  [{ url: '/api/projects/{project_id}/default' }] as const;

export type PostApiProjectsProjectIdDefaultMutationKey = ReturnType<typeof postApiProjectsProjectIdDefaultMutationKey>;

/**
 * @summary Set the default project
 * {@link /api/projects/:project_id/default}
 */
export function usePostApiProjectsProjectIdDefault(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdDefaultMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdDefault401Schema
        | PostApiProjectsProjectIdDefault403Schema
        | PostApiProjectsProjectIdDefault404Schema
      >,
      { projectId: PostApiProjectsProjectIdDefaultPathParamsSchema['project_id'] }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdDefaultMutationKey();

  return useMutation<
    PostApiProjectsProjectIdDefaultMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdDefault401Schema
      | PostApiProjectsProjectIdDefault403Schema
      | PostApiProjectsProjectIdDefault404Schema
    >,
    { projectId: PostApiProjectsProjectIdDefaultPathParamsSchema['project_id'] }
  >({
    mutationFn: async ({ projectId }) => {
      return postApiProjectsProjectIdDefault(projectId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiPushSubscriptionsMutationKey = () => [{ url: '/api/push_subscriptions' }] as const;

export type PostApiPushSubscriptionsMutationKey = ReturnType<typeof postApiPushSubscriptionsMutationKey>;

/**
 * @summary Creates a push subscription
 * {@link /api/push_subscriptions}
 */
export function usePostApiPushSubscriptions(
  options: {
    mutation?: UseMutationOptions<
      PostApiPushSubscriptionsMutationResponseSchema,
      ResponseErrorConfig<PostApiPushSubscriptions401Schema | PostApiPushSubscriptions422Schema>,
      { data: PostApiPushSubscriptionsMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiPushSubscriptionsMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiPushSubscriptionsMutationKey();

  return useMutation<
    PostApiPushSubscriptionsMutationResponseSchema,
    ResponseErrorConfig<PostApiPushSubscriptions401Schema | PostApiPushSubscriptions422Schema>,
    { data: PostApiPushSubscriptionsMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiPushSubscriptions(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiPushSubscriptionsPushSubscriptionIdMutationKey = () =>
  [{ url: '/api/push_subscriptions/{push_subscription_id}' }] as const;

export type PatchApiPushSubscriptionsPushSubscriptionIdMutationKey = ReturnType<
  typeof patchApiPushSubscriptionsPushSubscriptionIdMutationKey
>;

/**
 * @summary Updates a push subscription
 * {@link /api/push_subscriptions/:push_subscription_id}
 */
export function usePatchApiPushSubscriptionsPushSubscriptionId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
      ResponseErrorConfig<
        PatchApiPushSubscriptionsPushSubscriptionId401Schema | PatchApiPushSubscriptionsPushSubscriptionId404Schema
      >,
      {
        pushSubscriptionId: PatchApiPushSubscriptionsPushSubscriptionIdPathParamsSchema['push_subscription_id'];
        data: PatchApiPushSubscriptionsPushSubscriptionIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiPushSubscriptionsPushSubscriptionIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiPushSubscriptionsPushSubscriptionIdMutationKey();

  return useMutation<
    PatchApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
    ResponseErrorConfig<
      PatchApiPushSubscriptionsPushSubscriptionId401Schema | PatchApiPushSubscriptionsPushSubscriptionId404Schema
    >,
    {
      pushSubscriptionId: PatchApiPushSubscriptionsPushSubscriptionIdPathParamsSchema['push_subscription_id'];
      data: PatchApiPushSubscriptionsPushSubscriptionIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ pushSubscriptionId, data }) => {
      return patchApiPushSubscriptionsPushSubscriptionId(pushSubscriptionId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiPushSubscriptionsPushSubscriptionIdMutationKey = () =>
  [{ url: '/api/push_subscriptions/{push_subscription_id}' }] as const;

export type DeleteApiPushSubscriptionsPushSubscriptionIdMutationKey = ReturnType<
  typeof deleteApiPushSubscriptionsPushSubscriptionIdMutationKey
>;

/**
 * @summary Deletes a push subscription
 * {@link /api/push_subscriptions/:push_subscription_id}
 */
export function useDeleteApiPushSubscriptionsPushSubscriptionId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
      ResponseErrorConfig<
        DeleteApiPushSubscriptionsPushSubscriptionId401Schema | DeleteApiPushSubscriptionsPushSubscriptionId404Schema
      >,
      { pushSubscriptionId: DeleteApiPushSubscriptionsPushSubscriptionIdPathParamsSchema['push_subscription_id'] }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiPushSubscriptionsPushSubscriptionIdMutationKey();

  return useMutation<
    DeleteApiPushSubscriptionsPushSubscriptionIdMutationResponseSchema,
    ResponseErrorConfig<
      DeleteApiPushSubscriptionsPushSubscriptionId401Schema | DeleteApiPushSubscriptionsPushSubscriptionId404Schema
    >,
    { pushSubscriptionId: DeleteApiPushSubscriptionsPushSubscriptionIdPathParamsSchema['push_subscription_id'] }
  >({
    mutationFn: async ({ pushSubscriptionId }) => {
      return deleteApiPushSubscriptionsPushSubscriptionId(pushSubscriptionId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiPushSubscriptionsPingMutationKey = () => [{ url: '/api/push_subscriptions/ping' }] as const;

export type PostApiPushSubscriptionsPingMutationKey = ReturnType<typeof postApiPushSubscriptionsPingMutationKey>;

/**
 * @summary Requests a test message
 * {@link /api/push_subscriptions/ping}
 */
export function usePostApiPushSubscriptionsPing(
  options: {
    mutation?: UseMutationOptions<
      PostApiPushSubscriptionsPingMutationResponseSchema,
      ResponseErrorConfig<PostApiPushSubscriptionsPing401Schema>
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiPushSubscriptionsPingMutationKey();

  return useMutation<
    PostApiPushSubscriptionsPingMutationResponseSchema,
    ResponseErrorConfig<PostApiPushSubscriptionsPing401Schema>
  >({
    mutationFn: async () => {
      return postApiPushSubscriptionsPing(config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiQueuedTasksQueryKey = (params: GetApiQueuedTasksQueryParamsSchema) =>
  [{ url: '/api/queued_tasks' }, ...(params ? [params] : [])] as const;

export type GetApiQueuedTasksQueryKey = ReturnType<typeof getApiQueuedTasksQueryKey>;

export function getApiQueuedTasksQueryOptions(
  params: GetApiQueuedTasksQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiQueuedTasksQueryKey(params);
  return queryOptions<
    GetApiQueuedTasksQueryResponseSchema,
    ResponseErrorConfig<GetApiQueuedTasks400Schema | GetApiQueuedTasks401Schema>,
    GetApiQueuedTasksQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiQueuedTasks(params, config);
    },
  });
}

/**
 * @summary Retrieves queued tasks
 * {@link /api/queued_tasks}
 */
export function useGetApiQueuedTasks<
  TData = GetApiQueuedTasksQueryResponseSchema,
  TQueryData = GetApiQueuedTasksQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiQueuedTasksQueryKey,
>(
  params: GetApiQueuedTasksQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiQueuedTasksQueryResponseSchema,
        ResponseErrorConfig<GetApiQueuedTasks400Schema | GetApiQueuedTasks401Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiQueuedTasksQueryKey(params);

  const query = useQuery({
    ...(getApiQueuedTasksQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiQueuedTasks400Schema | GetApiQueuedTasks401Schema>> & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiQueuedTasksSuspenseQueryKey = (params: GetApiQueuedTasksQueryParamsSchema) =>
  [{ url: '/api/queued_tasks' }, ...(params ? [params] : [])] as const;

export type GetApiQueuedTasksSuspenseQueryKey = ReturnType<typeof getApiQueuedTasksSuspenseQueryKey>;

export function getApiQueuedTasksSuspenseQueryOptions(
  params: GetApiQueuedTasksQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiQueuedTasksSuspenseQueryKey(params);
  return queryOptions<
    GetApiQueuedTasksQueryResponseSchema,
    ResponseErrorConfig<GetApiQueuedTasks400Schema | GetApiQueuedTasks401Schema>,
    GetApiQueuedTasksQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiQueuedTasks(params, config);
    },
  });
}

/**
 * @summary Retrieves queued tasks
 * {@link /api/queued_tasks}
 */
export function useGetApiQueuedTasksSuspense<
  TData = GetApiQueuedTasksQueryResponseSchema,
  TQueryData = GetApiQueuedTasksQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiQueuedTasksSuspenseQueryKey,
>(
  params: GetApiQueuedTasksQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiQueuedTasksQueryResponseSchema,
        ResponseErrorConfig<GetApiQueuedTasks400Schema | GetApiQueuedTasks401Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiQueuedTasksSuspenseQueryKey(params);

  const query = useSuspenseQuery({
    ...(getApiQueuedTasksSuspenseQueryOptions(params, config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiQueuedTasks400Schema | GetApiQueuedTasks401Schema>> & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiLoginRefreshMutationKey = () => [{ url: '/api/login/refresh' }] as const;

export type PostApiLoginRefreshMutationKey = ReturnType<typeof postApiLoginRefreshMutationKey>;

/**
 * @summary Refreshes user authentication
 * {@link /api/login/refresh}
 */
export function usePostApiLoginRefresh(
  options: {
    mutation?: UseMutationOptions<
      PostApiLoginRefreshMutationResponseSchema,
      ResponseErrorConfig<PostApiLoginRefresh400Schema | PostApiLoginRefresh401Schema>,
      { headers: PostApiLoginRefreshHeaderParamsSchema }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiLoginRefreshMutationKey();

  return useMutation<
    PostApiLoginRefreshMutationResponseSchema,
    ResponseErrorConfig<PostApiLoginRefresh400Schema | PostApiLoginRefresh401Schema>,
    { headers: PostApiLoginRefreshHeaderParamsSchema }
  >({
    mutationFn: async ({ headers }) => {
      return postApiLoginRefresh(headers, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftActivitiesQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/shift_activities', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivities400Schema
      | GetApiProjectsProjectIdShiftActivities401Schema
      | GetApiProjectsProjectIdShiftActivities403Schema
      | GetApiProjectsProjectIdShiftActivities404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivities(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves shift activities
 * {@link /api/projects/:project_id/shift_activities}
 */
export function useGetApiProjectsProjectIdShiftActivities<
  TData = GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivities400Schema
          | GetApiProjectsProjectIdShiftActivities401Schema
          | GetApiProjectsProjectIdShiftActivities403Schema
          | GetApiProjectsProjectIdShiftActivities404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdShiftActivitiesQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesQueryOptions(
      projectId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivities400Schema
      | GetApiProjectsProjectIdShiftActivities401Schema
      | GetApiProjectsProjectIdShiftActivities403Schema
      | GetApiProjectsProjectIdShiftActivities404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/shift_activities', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivities400Schema
      | GetApiProjectsProjectIdShiftActivities401Schema
      | GetApiProjectsProjectIdShiftActivities403Schema
      | GetApiProjectsProjectIdShiftActivities404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivities(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves shift activities
 * {@link /api/projects/:project_id/shift_activities}
 */
export function useGetApiProjectsProjectIdShiftActivitiesSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivities400Schema
          | GetApiProjectsProjectIdShiftActivities401Schema
          | GetApiProjectsProjectIdShiftActivities403Schema
          | GetApiProjectsProjectIdShiftActivities404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdShiftActivitiesSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivities400Schema
      | GetApiProjectsProjectIdShiftActivities401Schema
      | GetApiProjectsProjectIdShiftActivities403Schema
      | GetApiProjectsProjectIdShiftActivities404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftActivitiesMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities' }] as const;

export type PostApiProjectsProjectIdShiftActivitiesMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftActivitiesMutationKey
>;

/**
 * @summary Creates a shift activity
 * {@link /api/projects/:project_id/shift_activities}
 */
export function usePostApiProjectsProjectIdShiftActivities(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivities401Schema
        | PostApiProjectsProjectIdShiftActivities403Schema
        | PostApiProjectsProjectIdShiftActivities404Schema
        | PostApiProjectsProjectIdShiftActivities422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'];
        data: PostApiProjectsProjectIdShiftActivitiesMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdShiftActivitiesMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftActivitiesMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivities401Schema
      | PostApiProjectsProjectIdShiftActivities403Schema
      | PostApiProjectsProjectIdShiftActivities404Schema
      | PostApiProjectsProjectIdShiftActivities422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesPathParamsSchema['project_id'];
      data: PostApiProjectsProjectIdShiftActivitiesMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdShiftActivities(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryKey(projectId, shiftActivityId);
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityId(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves a shift activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityId<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryKey(projectId, shiftActivityId);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdSuspenseQueryKey(projectId, shiftActivityId);
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityId(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves a shift activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdSuspenseQueryKey(projectId, shiftActivityId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}' }] as const;

export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationKey
>;

/**
 * @summary Updates a shift activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id}
 */
export function usePatchApiProjectsProjectIdShiftActivitiesShiftActivityId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId403Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'];
        shiftActivityId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id'];
        data?: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? patchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId401Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId403Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId404Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['project_id'];
      shiftActivityId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdPathParamsSchema['shift_activity_id'];
      data?: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, data }) => {
      return patchApiProjectsProjectIdShiftActivitiesShiftActivityId(projectId, shiftActivityId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/archive' }] as const;

export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationKey
>;

/**
 * @summary Archives a shift activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/archive}
 */
export function usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive401Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive403Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive404Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchivePathParamsSchema['project_id'];
        shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchivePathParamsSchema['shift_activity_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchiveMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive401Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive403Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive404Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchivePathParamsSchema['project_id'];
      shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdArchivePathParamsSchema['shift_activity_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId }) => {
      return postApiProjectsProjectIdShiftActivitiesShiftActivityIdArchive(projectId, shiftActivityId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/restore' }] as const;

export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationKey
>;

/**
 * @summary Restores a shift activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/restore}
 */
export function usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore401Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore403Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore404Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestorePathParamsSchema['project_id'];
        shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestorePathParamsSchema['shift_activity_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestoreMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore401Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore403Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore404Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestorePathParamsSchema['project_id'];
      shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRestorePathParamsSchema['shift_activity_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId }) => {
      return postApiProjectsProjectIdShiftActivitiesShiftActivityIdRestore(projectId, shiftActivityId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/daily_progress',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves a shift activity weekly work plans
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/daily_progress}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryKey(projectId, shiftActivityId);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/daily_progress',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSuspenseQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves a shift activity weekly work plans
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/daily_progress}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSuspenseQueryKey(projectId, shiftActivityId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgressSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDailyProgress404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/documents',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryKey(
    projectId,
    shiftActivityId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments(projectId, shiftActivityId, params, config);
    },
  });
}

/**
 * @summary Retrieves a shift activity documents
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/documents}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryKey(projectId, shiftActivityId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryOptions(
      projectId,
      shiftActivityId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/documents',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSuspenseQueryKey(
    projectId,
    shiftActivityId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments(projectId, shiftActivityId, params, config);
    },
  });
}

/**
 * @summary Retrieves a shift activity documents
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/documents}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSuspenseQueryKey(projectId, shiftActivityId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdDocumentsSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftActivitiesExportMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/export' }] as const;

export type PostApiProjectsProjectIdShiftActivitiesExportMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftActivitiesExportMutationKey
>;

/**
 * @summary Exports a list of shift activities
 * {@link /api/projects/:project_id/shift_activities/export}
 */
export function usePostApiProjectsProjectIdShiftActivitiesExport(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesExportMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivitiesExport401Schema
        | PostApiProjectsProjectIdShiftActivitiesExport403Schema
        | PostApiProjectsProjectIdShiftActivitiesExport404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesExportPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdShiftActivitiesExportMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdShiftActivitiesExportMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftActivitiesExportMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesExportMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivitiesExport401Schema
      | PostApiProjectsProjectIdShiftActivitiesExport403Schema
      | PostApiProjectsProjectIdShiftActivitiesExport404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesExportPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdShiftActivitiesExportMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdShiftActivitiesExport(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftActivitiesImportsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/imports' }] as const;

export type PostApiProjectsProjectIdShiftActivitiesImportsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftActivitiesImportsMutationKey
>;

/**
 * @summary Creates a shift activity import
 * {@link /api/projects/:project_id/shift_activities/imports}
 */
export function usePostApiProjectsProjectIdShiftActivitiesImports(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesImportsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivitiesImports401Schema
        | PostApiProjectsProjectIdShiftActivitiesImports403Schema
        | PostApiProjectsProjectIdShiftActivitiesImports404Schema
        | PostApiProjectsProjectIdShiftActivitiesImports422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesImportsPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdShiftActivitiesImportsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdShiftActivitiesImportsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftActivitiesImportsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesImportsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivitiesImports401Schema
      | PostApiProjectsProjectIdShiftActivitiesImports403Schema
      | PostApiProjectsProjectIdShiftActivitiesImports404Schema
      | PostApiProjectsProjectIdShiftActivitiesImports422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesImportsPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdShiftActivitiesImportsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdShiftActivitiesImports(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationKey =
  () =>
    [
      {
        url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/progress_logs/{progress_log_id}/document_references/{document_reference_id}',
      },
    ] as const;

export type DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationKey =
  ReturnType<
    typeof deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationKey
  >;

/**
 * @summary Removes a document from a shift activity progress log
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id/document_references/:document_reference_id}
 */
export function useDeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId401Schema
        | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId403Schema
        | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['project_id'];
        shiftActivityId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['shift_activity_id'];
        progressLogId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['progress_log_id'];
        documentReferenceId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['document_reference_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId401Schema
      | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId403Schema
      | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['project_id'];
      shiftActivityId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['shift_activity_id'];
      progressLogId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['progress_log_id'];
      documentReferenceId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceIdPathParamsSchema['document_reference_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, progressLogId, documentReferenceId }) => {
      return deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentReferencesDocumentReferenceId(
        projectId,
        shiftActivityId,
        progressLogId,
        documentReferenceId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id/documents',
      params: { projectId: projectId, shiftActivityId: shiftActivityId, progressLogId: progressLogId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryKey =
  ReturnType<typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryKey>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryKey(
    projectId,
    shiftActivityId,
    progressLogId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId && progressLogId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments(
        projectId,
        shiftActivityId,
        progressLogId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves shift activity progress log documents
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id/documents}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
  TQueryKey extends
    QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryKey(
      projectId,
      shiftActivityId,
      progressLogId,
      params
    );

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryOptions(
      projectId,
      shiftActivityId,
      progressLogId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id/documents',
      params: { projectId: projectId, shiftActivityId: shiftActivityId, progressLogId: progressLogId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSuspenseQueryKey =
  ReturnType<
    typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSuspenseQueryKey
  >;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey =
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSuspenseQueryKey(
      projectId,
      shiftActivityId,
      progressLogId,
      params
    );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId && progressLogId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments(
        projectId,
        shiftActivityId,
        progressLogId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves shift activity progress log documents
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id/documents}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
  TQueryKey extends
    QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSuspenseQueryKey(
      projectId,
      shiftActivityId,
      progressLogId,
      params
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      progressLogId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationKey = () =>
  [
    {
      url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/progress_logs/{progress_log_id}/documents',
    },
  ] as const;

export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationKey =
  ReturnType<
    typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationKey
  >;

/**
 * @summary Adds a document to a shift activity progress log (from an upload or an existing document)
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id/documents}
 */
export function usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'];
        shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'];
        progressLogId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'];
        data?: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationRequestSchema>
    > & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments400Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments401Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments404Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['project_id'];
      shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['shift_activity_id'];
      progressLogId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsPathParamsSchema['progress_log_id'];
      data?: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocumentsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, progressLogId, data }) => {
      return postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdDocuments(
        projectId,
        shiftActivityId,
        progressLogId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryKey(
    projectId,
    shiftActivityId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs(
        projectId,
        shiftActivityId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a shift activity progress logs
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryKey(projectId, shiftActivityId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryOptions(
      projectId,
      shiftActivityId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSuspenseQueryKey(
    projectId,
    shiftActivityId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs(
        projectId,
        shiftActivityId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a shift activity progress logs
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'],
  params?: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSuspenseQueryKey(
      projectId,
      shiftActivityId,
      params
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/progress_logs' }] as const;

export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationKey
>;

/**
 * @summary Creates a shift activity progress log
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs}
 */
export function usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs403Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'];
        shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'];
        data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs401Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs403Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs404Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['project_id'];
      shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsPathParamsSchema['shift_activity_id'];
      data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, data }) => {
      return postApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogs(
        projectId,
        shiftActivityId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id',
      params: { projectId: projectId, shiftActivityId: shiftActivityId, progressLogId: progressLogId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryKey(
    projectId,
    shiftActivityId,
    progressLogId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId && progressLogId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId(
        projectId,
        shiftActivityId,
        progressLogId,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a shift activity progress log
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryKey(
      projectId,
      shiftActivityId,
      progressLogId
    );

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryOptions(
      projectId,
      shiftActivityId,
      progressLogId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id',
      params: { projectId: projectId, shiftActivityId: shiftActivityId, progressLogId: progressLogId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSuspenseQueryKey(
    projectId,
    shiftActivityId,
    progressLogId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId && progressLogId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId(
        projectId,
        shiftActivityId,
        progressLogId,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a shift activity progress log
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
  TQueryKey extends
    QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'],
  progressLogId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSuspenseQueryKey(
      projectId,
      shiftActivityId,
      progressLogId
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      progressLogId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/progress_logs/{progress_log_id}' }] as const;

export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationKey
>;

/**
 * @summary Updates a shift activity progress log
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/progress_logs/:progress_log_id}
 */
export function usePatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId403Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'];
        shiftActivityId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'];
        progressLogId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id'];
        data?: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationRequestSchema>
    > & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId401Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId403Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId404Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['project_id'];
      shiftActivityId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['shift_activity_id'];
      progressLogId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdPathParamsSchema['progress_log_id'];
      data?: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, progressLogId, data }) => {
      return patchApiProjectsProjectIdShiftActivitiesShiftActivityIdProgressLogsProgressLogId(
        projectId,
        shiftActivityId,
        progressLogId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/readiness' }] as const;

export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationKey
>;

/**
 * @summary Updates shift activity readiness
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/readiness}
 */
export function usePatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness400Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness401Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness403Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness404Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessPathParamsSchema['project_id'];
        shiftActivityId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessPathParamsSchema['shift_activity_id'];
        data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? patchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness400Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness401Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness403Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness404Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessPathParamsSchema['project_id'];
      shiftActivityId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessPathParamsSchema['shift_activity_id'];
      data: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadinessMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, data }) => {
      return patchApiProjectsProjectIdShiftActivitiesShiftActivityIdReadiness(projectId, shiftActivityId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/requirements',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves the shift activity requirements
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryKey(projectId, shiftActivityId);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/requirements',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSuspenseQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves the shift activity requirements
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSuspenseQueryKey(projectId, shiftActivityId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/requirements' }] as const;

export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationKey
>;

/**
 * @summary Creates a shift activity requirement
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements}
 */
export function usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements403Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'];
        shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id'];
        data?: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements401Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements403Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements404Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['project_id'];
      shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsPathParamsSchema['shift_activity_id'];
      data?: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, data }) => {
      return postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirements(
        projectId,
        shiftActivityId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/requirements/sort' }] as const;

export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationKey
>;

/**
 * @summary Sorts shift activity requirements
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/sort}
 */
export function usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort400Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort401Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort403Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortPathParamsSchema['project_id'];
        shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortPathParamsSchema['shift_activity_id'];
        data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort400Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort401Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort403Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortPathParamsSchema['project_id'];
      shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortPathParamsSchema['shift_activity_id'];
      data: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSortMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, data }) => {
      return postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsSort(
        projectId,
        shiftActivityId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/requirements/{requirement_id}' }] as const;

export type PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationKey
>;

/**
 * @summary Updates a shift activity requirement
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/:requirement_id}
 */
export function usePatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema
        | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['project_id'];
        shiftActivityId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['shift_activity_id'];
        requirementId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['requirement_id'];
        data?: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationRequestSchema>
    > & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    patchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema
      | PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['project_id'];
      shiftActivityId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['shift_activity_id'];
      requirementId: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['requirement_id'];
      data?: PatchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, requirementId, data }) => {
      return patchApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId(
        projectId,
        shiftActivityId,
        requirementId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/requirements/{requirement_id}' }] as const;

export type DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationKey
>;

/**
 * @summary Deletes a shift activity requirement
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/:requirement_id}
 */
export function useDeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema
        | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema
        | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['project_id'];
        shiftActivityId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['shift_activity_id'];
        requirementId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['requirement_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId401Schema
      | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId403Schema
      | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['project_id'];
      shiftActivityId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['shift_activity_id'];
      requirementId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdPathParamsSchema['requirement_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, requirementId }) => {
      return deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementId(
        projectId,
        shiftActivityId,
        requirementId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationKey =
  () =>
    [
      {
        url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/requirements/{requirement_id}/completion',
      },
    ] as const;

export type PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationKey =
  ReturnType<
    typeof postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationKey
  >;

/**
 * @summary Marks shift activity requirement as completed
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/:requirement_id/completion}
 */
export function usePostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema
        | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['project_id'];
        shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['shift_activity_id'];
        requirementId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['requirement_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema
      | PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['project_id'];
      shiftActivityId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['shift_activity_id'];
      requirementId: PostApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['requirement_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, requirementId }) => {
      return postApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion(
        projectId,
        shiftActivityId,
        requirementId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationKey =
  () =>
    [
      {
        url: '/api/projects/{project_id}/shift_activities/{shift_activity_id}/requirements/{requirement_id}/completion',
      },
    ] as const;

export type DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationKey =
  ReturnType<
    typeof deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationKey
  >;

/**
 * @summary Marks shift activity requirement as incomplete
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/requirements/:requirement_id/completion}
 */
export function useDeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema
        | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema
        | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['project_id'];
        shiftActivityId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['shift_activity_id'];
        requirementId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['requirement_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion401Schema
      | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion403Schema
      | DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['project_id'];
      shiftActivityId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['shift_activity_id'];
      requirementId: DeleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletionPathParamsSchema['requirement_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftActivityId, requirementId }) => {
      return deleteApiProjectsProjectIdShiftActivitiesShiftActivityIdRequirementsRequirementIdCompletion(
        projectId,
        shiftActivityId,
        requirementId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves a shift activity resources usage material list
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryKey(projectId, shiftActivityId);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSuspenseQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves a shift activity resources usage material list
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsagePathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSuspenseQueryKey(projectId, shiftActivityId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsage404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage/stats',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats(
        projectId,
        shiftActivityId,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a shift activity resources usage stats
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage/stats}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryKey(projectId, shiftActivityId);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage/stats',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSuspenseQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats(
        projectId,
        shiftActivityId,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a shift activity resources usage stats
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/resources_usage/stats}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSuspenseQueryKey(
      projectId,
      shiftActivityId
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStatsSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdResourcesUsageStats404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/weekly_planning',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves the weekly planning items of an activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/weekly_planning}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryKey(projectId, shiftActivityId);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['shift_activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_activities/:shift_activity_id/weekly_planning',
      params: { projectId: projectId, shiftActivityId: shiftActivityId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['shift_activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSuspenseQueryKey(
    projectId,
    shiftActivityId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning404Schema
    >,
    GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftActivityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning(projectId, shiftActivityId, config);
    },
  });
}

/**
 * @summary Retrieves the weekly planning items of an activity
 * {@link /api/projects/:project_id/shift_activities/:shift_activity_id/weekly_planning}
 */
export function useGetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSuspense<
  TData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['project_id'],
  shiftActivityId: GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningPathParamsSchema['shift_activity_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning401Schema
          | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSuspenseQueryKey(projectId, shiftActivityId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanningSuspenseQueryOptions(
      projectId,
      shiftActivityId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning401Schema
      | GetApiProjectsProjectIdShiftActivitiesShiftActivityIdWeeklyPlanning404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/comments/collaborators',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryKey(
    projectId,
    shiftReportId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators(
        projectId,
        shiftReportId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves shift report collaborators comments
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/collaborators}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryKey(projectId, shiftReportId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryOptions(
      projectId,
      shiftReportId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/comments/collaborators',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSuspenseQueryKey(
    projectId,
    shiftReportId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators(
        projectId,
        shiftReportId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves shift report collaborators comments
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/collaborators}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSuspense<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSuspenseQueryKey(
      projectId,
      shiftReportId,
      params
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsSuspenseQueryOptions(
      projectId,
      shiftReportId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/comments/collaborators' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationKey
>;

/**
 * @summary Creates a shift report collaborators comment
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/collaborators}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'];
        data?: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators400Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators404Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsPathParamsSchema['shift_report_id'];
      data?: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaboratorsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, data }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdCommentsCollaborators(
        projectId,
        shiftReportId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/comments/public',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryKey(
    projectId,
    shiftReportId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic(projectId, shiftReportId, params, config);
    },
  });
}

/**
 * @summary Retrieves shift report public comments
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/public}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryKey(projectId, shiftReportId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryOptions(
      projectId,
      shiftReportId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/comments/public',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSuspenseQueryKey(
    projectId,
    shiftReportId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic(projectId, shiftReportId, params, config);
    },
  });
}

/**
 * @summary Retrieves shift report public comments
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/public}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSuspense<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSuspenseQueryKey(projectId, shiftReportId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicSuspenseQueryOptions(
      projectId,
      shiftReportId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/comments/public' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationKey
>;

/**
 * @summary Creates a shift report public comment
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/public}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'];
        data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic400Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicPathParamsSchema['shift_report_id'];
      data: PostApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublicMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, data }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdCommentsPublic(projectId, shiftReportId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/comments/{comment_id}' }] as const;

export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationKey
>;

/**
 * @summary Deletes a shift report comment
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/comments/:comment_id}
 */
export function useDeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId401Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId403Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId404Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId422Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema['project_id'];
        shiftReportId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema['shift_report_id'];
        commentId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema['comment_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId401Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId403Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId404Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId422Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema['project_id'];
      shiftReportId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema['shift_report_id'];
      commentId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentIdPathParamsSchema['comment_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, commentId }) => {
      return deleteApiProjectsProjectIdShiftReportsShiftReportIdCommentsCommentId(
        projectId,
        shiftReportId,
        commentId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationKey = () =>
  [
    { url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/document_references/{document_reference_id}' },
  ] as const;

export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationKey =
  ReturnType<
    typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationKey
  >;

/**
 * @summary Removes a document from a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/document_references/:document_reference_id}
 */
export function useDeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId401Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId403Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema['project_id'];
        shiftReportId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema['shift_report_id'];
        documentReferenceId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema['document_reference_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId401Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId403Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema['project_id'];
      shiftReportId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema['shift_report_id'];
      documentReferenceId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceIdPathParamsSchema['document_reference_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, documentReferenceId }) => {
      return deleteApiProjectsProjectIdShiftReportsShiftReportIdDocumentReferencesDocumentReferenceId(
        projectId,
        shiftReportId,
        documentReferenceId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/documents',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryKey(projectId, shiftReportId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdDocuments(projectId, shiftReportId, params, config);
    },
  });
}

/**
 * @summary Retrieves shift report documents
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/documents}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocuments<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryKey(projectId, shiftReportId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryOptions(
      projectId,
      shiftReportId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/documents',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSuspenseQueryKey(
    projectId,
    shiftReportId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdDocuments(projectId, shiftReportId, params, config);
    },
  });
}

/**
 * @summary Retrieves shift report documents
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/documents}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSuspense<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSuspenseQueryKey(projectId, shiftReportId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdDocumentsSuspenseQueryOptions(
      projectId,
      shiftReportId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/documents' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationKey
>;

/**
 * @summary Adds a document to a shift report (from an upload or an existing document)
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/documents}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdDocuments(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'];
        data?: PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments400Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdDocuments422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsPathParamsSchema['shift_report_id'];
      data?: PostApiProjectsProjectIdShiftReportsShiftReportIdDocumentsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, data }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdDocuments(projectId, shiftReportId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftReportsExportMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/export' }] as const;

export type PostApiProjectsProjectIdShiftReportsExportMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsExportMutationKey
>;

/**
 * @summary Exports a list of shift reports
 * {@link /api/projects/:project_id/shift_reports/export}
 */
export function usePostApiProjectsProjectIdShiftReportsExport(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsExportMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsExport400Schema
        | PostApiProjectsProjectIdShiftReportsExport401Schema
        | PostApiProjectsProjectIdShiftReportsExport403Schema
        | PostApiProjectsProjectIdShiftReportsExport404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsExportPathParamsSchema['project_id'];
        data: PostApiProjectsProjectIdShiftReportsExportMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdShiftReportsExportMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsExportMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsExportMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsExport400Schema
      | PostApiProjectsProjectIdShiftReportsExport401Schema
      | PostApiProjectsProjectIdShiftReportsExport403Schema
      | PostApiProjectsProjectIdShiftReportsExport404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsExportPathParamsSchema['project_id'];
      data: PostApiProjectsProjectIdShiftReportsExportMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdShiftReportsExport(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdExportMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/export' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdExportMutationKey
>;

/**
 * @summary Exports a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/export}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdExport(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdExport400Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdExport401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdExport403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdExport404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdExportPathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdExportPathParamsSchema['shift_report_id'];
        params: PostApiProjectsProjectIdShiftReportsShiftReportIdExportQueryParamsSchema;
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdExportMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdExportMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdExport400Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdExport401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdExport403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdExport404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdExportPathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdExportPathParamsSchema['shift_report_id'];
      params: PostApiProjectsProjectIdShiftReportsShiftReportIdExportQueryParamsSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, params }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdExport(projectId, shiftReportId, params, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdImportMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/import' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdImportMutationKey
>;

/**
 * @summary Imports data from an XLSX file to a draft shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/import}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdImport(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdImport400Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdImport401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdImport403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdImport404Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdImport422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdImportPathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdImportPathParamsSchema['shift_report_id'];
        data?: PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdImportMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdImport400Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdImport401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdImport403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdImport404Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdImport422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdImportPathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdImportPathParamsSchema['shift_report_id'];
      data?: PostApiProjectsProjectIdShiftReportsShiftReportIdImportMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, data }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdImport(projectId, shiftReportId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/people',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryKey(projectId, shiftReportId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdPeople(projectId, shiftReportId, params, config);
    },
  });
}

/**
 * @summary Retrieves team members with access to shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/people}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeople<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryKey(projectId, shiftReportId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryOptions(
      projectId,
      shiftReportId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdPeopleSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/people',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdPeopleSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdPeopleSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdPeopleSuspenseQueryKey(
    projectId,
    shiftReportId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdPeople(projectId, shiftReportId, params, config);
    },
  });
}

/**
 * @summary Retrieves team members with access to shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/people}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdPeopleSuspense<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPeoplePathParamsSchema['shift_report_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdPeopleQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdPeopleSuspenseQueryKey(projectId, shiftReportId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdPeopleSuspenseQueryOptions(
      projectId,
      shiftReportId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdPeople404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'],
  resourceType: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'],
  resourceId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/:resource_type/:resource_id/documents',
      params: {
        projectId: projectId,
        shiftReportId: shiftReportId,
        resourceType: resourceType,
        resourceId: resourceId,
      },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'],
  resourceType: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'],
  resourceId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryKey(
    projectId,
    shiftReportId,
    resourceType,
    resourceId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId && resourceType && resourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments(
        projectId,
        shiftReportId,
        resourceType,
        resourceId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves shift report resource documents
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/:resource_type/:resource_id/documents}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'],
  resourceType: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'],
  resourceId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryKey(
      projectId,
      shiftReportId,
      resourceType,
      resourceId,
      params
    );

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryOptions(
      projectId,
      shiftReportId,
      resourceType,
      resourceId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'],
  resourceType: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'],
  resourceId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/:resource_type/:resource_id/documents',
      params: {
        projectId: projectId,
        shiftReportId: shiftReportId,
        resourceType: resourceType,
        resourceId: resourceId,
      },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSuspenseQueryKey =
  ReturnType<typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSuspenseQueryKey>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'],
  resourceType: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'],
  resourceId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSuspenseQueryKey(
    projectId,
    shiftReportId,
    resourceType,
    resourceId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId && resourceType && resourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments(
        projectId,
        shiftReportId,
        resourceType,
        resourceId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves shift report resource documents
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/:resource_type/:resource_id/documents}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSuspense<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
  TQueryKey extends
    QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'],
  resourceType: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'],
  resourceId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSuspenseQueryKey(
      projectId,
      shiftReportId,
      resourceType,
      resourceId,
      params
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsSuspenseQueryOptions(
      projectId,
      shiftReportId,
      resourceType,
      resourceId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationKey = () =>
  [
    { url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/{resource_type}/{resource_id}/documents' },
  ] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationKey
>;

/**
 * @summary Adds a document to a shift report resource (from an upload or an existing document)
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/:resource_type/:resource_id/documents}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'];
        resourceType: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'];
        resourceId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'];
        data?: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationRequestSchema>
    > & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments400Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments404Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['shift_report_id'];
      resourceType: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_type'];
      resourceId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsPathParamsSchema['resource_id'];
      data?: PostApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, resourceType, resourceId, data }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocuments(
        projectId,
        shiftReportId,
        resourceType,
        resourceId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationKey =
  () =>
    [
      {
        url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/{resource_type}/{resource_id}/documents/{document_id}',
      },
    ] as const;

export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationKey =
  ReturnType<
    typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationKey
  >;

/**
 * @summary Removes a document from a shift report resource
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/:resource_type/:resource_id/documents/:document_id}
 */
export function useDeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId401Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId403Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['project_id'];
        shiftReportId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['shift_report_id'];
        documentId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['document_id'];
        resourceType: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['resource_type'];
        resourceId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['resource_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId401Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId403Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['project_id'];
      shiftReportId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['shift_report_id'];
      documentId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['document_id'];
      resourceType: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['resource_type'];
      resourceId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentIdPathParamsSchema['resource_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, documentId, resourceType, resourceId }) => {
      return deleteApiProjectsProjectIdShiftReportsShiftReportIdResourceTypeResourceIdDocumentsDocumentId(
        projectId,
        shiftReportId,
        documentId,
        resourceType,
        resourceId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'],
  kind: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/resources/:kind',
      params: { projectId: projectId, shiftReportId: shiftReportId, kind: kind },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'],
  kind: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryKey(
    projectId,
    shiftReportId,
    kind,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId && kind),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind(
        projectId,
        shiftReportId,
        kind,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves resources
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/resources/:kind}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'],
  kind: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryKey(projectId, shiftReportId, kind, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryOptions(
      projectId,
      shiftReportId,
      kind,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'],
  kind: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/resources/:kind',
      params: { projectId: projectId, shiftReportId: shiftReportId, kind: kind },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'],
  kind: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSuspenseQueryKey(
    projectId,
    shiftReportId,
    kind,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId && kind),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind(
        projectId,
        shiftReportId,
        kind,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves resources
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/resources/:kind}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSuspense<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'],
  kind: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSuspenseQueryKey(
      projectId,
      shiftReportId,
      kind,
      params
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindSuspenseQueryOptions(
      projectId,
      shiftReportId,
      kind,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/resources/{kind}' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationKey
>;

/**
 * @summary Create resource
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/resources/:kind}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'];
        kind: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'];
        data: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind404Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['shift_report_id'];
      kind: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindPathParamsSchema['kind'];
      data: PostApiProjectsProjectIdShiftReportsShiftReportIdResourcesKindMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, kind, data }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdResourcesKind(
        projectId,
        shiftReportId,
        kind,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftReportsCompletionsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsCompletionsPathParamsSchema['project_id']
) => [{ url: '/api/projects/:project_id/shift_reports/completions', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdShiftReportsCompletionsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsCompletionsQueryKey
>;

export function getApiProjectsProjectIdShiftReportsCompletionsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsCompletionsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsCompletionsQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
    ResponseErrorConfig<
      GetApiProjectsProjectIdShiftReportsCompletions401Schema | GetApiProjectsProjectIdShiftReportsCompletions403Schema
    >,
    GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsCompletions(projectId, config);
    },
  });
}

/**
 * @summary Retrieves published shift reports
 * {@link /api/projects/:project_id/shift_reports/completions}
 */
export function useGetApiProjectsProjectIdShiftReportsCompletions<
  TData = GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsCompletionsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsCompletionsPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsCompletions401Schema
          | GetApiProjectsProjectIdShiftReportsCompletions403Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdShiftReportsCompletionsQueryKey(projectId);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsCompletionsQueryOptions(
      projectId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      GetApiProjectsProjectIdShiftReportsCompletions401Schema | GetApiProjectsProjectIdShiftReportsCompletions403Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsCompletionsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsCompletionsPathParamsSchema['project_id']
) => [{ url: '/api/projects/:project_id/shift_reports/completions', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdShiftReportsCompletionsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsCompletionsSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsCompletionsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsCompletionsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsCompletionsSuspenseQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
    ResponseErrorConfig<
      GetApiProjectsProjectIdShiftReportsCompletions401Schema | GetApiProjectsProjectIdShiftReportsCompletions403Schema
    >,
    GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsCompletions(projectId, config);
    },
  });
}

/**
 * @summary Retrieves published shift reports
 * {@link /api/projects/:project_id/shift_reports/completions}
 */
export function useGetApiProjectsProjectIdShiftReportsCompletionsSuspense<
  TData = GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsCompletionsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsCompletionsPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsCompletionsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsCompletions401Schema
          | GetApiProjectsProjectIdShiftReportsCompletions403Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdShiftReportsCompletionsSuspenseQueryKey(projectId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsCompletionsSuspenseQueryOptions(
      projectId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      GetApiProjectsProjectIdShiftReportsCompletions401Schema | GetApiProjectsProjectIdShiftReportsCompletions403Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['shift_report_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/quality_indicators',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['shift_report_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey(projectId, shiftReportId);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators403Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators(projectId, shiftReportId, config);
    },
  });
}

/**
 * @summary Retrieves all quality indicators for a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/quality_indicators}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['shift_report_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators403Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey(projectId, shiftReportId);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryOptions(
      projectId,
      shiftReportId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators403Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['shift_report_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id/quality_indicators',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['shift_report_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSuspenseQueryKey(
    projectId,
    shiftReportId
  );
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators403Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators(projectId, shiftReportId, config);
    },
  });
}

/**
 * @summary Retrieves all quality indicators for a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/quality_indicators}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSuspense<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsPathParamsSchema['shift_report_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators403Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSuspenseQueryKey(projectId, shiftReportId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsSuspenseQueryOptions(
      projectId,
      shiftReportId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicators403Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/shift_reports', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsQueryKey
>;

export function getApiProjectsProjectIdShiftReportsQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReports400Schema
      | GetApiProjectsProjectIdShiftReports401Schema
      | GetApiProjectsProjectIdShiftReports403Schema
      | GetApiProjectsProjectIdShiftReports404Schema
    >,
    GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReports(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves published shift reports
 * {@link /api/projects/:project_id/shift_reports}
 */
export function useGetApiProjectsProjectIdShiftReports<
  TData = GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReports400Schema
          | GetApiProjectsProjectIdShiftReports401Schema
          | GetApiProjectsProjectIdShiftReports403Schema
          | GetApiProjectsProjectIdShiftReports404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdShiftReportsQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsQueryOptions(projectId, params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReports400Schema
      | GetApiProjectsProjectIdShiftReports401Schema
      | GetApiProjectsProjectIdShiftReports403Schema
      | GetApiProjectsProjectIdShiftReports404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/shift_reports', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReports400Schema
      | GetApiProjectsProjectIdShiftReports401Schema
      | GetApiProjectsProjectIdShiftReports403Schema
      | GetApiProjectsProjectIdShiftReports404Schema
    >,
    GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReports(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves published shift reports
 * {@link /api/projects/:project_id/shift_reports}
 */
export function useGetApiProjectsProjectIdShiftReportsSuspense<
  TData = GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReports400Schema
          | GetApiProjectsProjectIdShiftReports401Schema
          | GetApiProjectsProjectIdShiftReports403Schema
          | GetApiProjectsProjectIdShiftReports404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdShiftReportsSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReports400Schema
      | GetApiProjectsProjectIdShiftReports401Schema
      | GetApiProjectsProjectIdShiftReports403Schema
      | GetApiProjectsProjectIdShiftReports404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdShiftReportsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports' }] as const;

export type PostApiProjectsProjectIdShiftReportsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsMutationKey
>;

/**
 * @summary Creates a shift report
 * {@link /api/projects/:project_id/shift_reports}
 */
export function usePostApiProjectsProjectIdShiftReports(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReports401Schema
        | PostApiProjectsProjectIdShiftReports403Schema
        | PostApiProjectsProjectIdShiftReports404Schema
        | PostApiProjectsProjectIdShiftReports422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'];
        data: PostApiProjectsProjectIdShiftReportsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdShiftReportsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReports401Schema
      | PostApiProjectsProjectIdShiftReports403Schema
      | PostApiProjectsProjectIdShiftReports404Schema
      | PostApiProjectsProjectIdShiftReports422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsPathParamsSchema['project_id'];
      data: PostApiProjectsProjectIdShiftReportsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdShiftReports(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdShiftReportsArchivedQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsArchivedPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsArchivedQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/shift_reports/archived', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsArchivedQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsArchivedQueryKey
>;

export function getApiProjectsProjectIdShiftReportsArchivedQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsArchivedPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsArchivedQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsArchivedQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsArchived400Schema
      | GetApiProjectsProjectIdShiftReportsArchived401Schema
      | GetApiProjectsProjectIdShiftReportsArchived403Schema
      | GetApiProjectsProjectIdShiftReportsArchived404Schema
    >,
    GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsArchived(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves archived shift reports
 * {@link /api/projects/:project_id/shift_reports/archived}
 */
export function useGetApiProjectsProjectIdShiftReportsArchived<
  TData = GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsArchivedQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsArchivedPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsArchivedQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsArchived400Schema
          | GetApiProjectsProjectIdShiftReportsArchived401Schema
          | GetApiProjectsProjectIdShiftReportsArchived403Schema
          | GetApiProjectsProjectIdShiftReportsArchived404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdShiftReportsArchivedQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsArchivedQueryOptions(
      projectId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsArchived400Schema
      | GetApiProjectsProjectIdShiftReportsArchived401Schema
      | GetApiProjectsProjectIdShiftReportsArchived403Schema
      | GetApiProjectsProjectIdShiftReportsArchived404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsArchivedSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsArchivedPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsArchivedQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/shift_reports/archived', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsArchivedSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsArchivedSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsArchivedSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsArchivedPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsArchivedQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsArchivedSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsArchived400Schema
      | GetApiProjectsProjectIdShiftReportsArchived401Schema
      | GetApiProjectsProjectIdShiftReportsArchived403Schema
      | GetApiProjectsProjectIdShiftReportsArchived404Schema
    >,
    GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsArchived(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves archived shift reports
 * {@link /api/projects/:project_id/shift_reports/archived}
 */
export function useGetApiProjectsProjectIdShiftReportsArchivedSuspense<
  TData = GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsArchivedSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsArchivedPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsArchivedQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsArchivedQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsArchived400Schema
          | GetApiProjectsProjectIdShiftReportsArchived401Schema
          | GetApiProjectsProjectIdShiftReportsArchived403Schema
          | GetApiProjectsProjectIdShiftReportsArchived404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdShiftReportsArchivedSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsArchivedSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsArchived400Schema
      | GetApiProjectsProjectIdShiftReportsArchived401Schema
      | GetApiProjectsProjectIdShiftReportsArchived403Schema
      | GetApiProjectsProjectIdShiftReportsArchived404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsDraftQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsDraftPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsDraftQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/shift_reports/draft', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsDraftQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsDraftQueryKey
>;

export function getApiProjectsProjectIdShiftReportsDraftQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsDraftPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsDraftQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsDraftQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsDraft400Schema
      | GetApiProjectsProjectIdShiftReportsDraft401Schema
      | GetApiProjectsProjectIdShiftReportsDraft403Schema
      | GetApiProjectsProjectIdShiftReportsDraft404Schema
    >,
    GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsDraft(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves draft shift reports where the user is an owner or a collaborator
 * {@link /api/projects/:project_id/shift_reports/draft}
 */
export function useGetApiProjectsProjectIdShiftReportsDraft<
  TData = GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsDraftQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsDraftPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsDraftQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsDraft400Schema
          | GetApiProjectsProjectIdShiftReportsDraft401Schema
          | GetApiProjectsProjectIdShiftReportsDraft403Schema
          | GetApiProjectsProjectIdShiftReportsDraft404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdShiftReportsDraftQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsDraftQueryOptions(
      projectId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsDraft400Schema
      | GetApiProjectsProjectIdShiftReportsDraft401Schema
      | GetApiProjectsProjectIdShiftReportsDraft403Schema
      | GetApiProjectsProjectIdShiftReportsDraft404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsDraftSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsDraftPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsDraftQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/shift_reports/draft', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdShiftReportsDraftSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsDraftSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsDraftSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsDraftPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsDraftQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsDraftSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsDraft400Schema
      | GetApiProjectsProjectIdShiftReportsDraft401Schema
      | GetApiProjectsProjectIdShiftReportsDraft403Schema
      | GetApiProjectsProjectIdShiftReportsDraft404Schema
    >,
    GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsDraft(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves draft shift reports where the user is an owner or a collaborator
 * {@link /api/projects/:project_id/shift_reports/draft}
 */
export function useGetApiProjectsProjectIdShiftReportsDraftSuspense<
  TData = GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsDraftSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsDraftPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdShiftReportsDraftQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsDraftQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsDraft400Schema
          | GetApiProjectsProjectIdShiftReportsDraft401Schema
          | GetApiProjectsProjectIdShiftReportsDraft403Schema
          | GetApiProjectsProjectIdShiftReportsDraft404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdShiftReportsDraftSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsDraftSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsDraft400Schema
      | GetApiProjectsProjectIdShiftReportsDraft401Schema
      | GetApiProjectsProjectIdShiftReportsDraft403Schema
      | GetApiProjectsProjectIdShiftReportsDraft404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportId401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportId403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportId404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportId(projectId, shiftReportId, config);
    },
  });
}

/**
 * @summary Retrieves a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportId<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportId401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportId403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId);

  const query = useQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdQueryOptions(
      projectId,
      shiftReportId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportId401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportId403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdShiftReportsShiftReportIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id']
) =>
  [
    {
      url: '/api/projects/:project_id/shift_reports/:shift_report_id',
      params: { projectId: projectId, shiftReportId: shiftReportId },
    },
  ] as const;

export type GetApiProjectsProjectIdShiftReportsShiftReportIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdShiftReportsShiftReportIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdShiftReportsShiftReportIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdShiftReportsShiftReportIdSuspenseQueryKey(projectId, shiftReportId);
  return queryOptions<
    GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportId401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportId403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportId404Schema
    >,
    GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && shiftReportId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdShiftReportsShiftReportId(projectId, shiftReportId, config);
    },
  });
}

/**
 * @summary Retrieves a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id}
 */
export function useGetApiProjectsProjectIdShiftReportsShiftReportIdSuspense<
  TData = GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdShiftReportsShiftReportIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'],
  shiftReportId: GetApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdShiftReportsShiftReportIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdShiftReportsShiftReportId401Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportId403Schema
          | GetApiProjectsProjectIdShiftReportsShiftReportId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdShiftReportsShiftReportIdSuspenseQueryKey(projectId, shiftReportId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdShiftReportsShiftReportIdSuspenseQueryOptions(
      projectId,
      shiftReportId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdShiftReportsShiftReportId401Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportId403Schema
      | GetApiProjectsProjectIdShiftReportsShiftReportId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdShiftReportsShiftReportIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}' }] as const;

export type PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdShiftReportsShiftReportIdMutationKey
>;

/**
 * @summary Updates a draft shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id}
 */
export function usePatchApiProjectsProjectIdShiftReportsShiftReportId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdShiftReportsShiftReportId400Schema
        | PatchApiProjectsProjectIdShiftReportsShiftReportId401Schema
        | PatchApiProjectsProjectIdShiftReportsShiftReportId403Schema
        | PatchApiProjectsProjectIdShiftReportsShiftReportId404Schema
        | PatchApiProjectsProjectIdShiftReportsShiftReportId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'];
        shiftReportId: PatchApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'];
        data?: PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdShiftReportsShiftReportIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdShiftReportsShiftReportId400Schema
      | PatchApiProjectsProjectIdShiftReportsShiftReportId401Schema
      | PatchApiProjectsProjectIdShiftReportsShiftReportId403Schema
      | PatchApiProjectsProjectIdShiftReportsShiftReportId404Schema
      | PatchApiProjectsProjectIdShiftReportsShiftReportId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'];
      shiftReportId: PatchApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'];
      data?: PatchApiProjectsProjectIdShiftReportsShiftReportIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, data }) => {
      return patchApiProjectsProjectIdShiftReportsShiftReportId(projectId, shiftReportId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdShiftReportsShiftReportIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}' }] as const;

export type DeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdShiftReportsShiftReportIdMutationKey
>;

/**
 * @summary Deletes a draft shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id}
 */
export function useDeleteApiProjectsProjectIdShiftReportsShiftReportId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdShiftReportsShiftReportId400Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportId401Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportId403Schema
        | DeleteApiProjectsProjectIdShiftReportsShiftReportId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'];
        shiftReportId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdShiftReportsShiftReportIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdShiftReportsShiftReportIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdShiftReportsShiftReportId400Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportId401Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportId403Schema
      | DeleteApiProjectsProjectIdShiftReportsShiftReportId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['project_id'];
      shiftReportId: DeleteApiProjectsProjectIdShiftReportsShiftReportIdPathParamsSchema['shift_report_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftReportId }) => {
      return deleteApiProjectsProjectIdShiftReportsShiftReportId(projectId, shiftReportId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/archive' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationKey
>;

/**
 * @summary Archives a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/archive}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdArchive(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive404Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdArchivePathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdArchivePathParamsSchema['shift_report_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdArchiveMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive404Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdArchive422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdArchivePathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdArchivePathParamsSchema['shift_report_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftReportId }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdArchive(projectId, shiftReportId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/publish' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationKey
>;

/**
 * @summary Publishes a draft shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/publish}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdPublish(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish400Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishPathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishPathParamsSchema['shift_report_id'];
        params?: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishQueryParamsSchema;
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdPublishMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish400Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdPublish404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishPathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishPathParamsSchema['shift_report_id'];
      params?: PostApiProjectsProjectIdShiftReportsShiftReportIdPublishQueryParamsSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, params }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdPublish(projectId, shiftReportId, params, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/reset_section' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationKey
>;

/**
 * @summary Deletes all elements from a shift report section
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/reset_section}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdResetSection(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection400Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection401Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionPathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionPathParamsSchema['shift_report_id'];
        params?: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionQueryParamsSchema;
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection400Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdResetSection401Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionPathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionPathParamsSchema['shift_report_id'];
      params?: PostApiProjectsProjectIdShiftReportsShiftReportIdResetSectionQueryParamsSchema;
    }
  >({
    mutationFn: async ({ projectId, shiftReportId, params }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdResetSection(projectId, shiftReportId, params, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationKey = () =>
  [{ url: '/api/projects/{project_id}/shift_reports/{shift_report_id}/restore' }] as const;

export type PostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationKey = ReturnType<
  typeof postApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationKey
>;

/**
 * @summary Restores a shift report
 * {@link /api/projects/:project_id/shift_reports/:shift_report_id/restore}
 */
export function usePostApiProjectsProjectIdShiftReportsShiftReportIdRestore(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore401Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore403Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore404Schema
        | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdRestorePathParamsSchema['project_id'];
        shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdRestorePathParamsSchema['shift_report_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationKey();

  return useMutation<
    PostApiProjectsProjectIdShiftReportsShiftReportIdRestoreMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore401Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore403Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore404Schema
      | PostApiProjectsProjectIdShiftReportsShiftReportIdRestore422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdShiftReportsShiftReportIdRestorePathParamsSchema['project_id'];
      shiftReportId: PostApiProjectsProjectIdShiftReportsShiftReportIdRestorePathParamsSchema['shift_report_id'];
    }
  >({
    mutationFn: async ({ projectId, shiftReportId }) => {
      return postApiProjectsProjectIdShiftReportsShiftReportIdRestore(projectId, shiftReportId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsShowcasesMutationKey = () => [{ url: '/api/projects/showcases' }] as const;

export type PostApiProjectsShowcasesMutationKey = ReturnType<typeof postApiProjectsShowcasesMutationKey>;

/**
 * @summary Creates a Showcase project
 * {@link /api/projects/showcases}
 */
export function usePostApiProjectsShowcases(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsShowcasesMutationResponseSchema,
      ResponseErrorConfig<PostApiProjectsShowcases401Schema | PostApiProjectsShowcases403Schema>,
      { data: PostApiProjectsShowcasesMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiProjectsShowcasesMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsShowcasesMutationKey();

  return useMutation<
    PostApiProjectsShowcasesMutationResponseSchema,
    ResponseErrorConfig<PostApiProjectsShowcases401Schema | PostApiProjectsShowcases403Schema>,
    { data: PostApiProjectsShowcasesMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiProjectsShowcases(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id']
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/channel_configuration',
      params: { projectId: projectId, teamId: teamId },
    },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdChannelConfiguration(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieves the team channel configuration
 * {@link /api/projects/:project_id/teams/:team_id/channel_configuration}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfiguration<
  TData = GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
          | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
          | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryKey(projectId, teamId);

  const query = useQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryOptions(
      projectId,
      teamId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdTeamsTeamIdChannelConfigurationSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id']
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/channel_configuration',
      params: { projectId: projectId, teamId: teamId },
    },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdChannelConfigurationSuspenseQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdChannelConfigurationSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdChannelConfigurationSuspenseQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdChannelConfiguration(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieves the team channel configuration
 * {@link /api/projects/:project_id/teams/:team_id/channel_configuration}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdChannelConfigurationSuspense<
  TData = GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdTeamsTeamIdChannelConfigurationQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
          | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
          | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdChannelConfigurationSuspenseQueryKey(projectId, teamId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdChannelConfigurationSuspenseQueryOptions(
      projectId,
      teamId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
      | GetApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/channel_configuration' }] as const;

export type PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationKey
>;

/**
 * @summary Updates the team channel configuration
 * {@link /api/projects/:project_id/teams/:team_id/channel_configuration}
 */
export function usePatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
        | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
        | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema
        | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'];
        teamId: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id'];
        data?: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? patchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration401Schema
      | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration403Schema
      | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration404Schema
      | PatchApiProjectsProjectIdTeamsTeamIdChannelConfiguration422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['project_id'];
      teamId: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationPathParamsSchema['team_id'];
      data?: PatchApiProjectsProjectIdTeamsTeamIdChannelConfigurationMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, teamId, data }) => {
      return patchApiProjectsProjectIdTeamsTeamIdChannelConfiguration(projectId, teamId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdTeamsTeamIdJoinTokenQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id']
) =>
  [
    { url: '/api/projects/:project_id/teams/:team_id/join_token', params: { projectId: projectId, teamId: teamId } },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdJoinTokenQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdJoinTokenQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdJoinTokenQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdJoinToken(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieves a team join token
 * {@link /api/projects/:project_id/teams/:team_id/join_token}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdJoinToken<
  TData = GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
          | GetApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
          | GetApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdJoinTokenQueryKey(projectId, teamId);

  const query = useQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdJoinTokenQueryOptions(
      projectId,
      teamId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdTeamsTeamIdJoinTokenSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id']
) =>
  [
    { url: '/api/projects/:project_id/teams/:team_id/join_token', params: { projectId: projectId, teamId: teamId } },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdJoinTokenSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdJoinTokenSuspenseQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdJoinTokenSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdJoinTokenSuspenseQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdJoinToken(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieves a team join token
 * {@link /api/projects/:project_id/teams/:team_id/join_token}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdJoinTokenSuspense<
  TData = GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdJoinTokenSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdTeamsTeamIdJoinTokenQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
          | GetApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
          | GetApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdJoinTokenSuspenseQueryKey(projectId, teamId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdJoinTokenSuspenseQueryOptions(
      projectId,
      teamId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
      | GetApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdTeamsTeamIdJoinTokenMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/join_token' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdJoinTokenMutationKey
>;

/**
 * @summary Creates a new team join token (and expires existing join token)
 * {@link /api/projects/:project_id/teams/:team_id/join_token}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdJoinToken(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
        | PostApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
        | PostApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
        | PostApiProjectsProjectIdTeamsTeamIdJoinToken422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'];
        data: PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdJoinTokenMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
      | PostApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
      | PostApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
      | PostApiProjectsProjectIdTeamsTeamIdJoinToken422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'];
      data: PostApiProjectsProjectIdTeamsTeamIdJoinTokenMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, teamId, data }) => {
      return postApiProjectsProjectIdTeamsTeamIdJoinToken(projectId, teamId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/join_token' }] as const;

export type DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationKey
>;

/**
 * @summary Expires a team join token
 * {@link /api/projects/:project_id/teams/:team_id/join_token}
 */
export function useDeleteApiProjectsProjectIdTeamsTeamIdJoinToken(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
        | DeleteApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
        | DeleteApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'];
        teamId: DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdTeamsTeamIdJoinToken401Schema
      | DeleteApiProjectsProjectIdTeamsTeamIdJoinToken403Schema
      | DeleteApiProjectsProjectIdTeamsTeamIdJoinToken404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['project_id'];
      teamId: DeleteApiProjectsProjectIdTeamsTeamIdJoinTokenPathParamsSchema['team_id'];
    }
  >({
    mutationFn: async ({ projectId, teamId }) => {
      return deleteApiProjectsProjectIdTeamsTeamIdJoinToken(projectId, teamId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiTeamJoinTokensTokenQueryKey = (token: GetApiTeamJoinTokensTokenPathParamsSchema['token']) =>
  [{ url: '/api/team_join_tokens/:token', params: { token: token } }] as const;

export type GetApiTeamJoinTokensTokenQueryKey = ReturnType<typeof getApiTeamJoinTokensTokenQueryKey>;

export function getApiTeamJoinTokensTokenQueryOptions(
  token: GetApiTeamJoinTokensTokenPathParamsSchema['token'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiTeamJoinTokensTokenQueryKey(token);
  return queryOptions<
    GetApiTeamJoinTokensTokenQueryResponseSchema,
    ResponseErrorConfig<GetApiTeamJoinTokensToken404Schema>,
    GetApiTeamJoinTokensTokenQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!token,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiTeamJoinTokensToken(token, config);
    },
  });
}

/**
 * @summary Retrieves public team join token
 * {@link /api/team_join_tokens/:token}
 */
export function useGetApiTeamJoinTokensToken<
  TData = GetApiTeamJoinTokensTokenQueryResponseSchema,
  TQueryData = GetApiTeamJoinTokensTokenQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiTeamJoinTokensTokenQueryKey,
>(
  token: GetApiTeamJoinTokensTokenPathParamsSchema['token'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiTeamJoinTokensTokenQueryResponseSchema,
        ResponseErrorConfig<GetApiTeamJoinTokensToken404Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiTeamJoinTokensTokenQueryKey(token);

  const query = useQuery({
    ...(getApiTeamJoinTokensTokenQueryOptions(token, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiTeamJoinTokensToken404Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiTeamJoinTokensTokenSuspenseQueryKey = (token: GetApiTeamJoinTokensTokenPathParamsSchema['token']) =>
  [{ url: '/api/team_join_tokens/:token', params: { token: token } }] as const;

export type GetApiTeamJoinTokensTokenSuspenseQueryKey = ReturnType<typeof getApiTeamJoinTokensTokenSuspenseQueryKey>;

export function getApiTeamJoinTokensTokenSuspenseQueryOptions(
  token: GetApiTeamJoinTokensTokenPathParamsSchema['token'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiTeamJoinTokensTokenSuspenseQueryKey(token);
  return queryOptions<
    GetApiTeamJoinTokensTokenQueryResponseSchema,
    ResponseErrorConfig<GetApiTeamJoinTokensToken404Schema>,
    GetApiTeamJoinTokensTokenQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!token,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiTeamJoinTokensToken(token, config);
    },
  });
}

/**
 * @summary Retrieves public team join token
 * {@link /api/team_join_tokens/:token}
 */
export function useGetApiTeamJoinTokensTokenSuspense<
  TData = GetApiTeamJoinTokensTokenQueryResponseSchema,
  TQueryData = GetApiTeamJoinTokensTokenQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiTeamJoinTokensTokenSuspenseQueryKey,
>(
  token: GetApiTeamJoinTokensTokenPathParamsSchema['token'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiTeamJoinTokensTokenQueryResponseSchema,
        ResponseErrorConfig<GetApiTeamJoinTokensToken404Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiTeamJoinTokensTokenSuspenseQueryKey(token);

  const query = useSuspenseQuery({
    ...(getApiTeamJoinTokensTokenSuspenseQueryOptions(token, config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiTeamJoinTokensToken404Schema>> & {
    queryKey: TQueryKey;
  };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiTeamMembersMutationKey = () => [{ url: '/api/team_members' }] as const;

export type PostApiTeamMembersMutationKey = ReturnType<typeof postApiTeamMembersMutationKey>;

/**
 * @summary Adds a user to a team using a team join token
 * {@link /api/team_members}
 */
export function usePostApiTeamMembers(
  options: {
    mutation?: UseMutationOptions<
      PostApiTeamMembersMutationResponseSchema,
      ResponseErrorConfig<PostApiTeamMembers401Schema | PostApiTeamMembers422Schema>,
      { data: PostApiTeamMembersMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiTeamMembersMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiTeamMembersMutationKey();

  return useMutation<
    PostApiTeamMembersMutationResponseSchema,
    ResponseErrorConfig<PostApiTeamMembers401Schema | PostApiTeamMembers422Schema>,
    { data: PostApiTeamMembersMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiTeamMembers(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdTeamsTeamIdMembersMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/members' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdMembersMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdMembersMutationKey
>;

/**
 * @summary Invites a user to the team
 * {@link /api/projects/:project_id/teams/:team_id/members}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdMembers(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdMembersMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdMembers401Schema
        | PostApiProjectsProjectIdTeamsTeamIdMembers403Schema
        | PostApiProjectsProjectIdTeamsTeamIdMembers404Schema
        | PostApiProjectsProjectIdTeamsTeamIdMembers422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdMembersPathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdMembersPathParamsSchema['team_id'];
        data?: PostApiProjectsProjectIdTeamsTeamIdMembersMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdMembersMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdMembersMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdMembersMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdMembers401Schema
      | PostApiProjectsProjectIdTeamsTeamIdMembers403Schema
      | PostApiProjectsProjectIdTeamsTeamIdMembers404Schema
      | PostApiProjectsProjectIdTeamsTeamIdMembers422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdMembersPathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdMembersPathParamsSchema['team_id'];
      data?: PostApiProjectsProjectIdTeamsTeamIdMembersMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, teamId, data }) => {
      return postApiProjectsProjectIdTeamsTeamIdMembers(projectId, teamId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/members/{team_member_id}' }] as const;

export type PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationKey
>;

/**
 * @summary Updates a team member
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id}
 */
export function usePatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema
        | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema
        | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema
        | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['project_id'];
        teamId: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_id'];
        teamMemberId: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_member_id'];
        data?: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema
      | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema
      | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema
      | PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['project_id'];
      teamId: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_id'];
      teamMemberId: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_member_id'];
      data?: PatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, teamId, teamMemberId, data }) => {
      return patchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId(projectId, teamId, teamMemberId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/members/{team_member_id}' }] as const;

export type DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationKey
>;

/**
 * @summary Deletes an invited team member
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id}
 */
export function useDeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema
        | DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema
        | DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['project_id'];
        teamId: DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_id'];
        teamMemberId: DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_member_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId401Schema
      | DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId403Schema
      | DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['project_id'];
      teamId: DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_id'];
      teamMemberId: DeleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdPathParamsSchema['team_member_id'];
    }
  >({
    mutationFn: async ({ projectId, teamId, teamMemberId }) => {
      return deleteApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId(projectId, teamId, teamMemberId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/members/{team_member_id}/archive' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationKey
>;

/**
 * @summary Archives a team member
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id/archive}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive401Schema
        | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive403Schema
        | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive404Schema
        | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema['team_id'];
        teamMemberId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema['team_member_id'];
        data?: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive401Schema
      | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive403Schema
      | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive404Schema
      | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema['team_id'];
      teamMemberId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchivePathParamsSchema['team_member_id'];
      data?: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchiveMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, teamId, teamMemberId, data }) => {
      return postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive(
        projectId,
        teamId,
        teamMemberId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_id'],
  teamMemberId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_member_id']
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/members/:team_member_id/issue_dependencies',
      params: { projectId: projectId, teamId: teamId, teamMemberId: teamMemberId },
    },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_id'],
  teamMemberId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_member_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryKey(
    projectId,
    teamId,
    teamMemberId
  );
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies401Schema
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies403Schema
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId && teamMemberId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies(
        projectId,
        teamId,
        teamMemberId,
        config
      );
    },
  });
}

/**
 * @summary Retrieves team member issue dependencies
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id/issue_dependencies}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies<
  TData = GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_id'],
  teamMemberId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_member_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies401Schema
          | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies403Schema
          | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryKey(projectId, teamId, teamMemberId);

  const query = useQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryOptions(
      projectId,
      teamId,
      teamMemberId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies401Schema
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies403Schema
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_id'],
  teamMemberId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_member_id']
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/members/:team_member_id/issue_dependencies',
      params: { projectId: projectId, teamId: teamId, teamMemberId: teamMemberId },
    },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSuspenseQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_id'],
  teamMemberId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_member_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSuspenseQueryKey(
    projectId,
    teamId,
    teamMemberId
  );
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies401Schema
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies403Schema
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId && teamMemberId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies(
        projectId,
        teamId,
        teamMemberId,
        config
      );
    },
  });
}

/**
 * @summary Retrieves team member issue dependencies
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id/issue_dependencies}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSuspense<
  TData = GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_id'],
  teamMemberId: GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesPathParamsSchema['team_member_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies401Schema
          | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies403Schema
          | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSuspenseQueryKey(
      projectId,
      teamId,
      teamMemberId
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependenciesSuspenseQueryOptions(
      projectId,
      teamId,
      teamMemberId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies401Schema
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies403Schema
      | GetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/members/{team_member_id}/resend_invite_email' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationKey
>;

/**
 * @summary Resends invite email to an invited user
 * {@link /api/projects/:project_id/teams/:team_id/members/:team_member_id/resend_invite_email}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail401Schema
        | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail403Schema
        | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema['team_id'];
        teamMemberId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema['team_member_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail401Schema
      | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail403Schema
      | PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema['team_id'];
      teamMemberId: PostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmailPathParamsSchema['team_member_id'];
    }
  >({
    mutationFn: async ({ projectId, teamId, teamMemberId }) => {
      return postApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdResendInviteEmail(
        projectId,
        teamId,
        teamMemberId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['team_id']
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/metabase_dashboard',
      params: { projectId: projectId, teamId: teamId },
    },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard401Schema
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard403Schema
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard503Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieve metabase dashboard token
 * {@link /api/projects/:project_id/teams/:team_id/metabase_dashboard}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard<
  TData = GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard401Schema
          | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard403Schema
          | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard503Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryKey(projectId, teamId);

  const query = useQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryOptions(
      projectId,
      teamId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard401Schema
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard403Schema
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard503Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['team_id']
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/metabase_dashboard',
      params: { projectId: projectId, teamId: teamId },
    },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSuspenseQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSuspenseQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard401Schema
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard403Schema
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard503Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdMetabaseDashboard(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieve metabase dashboard token
 * {@link /api/projects/:project_id/teams/:team_id/metabase_dashboard}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSuspense<
  TData = GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboardQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard401Schema
          | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard403Schema
          | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard503Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSuspenseQueryKey(projectId, teamId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdMetabaseDashboardSuspenseQueryOptions(
      projectId,
      teamId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard401Schema
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard403Schema
      | GetApiProjectsProjectIdTeamsTeamIdMetabaseDashboard503Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/resources/{resource_id}/enable' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationKey
>;

/**
 * @summary Enables a resource
 * {@link /api/projects/:project_id/teams/:team_id/resources/:resource_id/enable}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable401Schema
        | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable403Schema
        | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema['team_id'];
        resourceId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema['resource_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnableMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable401Schema
      | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable403Schema
      | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema['team_id'];
      resourceId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnablePathParamsSchema['resource_id'];
    }
  >({
    mutationFn: async ({ projectId, teamId, resourceId }) => {
      return postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdEnable(projectId, teamId, resourceId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/resources/{resource_id}/disable' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationKey
>;

/**
 * @summary Disables a resource
 * {@link /api/projects/:project_id/teams/:team_id/resources/:resource_id/disable}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable401Schema
        | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable403Schema
        | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema['team_id'];
        resourceId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema['resource_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisableMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable401Schema
      | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable403Schema
      | PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema['team_id'];
      resourceId: PostApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisablePathParamsSchema['resource_id'];
    }
  >({
    mutationFn: async ({ projectId, teamId, resourceId }) => {
      return postApiProjectsProjectIdTeamsTeamIdResourcesResourceIdDisable(projectId, teamId, resourceId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdTeamsTeamIdResourcesKindQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'],
  kind: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/resources/:kind',
      params: { projectId: projectId, teamId: teamId, kind: kind },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdResourcesKindQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdResourcesKindQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'],
  kind: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdResourcesKindQueryKey(projectId, teamId, kind, params);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId && kind),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdResourcesKind(projectId, teamId, kind, params, config);
    },
  });
}

/**
 * @summary Retrieves resources
 * {@link /api/projects/:project_id/teams/:team_id/resources/:kind}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKind<
  TData = GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'],
  kind: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
          | GetApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
          | GetApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdResourcesKindQueryKey(projectId, teamId, kind, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdResourcesKindQueryOptions(
      projectId,
      teamId,
      kind,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdTeamsTeamIdResourcesKindSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'],
  kind: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/resources/:kind',
      params: { projectId: projectId, teamId: teamId, kind: kind },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdResourcesKindSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdResourcesKindSuspenseQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdResourcesKindSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'],
  kind: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdResourcesKindSuspenseQueryKey(projectId, teamId, kind, params);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId && kind),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdResourcesKind(projectId, teamId, kind, params, config);
    },
  });
}

/**
 * @summary Retrieves resources
 * {@link /api/projects/:project_id/teams/:team_id/resources/:kind}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdResourcesKindSuspense<
  TData = GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdResourcesKindSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'],
  kind: GetApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'],
  params?: GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdTeamsTeamIdResourcesKindQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
          | GetApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
          | GetApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdTeamsTeamIdResourcesKindSuspenseQueryKey(projectId, teamId, kind, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdResourcesKindSuspenseQueryOptions(
      projectId,
      teamId,
      kind,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
      | GetApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdTeamsTeamIdResourcesKindMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/resources/{kind}' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdResourcesKindMutationKey
>;

/**
 * @summary Create resource
 * {@link /api/projects/:project_id/teams/:team_id/resources/:kind}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdResourcesKind(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
        | PostApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
        | PostApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema
        | PostApiProjectsProjectIdTeamsTeamIdResourcesKind422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'];
        kind: PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'];
        data: PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdResourcesKindMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdResourcesKind401Schema
      | PostApiProjectsProjectIdTeamsTeamIdResourcesKind403Schema
      | PostApiProjectsProjectIdTeamsTeamIdResourcesKind404Schema
      | PostApiProjectsProjectIdTeamsTeamIdResourcesKind422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['team_id'];
      kind: PostApiProjectsProjectIdTeamsTeamIdResourcesKindPathParamsSchema['kind'];
      data: PostApiProjectsProjectIdTeamsTeamIdResourcesKindMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, teamId, kind, data }) => {
      return postApiProjectsProjectIdTeamsTeamIdResourcesKind(projectId, teamId, kind, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['team_id']
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/subscription_plan',
      params: { projectId: projectId, teamId: teamId },
    },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan401Schema
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan403Schema
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieves team current subscription plan
 * {@link /api/projects/:project_id/teams/:team_id/subscription_plan}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan<
  TData = GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan401Schema
          | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan403Schema
          | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryKey(projectId, teamId);

  const query = useQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryOptions(
      projectId,
      teamId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan401Schema
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan403Schema
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['team_id']
) =>
  [
    {
      url: '/api/projects/:project_id/teams/:team_id/subscription_plan',
      params: { projectId: projectId, teamId: teamId },
    },
  ] as const;

export type GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSuspenseQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSuspenseQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan401Schema
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan403Schema
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamIdSubscriptionPlan(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieves team current subscription plan
 * {@link /api/projects/:project_id/teams/:team_id/subscription_plan}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSuspense<
  TData = GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlanQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan401Schema
          | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan403Schema
          | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSuspenseQueryKey(projectId, teamId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdSubscriptionPlanSuspenseQueryOptions(
      projectId,
      teamId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan401Schema
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan403Schema
      | GetApiProjectsProjectIdTeamsTeamIdSubscriptionPlan404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdTeamsTeamIdSubscriptionMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/subscription' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionMutationKey
>;

/**
 * @summary Start a team subscription update
 * {@link /api/projects/:project_id/teams/:team_id/subscription}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdSubscription(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdSubscription401Schema
        | PostApiProjectsProjectIdTeamsTeamIdSubscription403Schema
        | PostApiProjectsProjectIdTeamsTeamIdSubscription404Schema
        | PostApiProjectsProjectIdTeamsTeamIdSubscription422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionPathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionPathParamsSchema['team_id'];
        data?: PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdSubscriptionMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdSubscription401Schema
      | PostApiProjectsProjectIdTeamsTeamIdSubscription403Schema
      | PostApiProjectsProjectIdTeamsTeamIdSubscription404Schema
      | PostApiProjectsProjectIdTeamsTeamIdSubscription422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionPathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionPathParamsSchema['team_id'];
      data?: PostApiProjectsProjectIdTeamsTeamIdSubscriptionMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, teamId, data }) => {
      return postApiProjectsProjectIdTeamsTeamIdSubscription(projectId, teamId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/subscription/confirm' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationKey
>;

/**
 * @summary Confirm a Stripe Billing subscription
 * {@link /api/projects/:project_id/teams/:team_id/subscription/confirm}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm401Schema
        | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm403Schema
        | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm404Schema
        | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmPathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmPathParamsSchema['team_id'];
        data?: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm401Schema
      | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm403Schema
      | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm404Schema
      | PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmPathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmPathParamsSchema['team_id'];
      data?: PostApiProjectsProjectIdTeamsTeamIdSubscriptionConfirmMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, teamId, data }) => {
      return postApiProjectsProjectIdTeamsTeamIdSubscriptionConfirm(projectId, teamId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/subscription/billing_portal' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationKey
>;

/**
 * @summary Retrieves an URL for the billing portal
 * {@link /api/projects/:project_id/teams/:team_id/subscription/billing_portal}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal401Schema
        | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal403Schema
        | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal404Schema
        | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalPathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalPathParamsSchema['team_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal401Schema
      | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal403Schema
      | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal404Schema
      | PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalPathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortalPathParamsSchema['team_id'];
    }
  >({
    mutationFn: async ({ projectId, teamId }) => {
      return postApiProjectsProjectIdTeamsTeamIdSubscriptionBillingPortal(projectId, teamId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdTeamsQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsPathParamsSchema['project_id']
) => [{ url: '/api/projects/:project_id/teams', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdTeamsQueryKey = ReturnType<typeof getApiProjectsProjectIdTeamsQueryKey>;

export function getApiProjectsProjectIdTeamsQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeams401Schema
      | GetApiProjectsProjectIdTeams403Schema
      | GetApiProjectsProjectIdTeams404Schema
    >,
    GetApiProjectsProjectIdTeamsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeams(projectId, config);
    },
  });
}

/**
 * @summary Retrieves teams
 * {@link /api/projects/:project_id/teams}
 */
export function useGetApiProjectsProjectIdTeams<
  TData = GetApiProjectsProjectIdTeamsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdTeamsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeams401Schema
          | GetApiProjectsProjectIdTeams403Schema
          | GetApiProjectsProjectIdTeams404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsQueryKey(projectId);

  const query = useQuery({
    ...(getApiProjectsProjectIdTeamsQueryOptions(projectId, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeams401Schema
      | GetApiProjectsProjectIdTeams403Schema
      | GetApiProjectsProjectIdTeams404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdTeamsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsPathParamsSchema['project_id']
) => [{ url: '/api/projects/:project_id/teams', params: { projectId: projectId } }] as const;

export type GetApiProjectsProjectIdTeamsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsSuspenseQueryKey
>;

export function getApiProjectsProjectIdTeamsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsSuspenseQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeams401Schema
      | GetApiProjectsProjectIdTeams403Schema
      | GetApiProjectsProjectIdTeams404Schema
    >,
    GetApiProjectsProjectIdTeamsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeams(projectId, config);
    },
  });
}

/**
 * @summary Retrieves teams
 * {@link /api/projects/:project_id/teams}
 */
export function useGetApiProjectsProjectIdTeamsSuspense<
  TData = GetApiProjectsProjectIdTeamsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdTeamsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeams401Schema
          | GetApiProjectsProjectIdTeams403Schema
          | GetApiProjectsProjectIdTeams404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsSuspenseQueryKey(projectId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdTeamsSuspenseQueryOptions(projectId, config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeams401Schema
      | GetApiProjectsProjectIdTeams403Schema
      | GetApiProjectsProjectIdTeams404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdTeamsMutationKey = () => [{ url: '/api/projects/{project_id}/teams' }] as const;

export type PostApiProjectsProjectIdTeamsMutationKey = ReturnType<typeof postApiProjectsProjectIdTeamsMutationKey>;

/**
 * @summary Creates a team
 * {@link /api/projects/:project_id/teams}
 */
export function usePostApiProjectsProjectIdTeams(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeams401Schema
        | PostApiProjectsProjectIdTeams403Schema
        | PostApiProjectsProjectIdTeams404Schema
        | PostApiProjectsProjectIdTeams422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsPathParamsSchema['project_id'];
        data?: PostApiProjectsProjectIdTeamsMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdTeamsMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeams401Schema
      | PostApiProjectsProjectIdTeams403Schema
      | PostApiProjectsProjectIdTeams404Schema
      | PostApiProjectsProjectIdTeams422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsPathParamsSchema['project_id'];
      data?: PostApiProjectsProjectIdTeamsMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdTeams(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdTeamsTeamIdQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id']
) => [{ url: '/api/projects/:project_id/teams/:team_id', params: { projectId: projectId, teamId: teamId } }] as const;

export type GetApiProjectsProjectIdTeamsTeamIdQueryKey = ReturnType<typeof getApiProjectsProjectIdTeamsTeamIdQueryKey>;

export function getApiProjectsProjectIdTeamsTeamIdQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamId401Schema
      | GetApiProjectsProjectIdTeamsTeamId403Schema
      | GetApiProjectsProjectIdTeamsTeamId404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamId(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieves a team
 * {@link /api/projects/:project_id/teams/:team_id}
 */
export function useGetApiProjectsProjectIdTeamsTeamId<
  TData = GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamId401Schema
          | GetApiProjectsProjectIdTeamsTeamId403Schema
          | GetApiProjectsProjectIdTeamsTeamId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdQueryKey(projectId, teamId);

  const query = useQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdQueryOptions(projectId, teamId, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamId401Schema
      | GetApiProjectsProjectIdTeamsTeamId403Schema
      | GetApiProjectsProjectIdTeamsTeamId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdTeamsTeamIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id']
) => [{ url: '/api/projects/:project_id/teams/:team_id', params: { projectId: projectId, teamId: teamId } }] as const;

export type GetApiProjectsProjectIdTeamsTeamIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdTeamsTeamIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdTeamsTeamIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdTeamsTeamIdSuspenseQueryKey(projectId, teamId);
  return queryOptions<
    GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamId401Schema
      | GetApiProjectsProjectIdTeamsTeamId403Schema
      | GetApiProjectsProjectIdTeamsTeamId404Schema
    >,
    GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && teamId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdTeamsTeamId(projectId, teamId, config);
    },
  });
}

/**
 * @summary Retrieves a team
 * {@link /api/projects/:project_id/teams/:team_id}
 */
export function useGetApiProjectsProjectIdTeamsTeamIdSuspense<
  TData = GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdTeamsTeamIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'],
  teamId: GetApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdTeamsTeamIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdTeamsTeamId401Schema
          | GetApiProjectsProjectIdTeamsTeamId403Schema
          | GetApiProjectsProjectIdTeamsTeamId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdTeamsTeamIdSuspenseQueryKey(projectId, teamId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdTeamsTeamIdSuspenseQueryOptions(
      projectId,
      teamId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdTeamsTeamId401Schema
      | GetApiProjectsProjectIdTeamsTeamId403Schema
      | GetApiProjectsProjectIdTeamsTeamId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdTeamsTeamIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}' }] as const;

export type PatchApiProjectsProjectIdTeamsTeamIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdTeamsTeamIdMutationKey
>;

/**
 * @summary Updates a team
 * {@link /api/projects/:project_id/teams/:team_id}
 */
export function usePatchApiProjectsProjectIdTeamsTeamId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdTeamsTeamId401Schema
        | PatchApiProjectsProjectIdTeamsTeamId403Schema
        | PatchApiProjectsProjectIdTeamsTeamId404Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'];
        teamId: PatchApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'];
        data?: PatchApiProjectsProjectIdTeamsTeamIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PatchApiProjectsProjectIdTeamsTeamIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiProjectsProjectIdTeamsTeamIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdTeamsTeamId401Schema
      | PatchApiProjectsProjectIdTeamsTeamId403Schema
      | PatchApiProjectsProjectIdTeamsTeamId404Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'];
      teamId: PatchApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'];
      data?: PatchApiProjectsProjectIdTeamsTeamIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, teamId, data }) => {
      return patchApiProjectsProjectIdTeamsTeamId(projectId, teamId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdTeamsTeamIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}' }] as const;

export type DeleteApiProjectsProjectIdTeamsTeamIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdTeamsTeamIdMutationKey
>;

/**
 * @summary Deletes a team
 * {@link /api/projects/:project_id/teams/:team_id}
 */
export function useDeleteApiProjectsProjectIdTeamsTeamId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdTeamsTeamId401Schema
        | DeleteApiProjectsProjectIdTeamsTeamId403Schema
        | DeleteApiProjectsProjectIdTeamsTeamId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'];
        teamId: DeleteApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdTeamsTeamIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdTeamsTeamIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdTeamsTeamId401Schema
      | DeleteApiProjectsProjectIdTeamsTeamId403Schema
      | DeleteApiProjectsProjectIdTeamsTeamId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdTeamsTeamIdPathParamsSchema['project_id'];
      teamId: DeleteApiProjectsProjectIdTeamsTeamIdPathParamsSchema['team_id'];
    }
  >({
    mutationFn: async ({ projectId, teamId }) => {
      return deleteApiProjectsProjectIdTeamsTeamId(projectId, teamId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationKey = () =>
  [{ url: '/api/projects/{project_id}/teams/{team_id}/resend_members_invites' }] as const;

export type PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationKey = ReturnType<
  typeof postApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationKey
>;

/**
 * @summary Resends invite email to all invited users on the team
 * {@link /api/projects/:project_id/teams/:team_id/resend_members_invites}
 */
export function usePostApiProjectsProjectIdTeamsTeamIdResendMembersInvites(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites401Schema
        | PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesPathParamsSchema['project_id'];
        teamId: PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesPathParamsSchema['team_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationKey();

  return useMutation<
    PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites401Schema
      | PostApiProjectsProjectIdTeamsTeamIdResendMembersInvites404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesPathParamsSchema['project_id'];
      teamId: PostApiProjectsProjectIdTeamsTeamIdResendMembersInvitesPathParamsSchema['team_id'];
    }
  >({
    mutationFn: async ({ projectId, teamId }) => {
      return postApiProjectsProjectIdTeamsTeamIdResendMembersInvites(projectId, teamId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiTimeZonesQueryKey = () => [{ url: '/api/time_zones' }] as const;

export type GetApiTimeZonesQueryKey = ReturnType<typeof getApiTimeZonesQueryKey>;

export function getApiTimeZonesQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiTimeZonesQueryKey();
  return queryOptions<
    GetApiTimeZonesQueryResponseSchema,
    ResponseErrorConfig<GetApiTimeZones401Schema>,
    GetApiTimeZonesQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiTimeZones(config);
    },
  });
}

/**
 * @summary Retrieves time zones
 * {@link /api/time_zones}
 */
export function useGetApiTimeZones<
  TData = GetApiTimeZonesQueryResponseSchema,
  TQueryData = GetApiTimeZonesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiTimeZonesQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiTimeZonesQueryResponseSchema,
        ResponseErrorConfig<GetApiTimeZones401Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiTimeZonesQueryKey();

  const query = useQuery({
    ...(getApiTimeZonesQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiTimeZones401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiTimeZonesSuspenseQueryKey = () => [{ url: '/api/time_zones' }] as const;

export type GetApiTimeZonesSuspenseQueryKey = ReturnType<typeof getApiTimeZonesSuspenseQueryKey>;

export function getApiTimeZonesSuspenseQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiTimeZonesSuspenseQueryKey();
  return queryOptions<
    GetApiTimeZonesQueryResponseSchema,
    ResponseErrorConfig<GetApiTimeZones401Schema>,
    GetApiTimeZonesQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiTimeZones(config);
    },
  });
}

/**
 * @summary Retrieves time zones
 * {@link /api/time_zones}
 */
export function useGetApiTimeZonesSuspense<
  TData = GetApiTimeZonesQueryResponseSchema,
  TQueryData = GetApiTimeZonesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiTimeZonesSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiTimeZonesQueryResponseSchema,
        ResponseErrorConfig<GetApiTimeZones401Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiTimeZonesSuspenseQueryKey();

  const query = useSuspenseQuery({
    ...(getApiTimeZonesSuspenseQueryOptions(config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiTimeZones401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiUsersConfirmationInstructionsMutationKey = () =>
  [{ url: '/api/users/confirmation/instructions' }] as const;

export type PostApiUsersConfirmationInstructionsMutationKey = ReturnType<
  typeof postApiUsersConfirmationInstructionsMutationKey
>;

/**
 * @summary Sends a confirmation email
 * {@link /api/users/confirmation/instructions}
 */
export function usePostApiUsersConfirmationInstructions(
  options: {
    mutation?: UseMutationOptions<
      PostApiUsersConfirmationInstructionsMutationResponseSchema,
      ResponseErrorConfig<Error>,
      { data: PostApiUsersConfirmationInstructionsMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiUsersConfirmationInstructionsMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiUsersConfirmationInstructionsMutationKey();

  return useMutation<
    PostApiUsersConfirmationInstructionsMutationResponseSchema,
    ResponseErrorConfig<Error>,
    { data: PostApiUsersConfirmationInstructionsMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiUsersConfirmationInstructions(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiUsersConfirmationMutationKey = () => [{ url: '/api/users/confirmation' }] as const;

export type PostApiUsersConfirmationMutationKey = ReturnType<typeof postApiUsersConfirmationMutationKey>;

/**
 * @summary Confirms a user
 * {@link /api/users/confirmation}
 */
export function usePostApiUsersConfirmation(
  options: {
    mutation?: UseMutationOptions<
      PostApiUsersConfirmationMutationResponseSchema,
      ResponseErrorConfig<PostApiUsersConfirmation400Schema | PostApiUsersConfirmation422Schema>,
      { data: PostApiUsersConfirmationMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiUsersConfirmationMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiUsersConfirmationMutationKey();

  return useMutation<
    PostApiUsersConfirmationMutationResponseSchema,
    ResponseErrorConfig<PostApiUsersConfirmation400Schema | PostApiUsersConfirmation422Schema>,
    { data: PostApiUsersConfirmationMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiUsersConfirmation(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiUsersMutationKey = () => [{ url: '/api/users' }] as const;

export type PostApiUsersMutationKey = ReturnType<typeof postApiUsersMutationKey>;

/**
 * @summary Creates a user
 * {@link /api/users}
 */
export function usePostApiUsers(
  options: {
    mutation?: UseMutationOptions<
      PostApiUsersMutationResponseSchema,
      ResponseErrorConfig<PostApiUsers422Schema>,
      { data: PostApiUsersMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiUsersMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiUsersMutationKey();

  return useMutation<
    PostApiUsersMutationResponseSchema,
    ResponseErrorConfig<PostApiUsers422Schema>,
    { data: PostApiUsersMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiUsers(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiUsersMeQueryKey = () => [{ url: '/api/users/me' }] as const;

export type GetApiUsersMeQueryKey = ReturnType<typeof getApiUsersMeQueryKey>;

export function getApiUsersMeQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiUsersMeQueryKey();
  return queryOptions<
    GetApiUsersMeQueryResponseSchema,
    ResponseErrorConfig<GetApiUsersMe401Schema>,
    GetApiUsersMeQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiUsersMe(config);
    },
  });
}

/**
 * @summary Retrieves current user
 * {@link /api/users/me}
 */
export function useGetApiUsersMe<
  TData = GetApiUsersMeQueryResponseSchema,
  TQueryData = GetApiUsersMeQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiUsersMeQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiUsersMeQueryResponseSchema,
        ResponseErrorConfig<GetApiUsersMe401Schema>,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiUsersMeQueryKey();

  const query = useQuery({
    ...(getApiUsersMeQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetApiUsersMe401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiUsersMeSuspenseQueryKey = () => [{ url: '/api/users/me' }] as const;

export type GetApiUsersMeSuspenseQueryKey = ReturnType<typeof getApiUsersMeSuspenseQueryKey>;

export function getApiUsersMeSuspenseQueryOptions(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const queryKey = getApiUsersMeSuspenseQueryKey();
  return queryOptions<
    GetApiUsersMeQueryResponseSchema,
    ResponseErrorConfig<GetApiUsersMe401Schema>,
    GetApiUsersMeQueryResponseSchema,
    typeof queryKey
  >({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiUsersMe(config);
    },
  });
}

/**
 * @summary Retrieves current user
 * {@link /api/users/me}
 */
export function useGetApiUsersMeSuspense<
  TData = GetApiUsersMeQueryResponseSchema,
  TQueryData = GetApiUsersMeQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiUsersMeSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiUsersMeQueryResponseSchema,
        ResponseErrorConfig<GetApiUsersMe401Schema>,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiUsersMeSuspenseQueryKey();

  const query = useSuspenseQuery({
    ...(getApiUsersMeSuspenseQueryOptions(config) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, ResponseErrorConfig<GetApiUsersMe401Schema>> & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiUsersMeMutationKey = () => [{ url: '/api/users/me' }] as const;

export type PatchApiUsersMeMutationKey = ReturnType<typeof patchApiUsersMeMutationKey>;

/**
 * @summary Updates current user
 * {@link /api/users/me}
 */
export function usePatchApiUsersMe(
  options: {
    mutation?: UseMutationOptions<
      PatchApiUsersMeMutationResponseSchema,
      ResponseErrorConfig<PatchApiUsersMe401Schema | PatchApiUsersMe422Schema>,
      { data: PatchApiUsersMeMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PatchApiUsersMeMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiUsersMeMutationKey();

  return useMutation<
    PatchApiUsersMeMutationResponseSchema,
    ResponseErrorConfig<PatchApiUsersMe401Schema | PatchApiUsersMe422Schema>,
    { data: PatchApiUsersMeMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return patchApiUsersMe(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiUsersPasswordInstructionsMutationKey = () => [{ url: '/api/users/password/instructions' }] as const;

export type PostApiUsersPasswordInstructionsMutationKey = ReturnType<
  typeof postApiUsersPasswordInstructionsMutationKey
>;

/**
 * @summary Sends password reset instructions
 * {@link /api/users/password/instructions}
 */
export function usePostApiUsersPasswordInstructions(
  options: {
    mutation?: UseMutationOptions<
      PostApiUsersPasswordInstructionsMutationResponseSchema,
      ResponseErrorConfig<Error>,
      { data: PostApiUsersPasswordInstructionsMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PostApiUsersPasswordInstructionsMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiUsersPasswordInstructionsMutationKey();

  return useMutation<
    PostApiUsersPasswordInstructionsMutationResponseSchema,
    ResponseErrorConfig<Error>,
    { data: PostApiUsersPasswordInstructionsMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return postApiUsersPasswordInstructions(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const patchApiUsersPasswordMutationKey = () => [{ url: '/api/users/password' }] as const;

export type PatchApiUsersPasswordMutationKey = ReturnType<typeof patchApiUsersPasswordMutationKey>;

/**
 * @summary Updates password with reset token
 * {@link /api/users/password}
 */
export function usePatchApiUsersPassword(
  options: {
    mutation?: UseMutationOptions<
      PatchApiUsersPasswordMutationResponseSchema,
      ResponseErrorConfig<PatchApiUsersPassword400Schema | PatchApiUsersPassword422Schema>,
      { data: PatchApiUsersPasswordMutationRequestSchema }
    >;
    client?: Partial<RequestConfig<PatchApiUsersPasswordMutationRequestSchema>> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? patchApiUsersPasswordMutationKey();

  return useMutation<
    PatchApiUsersPasswordMutationResponseSchema,
    ResponseErrorConfig<PatchApiUsersPassword400Schema | PatchApiUsersPassword422Schema>,
    { data: PatchApiUsersPasswordMutationRequestSchema }
  >({
    mutationFn: async ({ data }) => {
      return patchApiUsersPassword(data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdIssuesIssueIdWatchingsQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id']
) =>
  [
    { url: '/api/projects/:project_id/issues/:issue_id/watchings', params: { projectId: projectId, issueId: issueId } },
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdWatchingsQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdWatchingsQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdWatchingsQueryKey(projectId, issueId);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
    ResponseErrorConfig<
      GetApiProjectsProjectIdIssuesIssueIdWatchings401Schema | GetApiProjectsProjectIdIssuesIssueIdWatchings404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdWatchings(projectId, issueId, config);
    },
  });
}

/**
 * @summary Retrieves issue watchings
 * {@link /api/projects/:project_id/issues/:issue_id/watchings}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdWatchings<
  TData = GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdWatchings401Schema
          | GetApiProjectsProjectIdIssuesIssueIdWatchings404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdWatchingsQueryKey(projectId, issueId);

  const query = useQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdWatchingsQueryOptions(
      projectId,
      issueId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      GetApiProjectsProjectIdIssuesIssueIdWatchings401Schema | GetApiProjectsProjectIdIssuesIssueIdWatchings404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdIssuesIssueIdWatchingsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id']
) =>
  [
    { url: '/api/projects/:project_id/issues/:issue_id/watchings', params: { projectId: projectId, issueId: issueId } },
  ] as const;

export type GetApiProjectsProjectIdIssuesIssueIdWatchingsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdIssuesIssueIdWatchingsSuspenseQueryKey
>;

export function getApiProjectsProjectIdIssuesIssueIdWatchingsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdIssuesIssueIdWatchingsSuspenseQueryKey(projectId, issueId);
  return queryOptions<
    GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
    ResponseErrorConfig<
      GetApiProjectsProjectIdIssuesIssueIdWatchings401Schema | GetApiProjectsProjectIdIssuesIssueIdWatchings404Schema
    >,
    GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && issueId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdIssuesIssueIdWatchings(projectId, issueId, config);
    },
  });
}

/**
 * @summary Retrieves issue watchings
 * {@link /api/projects/:project_id/issues/:issue_id/watchings}
 */
export function useGetApiProjectsProjectIdIssuesIssueIdWatchingsSuspense<
  TData = GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdIssuesIssueIdWatchingsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'],
  issueId: GetApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdIssuesIssueIdWatchingsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdIssuesIssueIdWatchings401Schema
          | GetApiProjectsProjectIdIssuesIssueIdWatchings404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdIssuesIssueIdWatchingsSuspenseQueryKey(projectId, issueId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdIssuesIssueIdWatchingsSuspenseQueryOptions(
      projectId,
      issueId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      GetApiProjectsProjectIdIssuesIssueIdWatchings401Schema | GetApiProjectsProjectIdIssuesIssueIdWatchings404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdIssuesIssueIdWatchingsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/watchings' }] as const;

export type PostApiProjectsProjectIdIssuesIssueIdWatchingsMutationKey = ReturnType<
  typeof postApiProjectsProjectIdIssuesIssueIdWatchingsMutationKey
>;

/**
 * @summary Watches an issue
 * {@link /api/projects/:project_id/issues/:issue_id/watchings}
 */
export function usePostApiProjectsProjectIdIssuesIssueIdWatchings(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdIssuesIssueIdWatchings401Schema
        | PostApiProjectsProjectIdIssuesIssueIdWatchings403Schema
        | PostApiProjectsProjectIdIssuesIssueIdWatchings404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'];
        issueId: PostApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdIssuesIssueIdWatchingsMutationKey();

  return useMutation<
    PostApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdIssuesIssueIdWatchings401Schema
      | PostApiProjectsProjectIdIssuesIssueIdWatchings403Schema
      | PostApiProjectsProjectIdIssuesIssueIdWatchings404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'];
      issueId: PostApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return postApiProjectsProjectIdIssuesIssueIdWatchings(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationKey = () =>
  [{ url: '/api/projects/{project_id}/issues/{issue_id}/watchings' }] as const;

export type DeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationKey
>;

/**
 * @summary Unwatches an issue
 * {@link /api/projects/:project_id/issues/:issue_id/watchings}
 */
export function useDeleteApiProjectsProjectIdIssuesIssueIdWatchings(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdIssuesIssueIdWatchings401Schema
        | DeleteApiProjectsProjectIdIssuesIssueIdWatchings404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'];
        issueId: DeleteApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdIssuesIssueIdWatchingsMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdIssuesIssueIdWatchings401Schema
      | DeleteApiProjectsProjectIdIssuesIssueIdWatchings404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['project_id'];
      issueId: DeleteApiProjectsProjectIdIssuesIssueIdWatchingsPathParamsSchema['issue_id'];
    }
  >({
    mutationFn: async ({ projectId, issueId }) => {
      return deleteApiProjectsProjectIdIssuesIssueIdWatchings(projectId, issueId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities',
      params: { projectId: projectId, weeklyWorkPlanId: weeklyWorkPlanId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryKey(
    projectId,
    weeklyWorkPlanId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && weeklyWorkPlanId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities(
        projectId,
        weeklyWorkPlanId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a weekly work plan activities
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryKey(projectId, weeklyWorkPlanId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryOptions(
      projectId,
      weeklyWorkPlanId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities',
      params: { projectId: projectId, weeklyWorkPlanId: weeklyWorkPlanId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSuspenseQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSuspenseQueryKey(
    projectId,
    weeklyWorkPlanId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && weeklyWorkPlanId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities(
        projectId,
        weeklyWorkPlanId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a weekly work plan activities
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSuspense<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSuspenseQueryKey(
      projectId,
      weeklyWorkPlanId,
      params
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSuspenseQueryOptions(
      projectId,
      weeklyWorkPlanId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/activities' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationKey
>;

/**
 * @summary Creates a weekly work plan activity
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities400Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'];
        data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities400Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities404Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesPathParamsSchema['weekly_work_plan_id'];
      data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, data }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivities(
        projectId,
        weeklyWorkPlanId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/activities/batch' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationKey
>;

/**
 * @summary Creates a batch of weekly work plan activities
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/batch}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchPathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchPathParamsSchema['weekly_work_plan_id'];
        data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchPathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchPathParamsSchema['weekly_work_plan_id'];
      data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatchMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, data }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesBatch(
        projectId,
        weeklyWorkPlanId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'],
  activityId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id',
      params: { projectId: projectId, weeklyWorkPlanId: weeklyWorkPlanId, activityId: activityId },
    },
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'],
  activityId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryKey(
    projectId,
    weeklyWorkPlanId,
    activityId
  );
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && weeklyWorkPlanId && activityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(
        projectId,
        weeklyWorkPlanId,
        activityId,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a weekly work plan activity
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'],
  activityId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryKey(
      projectId,
      weeklyWorkPlanId,
      activityId
    );

  const query = useQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryOptions(
      projectId,
      weeklyWorkPlanId,
      activityId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'],
  activityId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id']
) =>
  [
    {
      url: '/api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id',
      params: { projectId: projectId, weeklyWorkPlanId: weeklyWorkPlanId, activityId: activityId },
    },
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'],
  activityId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSuspenseQueryKey(
    projectId,
    weeklyWorkPlanId,
    activityId
  );
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && weeklyWorkPlanId && activityId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(
        projectId,
        weeklyWorkPlanId,
        activityId,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a weekly work plan activity
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSuspense<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
  TQueryKey extends
    QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'],
  activityId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSuspenseQueryKey(
      projectId,
      weeklyWorkPlanId,
      activityId
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdSuspenseQueryOptions(
      projectId,
      weeklyWorkPlanId,
      activityId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/activities/{activity_id}' }] as const;

export type PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationKey
>;

/**
 * @summary Updates a weekly work plan activity
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id}
 */
export function usePatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
        | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
        | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
        | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'];
        weeklyWorkPlanId: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'];
        activityId: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'];
        data?: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationRequestSchema>
    > & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
      | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
      | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
      | PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'];
      weeklyWorkPlanId: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'];
      activityId: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'];
      data?: PatchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, activityId, data }) => {
      return patchApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(
        projectId,
        weeklyWorkPlanId,
        activityId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/activities/{activity_id}' }] as const;

export type DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationKey = ReturnType<
  typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationKey
>;

/**
 * @summary Deletes a weekly work plan activity
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id}
 */
export function useDeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
        | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
        | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'];
        weeklyWorkPlanId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'];
        activityId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId401Schema
      | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId403Schema
      | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['project_id'];
      weeklyWorkPlanId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['weekly_work_plan_id'];
      activityId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdPathParamsSchema['activity_id'];
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, activityId }) => {
      return deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityId(
        projectId,
        weeklyWorkPlanId,
        activityId,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/activities/sort' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationKey
>;

/**
 * @summary Sorts weekly work plan activities
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/sort}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort400Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortPathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortPathParamsSchema['weekly_work_plan_id'];
        data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort400Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortPathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortPathParamsSchema['weekly_work_plan_id'];
      data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSortMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, data }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesSort(
        projectId,
        weeklyWorkPlanId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationKey = () =>
  [
    {
      url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/activities/{activity_id}/scheduled_days',
    },
  ] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationKey =
  ReturnType<
    typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationKey
  >;

/**
 * @summary Creates a weekly work plan activity scheduled day
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id/scheduled_days}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays404Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema['weekly_work_plan_id'];
        activityId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema['activity_id'];
        data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationRequestSchema>
    > & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays404Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema['weekly_work_plan_id'];
      activityId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysPathParamsSchema['activity_id'];
      data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, activityId, data }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDays(
        projectId,
        weeklyWorkPlanId,
        activityId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationKey =
  () =>
    [
      {
        url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/activities/{activity_id}/scheduled_days/{date}',
      },
    ] as const;

export type DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationKey =
  ReturnType<
    typeof deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationKey
  >;

/**
 * @summary Deletes a weekly work plan activity scheduled day
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/activities/:activity_id/scheduled_days/:date}
 */
export function useDeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate(
  options: {
    mutation?: UseMutationOptions<
      DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationResponseSchema,
      ResponseErrorConfig<
        | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate401Schema
        | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate403Schema
        | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate404Schema
      >,
      {
        projectId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['project_id'];
        weeklyWorkPlanId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['weekly_work_plan_id'];
        activityId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['activity_id'];
        date: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['date'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ??
    deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationKey();

  return useMutation<
    DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDateMutationResponseSchema,
    ResponseErrorConfig<
      | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate401Schema
      | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate403Schema
      | DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate404Schema
    >,
    {
      projectId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['project_id'];
      weeklyWorkPlanId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['weekly_work_plan_id'];
      activityId: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['activity_id'];
      date: DeleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDatePathParamsSchema['date'];
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, activityId, date }) => {
      return deleteApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdActivitiesActivityIdScheduledDaysDate(
        projectId,
        weeklyWorkPlanId,
        activityId,
        date,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/progress_logs',
      params: { projectId: projectId, weeklyWorkPlanId: weeklyWorkPlanId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryKey(
    projectId,
    weeklyWorkPlanId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && weeklyWorkPlanId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs(
        projectId,
        weeklyWorkPlanId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a weekly work plan progress logs
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/progress_logs}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryKey(projectId, weeklyWorkPlanId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryOptions(
      projectId,
      weeklyWorkPlanId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryParamsSchema
) =>
  [
    {
      url: '/api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/progress_logs',
      params: { projectId: projectId, weeklyWorkPlanId: weeklyWorkPlanId },
    },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSuspenseQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSuspenseQueryKey(
    projectId,
    weeklyWorkPlanId,
    params
  );
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && weeklyWorkPlanId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs(
        projectId,
        weeklyWorkPlanId,
        params,
        config
      );
    },
  });
}

/**
 * @summary Retrieves a weekly work plan progress logs
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/progress_logs}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSuspense<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsPathParamsSchema['weekly_work_plan_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSuspenseQueryKey(
      projectId,
      weeklyWorkPlanId,
      params
    );

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogsSuspenseQueryOptions(
      projectId,
      weeklyWorkPlanId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdProgressLogs404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id']
) =>
  [
    { url: '/api/projects/:project_id/weekly_work_plans/shift_activities_finder', params: { projectId: projectId } },
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder(projectId, config);
    },
  });
}

/**
 * @summary Retrieves shift activities finder options for weekly work plans
 * {@link /api/projects/:project_id/weekly_work_plans/shift_activities_finder}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryKey(projectId);

  const query = useQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryOptions(
      projectId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id']
) =>
  [
    { url: '/api/projects/:project_id/weekly_work_plans/shift_activities_finder', params: { projectId: projectId } },
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSuspenseQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSuspenseQueryKey(projectId);
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder(projectId, config);
    },
  });
}

/**
 * @summary Retrieves shift activities finder options for weekly work plans
 * {@link /api/projects/:project_id/weekly_work_plans/shift_activities_finder}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSuspense<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSuspenseQueryKey(projectId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderSuspenseQueryOptions(
      projectId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const patchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/shift_activities_finder' }] as const;

export type PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationKey = ReturnType<
  typeof patchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationKey
>;

/**
 * @summary Updates shift activities finder options for weekly work plans
 * {@link /api/projects/:project_id/weekly_work_plans/shift_activities_finder}
 */
export function usePatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder(
  options: {
    mutation?: UseMutationOptions<
      PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationResponseSchema,
      ResponseErrorConfig<
        | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
        | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
        | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema
        | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder422Schema
      >,
      {
        projectId: PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id'];
        data: PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? patchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationKey();

  return useMutation<
    PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationResponseSchema,
    ResponseErrorConfig<
      | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder401Schema
      | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder403Schema
      | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder404Schema
      | PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder422Schema
    >,
    {
      projectId: PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderPathParamsSchema['project_id'];
      data: PatchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinderMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return patchApiProjectsProjectIdWeeklyWorkPlansShiftActivitiesFinder(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdWeeklyWorkPlansQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/weekly_work_plans', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlans400Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlans(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves all team weekly work plans
 * {@link /api/projects/:project_id/weekly_work_plans}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlans<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansQueryParamsSchema,
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlans400Schema
          | GetApiProjectsProjectIdWeeklyWorkPlans401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlans403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlans404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdWeeklyWorkPlansQueryKey(projectId, params);

  const query = useQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansQueryOptions(
      projectId,
      params,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlans400Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdWeeklyWorkPlansSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansQueryParamsSchema
) =>
  [
    { url: '/api/projects/:project_id/weekly_work_plans', params: { projectId: projectId } },
    ...(params ? [params] : []),
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansSuspenseQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansQueryParamsSchema,
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansSuspenseQueryKey(projectId, params);
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlans400Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!projectId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlans(projectId, params, config);
    },
  });
}

/**
 * @summary Retrieves all team weekly work plans
 * {@link /api/projects/:project_id/weekly_work_plans}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansSuspense<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'],
  params?: GetApiProjectsProjectIdWeeklyWorkPlansQueryParamsSchema,
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlans400Schema
          | GetApiProjectsProjectIdWeeklyWorkPlans401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlans403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlans404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getApiProjectsProjectIdWeeklyWorkPlansSuspenseQueryKey(projectId, params);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansSuspenseQueryOptions(
      projectId,
      params,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlans400Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlans404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const postApiProjectsProjectIdWeeklyWorkPlansMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansMutationKey
>;

/**
 * @summary Creates a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlans(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlans400Schema
        | PostApiProjectsProjectIdWeeklyWorkPlans401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlans403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlans404Schema
        | PostApiProjectsProjectIdWeeklyWorkPlans422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'];
        data: PostApiProjectsProjectIdWeeklyWorkPlansMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey = mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlans400Schema
      | PostApiProjectsProjectIdWeeklyWorkPlans401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlans403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlans404Schema
      | PostApiProjectsProjectIdWeeklyWorkPlans422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansPathParamsSchema['project_id'];
      data: PostApiProjectsProjectIdWeeklyWorkPlansMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, data }) => {
      return postApiProjectsProjectIdWeeklyWorkPlans(projectId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id']
) =>
  [
    {
      url: '/api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id',
      params: { projectId: projectId, weeklyWorkPlanId: weeklyWorkPlanId },
    },
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryKey(projectId, weeklyWorkPlanId);
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && weeklyWorkPlanId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId(projectId, weeklyWorkPlanId, config);
    },
  });
}

/**
 * @summary Retrieves a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema
        >,
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryKey(projectId, weeklyWorkPlanId);

  const query = useQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryOptions(
      projectId,
      weeklyWorkPlanId,
      config
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSuspenseQueryKey = (
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id']
) =>
  [
    {
      url: '/api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id',
      params: { projectId: projectId, weeklyWorkPlanId: weeklyWorkPlanId },
    },
  ] as const;

export type GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSuspenseQueryKey = ReturnType<
  typeof getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSuspenseQueryKey
>;

export function getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSuspenseQueryOptions(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id'],
  config: Partial<RequestConfig> & { client?: typeof client } = {}
) {
  const queryKey = getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSuspenseQueryKey(projectId, weeklyWorkPlanId);
  return queryOptions<
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema
    >,
    GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
    typeof queryKey
  >({
    enabled: !!(projectId && weeklyWorkPlanId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal;
      return getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId(projectId, weeklyWorkPlanId, config);
    },
  });
}

/**
 * @summary Retrieves a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id}
 */
export function useGetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSuspense<
  TData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
  TQueryData = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
  TQueryKey extends QueryKey = GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSuspenseQueryKey,
>(
  projectId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'],
  weeklyWorkPlanId: GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdQueryResponseSchema,
        ResponseErrorConfig<
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
          | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema
        >,
        TData,
        TQueryKey
      >
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { query: queryOptions, client: config = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSuspenseQueryKey(projectId, weeklyWorkPlanId);

  const query = useSuspenseQuery({
    ...(getApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdSuspenseQueryOptions(
      projectId,
      weeklyWorkPlanId,
      config
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    ResponseErrorConfig<
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
      | GetApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema
    >
  > & { queryKey: TQueryKey };

  query.queryKey = queryKey as TQueryKey;

  return query;
}

export const putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}' }] as const;

export type PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationKey = ReturnType<
  typeof putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationKey
>;

/**
 * @summary Updates a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id}
 */
export function usePutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId(
  options: {
    mutation?: UseMutationOptions<
      PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationResponseSchema,
      ResponseErrorConfig<
        | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId400Schema
        | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
        | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
        | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema
        | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId422Schema
      >,
      {
        projectId: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'];
        weeklyWorkPlanId: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id'];
        data?: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationRequestSchema;
      }
    >;
    client?: Partial<RequestConfig<PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationRequestSchema>> & {
      client?: typeof client;
    };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationKey();

  return useMutation<
    PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationResponseSchema,
    ResponseErrorConfig<
      | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId400Schema
      | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId401Schema
      | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId403Schema
      | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId404Schema
      | PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId422Schema
    >,
    {
      projectId: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['project_id'];
      weeklyWorkPlanId: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPathParamsSchema['weekly_work_plan_id'];
      data?: PutApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, data }) => {
      return putApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanId(projectId, weeklyWorkPlanId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/close' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationKey
>;

/**
 * @summary Closes a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/close}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose404Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClosePathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClosePathParamsSchema['weekly_work_plan_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdCloseMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose404Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClosePathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClosePathParamsSchema['weekly_work_plan_id'];
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdClose(projectId, weeklyWorkPlanId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/duplicate' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationKey
>;

/**
 * @summary Duplicates the weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/duplicate}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate400Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate404Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicatePathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicatePathParamsSchema['weekly_work_plan_id'];
        data?: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate400Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate404Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicatePathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicatePathParamsSchema['weekly_work_plan_id'];
      data?: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicateMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, data }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdDuplicate(
        projectId,
        weeklyWorkPlanId,
        data,
        config
      );
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/prefill' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationKey
>;

/**
 * @summary Prefills the weekly work plan (and deletes existing activities)
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/prefill}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill400Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill404Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillPathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillPathParamsSchema['weekly_work_plan_id'];
        data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationRequestSchema;
      }
    >;
    client?: Partial<
      RequestConfig<PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationRequestSchema>
    > & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill400Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill404Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillPathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillPathParamsSchema['weekly_work_plan_id'];
      data: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefillMutationRequestSchema;
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId, data }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPrefill(projectId, weeklyWorkPlanId, data, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/export' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationKey
>;

/**
 * @summary Requests a weekly work plan export
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/export}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportPathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportPathParamsSchema['weekly_work_plan_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportPathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExportPathParamsSchema['weekly_work_plan_id'];
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdExport(projectId, weeklyWorkPlanId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/lookback/export' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationKey
>;

/**
 * @summary Requests a weekly work plan lookback export
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/lookback/export}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport404Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportPathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportPathParamsSchema['weekly_work_plan_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport404Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportPathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExportPathParamsSchema['weekly_work_plan_id'];
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdLookbackExport(projectId, weeklyWorkPlanId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/publish' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationKey
>;

/**
 * @summary Publishes a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/publish}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish404Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishPathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishPathParamsSchema['weekly_work_plan_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish404Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishPathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublishPathParamsSchema['weekly_work_plan_id'];
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdPublish(projectId, weeklyWorkPlanId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/archive' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveMutationKey
>;

/**
 * @summary Archives a weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/archive}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive404Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchivePathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchivePathParamsSchema['weekly_work_plan_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchiveMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive404Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchivePathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchivePathParamsSchema['weekly_work_plan_id'];
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdArchive(projectId, weeklyWorkPlanId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}

export const postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreMutationKey = () =>
  [{ url: '/api/projects/{project_id}/weekly_work_plans/{weekly_work_plan_id}/restore' }] as const;

export type PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreMutationKey = ReturnType<
  typeof postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreMutationKey
>;

/**
 * @summary Restores an archived weekly work plan
 * {@link /api/projects/:project_id/weekly_work_plans/:weekly_work_plan_id/restore}
 */
export function usePostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore(
  options: {
    mutation?: UseMutationOptions<
      PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreMutationResponseSchema,
      ResponseErrorConfig<
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore401Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore403Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore404Schema
        | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore422Schema
      >,
      {
        projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestorePathParamsSchema['project_id'];
        weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestorePathParamsSchema['weekly_work_plan_id'];
      }
    >;
    client?: Partial<RequestConfig> & { client?: typeof client };
  } = {}
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {};
  const mutationKey =
    mutationOptions?.mutationKey ?? postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreMutationKey();

  return useMutation<
    PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestoreMutationResponseSchema,
    ResponseErrorConfig<
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore401Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore403Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore404Schema
      | PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore422Schema
    >,
    {
      projectId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestorePathParamsSchema['project_id'];
      weeklyWorkPlanId: PostApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestorePathParamsSchema['weekly_work_plan_id'];
    }
  >({
    mutationFn: async ({ projectId, weeklyWorkPlanId }) => {
      return postApiProjectsProjectIdWeeklyWorkPlansWeeklyWorkPlanIdRestore(projectId, weeklyWorkPlanId, config);
    },
    mutationKey,
    ...mutationOptions,
  });
}
