import { useMessageGetter } from '@messageformat/react';
import type { ProjectSchema } from '@shape-construction/api/src/types';
import {
  ActivitiesIcon,
  AdjustmentsHorizontalIcon,
  ChartBarSquareIcon,
  LockOpenIcon,
  MapPinIcon,
  NewspaperIcon,
  ShapeIssueTrackerIcon,
  ShapeProjectGalleryIcon,
  ShapeShiftReportIcon,
  ShapeWeeklyPlannerIcon,
  SquaresPlusIcon,
  TableEditIcon,
  UsersIcon,
  WrenchIcon,
} from '@shape-construction/arch-ui/src/Icons/outline';
import { ComputerDesktopIcon, ShapeChannelsIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import type { SidebarIconColor, SidebarIconVariant } from '@shape-construction/arch-ui/src/Sidebar/sidebar-config';
import { useFeatureFlag } from '@shape-construction/feature-flags';
import { useProjectChannelsCount } from 'app/channels/queries/useProjectChannels';

export type SidebarItemType = {
  key: string;
  title: string;
  image: React.ElementType;
  route: string;
  isActiveOn?: string;
  notifications?: number;
  color?: SidebarIconColor;
  variant?: SidebarIconVariant;
  disabled?: boolean;
  hidden?: boolean;
  hideTooltip?: boolean;
};

export const useHighlightItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const labels = useMessageGetter('navigation');
  const isDisabled = !currentProject || currentProject?.currentTeamMemberStatus !== 'joined';

  const notificationsCount = useProjectChannelsCount(currentProject?.id);
  const { value: isControlCenterEnabled } = useFeatureFlag('control-center');

  const appsMenu: SidebarItemType[] = [
    {
      key: 'issues',
      title: labels('issues'),
      image: ShapeIssueTrackerIcon,
      route: `/projects/${currentProject?.id}/issues`,
      isActiveOn: '/projects/:projectId/issues/*',
      color: 'primary',
      variant: 'contained',
      disabled: isDisabled,
    },
    {
      key: 'weekly_planner',
      title: labels('weeklyPlanner'),
      image: ShapeWeeklyPlannerIcon,
      route: `/projects/${currentProject?.id}/weekly-planner/plans`,
      isActiveOn: '/projects/:projectId/weekly-planner/*',
      color: 'success',
      variant: 'contained',
      disabled: isDisabled,
    },
    {
      key: 'shift_reports',
      title: labels('shiftReports'),
      image: ShapeShiftReportIcon,
      route: `/projects/${currentProject?.id}/shift-reports/drafts`,
      isActiveOn: '/projects/:projectId/shift-reports/*',
      color: 'pink',
      variant: 'contained',
      disabled: isDisabled,
    },
    {
      key: 'gallery',
      title: labels('gallery'),
      image: ShapeProjectGalleryIcon,
      route: `/projects/${currentProject?.id}/gallery`,
      isActiveOn: '/projects/:projectId/gallery/*',
      color: 'warning',
      variant: 'contained',
      disabled: isDisabled,
    },
    {
      key: 'channels',
      title: labels('channels'),
      image: ShapeChannelsIcon,
      route: `/projects/${currentProject?.id}/channels`,
      isActiveOn: '/projects/:projectId/channels/*',
      color: 'sky',
      variant: 'contained',
      disabled: isDisabled,
      notifications: notificationsCount,
    },
  ];

  if (isControlCenterEnabled) {
    appsMenu.push({
      key: 'control-center',
      title: labels('controlCenter'),
      image: ComputerDesktopIcon,
      route: `/projects/${currentProject?.id}/control-center`,
      isActiveOn: '/projects/:projectId/control-center/*',
      color: 'primary',
      variant: 'contained',
      disabled: isDisabled,
      notifications: notificationsCount,
    });
  }

  return appsMenu;
};

export const useOverviewItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const labels = useMessageGetter('navigation');
  const { value: isDataHealthDashboardEnabled } = useFeatureFlag('data-health-dashboard');
  const isDisabled = !currentProject || currentProject?.currentTeamMemberStatus !== 'joined';

  return [
    {
      key: 'timeline',
      title: labels('timeline'),
      image: NewspaperIcon,
      route: `/projects/${currentProject?.id}/timeline`,
      isActiveOn: '/projects/:projectId/timeline/*',
      disabled: isDisabled,
    },
    {
      key: 'activities',
      title: labels('activities'),
      image: ActivitiesIcon,
      route: `/projects/${currentProject?.id}/activities`,
      isActiveOn: '/projects/:projectId/activities/*',
      disabled: isDisabled,
    },
    {
      key: 'dashboard',
      title: labels('dashboard'),
      image: ChartBarSquareIcon,
      route: `/projects/${currentProject?.id}/dashboard`,
      isActiveOn: '/projects/:projectId/dashboard/*',
      disabled: isDisabled,
      hidden: isDataHealthDashboardEnabled,
    },
    {
      key: 'dashboards',
      title: labels('dashboard'),
      image: ChartBarSquareIcon,
      route: `/projects/${currentProject?.id}/dashboards`,
      isActiveOn: '/projects/:projectId/dashboards/*',
      disabled: isDisabled,
      hidden: !isDataHealthDashboardEnabled,
    },
  ].filter((s) => !s.hidden);
};

export const useAdminItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const items = [];
  const labels = useMessageGetter('admin.menu');

  if (currentProject?.availableActions?.manageLocations) {
    items.push({
      key: 'locations',
      title: labels('locations'),
      image: MapPinIcon,
      route: `/projects/${currentProject.id}/settings/locations`,
      isActiveOn: '/projects/:projectId/settings/locations/*',
      hideTooltip: true,
    });
  }

  if (currentProject?.availableActions?.manageDisciplines) {
    items.push({
      key: 'disciplines',
      title: labels('disciplines'),
      image: WrenchIcon,
      route: `/projects/${currentProject.id}/settings/disciplines`,
      isActiveOn: '/projects/:projectId/settings/disciplines/*',
      hideTooltip: true,
    });
  }

  items.push(
    {
      key: 'teams',
      title: labels('teams'),
      image: UsersIcon,
      route: `/projects/${currentProject?.id}/settings/teams`,
      isActiveOn: '/projects/:projectId/settings/teams/*',
      hideTooltip: true,
    },
    {
      key: 'access_requests',
      title: labels('accessRequests'),
      image: LockOpenIcon,
      route: `/projects/${currentProject?.id}/settings/access-requests`,
      isActiveOn: '/projects/:projectId/settings/access-requests/*',
      hideTooltip: true,
    },
    {
      key: 'custom_fields',
      title: labels('customFields'),
      image: TableEditIcon,
      route: `/projects/${currentProject?.id}/settings/custom-fields`,
      isActiveOn: '/projects/:projectId/settings/custom-fields/*',
      hideTooltip: true,
    },
    {
      key: 'resources',
      title: labels('resources'),
      image: SquaresPlusIcon,
      route: `/projects/${currentProject?.id}/settings/resources`,
      isActiveOn: '/projects/:projectId/settings/resources/*',
      hideTooltip: true,
    },
    {
      key: 'project',
      title: labels('projectSettings'),
      image: AdjustmentsHorizontalIcon,
      route: `/projects/${currentProject?.id}/settings/project`,
      isActiveOn: '/projects/:projectId/settings/project/*',
      hideTooltip: true,
    }
  );

  return items;
};

export const useSidebarItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const highlightItems = useHighlightItems(currentProject);
  const overviewItems = useOverviewItems(currentProject);
  const adminItems = useAdminItems(currentProject);

  return [...highlightItems, ...overviewItems, ...adminItems];
};
