import type { GetApiProjectsProjectIdIssuesQueryParamsSchema } from '@shape-construction/api/src/types';
import type { TabOption } from './issue-tab-options';

export type SortingOption = {
  sort_by: NonNullable<Exclude<GetApiProjectsProjectIdIssuesQueryParamsSchema['sort_by'], 'resolved'>>;
  sort_order: NonNullable<GetApiProjectsProjectIdIssuesQueryParamsSchema['sort_order']>;
};

export type SortingItems = Partial<Record<SortingOption['sort_by'], SortingOption['sort_order']>>;

export type CurrentSorting = {
  currentSortingOptions: {
    sort_by: SortingOption['sort_by'];
    sort_order: SortingOption['sort_order'];
  };
  setSortBy: (sort: SortingOption['sort_by']) => void;
  setSortOrder: (order: SortingOption['sort_order']) => void;
  reset: (arg: any) => void;
};

export const defaultTabSorting: Record<TabOption, SortingOption> = {
  myIssues: { sort_by: 'updated', sort_order: 'desc' },
  drafts: { sort_by: 'created', sort_order: 'desc' },
  all: { sort_by: 'created', sort_order: 'desc' },
  archived: { sort_by: 'created', sort_order: 'desc' },
};
