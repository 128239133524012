import { getApiFeatureFlagsQueryOptions } from '@shape-construction/api/src/hooks';
import type { TeamSchema, UserSchema } from '@shape-construction/api/src/types';
import { useQuery } from '@tanstack/react-query';
import React, { type PropsWithChildren, createContext, useContext, useMemo, useEffect } from 'react';
import { useDeviceSessionId } from './useDeviceSessionId';

type FeatureFlagContext = {
  deviceId: string;
  userId?: UserSchema['id'];
  teamId?: TeamSchema['id'];
};

const featureFlagsContext = createContext<FeatureFlagContext | undefined>(undefined);

export const useFeatureFlagContext = () => {
  const ctx = useContext(featureFlagsContext);
  if (!ctx) throw new Error('Cannot use feature flags outside FeatureFlagEntityProvider');
  return ctx;
};

type FeatureFlagContextProps = {
  deviceId?: string;
  userId?: UserSchema['id'];
  teamId?: TeamSchema['id'];
};

export const FeatureFlagEntityProvider: React.FC<PropsWithChildren<FeatureFlagContextProps>> = ({
  children,
  userId,
  teamId,
  deviceId: customDeviceId,
}) => {
  const defaultDeviceId = useDeviceSessionId();
  const deviceId = customDeviceId ?? defaultDeviceId;
  const ctxValue = useMemo(() => ({ userId, teamId, deviceId }), [userId, teamId, deviceId]);

  useWarmUpFeatureFlagsCache(ctxValue);

  return <featureFlagsContext.Provider value={ctxValue}>{children}</featureFlagsContext.Provider>;
};

const useWarmUpFeatureFlagsCache = ({ deviceId, userId }: FeatureFlagContext) => {
  const { refetch } = useQuery(getApiFeatureFlagsQueryOptions({ device_session_id: deviceId }));

  useEffect(() => {
    if (userId) refetch();
  }, [userId]);
};
