/**
 * Generated by kubb.
 * Do not edit manually.
 * Shape API
 */

export const shiftReportVisibilityEnum = {
  private: 'private',
  public: 'public',
  specific_teams: 'specific_teams',
} as const;

export type ShiftReportVisibilityEnumSchema =
  (typeof shiftReportVisibilityEnum)[keyof typeof shiftReportVisibilityEnum];

export type ShiftReportVisibilitySchema = ShiftReportVisibilityEnumSchema;
