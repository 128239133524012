import { useMessageGetter } from '@messageformat/react';
import {
  getApiUsersMeQueryKey,
  useDeleteApiLogout,
  useGetApiUsersMe,
  usePatchApiUsersMe,
  usePatchApiUsersPassword,
  usePostApiAuthentication,
  usePostApiLogin,
  usePostApiUsers,
  usePostApiUsersConfirmation,
  usePostApiUsersConfirmationInstructions,
  usePostApiUsersPasswordInstructions,
} from '@shape-construction/api/src/hooks';
import type { UserSchema } from '@shape-construction/api/src/types';
import { showErrorToast, showSuccessToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import { useQueryClient } from '@tanstack/react-query';
import { endSession } from 'app/session';
import { useNavigate } from 'react-router';

// queries
export const useUsersMe = () =>
  useGetApiUsersMe({
    query: {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  });

export const useCurrentUser = () => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData(getApiUsersMeQueryKey()) as UserSchema;
};

// mutations
export const useConfirmEmail = () => {
  const queryClient = useQueryClient();
  const messages = useMessageGetter('auth.confirmEmail.toast');

  return usePostApiUsersConfirmation({
    mutation: {
      onSuccess: (user) => {
        showSuccessToast({ message: messages('success') });

        queryClient.setQueryData(getApiUsersMeQueryKey(), user);
      },
    },
  });
};

export const useForgotPassword = usePostApiUsersPasswordInstructions;

export const useLogin = () => {
  const queryClient = useQueryClient();

  return usePostApiLogin({
    mutation: {
      onSuccess: (user) => {
        queryClient.setQueryData(getApiUsersMeQueryKey(), user);
      },
    },
  });
};

export const useLogout: typeof useDeleteApiLogout = (options) => {
  const navigate = useNavigate();

  return useDeleteApiLogout({
    ...options,
    mutation: {
      ...options?.mutation,
      onSettled: async () => {
        await endSession();
        navigate('/', { state: { redirect: null } });
      },
    },
  });
};

export const useResetPassword = usePatchApiUsersPassword;

export const useResendConfirmationEmail = () => {
  const messages = useMessageGetter('auth.verificationSent');

  return usePostApiUsersConfirmationInstructions({
    mutation: {
      onSuccess: () => {
        showSuccessToast({ message: messages('success') });
      },
      onError: () => {
        showErrorToast({ message: messages('failure') });
      },
    },
  });
};

export const useSignUpUser = () => {
  const queryClient = useQueryClient();

  return usePostApiUsers({
    mutation: {
      onSuccess: (user) => {
        if (user.emailConfirmed) queryClient.setQueryData(getApiUsersMeQueryKey(), user);
      },
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return usePatchApiUsersMe({
    mutation: {
      onSuccess: (user) => {
        queryClient.setQueryData(getApiUsersMeQueryKey(), user);
      },
    },
  });
};

export const useVerifyEmail = usePostApiAuthentication;
