import * as React from 'react';
import type { SVGProps } from 'react';
const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/clock">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.5999C17.302 21.5999 21.6 17.3018 21.6 11.9999C21.6 6.69797 17.302 2.3999 12 2.3999C6.69809 2.3999 2.40002 6.69797 2.40002 11.9999C2.40002 17.3018 6.69809 21.5999 12 21.5999ZM13.125 5.9999C13.125 5.37858 12.6213 4.8749 12 4.8749C11.3787 4.8749 10.875 5.37858 10.875 5.9999V11.9999C10.875 12.6212 11.3787 13.1249 12 13.1249H16.8C17.4213 13.1249 17.925 12.6212 17.925 11.9999C17.925 11.3786 17.4213 10.8749 16.8 10.8749H13.125V5.9999Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ClockIcon;
