import { useMessageGetter } from '@messageformat/react';
import { Button } from '@shape-construction/arch-ui';
import { PrinterIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import ModalBase from '@shape-construction/arch-ui/src/ModalBase';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { environment } from 'app/config/environment';
import React, { useCallback, useRef } from 'react';

const QRScanDetails = ({
  open,
  onClose,
}: React.ComponentPropsWithoutRef<typeof ModalBase.Root> & {
  open: boolean;
  onClose: () => void;
}) => {
  const messages = useMessageGetter('appSidebar.getShapeAndChannelsOnMobile');
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const isLargeScreen = useMediaQuery(breakpoints.up('sm'));
  const handlePrint = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.focus();
      iframeRef.current.contentWindow?.print();
    }
  }, []);
  return (
    <ModalBase.Root
      open={open}
      roundBorders
      onClose={onClose}
      className="max-w-[767px] sm:max-w-[708px] lg:max-w-[800px]"
    >
      <ModalBase.Header onClose={onClose}>
        <ModalBase.Title>
          <h1 className="text-lg leading-7 font-semibold">{messages('modal.title')}</h1>
        </ModalBase.Title>
      </ModalBase.Header>
      <ModalBase.Content className="px-0 border-t border-b">
        <iframe src="/documents/qr-codes-a4.pdf" title="qr-details" className="hidden" ref={iframeRef} />
        <div className="flex flex-col gap-6 pt-2">
          <div className="flex flex-col sm:flex-row p-4 justify-start items-center sm:items-start gap-4">
            <div className="flex shrink-0 w-[150px] h-[150px]">
              <img src="/images/qr-codes/shape.svg" alt="Shape App Logo" width={150} />
            </div>
            <div>
              <div className="flex flex-col gap-3 px-4">
                <img src="/images/logos/shape.svg" alt="Shape App Logo" width={140} />
                <h1 className="font-medium">{messages('modal.shapeApp.title')}</h1>
                <ul className="list-disc text-sm pl-5 space-y-2 text-neutral-subtle">
                  <li>
                    <span>
                      {messages('modal.shapeApp.step1a')}{' '}
                      <a href="https://app.shape.construction" target="_blank" rel="noreferrer">
                        https://app.shape.construction
                      </a>{' '}
                      {messages('modal.shapeApp.step1b')}
                    </span>
                  </li>
                  <li>{messages('modal.shapeApp.step2')}</li>
                  <li>{messages('modal.shapeApp.step3')}</li>
                  <li>{messages('modal.shapeApp.step4')}</li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="flex flex-col sm:flex-row p-4 justify-start items-center sm:items-start gap-4">
            <div className="flex shrink-0 w-[150px] h-[150px]">
              <img src="/images/qr-codes/channels.svg" alt="Channels App Logo" width={150} />
            </div>
            <div>
              <div className="flex flex-col gap-3 px-4">
                <img src="/images/logos/channels.svg" alt="Shape App Logo" width={140} />
                <h1 className="font-medium">{messages('modal.channelsApp.title')}</h1>
                <p className="text-sm space-y-2 text-neutral-subtle">{messages('modal.channelsApp.step1')}</p>
                <div className="gap-2 flex">
                  <a
                    data-testid="play-store-link"
                    href={environment.CHANNELS_PLAY_STORE_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/store/googleplay.png" alt="Download from play store" width={120} />
                  </a>
                  <a
                    data-testid="app-store-link"
                    href={environment.CHANNELS_APP_STORE_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/store/appstore.png" alt="Download from app store" width={120} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBase.Content>
      <ModalBase.Footer>
        {isLargeScreen && (
          <Button onClick={handlePrint} size={'md'} color={'primary'} variant={'contained'}>
            <PrinterIcon className="h-4 w-4" />
            {messages('modal.print')}
          </Button>
        )}
      </ModalBase.Footer>
    </ModalBase.Root>
  );
};

export default QRScanDetails;
