import React, { type PropsWithChildren } from 'react';
import CheckIcon from '../Icons/solid/CheckIcon';
import { cn } from '../utils/classes';

export type CheckListItemProps = PropsWithChildren & {
  variant: 'success' | 'error' | 'pending';
};

export const CheckListItem: React.FC<CheckListItemProps> = ({ variant, children }) => {
  const isSuccess = variant === 'success';
  const isError = variant === 'error';
  const isPending = variant === 'pending';

  return (
    <li className="flex items-center gap-1" data-state={variant} aria-busy={isPending} aria-invalid={isError}>
      {isSuccess ? (
        <div className="size-4 flex items-center justify-center rounded-full bg-emerald-500 relative">
          <CheckIcon className="text-white size-3" />
        </div>
      ) : (
        <div
          className={cn('size-4 rounded-full', {
            'bg-gray-300': isPending,
            'bg-red-400': isError,
          })}
        />
      )}
      <p
        className={cn('text-xs', {
          'text-gray-500': isPending || isSuccess,
          'text-red-700': isError,
        })}
      >
        {children}
      </p>
    </li>
  );
};

CheckListItem.displayName = 'CheckList.Item';
