import { useComposedRefs } from '@radix-ui/react-compose-refs';
import { useDebounceCallback } from '@shape-construction/hooks';
import React, { createContext, forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../Icons/outline';
import VisuallyHidden from '../../VisuallyHidden';
import { cn } from '../../utils/classes';

const ScrollableContext = createContext<{
  overflow: { top: boolean; bottom: boolean };
  scrollToBottom: () => void;
  scrollToTop: () => void;
}>({
  overflow: { top: false, bottom: false },
  scrollToBottom: () => {},
  scrollToTop: () => {},
});
export const SidebarScrollableContentRoot = forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, children, ...props }, forwardedRef) => {
    const ref = useRef<HTMLElement | null>(null);

    const [offsetY, setOffsetY] = useState(0);
    const [scrollYPosition, setScrollYPosition] = useState(0);

    const handleResize = useCallback(() => {
      if (!ref?.current) return;
      setOffsetY(ref.current.scrollHeight - ref.current.clientHeight);
    }, []);
    const debouncedHandleResize = useDebounceCallback(handleResize, 250);

    const handleScroll = useCallback(() => {
      if (!ref?.current) return;
      setOffsetY(ref.current.scrollHeight - ref.current.clientHeight);
      setScrollYPosition(ref.current.scrollTop);
    }, []);

    const scrollToBottom = useCallback(
      () => ref?.current?.scroll({ top: ref?.current?.scrollHeight, behavior: 'smooth' }),
      []
    );
    const scrollToTop = useCallback(() => ref?.current?.scroll({ top: 0, behavior: 'smooth' }), []);

    const setRef = useCallback((node: HTMLElement | null) => {
      if (node !== null) {
        ref.current = node;

        debouncedHandleResize();
        ref?.current?.addEventListener('scroll', handleScroll);
      } else {
        setOffsetY(0);
        setScrollYPosition(0);

        if (ref?.current) {
          ref.current.removeEventListener('scroll', handleScroll);
          ref.current = null;
        }
      }
    }, []);

    const combinedRef = useComposedRefs<HTMLElement | null>(forwardedRef, setRef);

    useEffect(() => {
      window.addEventListener('resize', debouncedHandleResize);

      return () => {
        window.removeEventListener('resize', debouncedHandleResize);
      };
    }, []);

    const context = useMemo(
      () => ({
        overflow: { top: scrollYPosition > 0, bottom: offsetY - scrollYPosition > 1 },
        scrollToBottom,
        scrollToTop,
      }),
      [offsetY, scrollYPosition, scrollToBottom, scrollToTop]
    );

    return (
      <ScrollableContext.Provider value={context}>
        <div
          ref={combinedRef}
          data-sidebar="scrollable-content"
          data-testid="sidebar-scrollable-content"
          className={cn('relative flex flex-col flex-1 overflow-auto', className)}
          {...props}
        >
          {children}
        </div>
      </ScrollableContext.Provider>
    );
  }
);

export const SidebarScrollDownButton = ({ className, ...props }: Omit<React.ComponentProps<'button'>, 'children'>) => {
  const { overflow, scrollToBottom } = useContext(ScrollableContext);

  if (!overflow.bottom) return null;
  return (
    <button {...props} onClick={scrollToBottom} className={cn('sticky bottom-0 z-10', className)} type="button">
      <div className="h-5 border-b border-neutral-subtlest bg-gradient-to-t from-gray-50/90 from-50% to-gray-50/0" />
      <ChevronDownIcon
        data-testid="chevron-down-icon"
        className="size-3 absolute bottom-0 left-1/2 -translate-x-1/2 text-icon-neutral"
      />
      <VisuallyHidden.Root>sidebar overflow bottom</VisuallyHidden.Root>
    </button>
  );
};

export const SidebarScrollUpButton = ({ className, ...props }: Omit<React.ComponentProps<'button'>, 'children'>) => {
  const { overflow, scrollToTop } = useContext(ScrollableContext);

  if (!overflow.top) return null;
  return (
    <button {...props} onClick={scrollToTop} className={cn('sticky top-0 z-10', className)} type="button">
      <div className="h-5 border-t border-neutral-subtlest bg-gradient-to-b from-gray-50/90 from-50% to-gray-50/0" />
      <ChevronUpIcon
        data-testid="chevron-up-icon"
        className="size-3 absolute top-0 left-1/2 -translate-x-1/2 text-icon-neutral"
      />
      <VisuallyHidden.Root>sidebar overflow top</VisuallyHidden.Root>
    </button>
  );
};

export default {
  Root: SidebarScrollableContentRoot,
  ScrollDownButton: SidebarScrollDownButton,
  ScrollUpButton: SidebarScrollUpButton,
};
