import * as React from 'react';
import type { SVGProps } from 'react';
const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/calendar">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90002 2.40002C7.39708 2.40002 7.80002 2.80297 7.80002 3.30002V4.80002H16.2V3.30002C16.2 2.80297 16.603 2.40002 17.1 2.40002C17.5971 2.40002 18 2.80297 18 3.30002V4.80002H18.3C20.1226 4.80002 21.6 6.27749 21.6 8.10003V18.3C21.6 20.1226 20.1226 21.6 18.3 21.6H5.70003C3.87749 21.6 2.40002 20.1226 2.40002 18.3V8.10003C2.40002 6.27749 3.87748 4.80002 5.70002 4.80002H6.00002V3.30002C6.00002 2.80297 6.40297 2.40002 6.90002 2.40002ZM5.70002 9.00002C4.8716 9.00002 4.20002 9.6716 4.20002 10.5V18.3C4.20002 19.1285 4.8716 19.8 5.70003 19.8H18.3C19.1285 19.8 19.8 19.1285 19.8 18.3V10.5C19.8 9.6716 19.1285 9.00002 18.3 9.00002H5.70002Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default CalendarIcon;
