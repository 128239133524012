import * as React from 'react';
import type { SVGProps } from 'react';
const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/plus">
      <path
        id="Icon"
        d="M12.9 5.6998C12.9 5.20275 12.4971 4.7998 12 4.7998C11.503 4.7998 11.1 5.20275 11.1 5.6998V11.0998H5.70005C5.20299 11.0998 4.80005 11.5027 4.80005 11.9998C4.80005 12.4969 5.20299 12.8998 5.70005 12.8998L11.1 12.8998V18.2998C11.1 18.7969 11.503 19.1998 12 19.1998C12.4971 19.1998 12.9 18.7969 12.9 18.2998V12.8998H18.3C18.7971 12.8998 19.2 12.4969 19.2 11.9998C19.2 11.5027 18.7971 11.0998 18.3 11.0998H12.9V5.6998Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PlusIcon;
