import * as React from 'react';
import type { SVGProps } from 'react';
const PlusCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/plus-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.6004C17.302 21.6004 21.6 17.3023 21.6 12.0004C21.6 6.69846 17.302 2.40039 12 2.40039C6.69809 2.40039 2.40002 6.69846 2.40002 12.0004C2.40002 17.3023 6.69809 21.6004 12 21.6004ZM12.9 8.10039C12.9 7.60333 12.4971 7.20039 12 7.20039C11.503 7.20039 11.1 7.60333 11.1 8.10039V11.1004H8.10003C7.60297 11.1004 7.20003 11.5033 7.20003 12.0004C7.20003 12.4974 7.60297 12.9004 8.10003 12.9004H11.1V15.9004C11.1 16.3974 11.503 16.8004 12 16.8004C12.4971 16.8004 12.9 16.3974 12.9 15.9004V12.9004H15.9C16.3971 12.9004 16.8 12.4974 16.8 12.0004C16.8 11.5033 16.3971 11.1004 15.9 11.1004H12.9V8.10039Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PlusCircleIcon;
