import * as Sentry from '@sentry/react';
import { usePostApiPushSubscriptions } from '@shape-construction/api/src/hooks';
import { atom, useAtom } from 'jotai';
import { useCallback, useState } from 'react';
import {
  askUserPermission,
  createNotificationSubscription,
  getUserConsent,
  getUserSubscription,
  isPushNotificationSupported,
} from '../../../lib/push-notifications';

const pushNotificationSupported = isPushNotificationSupported();
const userConsentAtom = atom(getUserConsent());

export function usePushNotifications() {
  const [userConsent, setUserConsent] = useAtom(userConsentAtom);
  const [userSubscription, setUserSubscription] = useState<PushSubscription | null>(null);
  const [pushServerSubscriptionId] = useState();
  const { mutate: savePushNotificationSubscription } = usePostApiPushSubscriptions();
  /**
   * handler that sends the push subscription to the push server.
   */
  const handleSendSubscriptionToPushServer = useCallback(
    // @ts-expect-error TODO: Verify this type
    (userSubscription) => {
      if (userSubscription) savePushNotificationSubscription({ data: userSubscription.toJSON() });
    },
    [userSubscription, savePushNotificationSubscription]
  );

  /**
   * define a handler that creates a push notification subscription.
   * once the subscription is created, it uses the setUserSubscription
   */
  const handleSubscribeToPushNotification = useCallback(() => {
    createNotificationSubscription()
      .then((subscription) => {
        setUserSubscription(subscription);
        handleSendSubscriptionToPushServer(subscription);
      })
      .catch((error) => Sentry.captureException(error));
  }, [handleSendSubscriptionToPushServer]);

  /**
   * handler that creates a push notification subscription.
   */
  const handleUserPermission = useCallback(async () => {
    if (userConsent !== 'granted') {
      const consent = await askUserPermission();
      setUserConsent(consent);
    }
  }, [userConsent, setUserConsent]);

  /**
   * Enable a subscription if it exists. If not, the function initiates
   * a new subscription process after user allowed notifications in browser
   */
  const handleSubscription = useCallback(async () => {
    if (pushNotificationSupported && !userSubscription) {
      getUserSubscription().then((existingSubscription) => {
        if (existingSubscription) {
          // Set a subscription on mount when notifications are on.
          setUserSubscription(existingSubscription);
        } else if (!existingSubscription && userConsent === 'granted') {
          // Handle a subscription function should be called once a user pressed the 'Allow' button.
          handleSubscribeToPushNotification();
        }
      });
    }
  }, [handleSubscribeToPushNotification, userConsent, userSubscription]);

  return {
    handleSubscription,
    onAskPushNotificationPermission: handleUserPermission,
    onSendSubscriptionToPushServer: handleSendSubscriptionToPushServer,
    onSubscribeToPushNotification: handleSubscribeToPushNotification,
    pushNotificationSupported,
    pushServerSubscriptionId,
    userConsent,
    userSubscription,
  };
}
