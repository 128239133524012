import * as React from 'react';
import type { SVGProps } from 'react';
const ExclamationCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/exclamation-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 11.9999C21.6 17.3018 17.302 21.5999 12 21.5999C6.69809 21.5999 2.40002 17.3018 2.40002 11.9999C2.40002 6.69797 6.69809 2.3999 12 2.3999C17.302 2.3999 21.6 6.69797 21.6 11.9999ZM12 5.9999C12.4971 5.9999 12.9 6.40285 12.9 6.8999V12.2999C12.9 12.797 12.4971 13.1999 12 13.1999C11.503 13.1999 11.1 12.797 11.1 12.2999V6.8999C11.1 6.40285 11.503 5.9999 12 5.9999ZM12 17.9999C12.6628 17.9999 13.2 17.4626 13.2 16.7999C13.2 16.1372 12.6628 15.5999 12 15.5999C11.3373 15.5999 10.8 16.1372 10.8 16.7999C10.8 17.4626 11.3373 17.9999 12 17.9999Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ExclamationCircleIcon;
