import * as React from 'react';
import type { SVGProps } from 'react';
const CircleDotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/circle-dot">
      <path
        id="Exclude"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.5999C17.302 21.5999 21.6 17.3018 21.6 11.9999C21.6 6.69797 17.302 2.3999 12 2.3999C6.69809 2.3999 2.40002 6.69797 2.40002 11.9999C2.40002 17.3018 6.69809 21.5999 12 21.5999ZM4.40002 11.9999C4.40002 7.80254 7.80266 4.3999 12 4.3999C16.1974 4.3999 19.6 7.80254 19.6 11.9999C19.6 16.1973 16.1974 19.5999 12 19.5999C7.80266 19.5999 4.40002 16.1973 4.40002 11.9999Z"
        fill="currentColor"
      />
      <path
        id="Icon"
        d="M12 8.3999C10.0118 8.3999 8.40002 10.0117 8.40002 11.9999C8.40002 13.9881 10.0118 15.5999 12 15.5999C13.9883 15.5999 15.6 13.9881 15.6 11.9999C15.6 10.0117 13.9883 8.3999 12 8.3999Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default CircleDotIcon;
