import { useMessageGetter } from '@messageformat/react';
import type { GetApiProjectsProjectIdDocumentsQueryParamsSchema } from '@shape-construction/api/src/types';
import { InputAdornment, Portal, Select, type SelectRootProps } from '@shape-construction/arch-ui';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import React, { Fragment, forwardRef, useMemo } from 'react';

type Item = {
  name: string;
  value: GetApiProjectsProjectIdDocumentsQueryParamsSchema['sort_order'];
};

export type SortOrderSelectProps = SelectRootProps<Item['value'], false>;

export const SortOrderSelect = forwardRef<React.ElementRef<typeof Select.Root>, SortOrderSelectProps>((props, ref) => {
  const messageGetter = useMessageGetter('projectGallery.sort');

  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  const options: Item[] = useMemo(
    () => [
      { name: messageGetter('newest'), value: 'desc' },
      { name: messageGetter('oldest'), value: 'asc' },
    ],
    [messageGetter]
  );
  const selected = useMemo(
    () => options.find((option) => option.value === props.value) || options[0],
    [props.value, options]
  );

  const PanelWrapper = isLargeScreen ? Fragment : Portal;

  return (
    <Select.Root ref={ref} {...props} value={selected.value}>
      <Select.Trigger size="sm" startAdornment={<InputAdornment>{messageGetter('label')}:</InputAdornment>}>
        <Select.Value value={selected.name} />
      </Select.Trigger>
      <PanelWrapper>
        <Select.ResponsivePanel>
          <Select.Options>
            {options.map((option) => (
              <Select.Option key={option.name} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
          </Select.Options>
        </Select.ResponsivePanel>
      </PanelWrapper>
    </Select.Root>
  );
});
SortOrderSelect.displayName = 'SortOrderSelect';
