import * as React from 'react';
import type { SVGProps } from 'react';
const QuestionMarkCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/question-mark-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12.0004C21.6 17.3023 17.302 21.6004 12 21.6004C6.69809 21.6004 2.40002 17.3023 2.40002 12.0004C2.40002 6.69846 6.69809 2.40039 12 2.40039C17.302 2.40039 21.6 6.69846 21.6 12.0004ZM10.7272 8.32757C10.3758 8.67904 9.80591 8.67904 9.45444 8.32757C9.10297 7.9761 9.10297 7.40625 9.45444 7.05478C10.8603 5.64889 13.1397 5.64889 14.5456 7.05478C15.9515 8.46066 15.9515 10.7401 14.5456 12.1459C14.0746 12.6169 13.5034 12.9311 12.9 13.0865V13.5004C12.9 13.9974 12.4971 14.4004 12 14.4004C11.503 14.4004 11.1 13.9974 11.1 13.5004V12.9004C11.1 12.036 11.784 11.4943 12.3976 11.3563C12.7179 11.2842 13.022 11.124 13.2728 10.8732C13.9758 10.1702 13.9758 9.03051 13.2728 8.32757C12.5699 7.62462 11.4302 7.62462 10.7272 8.32757ZM12 18.0004C12.6628 18.0004 13.2 17.4631 13.2 16.8004C13.2 16.1377 12.6628 15.6004 12 15.6004C11.3373 15.6004 10.8 16.1377 10.8 16.8004C10.8 17.4631 11.3373 18.0004 12 18.0004Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default QuestionMarkCircleIcon;
