import { postApiAnalyticalEvents } from '@shape-construction/api/src/api';
import { useMutation } from '@tanstack/react-query';
import { useCookieConsent } from 'app/analytics/hooks/useCookieConsent';
import { trackerTypes } from 'app/analytics/trackingConfig';

export const useSendAnalyticsEvent = () => {
  const { consentData } = useCookieConsent();
  const isAnalyticsEventConsented = !!consentData?.[trackerTypes.mixpanel];

  const mutationFn = isAnalyticsEventConsented ? postApiAnalyticalEvents : () => Promise.resolve();

  return useMutation({ mutationFn });
};
