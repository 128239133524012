import type { FeatureFlagSchema, TeamSchema } from '@shape-construction/api/src/types';
import { FEATURE_FLAGS_CONFIG, type FlagName } from '../../featureFlags.config';
import type { FeatureFlags, FeatureFlagsByTarget, FlagConfig, FlagResult } from '../types';

export const toFeatureFlagValue = <
  Name extends FlagName,
  Config extends FlagConfig<any> = (typeof FEATURE_FLAGS_CONFIG)[Name],
>(
  name: Name,
  flagVal?: FeatureFlagSchema,
  defaultValue: Config['default'] = FEATURE_FLAGS_CONFIG[name]?.default
): FlagResult<Config> => {
  switch (flagVal?.evaluationType) {
    case 'variant':
      return {
        value: flagVal.value ?? defaultValue ?? '',
        attachment: flagVal.attachment,
      } as FlagResult<Config>;
    case 'boolean':
      return { value: flagVal.enabled ?? defaultValue ?? false } as FlagResult<Config>;
    default:
      return { value: defaultValue } as FlagResult<Config>;
  }
};

export const pickTargetFeatureFlag = <
  Name extends FlagName,
  Config extends FlagConfig<any> = (typeof FEATURE_FLAGS_CONFIG)[Name],
>(
  name: Name,
  flagsByTarget?: FeatureFlagsByTarget,
  options: {
    defaultValue?: Config['default'];
    currentTeamId?: TeamSchema['id'];
  } = {}
): FlagResult<Config> => {
  const { currentTeamId } = options;
  const flagConfig = FEATURE_FLAGS_CONFIG[name] ?? {};
  const target = flagConfig.target ?? 'user';

  let flags: FeatureFlags<FlagName> | undefined;
  if (target === 'session') {
    flags = flagsByTarget?.session;
  } else if (target === 'user' && flagsByTarget?.user) {
    flags = flagsByTarget?.user;
  } else if (target === 'team' && currentTeamId) {
    flags = flagsByTarget?.teams?.[currentTeamId];
  }

  let flagVal = flags?.[name];
  if (!flagVal && flagConfig.allowSessionFallback) {
    flagVal = flagsByTarget?.session?.[name];
  }

  return toFeatureFlagValue(name, flagVal, options.defaultValue);
};

export const mapValues = <T, R>(obj: Record<string, T>, fn: (value: T, key: string) => R): Record<string, R> =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, fn(value, key)]));

export const toFeatureFlags = (flags: FeatureFlagSchema[]): FeatureFlags<string> =>
  Object.fromEntries(flags.map((flag) => [flag.key, flag]));
