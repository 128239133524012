import {
  getApiProjectsProjectIdShiftReportsArchivedQueryKey,
  getApiProjectsProjectIdShiftReportsDraftQueryKey,
  getApiProjectsProjectIdShiftReportsQueryKey,
  getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey,
  getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey,
  getApiProjectsProjectIdShiftReportsShiftReportIdQueryOptions,
  useDeleteApiProjectsProjectIdShiftReportsShiftReportId,
  useGetApiProjectsProjectIdShiftReports,
  useGetApiProjectsProjectIdShiftReportsArchived,
  useGetApiProjectsProjectIdShiftReportsCompletions,
  useGetApiProjectsProjectIdShiftReportsDraft,
  useGetApiProjectsProjectIdShiftReportsShiftReportId,
  useGetApiProjectsProjectIdShiftReportsShiftReportIdPeople,
  usePatchApiProjectsProjectIdShiftReportsShiftReportId,
  usePostApiProjectsProjectIdShiftReports,
  usePostApiProjectsProjectIdShiftReportsExport,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdArchive,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdExport,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdImport,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdPublish,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdResetSection,
  usePostApiProjectsProjectIdShiftReportsShiftReportIdRestore,
} from '@shape-construction/api/src/hooks';
import type {
  GetApiProjectsProjectIdShiftReportsQueryParamsSchema,
  ProjectSchema,
} from '@shape-construction/api/src/types';
import { useQueryClient } from '@tanstack/react-query';

// queries
export const useShiftReports = useGetApiProjectsProjectIdShiftReports;

export const useShiftReportsDraft = useGetApiProjectsProjectIdShiftReportsDraft;

export const useShiftReportsArchive = useGetApiProjectsProjectIdShiftReportsArchived;

export const useShiftReportsPeople = useGetApiProjectsProjectIdShiftReportsShiftReportIdPeople;

export const useShiftReport = useGetApiProjectsProjectIdShiftReportsShiftReportId;
export const getShiftReportQueryOptions = getApiProjectsProjectIdShiftReportsShiftReportIdQueryOptions;

export const usePreviousShiftReport = (
  projectId: ProjectSchema['id'],
  authorId: GetApiProjectsProjectIdShiftReportsQueryParamsSchema['author_id'],
  options?: Parameters<typeof useGetApiProjectsProjectIdShiftReports>[2]
) => {
  const { data } = useGetApiProjectsProjectIdShiftReports(
    projectId,
    { page_size: 1, author_id: authorId },
    { query: { refetchOnMount: 'always' } }
  );
  const shiftReportId = data?.shiftReports?.[0]?.id;
  return useGetApiProjectsProjectIdShiftReportsShiftReportId(projectId, shiftReportId!, {
    query: {
      enabled: Boolean(data?.meta.total),
    },
  });
};

export const useShiftReportsCompletions = useGetApiProjectsProjectIdShiftReportsCompletions;

// mutations
export const useCreateShiftReport = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReports({
    mutation: {
      onSettled: (_, __, { projectId }) =>
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsDraftQueryKey(projectId),
        }),
    },
  });
};

export const usePublishShiftReport = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdPublish({
    mutation: {
      onSettled: (_, __, { projectId, shiftReportId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsDraftQueryKey(projectId),
        });
      },
    },
  });
};

export const useUpdateShiftReport = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdShiftReportsShiftReportId({
    mutation: {
      mutationKey: ['update-shift-report-mutation'],
      onSuccess(updatedShiftReport, { projectId, shiftReportId }) {
        queryClient.setQueryData(
          getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
          updatedShiftReport
        );
      },
      onSettled: (_, __, { projectId, shiftReportId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsShiftReportIdQualityIndicatorsQueryKey(projectId, shiftReportId),
        });
      },
      onError: (error, { projectId, shiftReportId }) => {
        if (error?.response?.status === 404) {
          queryClient.invalidateQueries({
            queryKey: getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
          });
        }
      },
    },
  });
};

export const useShiftReportExport = usePostApiProjectsProjectIdShiftReportsShiftReportIdExport;

export const useShiftReportArchive = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdArchive({
    mutation: {
      onSettled: (_, __, { projectId, shiftReportId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsArchivedQueryKey(projectId),
        });
      },
    },
  });
};

export const useShiftReportRestore = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdRestore({
    mutation: {
      onSettled: (_, __, { projectId, shiftReportId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsArchivedQueryKey(projectId),
        });
      },
    },
  });
};

export const useShiftReportsDraftDelete = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdShiftReportsShiftReportId({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdShiftReportsDraftQueryKey(projectId),
        });
      },
    },
  });
};

export const useShiftReportsResetSection = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdResetSection({
    mutation: {
      mutationKey: ['reset-section-shift-report-mutation'],

      onSuccess(updatedShiftReport, { projectId, shiftReportId }) {
        queryClient.setQueryData(
          getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
          updatedShiftReport
        );
      },
      onError(error, { projectId, shiftReportId }) {
        if (error?.response?.status === 400) {
          queryClient.invalidateQueries({
            queryKey: getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
          });
        }
      },
    },
  });
};

export const useExportShiftReports = usePostApiProjectsProjectIdShiftReportsExport;

export const useImportShiftReport = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdShiftReportsShiftReportIdImport({
    mutation: {
      mutationKey: ['import-shift-report-mutation'],

      onSuccess(updatedShiftReport, { projectId, shiftReportId }) {
        queryClient.setQueryData(
          getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
          updatedShiftReport
        );
      },
      onError(error, { projectId, shiftReportId }) {
        if (error?.response?.status === 404) {
          queryClient.invalidateQueries({
            queryKey: getApiProjectsProjectIdShiftReportsShiftReportIdQueryKey(projectId, shiftReportId),
          });
        }
      },
    },
  });
};
