import {
  ConfirmationModalContent as Content,
  ConfirmationModalFooter as Footer,
  ConfirmationModalHeader as Header,
  ConfirmationModalImage as Image,
  ConfirmationModalRoot as Root,
  ConfirmationModalSubTitle as SubTitle,
  ConfirmationModalTitle as Title,
} from './ConfirmationModal';

export default { Content, Footer, Header, Image, Root, SubTitle, Title };
