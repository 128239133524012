import { useMessage } from '@messageformat/react';
import {
  getApiProjectsProjectIdIssues,
  postApiProjectsProjectIdIssues,
  deleteApiProjectsProjectIdIssuesIssueIdWatchings as unwatchIssue,
  postApiProjectsProjectIdIssuesIssueIdWatchings as watchIssue,
} from '@shape-construction/api/src/api';
import {
  getApiProjectsProjectIdIssuesGroupCountQueryKey,
  getApiProjectsProjectIdIssuesGroupCountQueryOptions,
  getApiProjectsProjectIdIssuesIssueIdQueryKey,
  getApiProjectsProjectIdIssuesIssueIdQueryOptions,
  getApiProjectsProjectIdIssuesIssueIdVisitQueryKey,
  getApiProjectsProjectIdIssuesQueryKey,
  useDeleteApiProjectsProjectIdIssuesIssueId,
  useGetApiProjectsProjectIdIssues,
  useGetApiProjectsProjectIdIssuesIssueId,
  useGetApiProjectsProjectIdIssuesIssueIdVisit,
  usePatchApiProjectsProjectIdIssuesIssueId,
  usePostApiProjectsProjectIdIssuesExport,
  usePostApiProjectsProjectIdIssuesIssueIdArchive,
  usePostApiProjectsProjectIdIssuesIssueIdRestore,
  usePostApiProjectsProjectIdIssuesIssueIdSubmit,
  usePostApiProjectsProjectIdIssuesIssueIdUpdateImpact,
  usePostApiProjectsProjectIdIssuesIssueIdVisit,
} from '@shape-construction/api/src/hooks';
import type {
  IssueBasicDetailsSchema,
  IssueSchema,
  IssueVisitSchema,
  OffsetPaginationSchema,
  PostApiProjectsProjectIdIssuesMutationRequestSchema,
  ProjectSchema,
} from '@shape-construction/api/src/types';
import { showSuccessToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import { type UseMutationOptions, infiniteQueryOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import UtilsStats from 'app/components/Utils/UtilsStats';
import type { IssueImageUpload } from 'app/localData/issueImages';
import { type Issue, buildIssue, createIssue, deleteIssue } from 'app/localData/issues';
import { builderIssue } from 'app/store/issues/issues-action-creators';
import type { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { retryOnNetworkOrServerErrors } from '../utils';
import { getInfinityIssueFeedQueryOptions } from './feed/feed';
import { useUploadIssueImage } from './images/images';

export type IssuesIndex = {
  issues: IssueBasicDetailsSchema[];
  meta: OffsetPaginationSchema['meta'];
};

// queries
export const getProjectIssuesInfiniteQueryOptions = (...args: Parameters<typeof getApiProjectsProjectIdIssues>) => {
  const [projectId, params, options] = args;

  return infiniteQueryOptions({
    queryKey: getApiProjectsProjectIdIssuesQueryKey(projectId, params),
    queryFn: ({ pageParam, signal }) =>
      getApiProjectsProjectIdIssues(projectId, { ...params, ...pageParam }, { ...options, signal }),
    getNextPageParam: ({ meta }) => (meta.currentPage !== meta.totalPages ? { page: meta.currentPage + 1 } : undefined),
    initialPageParam: { page: undefined as undefined | number },
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  });
};

export const getProjectIssuesGroupQueryOptions = getApiProjectsProjectIdIssuesGroupCountQueryOptions;

export const useProjectIssues = useGetApiProjectsProjectIdIssues;

/**
 * @deprecated use getProjectIssueQueryOptions instead
 */
export const useProjectIssue = useGetApiProjectsProjectIdIssuesIssueId;
export const getProjectIssueQueryOptions = getApiProjectsProjectIdIssuesIssueIdQueryOptions;

// mutations
type CreateIssueParameters = PostApiProjectsProjectIdIssuesMutationRequestSchema['issue'] & {
  projectId: ProjectSchema['id'];
  images?: IssueImageUpload[];
  idempotencyKey?: string;
};
export const useCreateIssueOptions = (): UseMutationOptions<IssueSchema, AxiosError, CreateIssueParameters, Issue> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate: uploadImage } = useUploadIssueImage();
  const uploadSuccessMessage = useMessage('issue.uploadSync.status.success');

  return {
    mutationKey: ['createIssue'],
    mutationFn: ({ projectId, idempotencyKey, ...issue }: CreateIssueParameters) =>
      postApiProjectsProjectIdIssues(projectId, { issue }, idempotencyKey ? { 'Idempotency-Key': idempotencyKey } : {}),
    onMutate: ({
      projectId,
      ...issue
    }: PostApiProjectsProjectIdIssuesMutationRequestSchema['issue'] & {
      projectId: ProjectSchema['id'];
    }) => {
      const temporaryIssue = buildIssue(issue, projectId);

      createIssue(temporaryIssue);

      return temporaryIssue;
    },
    onSuccess: (issue: IssueSchema, variables, context) => {
      showSuccessToast({
        message: uploadSuccessMessage,
        alignContent: 'start',
        id: 'upload-issue-success',
      });
      dispatch(builderIssue(issue.id));
      queryClient.invalidateQueries({
        queryKey: getApiProjectsProjectIdIssuesQueryKey(issue.projectId),
      });
      deleteIssue(context!.id);

      variables.images?.forEach((image) =>
        uploadImage({
          file: image.originalFile!,
          kind: 'image' as const,
          issueId: issue.id,
          projectId: issue.projectId,
          temporaryId: UtilsStats.uuidv4(),
        })
      );
    },

    retry: (_, error) => retryOnNetworkOrServerErrors(error),
  };
};

export const useCreateIssue = () => {
  return useMutation(useCreateIssueOptions());
};

export const useQuickCreateIssue = () => {
  const queryClient = useQueryClient();
  const { mutate: uploadImage } = useUploadIssueImage();

  return useMutation({
    mutationKey: ['createIssue'],
    mutationFn: ({ projectId, idempotencyKey, ...issue }: CreateIssueParameters) =>
      postApiProjectsProjectIdIssues(projectId, { issue }, idempotencyKey ? { 'Idempotency-Key': idempotencyKey } : {}),
    onMutate: ({
      projectId,
      images,
      ...issue
    }: CreateIssueParameters & {
      projectId: ProjectSchema['id'];
    }) => {
      const temporaryIssue = buildIssue(issue, projectId);

      createIssue(temporaryIssue);

      return temporaryIssue;
    },
    onSuccess: (issue: IssueSchema, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: getApiProjectsProjectIdIssuesQueryKey(issue.projectId),
      });
      deleteIssue(context!.id);

      variables.images?.forEach((image) =>
        uploadImage({
          file: image.originalFile!,
          kind: 'image' as const,
          issueId: issue.id,
          projectId: issue.projectId,
          temporaryId: UtilsStats.uuidv4(),
        })
      );
    },
    retry: (_, error) => retryOnNetworkOrServerErrors(error as AxiosError),
  });
};

export const useUpdateIssue: typeof usePatchApiProjectsProjectIdIssuesIssueId = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdIssuesIssueId({
    mutation: {
      onSuccess: (issue) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesQueryKey(issue.projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesGroupCountQueryKey(issue.projectId),
          exact: false,
        });
        queryClient.setQueryData<IssueSchema>(
          getApiProjectsProjectIdIssuesIssueIdQueryKey(issue.projectId, issue.id),
          issue
        );
      },
    },
  });
};

export const useUpdateIssueImpact = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdUpdateImpact({
    mutation: {
      onSuccess: (issue, { projectId, issueId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesQueryKey(issue.projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesGroupCountQueryKey(issue.projectId),
          exact: false,
        });
        queryClient.setQueryData<IssueSchema>(getApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId), issue);
      },
    },
  });
};

export const useIssueVisit = useGetApiProjectsProjectIdIssuesIssueIdVisit;

export const useVisitIssue = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdVisit({
    mutation: {
      onSuccess: (visit, { projectId, issueId }) => {
        queryClient.setQueryData<IssueVisitSchema>(
          getApiProjectsProjectIdIssuesIssueIdVisitQueryKey(projectId, issueId),
          visit
        );
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesGroupCountQueryKey(projectId),
          exact: false,
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesQueryKey(projectId),
        });
      },
    },
  });
};

export const useDeleteIssue = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdIssuesIssueId({
    mutation: {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesGroupCountQueryKey(projectId),
          exact: false,
        });
      },
    },
  });
};

export const useIssueSubmit = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdSubmit({
    mutation: {
      onSuccess: (issue, { projectId }) => {
        queryClient.setQueryData<IssueSchema>(getApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issue.id), issue);
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesGroupCountQueryKey(projectId),
          exact: false,
        });
      },
    },
  });
};

export const useIssueArchive = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdArchive({
    mutation: {
      onSuccess: (issue, { projectId, issueId }) => {
        queryClient.setQueryData<IssueSchema>(getApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId), issue);
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesGroupCountQueryKey(projectId),
          exact: false,
        });
        queryClient.invalidateQueries(getInfinityIssueFeedQueryOptions('public', projectId, issueId));
      },
    },
  });
};

export const useIssueRestore = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdIssuesIssueIdRestore({
    mutation: {
      onSuccess: (issue, { projectId, issueId }) => {
        queryClient.setQueryData<IssueSchema>(getApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId), issue);
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesQueryKey(projectId),
        });
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdIssuesGroupCountQueryKey(projectId),
          exact: false,
        });
        queryClient.invalidateQueries(getInfinityIssueFeedQueryOptions('public', projectId, issueId));
      },
    },
  });
};

export const useIssueWatch = (projectId: ProjectSchema['id'], issueId: IssueSchema['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (isWatching: boolean) => {
      const method = isWatching ? watchIssue : unwatchIssue;
      return method(projectId, issueId);
    },
    onSuccess: (_, isWatching) => {
      queryClient.setQueryData<IssueSchema | undefined>(
        getApiProjectsProjectIdIssuesIssueIdQueryKey(projectId, issueId),
        (issue) => {
          if (!issue) return undefined;

          return {
            ...issue,
            isWatching,
          };
        }
      );
      queryClient.invalidateQueries({
        queryKey: getApiProjectsProjectIdIssuesQueryKey(projectId),
      });
    },
  });
};

export const useIssuesExport = usePostApiProjectsProjectIdIssuesExport;
