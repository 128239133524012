import React, { useState } from 'react';
import FileExtensionIcon from '../FileExtensionIcon';
import { CheckIcon } from '../Icons/solid';
import { ProgressBarRoot } from '../ProgressBar/ProgressBar';
import Spinner from '../Spinner';
import type { File, ImageFile } from '../types/File';
import { cn } from '../utils/classes';

export const sizes = ['small', 'large', 'proportional'] as const;
export type FileThumbnailSize = (typeof sizes)[number];

export type FileThumbnailProps = Pick<File, 'extension' | 'isUploading' | 'uploadProgress'> & {
  caption: string;
  className?: string;
  fileId: File['id'];
  onClick?: (id: string) => void;
  thumbnailUrl?: ImageFile['mediaUrl']['thumbnail'];
  as?: 'button' | 'div';
  size?: FileThumbnailSize;
  hideCaption?: boolean;
};

const FileThumbnail: React.FC<FileThumbnailProps> = ({
  caption,
  extension,
  fileId,
  isUploading,
  uploadProgress,
  onClick,
  thumbnailUrl,
  className,
  as: As = 'button',
  size = 'large',
  hideCaption,
}) => {
  const handleOnClick = () => onClick?.(fileId);
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(Boolean(thumbnailUrl));
  const isButton = As === 'button';

  const displayDocumentCaption = !hideCaption && (size === 'large' || size === 'proportional');

  return (
    <As
      {...(isButton && {
        type: 'button',
        disabled: !onClick,
        onClick: handleOnClick,
      })}
      className={cn(
        'shrink-0 overflow-hidden rounded-md border border-neutral-subtlest',
        {
          'w-40': size === 'large',
          'w-20': size === 'small',
        },
        className
      )}
      aria-label={caption}
    >
      <figure
        className={cn({
          'aspect-[4/3] overflow-hidden flex flex-col': size === 'proportional',
        })}
      >
        <div
          className={cn('flex items-center justify-center bg-gray-100 overflow-hidden', {
            'h-20': size === 'large',
            'h-16': size === 'small',
            'h-full w-ful': size === 'proportional',
            'hover:opacity-50': isButton,
          })}
        >
          {thumbnailUrl ? (
            <>
              {isThumbnailLoading && <Spinner className="absolute" size="lg" />}
              <img
                src={thumbnailUrl}
                className="h-full w-full object-cover"
                aria-label="thumbnail-image"
                onLoad={() => setIsThumbnailLoading(false)}
              />
            </>
          ) : (
            <FileExtensionIcon
              data-testid={`extension-${extension}`}
              extension={extension}
              width={size === 'large' || size === 'proportional' ? 40 : 24}
              height={size === 'large' || size === 'proportional' ? 40 : 24}
            />
          )}
        </div>
        {isUploading && (
          <div data-testid="upload-overlay" aria-busy={isUploading}>
            <div
              className={cn('absolute top-0 flex w-full justify-center bg-white align-middle opacity-80', {
                'h-16': size === 'small',
                'h-20': size === 'large',
                'aspect-[4/3]': size === 'proportional',
              })}
            />

            {uploadProgress !== undefined && (
              <ProgressBarRoot
                progress={uploadProgress}
                size="small"
                className={cn('absolute transition-all duration-500 ease-out', {
                  'top-[60px]': size === 'small',
                  'top-[76px]': size === 'large',
                  'top-1': size === 'proportional',
                })}
              />
            )}
          </div>
        )}
        {displayDocumentCaption && (
          <figcaption
            className="m-0 h-8 w-full overflow-hidden text-ellipsis whitespace-nowrap p-2 text-left text-xs text-neutral-bold shrink-0"
            title={caption}
          >
            {caption}
          </figcaption>
        )}
      </figure>
      <CheckIcon className="w-4 h-4 rounded absolute top-1 right-1 text-white hidden" />
    </As>
  );
};

export default FileThumbnail;
