import * as React from 'react';
import type { SVGProps } from 'react';
const CameraIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/camera">
      <g id="Icon">
        <path
          d="M12 16.8001C13.9882 16.8001 15.6 15.1883 15.6 13.2001C15.6 11.2119 13.9882 9.6001 12 9.6001C10.0117 9.6001 8.39995 11.2119 8.39995 13.2001C8.39995 15.1883 10.0117 16.8001 12 16.8001Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.19995 9.6001C1.19995 8.27461 2.27447 7.2001 3.59995 7.2001H4.71551C5.51796 7.2001 6.26731 6.79905 6.71243 6.13138L7.68747 4.66882C8.13259 4.00114 8.88195 3.6001 9.68439 3.6001H14.3155C15.118 3.6001 15.8673 4.00114 16.3124 4.66882L17.2875 6.13138C17.7326 6.79905 18.4819 7.2001 19.2844 7.2001H20.4C21.7254 7.2001 22.8 8.27461 22.8 9.6001V18.0001C22.8 19.3256 21.7254 20.4001 20.4 20.4001H3.59995C2.27447 20.4001 1.19995 19.3256 1.19995 18.0001V9.6001ZM17.4 13.2001C17.4 16.1824 14.9823 18.6001 12 18.6001C9.01761 18.6001 6.59995 16.1824 6.59995 13.2001C6.59995 10.2178 9.01761 7.8001 12 7.8001C14.9823 7.8001 17.4 10.2178 17.4 13.2001Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default CameraIcon;
