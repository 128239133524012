import classNames from 'clsx';
import React from 'react';
import { ProfileIcon } from '../../Icons/custom';
import type { ThemeColors } from '../../types/Colors';
import type { DefaultSizes } from '../../types/Sizes';
import type { User } from '../../types/User';
import { AvatarHighlightedColorClasses } from '../AvatarHighlightedColorClasses';
import { AvatarSizeClasses } from '../AvatarSizeClasses';
export interface UserAvatarProps {
  user?: User;
  /**
   * @param size - size of the component
   * */
  size: DefaultSizes;
  /**
   * @param highlighted - add a highlight to the avatar
   * @default false
   */
  highlighted?: boolean;
  /**
   * @param highlightedColor - the highlight color to the avatar
   */
  highlightedColor?: ThemeColors;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  size,
  highlighted = false,
  highlightedColor = 'default',
}) => {
  const { root, fontSize } = AvatarSizeClasses({ size });
  const highlightedClass = highlighted ? `ring-2 ${AvatarHighlightedColorClasses[highlightedColor]}` : '';

  if (!user || (!user?.name && !user.avatarUrl)) {
    return (
      <div className={classNames('inline-block overflow-hidden rounded-full bg-gray-100', highlightedClass, root)}>
        <ProfileIcon
          className="h-full w-full text-gray-300"
          role="presentation"
          aria-label="Generic user"
          viewBox="0 0 32 19"
        />
      </div>
    );
  }

  return user.avatarUrl ? (
    <img
      className={classNames('inline-block rounded-full bg-white', highlightedClass, root)}
      src={user.avatarUrl}
      alt={user.name}
      role="presentation"
    />
  ) : (
    <div
      className={classNames('flex items-center justify-center rounded-full bg-indigo-400', highlightedClass, root)}
      role="presentation"
    >
      <span className={classNames('uppercase text-white leading-none', fontSize)}>
        {user.firstName?.charAt(0) || user.name?.slice(0, 2)}
        {user.lastName?.charAt(0) || user.firstName?.charAt(1)}
      </span>
    </div>
  );
};
