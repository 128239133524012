import * as React from 'react';
import type { SVGProps } from 'react';
const XCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/x-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.6004C17.302 21.6004 21.6 17.3023 21.6 12.0004C21.6 6.69846 17.302 2.40039 12 2.40039C6.69809 2.40039 2.40002 6.69846 2.40002 12.0004C2.40002 17.3023 6.69809 21.6004 12 21.6004ZM9.93642 8.664C9.58495 8.31252 9.0151 8.31252 8.66363 8.664C8.31216 9.01547 8.31216 9.58532 8.66363 9.93679L10.7272 12.0004L8.66363 14.064C8.31216 14.4155 8.31216 14.9853 8.66363 15.3368C9.0151 15.6883 9.58495 15.6883 9.93642 15.3368L12 13.2732L14.0636 15.3368C14.4151 15.6883 14.985 15.6883 15.3364 15.3368C15.6879 14.9853 15.6879 14.4155 15.3364 14.064L13.2728 12.0004L15.3364 9.93679C15.6879 9.58532 15.6879 9.01547 15.3364 8.664C14.985 8.31252 14.4151 8.31252 14.0636 8.664L12 10.7276L9.93642 8.664Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default XCircleIcon;
