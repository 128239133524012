import { useMessageGetter } from '@messageformat/react';
import { Card, cn } from '@shape-construction/arch-ui';
import { ArrowUpCircleIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useSidebar } from '@shape-construction/arch-ui/src/Sidebar';
import { now, parseDate } from '@shape-construction/utils/DateTime';
import { useQuery } from '@tanstack/react-query';
import { getProjectQueryOptions } from 'app/queries/projects/projects';
import { useTeamsSubscriptionPlan } from 'app/queries/teamsSubscriptionPlan/teamsSubscriptionPlan';
import React from 'react';
import { useMatch, useNavigate } from 'react-router';

type AppSidebarUpgradePlanButtonProps = {
  activeTeamSubscriptionPlanSlug: string;
  trialDaysLeft: string;
  currentTeamUrl: string;
};

const AppSidebarUpgradePlanButton: React.FC<AppSidebarUpgradePlanButtonProps> = ({
  activeTeamSubscriptionPlanSlug,
  trialDaysLeft,
  currentTeamUrl,
}) => {
  const navigate = useNavigate();
  const { isLargeScreen, open, toggleSidebar } = useSidebar();

  const showFullVersion = !isLargeScreen || open;
  const upgradePlanButtonMessages = useMessageGetter(`appSidebar.upgradePlanButton.${activeTeamSubscriptionPlanSlug}`);

  const redirectToSubscriptionPage = () => {
    if (!isLargeScreen) toggleSidebar(false);
    navigate(currentTeamUrl);
  };

  return (
    <button
      type="button"
      aria-label={upgradePlanButtonMessages('title')}
      onClick={redirectToSubscriptionPage}
      className="flex text-left text-white hover:opacity-90 transition-all duration-200"
    >
      <Card
        size="large"
        className={cn('bg-success-bold border-success-bold w-full', { 'w-10 h-10': !showFullVersion })}
      >
        <Card.Body className={cn('flex p-2', { 'items-center justify-center': !showFullVersion })}>
          <div className="flex gap-3">
            <div className="flex items-center w-5 h-5">
              <ArrowUpCircleIcon className="text-white" width={24} />
            </div>
            {showFullVersion && (
              <div className="font-semibold flex flex-col gap-y-1">
                <h2 className="font-semibold">{upgradePlanButtonMessages('title')}</h2>
                <p className="text-xs leading-4 font-medium text-indigo-50">
                  {upgradePlanButtonMessages('description', { trialDays: trialDaysLeft })}
                </p>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </button>
  );
};

const AppSidebarUpgradePlanButtonContainer: React.FC = () => {
  const pathParams = useMatch('/projects/:projectId/*');
  const projectId = pathParams?.params.projectId;
  const { data: project } = useQuery(getProjectQueryOptions(projectId!));
  const currentTeamUrl = `/projects/${projectId}/settings/teams/${project?.currentTeamId}/subscription`;
  const { data: teamSubscriptionData } = useTeamsSubscriptionPlan(projectId!, project?.currentTeamId!);
  const trialDaysLeft = parseDate(teamSubscriptionData?.trialEndAt || new Date())
    .diff(now(), 'days')
    .toString();

  const canEditTeamSubscription = teamSubscriptionData?.availableActions.edit || false;
  const activeTeamSubscriptionPlanSlug = teamSubscriptionData?.activePlanSlug;
  const inFreeOrTrialSubscriptionPlan =
    activeTeamSubscriptionPlanSlug === 'free' || activeTeamSubscriptionPlanSlug === 'trial';
  const canShowAppSidebarUpgradePlanButton = canEditTeamSubscription && inFreeOrTrialSubscriptionPlan;

  if (!canShowAppSidebarUpgradePlanButton) return null;

  return (
    <AppSidebarUpgradePlanButton
      activeTeamSubscriptionPlanSlug={activeTeamSubscriptionPlanSlug}
      trialDaysLeft={trialDaysLeft}
      currentTeamUrl={currentTeamUrl}
    />
  );
};

export { AppSidebarUpgradePlanButtonContainer as AppSidebarUpgradePlanButton };
