import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../Icons/solid';
import { cn } from '../../utils/classes';

const indentClasses: Record<number, string> = {
  1: 'pl-2',
  2: 'pl-8',
  3: 'pl-14',
  4: 'pl-20',
};

export interface TableGroupRowProps {
  isCollapsed: boolean;
  title: string;
  label: React.ReactNode;
  children: React.ReactNode;
  onToggleRow: () => void;
  indentLevel?: number;
}

export const TableGroupRow: React.FC<TableGroupRowProps> = ({
  isCollapsed,
  title,
  label,
  children,
  onToggleRow,
  indentLevel = 1,
}) => {
  const groupClassName = cn('flex items-center w-full px-2 py-1', indentClasses[indentLevel] || indentClasses[4]);

  return (
    <>
      <tr className="h-9 bg-gray-100 cursor-pointer border-y border-y-gray-200 last:border-b-0">
        <td colSpan={100}>
          <button
            aria-expanded={!isCollapsed}
            aria-label={title}
            className={groupClassName}
            onClick={onToggleRow}
            type="button"
          >
            {isCollapsed ? (
              <ChevronDownIcon className="w-5 h-5 bg-neutral-alpha-subtlest" />
            ) : (
              <ChevronUpIcon className="w-5 h-5 bg-neutral-alpha-subtlest" />
            )}
            <span className="flex items-center gap-2 ml-2">
              <span className="text-xs font-medium text-neutral-subtlest">{title}</span>
              {label}
            </span>
          </button>
        </td>
      </tr>
      {!isCollapsed ? children : null}
    </>
  );
};
