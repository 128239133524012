import {
  ModalContent as Content,
  ModalFooter as Footer,
  ModalHeader as Header,
  ModalRoot as Root,
  ModalSubTitle as SubTitle,
  ModalTitle as Title,
} from './Modal';

export default { Content, Footer, Header, Root, SubTitle, Title };
