import * as React from 'react';
import type { SVGProps } from 'react';
const MegaphoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/megaphone">
      <g id="Icon">
        <path
          d="M16.7041 4.61414C14.3831 5.81033 11.8364 6.62943 9.1446 6.99085C8.11646 7.1289 7.06677 7.20023 5.99995 7.20023C3.34898 7.20023 1.19995 9.34926 1.19995 12.0002C1.19995 14.4463 3.02967 16.4651 5.39512 16.7625C5.74534 18.1278 6.2517 19.4725 6.9204 20.772C7.39627 21.6968 8.53077 21.9706 9.38111 21.4797L10.4203 20.8797C11.2921 20.3763 11.549 19.2944 11.1267 18.4541C10.9274 18.0575 10.7482 17.6557 10.5887 17.2498C12.7491 17.6793 14.8023 18.4062 16.7041 19.3863C17.5426 17.0819 18 14.5945 18 12.0002C18 9.40597 17.5426 6.91853 16.7041 4.61414Z"
          fill="currentColor"
        />
        <path
          d="M18.2913 3.71609C19.2663 6.29128 19.8 9.08343 19.8 12.0002C19.8 14.917 19.2663 17.7092 18.2913 20.2844C18.2912 20.2843 18.2913 20.2844 18.2913 20.2844L18.2562 20.3771C18.0782 20.8412 18.3101 21.3617 18.7742 21.5397C19.2383 21.7176 19.7588 21.4857 19.9368 21.0216C20.0401 20.7522 20.139 20.4806 20.2332 20.2069C20.8982 18.2754 21.3349 16.2381 21.5114 14.1277C22.2774 13.7268 22.8 12.9251 22.8 12.0002C22.8 11.0754 22.2774 10.2737 21.5114 9.87281C21.3349 7.76232 20.8982 5.72505 20.2332 3.79358C20.139 3.51984 20.0401 3.24823 19.9368 2.97885C19.7588 2.51475 19.2383 2.28281 18.7742 2.4608C18.3101 2.63879 18.0782 3.1593 18.2562 3.6234L18.2913 3.71609C18.2913 3.71605 18.2912 3.71613 18.2913 3.71609Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default MegaphoneIcon;
