import {
  getApiProjectsProjectIdLocationsQueryKey,
  getApiProjectsProjectIdLocationsQueryOptions,
  useDeleteApiProjectsProjectIdLocationsLocationId,
  useGetApiProjectsProjectIdLocations,
  usePatchApiProjectsProjectIdLocationsLocationId,
  usePostApiProjectsProjectIdLocations,
  usePostApiProjectsProjectIdLocationsLocationIdSort,
} from '@shape-construction/api/src/hooks';
import type { LocationListSchema } from '@shape-construction/api/src/types';
import { useQueryClient } from '@tanstack/react-query';

// queries
export const useProjectLocations = useGetApiProjectsProjectIdLocations;
export const getProjectLocationsQueryOptions = getApiProjectsProjectIdLocationsQueryOptions;

// mutations
export const useCreateProjectLocation = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdLocations({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdLocationsQueryKey(projectId),
        });
      },
    },
  });
};

export const useUpdateProjectLocation = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdLocationsLocationId({
    mutation: {
      onSuccess: (updatedLocation, { projectId }) => {
        queryClient.setQueryData<LocationListSchema>(
          getApiProjectsProjectIdLocationsQueryKey(projectId),
          (data = []) => {
            const result = data?.map((currentLocation) => {
              if (currentLocation.id === updatedLocation.id) return updatedLocation;
              return currentLocation;
            });

            return result;
          }
        );
      },
    },
  });
};

export const useDeleteProjectLocation = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdLocationsLocationId({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdLocationsQueryKey(projectId),
        });
      },
    },
  });
};

export const useSortProjectLocations = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdLocationsLocationIdSort({
    mutation: {
      onSettled: (_, __, { projectId }) => {
        queryClient.invalidateQueries({
          queryKey: getApiProjectsProjectIdLocationsQueryKey(projectId),
        });
      },
    },
  });
};
