import { Dialog, type DialogProps, Transition } from '@headlessui/react';
import classNames from 'clsx';
import React, { Fragment } from 'react';

import { IconButton } from '../Button';
import { XMarkIcon } from '../Icons/solid';
import { cn } from '../utils/classes';
import { type Transition as TransitionType, transitions } from './config/transitions';

export type MaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'none';

const maxWithOptions = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  none: 'max-w-none',
} as { [key in MaxWidth]: string };

const getMaxWidth = (maxWidth: MaxWidth) => maxWithOptions[maxWidth];

type OwnProps = React.PropsWithChildren<{
  /**
   * Root component classname
   */
  className?: string;
  /**
   * If true, will not trigger onClose.
   */
  disableEscapeKey?: boolean;
  /**
   * Called when the Dialog is dismissed (via the overlay or Escape key). Typically used to close the dialog by setting open to false.
   */
  onClose: (value?: boolean) => void;
  /**
   * Set modal fullscreen
   */
  fullScreen?: boolean;
  /**
   * Set modal full width
   */
  fullWidth?: boolean;
  /**
   * Determine the max-width of the dialog.
   */
  maxWidth?: MaxWidth;
  /**
   * Adds an outside pad around the modal. Useful when used, for example, with fullScreen set to true.
   */
  outsidePad?: boolean;
  /**
   * Adds rounded borders. Usually used when not in fullscreen and without outsidePad
   */
  roundBorders?: boolean;
  /**
   * Type of transitions to be applied to the modal. When auto, it will apply slide-bottom when small screen and fade for larger screens
   */
  transition?: TransitionType;
  /**
   * Override z-index of the modal
   */
  zIndex?: number;
}>;

export type ModalBaseRootProps = Omit<DialogProps<'div'>, 'onClose' | 'unmount'> & OwnProps;

export const ModalBaseRoot = ({
  children,
  className,
  disableEscapeKey,
  fullScreen,
  fullWidth,
  maxWidth,
  outsidePad,
  roundBorders,
  zIndex,
  transition = 'none',
  onClose,
  ...modalProps
}: ModalBaseRootProps) => {
  const hasOverlay = !fullScreen || outsidePad;

  const handleClose: ModalBaseRootProps['onClose'] = (open) => {
    // When open is false, it means key down has been pressed
    if (open === false && disableEscapeKey) return;

    onClose(open);
  };

  return (
    <Transition as={Fragment} show={modalProps.open}>
      <Dialog
        onClose={handleClose}
        as="div"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        {...modalProps}
      >
        <div style={{ zIndex }} className="fixed inset-0 z-modal flex items-center justify-center">
          {hasOverlay && <ModalBaseOverlay />}
          <Transition.Child
            as="div"
            className={cn(
              'flex flex-col max-h-full',
              {
                'w-full': fullWidth,
                'h-full w-full': fullScreen,
              },
              maxWidth && getMaxWidth(maxWidth),
              className as string
            )}
            {...transitions[transition]}
          >
            <div
              className={classNames('flex grow transform flex-col overflow-hidden bg-white', {
                'm-8 shadow-xl': outsidePad,
                'rounded-lg': roundBorders,
              })}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export type ModalBaseCloseButtonProps = {
  onClose: () => void;
};
export const ModalBaseCloseButton: React.FC<ModalBaseCloseButtonProps> = ({ onClose }) => {
  return <IconButton icon={XMarkIcon} onClick={onClose} size="sm" color="secondary" variant="text" />;
};

export type ModalBaseContentProps = React.HtmlHTMLAttributes<HTMLDivElement>;
export const ModalBaseContent: React.FC<ModalBaseContentProps> = ({ className, ...props }) => {
  return (
    <div
      data-testid="modal-content"
      className={cn('flex h-full grow flex-col overflow-auto px-6', className)}
      {...props}
    />
  );
};

export interface ModalBaseFooterProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /**
   * If the footer should render a top border line.
   */
  topBorder?: boolean;
}
export const ModalBaseFooter: React.FC<ModalBaseFooterProps> = ({ className, topBorder = false, ...props }) => {
  return (
    <div
      data-testid="modal-footer"
      {...props}
      className={cn(
        'flex w-full items-center justify-end space-x-2 px-5 pt-4 pb-6 sm:pb-4',
        topBorder && 'border-t border-gray-200',
        className
      )}
    />
  );
};

const ModalBaseCloseIcon: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ onClick }) => (
  <button
    type="button"
    className="absolute right-5 top-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    onClick={onClick}
    aria-label="Close Overlay"
  >
    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
  </button>
);

export interface ModalBaseHeaderProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /**
   * If the header should render a bottom border line.
   */
  bottomBorder?: boolean;
  /**
   * Callback called when clicked on the close icon button. This prop manage if renders or not the close icon component.
   */
  onClose?: () => void;
}
export const ModalBaseHeader: React.FC<ModalBaseHeaderProps> = ({
  bottomBorder = false,
  className,
  children,
  onClose,
  ...props
}) => {
  return (
    <div
      data-testid="modal-header"
      className={cn(
        'relative w-full flex flex-col py-5 px-4 gap-2',
        {
          'pr-16': onClose,
          'border-b border-gray-200': bottomBorder,
        },
        className
      )}
      {...props}
    >
      {children}
      {onClose && <ModalBaseCloseIcon onClick={onClose} />}
    </div>
  );
};

export interface ModalBaseOverlayProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const ModalBaseOverlay: React.FC<ModalBaseOverlayProps> = ({ className, ...props }) => {
  return <Dialog.Overlay {...props} className={cn('fixed inset-0 bg-black opacity-30', className)} />;
};

export type ModalBaseTitleProps = React.ComponentPropsWithRef<typeof Dialog.Title>;
export const ModalBaseTitle: React.FC<ModalBaseTitleProps> = ({ className, as: Wrapper = Dialog.Title, ...props }) => (
  <Wrapper className={cn('text-lg font-medium text-gray-900 leading-6', className)} {...props} />
);

export type ModalBaseSubTitleProps = React.ComponentPropsWithRef<typeof Dialog.Title>;
export const ModalBaseSubTitle: React.FC<ModalBaseSubTitleProps> = ({
  className,
  as: Wrapper = Dialog.Description,
  ...props
}) => {
  return <Wrapper as="h3" className={cn('text-sm font-normal leading-5 text-gray-500', className)} {...props} />;
};
