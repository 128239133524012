import React, { type ComponentProps } from 'react';
import Alert from '../Alert';
import Button from '../Button';
import Drawer from '../Drawer';
import { ArrowTopRightOnSquareIcon, EnvelopeIcon } from '../Icons/outline';
import { CalendarIcon, CircleDotIcon } from '../Icons/solid';
import Link from '../Link';
import { ArticleDetails } from './components/ArticleDetails';
import { ListArticlesContainer } from './components/ListArticlesContainer';
import { ListSections, ListSectionsSkeleton } from './components/ListSections';
import type { Article, Section } from './types';

export type HelpCenterProps = Pick<ComponentProps<typeof Drawer.Root>, 'open' | 'onClose'> & {
  articles: Article[];
  sections: Section[];
  helpCenterUrl: string;
  helpCenterEmail: string;
  selectedArticle: Article | undefined;
  selectedSection: Section | undefined;
  onSelectArticle: (article: Article | undefined) => void;
  isLoading?: boolean;
  showProductTipsAlert?: boolean;
  seeTips?: () => void;
  productTour?: React.ReactNode;
  onOpenBookSession: () => void;
};

export const HelpCenter: React.FC<HelpCenterProps> = ({
  articles,
  sections,

  selectedSection,
  selectedArticle,
  onSelectArticle,

  helpCenterUrl,
  helpCenterEmail,

  isLoading = false,

  showProductTipsAlert = false,
  seeTips,

  productTour,
  onOpenBookSession,

  ...drawerProps
}) => {
  return (
    <Drawer.Root {...drawerProps}>
      <Drawer.Header onClose={drawerProps.onClose} className="px-4">
        <Drawer.Title>Help & Tips</Drawer.Title>
      </Drawer.Header>
      <Drawer.Content>
        {showProductTipsAlert && (
          <Alert
            color="discovery"
            emphasis="bold"
            rounded={false}
            displayInRow
            customIcon={<CircleDotIcon className="h-5 w-5text-white" />}
          >
            <Alert.Message>Want to see the highlight tips again?</Alert.Message>
            <Alert.Actions>
              <Button variant="outlined" color="white" size="xs" onClick={seeTips}>
                See tips
              </Button>
            </Alert.Actions>
          </Alert>
        )}
        {productTour}
        {selectedArticle ? (
          <div data-testid="article section">
            <ArticleDetails onNavigateBack={() => onSelectArticle(undefined)} article={selectedArticle} />
          </div>
        ) : (
          <>
            <ListArticlesContainer
              isLoading={isLoading}
              articles={articles}
              helpCenterUrl={helpCenterUrl}
              helpCenterEmail={helpCenterEmail}
              selectedSection={selectedSection}
              onSelectArticle={onSelectArticle}
            />
            {isLoading ? (
              <ListSectionsSkeleton />
            ) : (
              <ListSections.Root title="Learn more about Shape">
                {sections.map((section) => (
                  <a
                    key={section.id}
                    aria-label="section"
                    target="_blank"
                    rel="noreferrer"
                    href={section.html_url}
                    className="w-full text-left"
                  >
                    <ListSections.Item
                      aria-label="section"
                      className="border-t text-neutral-bold"
                      icon={<ArrowTopRightOnSquareIcon className="h-5 w-5 text-neutral-subtle" />}
                    >
                      {section.name}
                    </ListSections.Item>
                  </a>
                ))}
                <button type="button" className="w-full" onClick={onOpenBookSession}>
                  <ListSections.Item
                    aria-label="book a free setup session"
                    className="border-y border-discovery-subtle bg-discovery-subtlest text-discovery"
                    icon={<CalendarIcon className="h-5 w-5 text-icon-discovery" />}
                  >
                    Book a free setup session
                  </ListSections.Item>
                </button>
              </ListSections.Root>
            )}
          </>
        )}
      </Drawer.Content>
      <Drawer.Footer className="p-4 gap-x-6">
        <Link
          href={`mailto:${helpCenterEmail}`}
          target="_blank"
          rel="noreferrer"
          color="primary"
          leadingIcon={EnvelopeIcon}
        >
          Contact support
        </Link>
        <Link
          href={helpCenterUrl}
          target="_blank"
          rel="noreferrer"
          color="primary"
          leadingIcon={ArrowTopRightOnSquareIcon}
        >
          Go to help center
        </Link>
      </Drawer.Footer>
    </Drawer.Root>
  );
};
