import { useMessage } from '@messageformat/react';
import { Dropdown, ProgressBar } from '@shape-construction/arch-ui';
import { CheckCircleIcon, ChevronRightIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import React, { type FC } from 'react';
import { Link } from 'react-router';

export type ProjectSetupProgressProps = {
  options: {
    label: string;
    href: string;
    completed: boolean;
  }[];
  percentage: number;
};

export const ProjectSetupProgress: FC<ProjectSetupProgressProps> = ({ options, percentage }) => {
  const progressLabel = useMessage('projectSetup.label');

  return (
    <Dropdown
      button={
        <button type="button" className="w-full" aria-label="project setup progress">
          <ProgressBar.Root aria-label="project progress" progress={percentage} color="primary">
            <ProgressBar.Header showProgress>
              <ProgressBar.Title>{progressLabel}</ProgressBar.Title>
            </ProgressBar.Header>
          </ProgressBar.Root>
        </button>
      }
      items={
        <div className="flex w-full flex-col divide-y divide-gray-100">
          {options.map(({ label, href, completed }) => {
            if (completed)
              return (
                <div className="border-bottom" key={label}>
                  <Dropdown.Item
                    aria-label={label}
                    disabled
                    endAdornment={<CheckCircleIcon className="h-4 w-4 text-green-500" />}
                  >
                    {label}
                  </Dropdown.Item>
                </div>
              );

            return (
              <Link to={href} key={label} aria-label={label} className="border-bottom">
                <Dropdown.Item endAdornment={<ChevronRightIcon className="h-4 w-4 text-gray-700" />}>
                  {label}
                </Dropdown.Item>
              </Link>
            );
          })}
        </div>
      }
    />
  );
};
