import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronDoubleLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chevron-double-left">
      <g id="Icon">
        <path
          d="M11.9136 11.3635C11.5622 11.7149 11.5622 12.2848 11.9136 12.6362L15.4136 16.1362C15.7651 16.4877 16.3349 16.4877 16.6864 16.1362C17.0379 15.7848 17.0379 15.2149 16.6864 14.8635L13.8228 11.9999L16.6864 9.13625C17.0379 8.78478 17.0379 8.21493 16.6864 7.86346C16.3349 7.51199 15.7651 7.51199 15.4136 7.86346L11.9136 11.3635Z"
          fill="currentColor"
        />
        <path
          d="M6.91363 11.3635C6.56216 11.7149 6.56216 12.2848 6.91363 12.6363L10.4136 16.1362C10.7651 16.4877 11.3349 16.4877 11.6864 16.1362C12.0379 15.7848 12.0379 15.2149 11.6864 14.8635L8.82282 11.9999L11.6864 9.13625C12.0379 8.78478 12.0379 8.21493 11.6864 7.86346C11.3349 7.51199 10.7651 7.51199 10.4136 7.86346L6.91363 11.3635Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default ChevronDoubleLeftIcon;
