import * as React from 'react';
import type { SVGProps } from 'react';
const PhotoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/photo">
      <g id="Group 1">
        <path
          id="Rectangle 30"
          d="M3.25 14V18.5C3.25 19.3284 3.92157 20 4.75 20H19.25C20.0784 20 20.75 19.3284 20.75 18.5V14"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinejoin="round"
        />
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 3.25C3.50736 3.24976 2.5 4.25713 2.5 5.49977L2.50002 15.4998C2.50002 15.8145 2.69652 16.0957 2.99205 16.204C3.28758 16.3123 3.61926 16.2245 3.82256 15.9842L8.62055 10.3139C8.71503 10.2022 8.88475 10.1952 8.98817 10.2986L13.2197 14.5301C13.3603 14.6707 13.5511 14.7498 13.75 14.7498C13.9489 14.7498 14.1397 14.6707 14.2804 14.5301L16.5732 12.2372C16.6709 12.1396 16.8292 12.1396 16.9268 12.2372L20.2197 15.5301C20.4342 15.7446 20.7568 15.8088 21.037 15.6927C21.3173 15.5766 21.5 15.3031 21.5 14.9998V5.5018C21.5 4.25822 20.4912 3.25052 19.2476 3.25128C15.7376 3.25344 12.2276 3.25229 8.71753 3.25113C7.39502 3.25069 6.07251 3.25026 4.75 3.25ZM14.15 6.75038C13.5149 6.75038 13 7.26526 13 7.90038C13 8.53551 13.5149 9.05038 14.15 9.05038C14.7851 9.05038 15.3 8.53551 15.3 7.90038C15.3 7.26526 14.7851 6.75038 14.15 6.75038Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default PhotoIcon;
