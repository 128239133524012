import {
  ModalBaseCloseButton as CloseButton,
  ModalBaseContent as Content,
  ModalBaseFooter as Footer,
  ModalBaseHeader as Header,
  ModalBaseOverlay as Overlay,
  ModalBaseRoot as Root,
  ModalBaseSubTitle as SubTitle,
  ModalBaseTitle as Title,
} from './ModalBase';

export default {
  CloseButton,
  Content,
  Footer,
  Header,
  Overlay,
  Root,
  SubTitle,
  Title,
};
