import * as React from 'react';
import type { SVGProps } from 'react';
const ChevronDoubleRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chevron-double-right">
      <g id="Icon">
        <path
          d="M12.0864 11.3635C12.4379 11.7149 12.4379 12.2848 12.0864 12.6362L8.58644 16.1362C8.23497 16.4877 7.66512 16.4877 7.31365 16.1362C6.96218 15.7848 6.96218 15.2149 7.31365 14.8635L10.1773 11.9999L7.31365 9.13625C6.96218 8.78478 6.96218 8.21493 7.31365 7.86346C7.66513 7.51199 8.23497 7.51199 8.58644 7.86346L12.0864 11.3635Z"
          fill="currentColor"
        />
        <path
          d="M17.0864 11.3635C17.4379 11.7149 17.4379 12.2848 17.0864 12.6363L13.5864 16.1362C13.235 16.4877 12.6651 16.4877 12.3137 16.1362C11.9622 15.7848 11.9622 15.2149 12.3137 14.8635L15.1773 11.9999L12.3137 9.13625C11.9622 8.78478 11.9622 8.21493 12.3137 7.86346C12.6651 7.51199 13.235 7.51199 13.5864 7.86346L17.0864 11.3635Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
export default ChevronDoubleRightIcon;
