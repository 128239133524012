import * as React from 'react';
import type { SVGProps } from 'react';
const ChatPrivateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/chat-private">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12.0001C21.6 16.6393 17.302 20.4001 12 20.4001C10.2101 20.4001 8.53451 19.9714 7.10015 19.2251L2.40002 20.4001L4.00599 16.6529C2.99139 15.3209 2.40002 13.7209 2.40002 12.0001C2.40002 7.36091 6.69809 3.6001 12 3.6001C17.302 3.6001 21.6 7.36091 21.6 12.0001ZM12 6.37512C10.3431 6.37512 9 7.71827 9 9.37512V11.0013C8.36258 11.0299 7.85455 11.5557 7.85455 12.2001V15.8001C7.85455 16.4629 8.39181 17.0001 9.05455 17.0001H15.0546C15.7173 17.0001 16.2546 16.4629 16.2546 15.8001V12.2001C16.2546 11.5374 15.7173 11.0001 15.0546 11.0001H15V9.37512C15 7.71827 13.6569 6.37512 12 6.37512ZM13.5 11.0001V9.37512C13.5 8.54669 12.8284 7.87512 12 7.87512C11.1716 7.87512 10.5 8.54669 10.5 9.37512V11.0001H13.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ChatPrivateIcon;
