import * as React from 'react';
import type { SVGProps } from 'react';
const CheckCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/check-circle">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.5999C17.302 21.5999 21.6 17.3018 21.6 11.9999C21.6 6.69797 17.302 2.3999 12 2.3999C6.69809 2.3999 2.40002 6.69797 2.40002 11.9999C2.40002 17.3018 6.69809 21.5999 12 21.5999ZM16.6279 9.82926C16.9202 9.42727 16.8314 8.86439 16.4294 8.57204C16.0274 8.27969 15.4645 8.36856 15.1722 8.77055L10.9917 14.5187L8.73642 12.2635C8.38495 11.912 7.8151 11.912 7.46363 12.2635C7.11216 12.615 7.11216 13.1848 7.46363 13.5363L10.4636 16.5363C10.6497 16.7224 10.908 16.8177 11.1704 16.7971C11.4328 16.7766 11.6731 16.6421 11.8279 16.4293L16.6279 9.82926Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default CheckCircleIcon;
